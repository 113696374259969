<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Stock Details</nz-page-header-title>
      <nz-page-header-subtitle>View your stock details here</nz-page-header-subtitle>
    </nz-page-header>
    <nz-divider></nz-divider>
    <nz-descriptions nzBordered>
      <nz-descriptions-item nzTitle="Supplier Name">{{NewStock.Invoice.SupplierName}}</nz-descriptions-item>
      <nz-descriptions-item nzTitle="Stock Date"> {{NewStock.StockDate | date: 'dd-MMM-yyyy'}}</nz-descriptions-item>
      <nz-descriptions-item nzTitle="Invoice Number">{{NewStock.Invoice.InvoiceNumber}}</nz-descriptions-item>
      <nz-descriptions-item nzTitle="Invoice Date">{{NewStock.Invoice.InvoiceDate | date:
        'dd-MMM-yyyy'}}</nz-descriptions-item>
      <nz-descriptions-item nzTitle="Total Price">
        {{NewStock.Invoice.InvoiceTotal}}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="View Invoice">
        <a class="btn btn-sm btn-light-info"
          *ngIf="this.NewStock.Invoice.InvoiceFile != null && this.NewStock.Invoice.InvoiceFile != ''"
          [href]="NewStock.Invoice.InvoiceFile" target="_blank">Link to Invoice</a>
        <span *ngIf="this.NewStock.Invoice.InvoiceFile == null">No invoice file found</span>
        <span *ngIf="this.NewStock.Invoice.InvoiceFile == ''">
          <p>This is a transfer stock detail or</p>
          <p> no invoice was uploaded</p>
        </span>
      </nz-descriptions-item>
    </nz-descriptions>

    <nz-descriptions *ngIf="this.NewStock.IsQualityInspectionCompleted" nzBordered nzTitle="Quality Inspection Details"
      [nzColumn]="2">
      <nz-descriptions-item
        nzTitle="Quality Inspection Completed By">{{NewStock.QualityInspectionCompletedBy?.Name}}</nz-descriptions-item>
      <nz-descriptions-item nzTitle="Quality Inspection Date"> {{NewStock.QualityInspectionCompletedDate |
        DatetimeConverter}}</nz-descriptions-item>

      <nz-descriptions-item nzTitle="View Reports and Images">
        <div *ngIf="hasInspectionFiles()">
          <strong>Inspection Files:</strong>
          <div class="mt-2">
            <ng-container *ngFor="let product of NewStock.StockProduct">
              <ng-container *ngIf="product.FileUploads && product.FileUploads.length > 0">
                <div class="product-files mb-2">
                  <div class="product-name text-muted">{{product.ProductName}}</div>
                  <div class="file-links">
                    <ng-container *ngFor="let file of product.FileUploads">
                      <a class="btn btn-sm btn-light me-2 mb-1" (click)="openFile(file)" target="_blank">
                        <i nz-icon [nzType]="getFileIcon(file.FileName)" nzTheme="outline"></i>
                        {{file.FileName}}
                      </a>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <div *ngIf="!hasInspectionFiles()" class="text-muted">
          No inspection files uploaded
        </div>
      </nz-descriptions-item>
    </nz-descriptions>

    <br><br>
    <nz-table [nzPageSize]="100" #basicTable [nzData]="this.NewStock.StockProduct">
      <thead>
        <tr>
          <th></th>
          <th>Product Type</th>
          <th>Product Name</th>
          <!-- <th>SKU</th> -->
          <th>Barcode</th>
          <!-- <th>Mfd Date</th>
          <th>Expiry</th> -->
          <!-- <th>Unit</th> -->
          <th>Quantity</th>
          <!-- <th>S&H/Unit</th>
          <th>Freight/Unit</th>
          <th>Misc/Unit</th>
          <th>Invoice Price/Unit</th> -->
          <th>Total Price/Unit</th>
          <!-- <th>Grade</th> -->
          <th>Accepted Qty</th>
          <th style="width: 8%;">Rejected Qty</th>
          <th>Comments</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <ng-template ngFor let-data [ngForOf]="basicTable.data">
          <tr>
            <td [(nzExpand)]="data.ExpandRecord"></td>
            <td>{{ data.ProductType }}</td>
            <td>{{ data.ProductName }}</td>
            <!-- <td>{{ data.Sku }}</td> -->
            <td>{{ data.Barcode }}</td>
            <!-- <td>{{ data.ManufacturedDate | date: 'dd-MMM-yyyy' }}</td>
            <td>{{ data.ExpiryDate | date: 'dd-MMM-yyyy' }}</td> -->
            <!-- <td>{{data.Unit}}</td> -->
            <td>{{data.Quantity}} {{data.Unit}}</td>
            <!-- <td>{{data.ShippingHandlingPerUnit}}</td>
            <td>{{data.FreightPerUnit}}</td>
            <td>{{data.MiscPerUnit}}</td>
            <td>{{data.InvoicePricePerUnit}}</td> -->
            <td>&#8377; {{data.PricePerUnit}}</td>
            <!-- <td>{{data.Grade}}</td> -->
            <td>{{data.AcceptedQuantity}} {{data.Unit}}</td>
            <td>{{data.RejectedQuantity == null ? 0 : data.RejectedQuantity}} {{data.Unit}}</td>
            <td>{{data.Comments}}</td>
            <td>
              <button nz-button nzType="primary" nzSize="small" nzShape="round" (click)="OpenMoreDetails(data)"><span
                  nz-icon nzType="eye"></span></button>
              <button nz-button nzType="primary" nzSize="small" nzShape="round" (click)="openPriceUpdate(data)" *ngIf="permission.Edit"><span
                  nz-icon nzType="edit"></span></button>
            </td>
          </tr>
          <tr [nzExpand]="data.ExpandRecord">
            <table>
              <tr>
                <td style="vertical-align: top;">

                  <nz-card style="width: 97%;" nzTitle="Allocation Details">
                    <p><nz-table #innerTable [nzData]="data.StockProductAllocation" nzSize="middle"
                        [nzShowPagination]="false">
                        <thead>
                          <tr>
                            <th style="background: aliceblue;">Accepted/Rejected</th>
                            <th style="background: aliceblue;">Quantity</th>
                            <th style="background: aliceblue;">Rack Allocated</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let da of innerTable.data">
                            <td>{{ da.InspectionType }}</td>
                            <td>{{ da.Quantity }} {{data.Unit}}</td>
                            <td>{{ da.RackName }}</td>
                          </tr>
                        </tbody>
                      </nz-table></p>
                  </nz-card>
                </td>
                <td style="vertical-align: top;">
                  <nz-card style="width: 97%;" nzTitle="Rejected Item Details">
                    <p><nz-table #innerTableri [nzData]="data.StockProductManageRejected" nzSize="middle"
                        [nzShowPagination]="false">
                        <thead>
                          <tr>
                            <th style="background: aliceblue;">Quantity</th>
                            <th style="background: aliceblue;">Action</th>
                            <th style="background: aliceblue;">Dispact Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let dat of innerTableri.data">
                            <td>{{ dat.Quantity }} {{data.Unit}}</td>
                            <td>{{ dat.ItemAction }}</td>
                            <td>
                              <span *ngIf="dat.ItemAction != 'Dispatch'">N/A</span>
                              <span *ngIf="dat.ItemAction == 'Dispatch' && dat.StockProductRejectedDispatch != null">
                                <b>Dispatch id : </b>{{dat.StockProductRejectedDispatch.DispatchId}} ; <b>Transport
                                  Company : </b>{{dat.StockProductRejectedDispatch.TransportCompany}}; <b>Vehicle No :
                                </b>{{dat.StockProductRejectedDispatch.VehicleNumber == null ? 'Others' :
                                dat.StockProductRejectedDispatch.VehicleNumber }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </nz-table></p>
                  </nz-card>

                </td>
              </tr>
            </table>





            <!-- <table nzSize="small" style="table-layout: auto; background: white; width: 35%;" ng-reflect-list-of-col-width=",,,20%">
          <thead class="ant-table-thead ng-star-inserted">
            <tr class="ant-table-row ng-star-inserted">
            <th  style="background: aliceblue;" class="ant-table-cell">Accepted/Rejected</th>
            <th  style="background: aliceblue;" class="ant-table-cell" >Quantity</th>
            <th  style="background: aliceblue;" class="ant-table-cell">Rack Allocated</th>
           </tr>
          </thead>
        <tbody class="ant-table-tbody ng-star-inserted">
                <tr *ngFor="let alloc of data.StockProductAllocation" class="ant-table-thead ng-star-inserted">
                    <td class="ant-table-cell">{{alloc.InspectionType}}</td>
                    <td class="ant-table-cell">{{alloc.Quantity}}</td>
                    <td class="ant-table-cell">{{alloc.RackAllocation}}</td>
                </tr>
          </tbody>
      </table> -->
          </tr>
        </ng-template>
      </tbody>
    </nz-table>

    <br>
    <div class="d-flex justify-content-end">
      <!--begin::Button-->
      <a routerLink="/home/stocklist" class="btn btn-success">Back to Stock List</a>
      <!--end::Button-->
    </div>
  </div>
</div>

<nz-modal [nzWidth]="700" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isPriceUpdateVisible" [nzTitle]="PriceUpdateHeader"
  [nzContent]="PriceUpdateContent" (nzOnCancel)="handlePriceUpdateCancel()" (nzOnOk)="UpdateStockProduct()"
  nzOkText="Update" nzCancelText="Cancel">
  <ng-template #PriceUpdateTitle>Update Price</ng-template>

  <ng-template #PriceUpdateHeader>
    <div>
      Update Price for {{SelectedProductDetails.ProductName}}
    </div>
  </ng-template>
  <ng-template #PriceUpdateContent>
    <div nz-row [nzGutter]="16">
      <!-- Invoice Price -->
      <div nz-col [nzSpan]="5">
        <div class="price-input-container">
          <label class="d-block mb-2">Invoice Price/Unit</label>
          <nz-input-group nzPrefix="&#8377;">
            <input nz-input type="number" class="w-100" nzSize="default" nzPlaceHolder="Enter price"
              [(ngModel)]="StockProductPriceUpdate.InvoicePricePerUnit" (ngModelChange)="calculateTotalPrice(StockProductPriceUpdate)">
          </nz-input-group>
        </div>
      </div>

      <!-- Shipping & Handling -->
      <div nz-col [nzSpan]="4">
        <div class="price-input-container">
          <label class="d-block mb-2">S&H/Unit</label>
          <nz-input-group nzPrefix="&#8377;">
            <input nz-input type="number" class="w-100" nzSize="default" nzPlaceHolder="Enter S&H"
              [(ngModel)]="StockProductPriceUpdate.ShippingHandlingPerUnit" (ngModelChange)="calculateTotalPrice(StockProductPriceUpdate)">
          </nz-input-group>
        </div>
      </div>

      <!-- Freight -->
      <div nz-col [nzSpan]="4">
        <div class="price-input-container">
          <label class="d-block mb-2">Freight/Unit</label>
          <nz-input-group nzPrefix="&#8377;">
            <input nz-input type="number" class="w-100" nzSize="default" nzPlaceHolder="Enter freight"
              [(ngModel)]="StockProductPriceUpdate.FreightPerUnit" (ngModelChange)="calculateTotalPrice(StockProductPriceUpdate)">
          </nz-input-group>
        </div>
      </div>

      <!-- Miscellaneous -->
      <div nz-col [nzSpan]="4">
        <div class="price-input-container">
          <label class="d-block mb-2">Misc/Unit</label>
          <nz-input-group nzPrefix="&#8377;">
            <input nz-input type="number" class="w-100" nzSize="default" nzPlaceHolder="Enter misc"
              [(ngModel)]="StockProductPriceUpdate.MiscPerUnit" (ngModelChange)="calculateTotalPrice(StockProductPriceUpdate)">
          </nz-input-group>
        </div>
      </div>

      <!-- Total Price -->
      <div nz-col [nzSpan]="4">
        <div class="price-input-container">
          <label class="d-block mb-2">Total Price/Unit</label>
          <div class="total-price-display">
            &#8377; {{StockProductPriceUpdate.PricePerUnit | number:'1.2-2'}}
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</nz-modal>

<nz-modal [nzWidth]="600" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isMoreDetailsVisible" [nzTitle]="MoreDetailsHeader"
  [nzContent]="MoreDetailsContent" [nzFooter]="MoreDetailsFooter" (nzOnCancel)="handleMoreDetailsCancel()">
  <ng-template #MoreDetailsTitle>More Details</ng-template>

  <ng-template #MoreDetailsHeader>
    <div>
      More Details
    </div>
  </ng-template>
  <ng-template #MoreDetailsContent>
    <nz-descriptions nzBordered [nzColumn]="2">
      <nz-descriptions-item nzTitle="Mfg Date">
        {{SelectedProductDetails.ManufacturedDate == null ? 'Not Provided' : (SelectedProductDetails.ManufacturedDate | date: 'dd-MMM-yyyy')}}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Expiry Date">
        {{SelectedProductDetails.ExpiryDate == null ? 'Not Provided' : (SelectedProductDetails.ExpiryDate | date: 'dd-MMM-yyyy')}}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Grade">
        {{SelectedProductDetails.Grade}}
      </nz-descriptions-item>
    </nz-descriptions>
    <nz-divider nzText="Price per Unit Details"></nz-divider>
    <nz-descriptions nzBordered [nzColumn]="2">
      <nz-descriptions-item nzTitle="Invoice Price/Unit">
        &#8377; {{SelectedProductDetails.InvoicePricePerUnit}}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="S&H/Unit">
        &#8377; {{SelectedProductDetails.ShippingHandlingPerUnit}}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Freight/Unit">
        &#8377; {{SelectedProductDetails.FreightPerUnit}}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Misc/Unit">
        &#8377; {{SelectedProductDetails.MiscPerUnit}}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Total Price/Unit">
        &#8377; {{SelectedProductDetails.PricePerUnit}}
      </nz-descriptions-item>
    </nz-descriptions>
  </ng-template>
  <ng-template #MoreDetailsFooter>
    <div class="d-flex justify-content-end">
      <!--begin::Button-->
      <button class="btn btn-success" (click)="handleMoreDetailsCancel()">Close</button>
      <!--end::Button-->
    </div>
  </ng-template>
</nz-modal>
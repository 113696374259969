import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SessionExceptionService {
    private ApiUrl = environment.Api_Url;

    constructor(private http: HttpClient) { }

    getExceptionList(): Observable<string[]> {
        return this.http.get<string[]>(`${this.ApiUrl}userrolesresponsibility/getalluserexceptionforcelogout`)
            .pipe(
                catchError(error => {
                    console.error('Error fetching session exceptions:', error);
                    return of([]); // Return empty array in case of error
                })
            );
    }
} 
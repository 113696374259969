<nz-layout class="app-layout">
  <nz-sider class="menu-sidebar sidebar-overflow" nzCollapsible nzWidth="256px" nzBreakpoint="md"
    [(nzCollapsed)]="isCollapsed" [nzTrigger]="null">
    <div class="sidebar-logo">
      <img src="assets/img/s-logo.png" alt="logo">
      &nbsp; <img src="assets/img/zaibunco.png" style="width: 60%;">
    </div>
    <div class="sidebar-logo">
      <img src="assets/img/avatarlogo.png" alt="logo"
        [ngClass]="[(isCollapsed) ? 'sidebar-logo_usericon' : 'sidebar-logo_userfull']">
      <h1>{{AzureUserName}}
      </h1>
    </div>
    <ul *ngIf="UserRoles" nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
        <li *ngIf="CheckModule('Dashboard')" nz-submenu [(nzOpen)]="openMap['sub1']" (nzOpenChange)="openHandler('sub1')" nzTitle="Dashboard"
            nzIcon="dashboard">
            <ul>
                <li nz-menu-item nzMatchRouter class="sideMenuHeading" [ngClass]="!isCollapsed ? 'd-none' : 'd-flex'">Dashboard</li>
                <li *ngIf="CheckResponsibility('Dashboard - Welcome','View')" nz-menu-item nzMatchRouter>
                    <a (click)="isCollapsed = true" routerLink="/home/welcome">Welcome</a>
                </li>

            </ul>
        </li>
        <li *ngIf="CheckModule('Reports')" nz-submenu nzTitle="Reports" nzIcon="bar-chart" [(nzOpen)]="openMap['sub21']"
            (nzOpenChange)="openHandler('sub21')">
            <ul>
                <li nz-menu-item nzMatchRouter class="sideMenuHeading" [ngClass]="!isCollapsed ? 'd-none' : 'd-flex'">Report</li>
                <li nz-submenu nzTitle="Sales Reports" nzIcon="line-chart" *ngIf="CheckSalesReportSubMenuPermission()" [(nzOpen)]="openReportsMap['sub1']"
                (nzOpenChange)="openReportsHandler('sub1')">
                    <ul>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Reports - Sales')">
                            <a (click)="isCollapsed = true" routerLink="/home/reports/salesreport">Sales Report</a>
                        </li>
                    </ul>
                </li>
                <li nz-submenu nzTitle="Materials Stock Reports" nzIcon="bar-chart" *ngIf="CheckStockReportSubMenuPermission()" [(nzOpen)]="openReportsMap['sub2']"
                (nzOpenChange)="openReportsHandler('sub2')">
                    <ul>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Reports - Stock Availability')">
                            <a (click)="isCollapsed = true" routerLink="/home/reports/productwisestockwithsupplier">Stock Availability</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Reports - Category Wise Stock')">
                          <a (click)="isCollapsed = true" routerLink="/home/reports/categorywisestock">Category Wise Stock</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Reports - Stock Consumption')">
                          <a (click)="isCollapsed = true" routerLink="/home/reports/stockconsumptionreport">Stock Consumption</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Reports - Stock')">
                            <a (click)="isCollapsed = true" routerLink="/home/stock"> Stock</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Reports - Store Wise Stock')">
                            <a (click)="isCollapsed = true" routerLink="/home/storewisestock">Store Wise Stock</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Reports - Product Wise Stock')">
                            <a (click)="isCollapsed = true" routerLink="/home/productwiseStock">Product Wise Stock</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Reports - Product Stock History')">
                            <a (click)="isCollapsed = true" routerLink="/home/reports/productstockhistory">Product Stock History</a>
                        </li>
                        
                    </ul>
                </li>
                <li nz-submenu nzTitle="Product Stock Reports" nzIcon="bar-chart" *ngIf="CheckProductStockReportSubMenuPermission()" [(nzOpen)]="openReportsMap['sub3']"
                (nzOpenChange)="openReportsHandler('sub3')">
                    <ul>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Reports - Product Stock Summary')">
                            <a (click)="isCollapsed = true" routerLink="/home/reports/productstocksummaryreport">Product Stock Summary</a>
                        </li>
                    </ul>
                </li>
                <li nz-submenu nzTitle="Purchase Reports" nzIcon="bar-chart" *ngIf="CheckPurchaseReportSubMenuPermission()" [(nzOpen)]="openReportsMap['sub4']"
                (nzOpenChange)="openReportsHandler('sub4')">
                    <ul>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Reports - Purchase')">
                            <a (click)="isCollapsed = true" routerLink="/home/reports/purchase">Purchase</a>
                        </li>
                    </ul>
                </li>
                <li nz-submenu nzTitle="Production Reports" nzIcon="bar-chart" *ngIf="CheckProductionReportSubMenuPermission()" [(nzOpen)]="openReportsMap['sub5']"
                (nzOpenChange)="openReportsHandler('sub5')">
                    <ul>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Reports - Production Planning')">
                            <a (click)="isCollapsed = true" routerLink="/home/reports/productionplanningreport">Production Planning</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Reports - Production Status')">
                            <a (click)="isCollapsed = true" routerLink="/home/reports/productionstatusreport">Production Status</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Reports - Manufacturing Heartbeat')">
                            <a (click)="isCollapsed = true" routerLink="/home/reports/productionheartbeat">Production Heartbeat</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Reports - Paste Consumption')">
                            <a (click)="isCollapsed = true" routerLink="/home/reports/pasteconsumptionreport">Paste Consumption</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Reports - Post Process')">
                            <a (click)="isCollapsed = true" routerLink="/home/reports/postprocessreport">Post Process Report</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Reports - Yield')">
                            <a (click)="isCollapsed = true" routerLink="/home/reports/yieldreport">Yield </a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Reports - Wastage')">
                            <a (click)="isCollapsed = true" routerLink="/home/reports/wastagereport">Wastage Report </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
        <li *ngIf="CheckModule('Sales')" nz-submenu nzTitle="Sales" nzIcon="fund" [(nzOpen)]="openMap['sub2']"
            (nzOpenChange)="openHandler('sub2')">
            <ul>
                <li nz-menu-item nzMatchRouter class="sideMenuHeading" [ngClass]="!isCollapsed ? 'd-none' : 'd-flex'">Sales</li>
                <li nz-submenu nzTitle="Sales Order" nzIcon="solution" *ngIf="CheckModule('Sales - Sales Order')">
                    <ul>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Sales - Sales Order','Add')">
                            <a (click)="isCollapsed = true" routerLink="/home/salesorder/add">Add</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Sales - Sales Order','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/salesorder/list">List</a>
                        </li>
                    </ul>
                </li>

                <li nz-submenu nzTitle="Proforma Invoice" nzIcon="file-text" *ngIf="CheckModule('Sales - Proforma')">
                    <ul>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Sales - Proforma','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/salesorder/proformaInvoice/list">List</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Sales - Proforma','Add')">
                            <a (click)="isCollapsed = true" routerLink="/home/salesorder/proformaInvoice/add">Add</a>
                        </li>

                    </ul>
                </li>
            </ul>
        </li>
        <li *ngIf="CheckModule('Demand')" nz-submenu nzTitle="Demand" nzIcon="alert">
            <ul>
                <li nz-menu-item nzMatchRouter class="sideMenuHeading" [ngClass]="!isCollapsed ? 'd-none' : 'd-flex'">Demand</li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Demand','View')">
                    <a (click)="isCollapsed = true" routerLink="/home/po/demandlist">List</a>
                </li>
            </ul>
        </li>
        <li *ngIf="CheckModule('Purchase Order')" nz-submenu nzTitle="Purchase Order" [(nzOpen)]="openMap['sub4']"
            (nzOpenChange)="openHandler('sub4')" nzIcon="shopping">
            <ul>
                <li nz-menu-item nzMatchRouter class="sideMenuHeading" [ngClass]="!isCollapsed ? 'd-none' : 'd-flex'">Purchase Order</li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Purchase Order','View')">
                    <a (click)="isCollapsed = true" routerLink="/home/po/list">List</a>
                </li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Purchase Order','Add')">
                    <a (click)="isCollapsed = true" routerLink="/home/po/add">Add</a>
                </li>

            </ul>
        </li>
        <li *ngIf="CheckModule('Production')" nz-submenu nzTitle="Production" nzIcon="build"
            [(nzOpen)]="openMap['sub3']" (nzOpenChange)="openHandler('sub3')">
            <ul>
                <li nz-menu-item nzMatchRouter class="sideMenuHeading" [ngClass]="!isCollapsed ? 'd-none' : 'd-flex'">Production</li>

                <li *ngIf="CheckModule('Production - Formulation Code')" nz-submenu nzTitle="Formulation Code"
                    nzIcon="calculator">
                    <ul>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Production - Formulation Code','Add')">
                            <a (click)="isCollapsed = true" routerLink="/home/production/formulationcode/add">Add</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Production - Formulation Code','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/production/formulationcode/list">List</a>
                        </li>
                    </ul>
                </li>

                <li *ngIf="CheckModule('Production - Pigment MB')" nz-submenu nzTitle="Pigment MB" nzIcon="bg-colors">
                    <ul>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Production - Pigment MB','Add')">
                            <a (click)="isCollapsed = true" routerLink="/home/production/pigmentmb/add">Add</a>
                        </li>
                    </ul>
                </li>

                <li *ngIf="CheckModule('Production - Mixing')" nz-submenu nzTitle="Mixing" nzIcon="experiment">
                    <ul>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Production - Mixing','Add')">
                            <a (click)="isCollapsed = true" routerLink="/home/production/mixing/add">Add</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Production - Mixing','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/production/mixing/list">List</a>
                        </li>


                    </ul>
                </li>

                <li *ngIf="CheckModule('Production - Workplan List')" nz-menu-item nzMatchRouter>
                    <i nz-icon nzType="calendar"></i>
                    <span><a (click)="isCollapsed = true" routerLink="/home/production/workplan">WorkPlan List</a></span>
                </li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Production - Start Production')">
                    <i nz-icon nzType="play-circle"></i>
                    <span><a (click)="isCollapsed = true" routerLink="/home/production/start">Start Production</a></span>
                </li>


                <li *ngIf="CheckModule('Production - Jumbo Master')" nz-submenu nzTitle="Jumbo Master" nzIcon="file">
                    <ul>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Production - Jumbo Master','Add')">
                            <a (click)="isCollapsed = true" routerLink="/home/production/jumbo/add">Jumbo Add </a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Production - Jumbo Master','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/production/jumbo/list">Jumbo List</a>
                        </li>


                    </ul>
                </li>
                <li *ngIf="CheckModule('Production - Post Process')" nz-submenu nzTitle="Post Process" nzIcon="deployment-unit">
                    <ul>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Production - Post Process','Add')">
                            <span><a (click)="isCollapsed = true" routerLink="/home/production/postprocess">Add</a></span>
                        </li>
                        <!-- <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Post Process - Lamination','View')">
                            <span><a (click)="isCollapsed = true" routerLink="/home/production/postprocess/lamination">Lamination Process</a></span>
                        </li> -->
                    </ul>
                </li>
                <li *ngIf="CheckModule('Production - Final Inspection')" nz-submenu nzTitle="Final Inspection" nzIcon="check-circle">
                    <ul>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Production - Final Inspection','Add')">
                            <a (click)="isCollapsed = true" routerLink="/home/production/finalInspection/add">Add</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Production - Final Inspection','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/production/finalInspection/list">List</a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="CheckModule('Production - Downtime')" nz-menu-item nzMatchRouter>
                    <i nz-icon nzType="arrow-down"></i>
                    <span><a (click)="isCollapsed = true" routerLink="/home/production/downtime">Downtime</a></span>
                </li>
                <li *ngIf="CheckModule('Production - ActivityLog')" nz-menu-item nzMatchRouter>
                    <i nz-icon nzType="unordered-list"></i>
                    <span><a (click)="isCollapsed = true" routerLink="/home/production/ActivityLog">Activity Log</a></span>
                </li>
                <!-- <li nz-menu-item *ngIf="CheckModule('Production - Dispatch')" nzMatchRouter>
      <i nz-icon nzType="project"></i>
      <span><a (click)="isCollapsed = true" routerLink="/home/production/dispatch">Dispatch</a></span>
    </li> -->


            </ul>
        </li>
        <li *ngIf="CheckModule('Dispatch')" nz-submenu nzTitle="Dispatch" [(nzOpen)]="openMap['sub22']"
            (nzOpenChange)="openHandler('sub22')" nzIcon="car">
            <ul>
                <li nz-menu-item nzMatchRouter class="sideMenuHeading" [ngClass]="!isCollapsed ? 'd-none' : 'd-flex'">Dispatch</li>
                <li nz-submenu nzTitle="Packaging" nzIcon="dropbox" *ngIf="CheckModule('Dispatch - Packaging')">
                    <ul>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Dispatch - Packaging','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/dispatch/packaging/list">List</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Dispatch - Packaging','Add')">
                            <a (click)="isCollapsed = true" routerLink="/home/dispatch/packaging/add">Add</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
        <li *ngIf="CheckModule('Issue')" nz-submenu nzTitle="Issue" [(nzOpen)]="openMap['sub7']"
            (nzOpenChange)="openHandler('sub7')" nzIcon="inbox">
            <ul>
                <li nz-menu-item nzMatchRouter class="sideMenuHeading" [ngClass]="!isCollapsed ? 'd-none' : 'd-flex'">Issue</li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Issue','View')">
                    <a (click)="isCollapsed = true" routerLink="/home/issue/list">List</a>
                </li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Issue','Add')">
                    <a (click)="isCollapsed = true" routerLink="/home/issue/add">Add</a>
                </li>

            </ul>
        </li>
        <li *ngIf="CheckModule('Consumption')" nz-submenu nzTitle="Consumption" [(nzOpen)]="openMap['sub15']"
            (nzOpenChange)="openHandler('sub15')" nzIcon="minus-circle">
            <ul>
                <li nz-menu-item nzMatchRouter class="sideMenuHeading" [ngClass]="!isCollapsed ? 'd-none' : 'd-flex'">Consumption</li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Consumption','View')">
                    <a (click)="isCollapsed = true" routerLink="/home/consumption/list">List</a>
                </li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Consumption','View')">
                    <a (click)="isCollapsed = true" routerLink="/home/consumption/pendingorders">Pending Orders List</a>
                </li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Consumption','Add')">
                    <a (click)="isCollapsed = true" routerLink="/home/consumption/add">Add</a>
                </li>
            </ul>
        </li>
        <li *ngIf="CheckModule('Costing')" nz-submenu nzTitle="Costing" [(nzOpen)]="openMap['sub8']"
            (nzOpenChange)="openHandler('sub8')" nzIcon="dollar">
            <ul>
                <li nz-menu-item nzMatchRouter class="sideMenuHeading" [ngClass]="!isCollapsed ? 'd-none' : 'd-flex'">Costing</li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Costing','View')">
                    <a (click)="isCollapsed = true" routerLink="/home/costing/list">List</a>
                </li>
                <!-- <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Costing','Edit')">
                    <a (click)="isCollapsed = true" routerLink="/home/costing/add">Add</a>
                </li> -->
                    <li nz-submenu nzTitle="Estimation" nzIcon="calculator" *ngIf="CheckResponsibility('Costing - Estimation','View')">
                        <ul>
                            <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Costing - Estimation','Add')">
                                <a (click)="isCollapsed = true" routerLink="/home/costing/estimation/add">Add</a>
                            </li>
                            <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Costing - Estimation','View')">
                                <a (click)="isCollapsed = true" routerLink="/home/costing/estimation/list">List</a>
                            </li>
                        </ul>
                    </li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Costing - Overhead','View')">
                    <a (click)="isCollapsed = true" routerLink="/home/costing/overhead">Overhead</a>
                </li>
            </ul>
        </li>
        <li *ngIf="CheckModule('Inventory')" nz-submenu nzTitle="Inventory" nzIcon="database" [(nzOpen)]="openMap['sub6']"
            (nzOpenChange)="openHandler('sub6')">
            <ul>
                <li nz-menu-item nzMatchRouter class="sideMenuHeading" [ngClass]="!isCollapsed ? 'd-none' : 'd-flex'">Inventory</li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Inventory - Opening Stock')">
                    <a (click)="isCollapsed = true" routerLink="/home/openingstock">Opening Stock</a>
                </li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Inventory - Add Stock')">
                    <a (click)="isCollapsed = true" routerLink="/home/addstock">Add Stock</a>
                </li>
                  <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Inventory - Knitting Division Stock')">
                      <a (click)="isCollapsed = true" routerLink="/home/knittingdivisionstocklist">Knitting Division Stock List</a>
                  </li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Inventory - Stock List')">
                    <a (click)="isCollapsed = true" routerLink="/home/stocklist">Stock Listing</a>
                </li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Inventory - Stock Label List')">
                    <a (click)="isCollapsed = true" routerLink="/home/stocklabel/list">Stock Label Listing</a>
                </li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Inventory - Stock Quality Inspection')">
                    <a (click)="isCollapsed = true" routerLink="/home/stockqualityinspectionlist">Stock Quality Inspection</a>
                </li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Inventory - Inspection')">
                    <a (click)="isCollapsed = true" routerLink="/home/stockinspectionlist">Stock Inspection</a>
                </li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Inventory - Allocation')">
                    <a (click)="isCollapsed = true" routerLink="/home/stockallocationlist">Stock Allocation</a>
                </li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Inventory - Rejected')">
                    <a (click)="isCollapsed = true" routerLink="/home/stockmanagerejectedlist">Stock Rejected Items</a>
                </li>
            </ul>
        </li>
        <li *ngIf="CheckModule('Gate')" nz-submenu nzTitle="Gate In/Out" nzIcon="swap" [(nzOpen)]="openMap['sub5']"
            (nzOpenChange)="openHandler('sub5')">
            <ul>
                <li nz-menu-item nzMatchRouter class="sideMenuHeading" [ngClass]="!isCollapsed ? 'd-none' : 'd-flex'">Gate In/Out</li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Gate In')">
                    <a (click)="isCollapsed = true" routerLink="/home/gatein">Gate In</a>
                </li>

                <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Gate Out')">
                    <a (click)="isCollapsed = true" routerLink="/home/gateout">Gate Out</a>
                </li>
            </ul>
        </li>
        <li *ngIf="CheckModule('Issue Gate Pass')" nzTitle="Issue Gate Pass" nz-submenu nzMatchRouter nzIcon="export">
            <ul>
                <li nz-menu-item nzMatchRouter class="sideMenuHeading" [ngClass]="!isCollapsed ? 'd-none' : 'd-flex'">Issue Gate Pass</li>
                <li nz-menu-item nzMatchRouter nzTitle="Issue Gate Pass" nzIcon="export">
                    <a (click)="isCollapsed = true" routerLink="/home/gatepass">Issue Gate Pass</a>
                </li>
                </ul>

            <!-- <i nz-icon nzType="appstore" nzTitle="Gate In/Out"></i>
            <span><a (click)="isCollapsed = true" routerLink="/home/gatepass">Issue Gate Pass</a></span>
            </ul> -->
            <!-- <span>Issue Gate Pass</span> -->
        </li>
        <li *ngIf="CheckModule('Out Pass')" nz-submenu nzTitle="Out pass" nzIcon="poweroff">
            <ul><li nz-menu-item nzMatchRouter class="sideMenuHeading" [ngClass]="!isCollapsed ? 'd-none' : 'd-flex'">Out pass</li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Out Pass','Add')">
                    <a (click)="isCollapsed = true" routerLink="/home/outpass/add">Add</a>
                </li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Out Pass','View')">
                    <a (click)="isCollapsed = true" routerLink="/home/outpass/list">List</a>
                </li>
            </ul>
        </li>
          <li *ngIf="CheckModule('QuickTools')" nz-submenu nzTitle="Quick Tools" nzIcon="tool">
              <ul><li nz-menu-item nzMatchRouter class="sideMenuHeading" [ngClass]="!isCollapsed ? 'd-none' : 'd-flex'">Quick Tools</li>
                  <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('QuickTools - Measurement Conversion','View')">
                      <a (click)="isCollapsed = true" routerLink="/home/quicktools/measurementconversion-new">Measurement Conversion</a>
                  </li>
              </ul>
          </li>
          <li *ngIf="CheckModule('IoT Devices')" nz-submenu nzTitle="IoT Devices" nzIcon="cluster">
              <ul><li nz-menu-item nzMatchRouter class="sideMenuHeading" [ngClass]="!isCollapsed ? 'd-none' : 'd-flex'">IoT Devices</li>
                  <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('IoT Devices - Digital Weight Machine','View')">
                      <a (click)="isCollapsed = true" routerLink="/home/digitalWeightMachine">Digital Weight Machine</a>
                  </li>
              </ul>
          </li>
        <li *ngIf="CheckModule('Post Process')" nz-submenu nzTitle="Post Process" nzIcon="deployment-unit">
            <ul>
                <li nz-menu-item nzMatchRouter class="sideMenuHeading" [ngClass]="!isCollapsed ? 'd-none' : 'd-flex'">Post Process</li>
                <li *ngIf="CheckModule('Post Process - Print')" nz-menu-item>
                    <a (click)="isCollapsed = true" routerLink="/home/production/postprocessprint">Print</a>
                </li>
                <li *ngIf="CheckModule('Post Process - Embossing')" nz-menu-item>
                    <a (click)="isCollapsed = true" routerLink="/home/production/embossinglist">Embossing</a>
                </li>
                <li *ngIf="CheckModule('Post Process - Vaccum')" nz-menu-item>
                    <a (click)="isCollapsed = true" routerLink="/home/production/vaccumlist">Vaccum</a>
                </li>
                <li *ngIf="CheckModule('Post Process - Tumbling')" nz-menu-item>
                    <a (click)="isCollapsed = true" routerLink="/home/production/tumblinglist">Tumbling</a>
                </li>
                <li *ngIf="CheckModule('Post Process - Lacquer')" nz-menu-item>
                    <a (click)="isCollapsed = true" routerLink="/home/production/lacquerlist">Lacquer</a>
                </li>
            </ul>
        </li>
        <li *ngIf="CheckModule('Transport')" nz-submenu nzTitle="Transport" nzIcon="rocket" [(nzOpen)]="openMap['sub9']"
            (nzOpenChange)="openHandler('sub9')">
            <ul>
                <li nz-menu-item nzMatchRouter class="sideMenuHeading" [ngClass]="!isCollapsed ? 'd-none' : 'd-flex'">Transport</li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Transport','View')">
                    <a (click)="isCollapsed = true" routerLink="/home/transportlist">Transport List</a>
                </li>

            </ul>
        </li>
        <li *ngIf="CheckModule('Supplier')" nz-submenu nzTitle="Supplier" nzIcon="shop" [(nzOpen)]="openMap['sub10']"
            (nzOpenChange)="openHandler('sub10')">
            <ul>
                <li nz-menu-item nzMatchRouter class="sideMenuHeading" [ngClass]="!isCollapsed ? 'd-none' : 'd-flex'">Supplier</li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Supplier','View')">
                    <a (click)="isCollapsed = true" routerLink="/home/supplierlist">Supplier List</a>
                </li>

            </ul>
        </li>
        <li *ngIf="CheckModule('Customer')" nz-submenu nzTitle="Customer" nzIcon="user" [(nzOpen)]="openMap['sub11']"
            (nzOpenChange)="openHandler('sub11')">
            <ul>
                <li nz-menu-item nzMatchRouter class="sideMenuHeading" [ngClass]="!isCollapsed ? 'd-none' : 'd-flex'">Customer List</li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Customer','View')">
                    <a (click)="isCollapsed = true" routerLink="/home/customerlist">Customer List</a>
                </li>

            </ul>
        </li>
        <li *ngIf="CheckModule('Product')" nz-submenu nzTitle="Product" [(nzOpen)]="openMap['sub12']"
            (nzOpenChange)="openHandler('sub12')" nzIcon="tags">
            <ul>
                <li nz-menu-item nzMatchRouter class="sideMenuHeading" [ngClass]="!isCollapsed ? 'd-none' : 'd-flex'">Product List</li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Product','View')">
                    <a (click)="isCollapsed = true" routerLink="/home/productlist">Product List</a>
                </li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Product - Category')">
                    <a (click)="isCollapsed = true" routerLink="/home/productcategorylist">Category</a>
                </li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Product - Sub Category')">
                    <a (click)="isCollapsed = true" routerLink="/home/productfirstsubcategorylist">Sub Category</a>
                </li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckModule('Product - 2nd SubCategory')">
                        <a (click)="isCollapsed = true" routerLink="/home/productsecsubcategorylist">2nd Sub
                            Category</a>
                </li>
            </ul>
         </li>
        <li *ngIf="CheckModule('Master')" nz-submenu [(nzOpen)]="openMap['sub13']" (nzOpenChange)="openHandler('sub13')"
            nzTitle="Master" nzIcon="apartment">
            <ul>
                <li nz-menu-item nzMatchRouter class="sideMenuHeading" [ngClass]="!isCollapsed ? 'd-none' : 'd-flex'">Master</li>
                <li nz-submenu nzTitle="General" nzIcon="setting" *ngIf="CheckModule('Master')">
                    <ul>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Master - Branch','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/branchlist">Branch</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Master - BankDetails','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/BankDetailslist">Bank Details</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Master - Department','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/departmentlist">Department</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Master - Tag','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/taglist">Tag</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Master - Store','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/storelist">Stores</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Master - Rack','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/racklist">Rack</a>
                        </li>                        
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Master - Unit','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/measurementunit">Measurement Unit</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Master - PaymentTerm','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/paymenttermlist">Payment Term</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Master - Delivery Term','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/deliverytermlist">Delivery Term</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Master - Conversion','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/measurementconversion">Measurement Conversion</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Master - Out Pass Purpose','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/outpasspurpose">Out Pass Purpose</a>
                        </li>
                    </ul>
                </li>
                <li nz-submenu nzTitle="Production" nzIcon="build">
                    <ul>
                        <li nz-menu-item nzMatchRouter class="sideMenuHeading" [ngClass]="!isCollapsed ? 'd-none' : 'd-flex'">Production Masters</li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Production Master - Downtime Reason','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/productionmaster/downtimereason">Downtime Reason</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Master - Designation','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/designation">Employee Designation</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Master - Factory Workers','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/factoryworkers">Factory Employee</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Master - Packaging Type','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/packagingtype">Packaging Type</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Master - Color','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/colorlist">Color</a>
                        </li>
                        <!-- <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Master - Element','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/elementlist">Element</a>
                        </li> -->
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Master - Grain','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/grainlist">Grain</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Master - Thickness','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/thicknesslist">Thickness</a>
                        </li>
                        <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Master - Width','View')">
                            <a (click)="isCollapsed = true" routerLink="/home/widthlist">Width</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
        
        <!-- Notification -->
        <li *ngIf="CheckModule('Notification')" nz-submenu nzTitle="Notification" [(nzOpen)]="openMap['sub23']"
        (nzOpenChange)="openHandler('sub23')" nzIcon="notification">
            <ul>
                <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Notification - Production Stages','View')">
                    <a routerLink="/home/notificationlist">Production Stages List</a>
                </li>
                 <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Notification - Notification Group','View')">
                    <a routerLink="/home/notificationGrouplist">Notification Group List</a>
                </li>
                 <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Notification - WhatsApp Template','View')">
                    <a routerLink="/home/whatsAppTemplateListComponent">WhatsApp Template List</a>
                </li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Notification - WhatsApp Configuration','View')">
                    <a routerLink="/home/WhatsappConfigList">Whatsapp Config List</a>
                </li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Notification - Email Group Mapping','View')">
                    <a routerLink="/home/EmailGroupMappingList">Email Group Mapping</a>
                </li>
            </ul>
        </li>
        <li *ngIf="CheckModule('Admin')" nz-submenu nzTitle="Admin" nzIcon="control" [(nzOpen)]="openMap['sub14']"
            (nzOpenChange)="openHandler('sub14')">
            <ul>
                    <li nz-menu-item nzMatchRouter class="sideMenuHeading"
                        [ngClass]="!isCollapsed ? 'd-none' : 'd-flex'">Admin</li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Admin - Users','Manage')">
                    <a (click)="isCollapsed = true" routerLink="/home/admin/users">Manage Users</a>
                </li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Admin - Operations','Manage')">
                    <a (click)="isCollapsed = true" routerLink="/home/admin/operations">Manage Operations</a>
                </li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Admin - Roles','Manage')">
                    <a (click)="isCollapsed = true" routerLink="/home/admin/rolemaster">Manage Roles</a>
                </li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Admin - Users Login History','View')">
                        <a (click)="isCollapsed = true" routerLink="/home/admin/usersloginhistory">Users Login
                            History</a>
                </li>
                <li nz-menu-item nzMatchRouter *ngIf="CheckResponsibility('Admin - General Configuration','View')">
                    <a (click)="isCollapsed = true" routerLink="/home/admin/generalconfig">General Configuration</a>
                </li>


            </ul>
        </li>
        <li nz-submenu nzTitle="OLD" style="display:none">
            <ul>
                    <li nz-menu-item nzMatchRouter class="sideMenuHeading"
                        [ngClass]="!isCollapsed ? 'd-none' : 'd-flex'">OLD</li>
                <li *ngIf="UserRoles.Admin || UserRoles.ProductionModule" nz-submenu nzTitle="Production"
                    [(nzOpen)]="openMap['sub17']" (nzOpenChange)="openHandler('sub11')" nzIcon="project">
                    <ul>
                        <li nz-menu-item nzMatchRouter>
                            <a (click)="isCollapsed = true" routerLink="/home/newproduction/add">Add</a>
                        </li> 
                    </ul>
                </li>
                <li *ngIf="UserRoles.Admin || UserRoles.ProductionModule" nz-submenu nzTitle="Mixing"
                    [(nzOpen)]="openMap['sub18']" (nzOpenChange)="openHandler('sub16')" nzIcon="project">
                    <ul>
                        <li nz-menu-item nzMatchRouter>
                            <a (click)="isCollapsed = true" routerLink="/home/newmixing/add">Add</a>
                        </li>

                    </ul>
                </li>
                <li nz-menu-item nzMatchRouter>
                    <a (click)="isCollapsed = true" routerLink="/home/orderlist">Order List</a>
                </li>
                <li nz-menu-item nzMatchRouter>
                    <a (click)="isCollapsed = true" routerLink="/home/salesorder"> Old Sales Order</a>
                </li>
                <li *ngIf="UserRoles.Admin || UserRoles.ProductionModule" nz-submenu nzTitle="Process"
                    [(nzOpen)]="openMap['sub15']" (nzOpenChange)="openHandler('sub15')" nzIcon="project">
                    <ul>
                        <li nz-menu-item nzMatchRouter>
                            <a (click)="isCollapsed = true" routerLink="/home/process/list">List</a>
                        </li>
                        <li nz-menu-item nzMatchRouter>
                            <a (click)="isCollapsed = true" routerLink="/home/process/add">Add</a>
                        </li>

                    </ul>
                </li>
                <li nz-menu-item nzMatchRouter>
                    <a (click)="isCollapsed = true" routerLink="/home/precosting">Pre Costing</a>
                </li>
                <li nz-menu-item nzMatchRouter>
                    <a (click)="isCollapsed = true" routerLink="/home/formulationcode">Old Formulation Code</a>
                </li>
                <li *ngIf="UserRoles.Admin || UserRoles.ProductionModule" nz-submenu nzTitle=" Old Production"
                    nzIcon="project">
                    <ul>
                        <li nz-menu-item nzMatchRouter>
                            <a (click)="isCollapsed = true" routerLink="/home/production/list">List</a>
                        </li>
                        <li nz-menu-item nzMatchRouter>
                            <a (click)="isCollapsed = true" routerLink="/home/production/add">Add</a>
                        </li>


                    </ul>
                </li>
            </ul>
        </li>

    </ul>
  </nz-sider>
  <nz-layout style="margin-left: 500">
    <nz-header>
      <div class="app-header">
        <span class="header-trigger" [ngClass]="[(isCollapsed) ? 'noncollapse-trigger' : 'collapse-trigger']"
          (click)="isCollapsed = !isCollapsed">
                    <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
                        style="font-size: larger;"></i>
        </span>
        <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="MoreOptionsMenu" *ngIf="CheckModule('Top Menu More Options')">
            <nz-avatar nz-dropdown nzTrigger="click" [nzDropdownMenu]="MoreOptionsMenu" [nzSize]="32" nzIcon="more"
              style="line-height: 25px;"></nz-avatar>
        </a>
        <nz-dropdown-menu #MoreOptionsMenu="nzDropdownMenu">
            <ul nz-menu nzSelectable>
              <li nz-menu-item *ngIf="CheckResponsibility('Top Menu More Options - Barcode Scanner','Add')">
                <a (click)="openBarcodeScanner()" style="display: flex; align-items: center;">
                  <i nz-icon nzType="mobile" nzTheme="outline" style="margin-right: 10px;"></i> Scan Barcode
                </a>
              </li>
              <li nz-menu-item *ngIf="CheckResponsibility('Top Menu More Options - External Scanner','Add')">
                <a (click)="openExternalDeviceScanner()" style="display: flex; align-items: center;">
                  <i nz-icon nzType="scan" nzTheme="outline" style="margin-right: 10px;"></i> External Scanner
                </a>
              </li>
              <li nz-menu-item *ngIf="CheckResponsibility('Top Menu More Options - Barcode Manual Entry','Add')">
                <a (click)="openManualInput()" style="display: flex; align-items: center;">
                  <i nz-icon nzType="key" nzTheme="outline" style="margin-right: 10px;"></i> Manual Entry
                </a>
              </li>
            </ul>
          </nz-dropdown-menu>
        <div class="logout">
          {{ this.innerWidth>768 ? UserID : ''}}
          <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">

            <nz-avatar nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" [nzSize]="32" nzIcon="user"
              style="line-height: 25px;"></nz-avatar>
          </a>
          <span
                        style="position: absolute;right: 42%;text-transform: uppercase;font-size: 20px;color: #001529;font-weight: 600;"
                        *ngIf="this.innerWidth>1000">
            {{DivisionName}}
          </span>

          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu nzSelectable>
                            <li nz-menu-item><a href="/welcome"> <i nz-icon nzType="swap" nzTheme="outline"></i> Switch
                                    Division</a>
              </li>
                            <li nz-menu-item><a href="https://portal.microsoftonline.com/ChangePassword.aspx"> <i
                                        nz-icon nzType="swap" nzTheme="outline"></i> Change Password</a> </li>
              <li nz-menu-item>
                <a (click)="logout()">
                  <i nz-icon nzType="logout" nzTheme="outline"></i> Logout
                </a>
              </li>

            </ul>
          </nz-dropdown-menu>
          <span></span>

        </div>
      </div>
    </nz-header>
    <nz-content [ngClass]="[(isCollapsed) ? 'noncollapsed-content' : 'collapsed-content']">
      <div>
        <router-outlet *ngIf="IsMenuLoaded && !isIframe"></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
<app-loader></app-loader>
<app-BCScannerView></app-BCScannerView>
<app-barcode-label-update-modal></app-barcode-label-update-modal>
<app-prd-downtime></app-prd-downtime>
<!-- <app-auth *ngIf="!this.isUserLoggedIn"></app-auth> -->
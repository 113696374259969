<nz-modal [nzWidth]="600" [nzVisible]="IsPrdDowntimeVisible" [nzTitle]="modalTitle" [nzContent]="modalContent"
  [nzFooter]="modalFooter" nzCentered="true" (nzOnCancel)="handleCancel()">
  <ng-template #modalTitle>Add Production Downtime for {{SelectedProductionLineType}} Line</ng-template>

  <ng-template #modalContent>
    <div class="downtime-form">
      <div class="mb-4">
        <label class="required form-label">Downtime Reason</label>
        <nz-select class="form-select w-100" nzSize="large" [(ngModel)]="NewDowntime.ProductionDowntimeReasonId"
          nzAllowClear nzShowSearch nzPlaceHolder="Choose">
          <nz-option *ngFor="let s of ProductionDowntimeReasonList" [nzValue]="s.ProductionDowntimeReasonId"
            [nzLabel]="s.ReasonName + ' (' + s.ReasonCode + ')'">
          </nz-option>
        </nz-select>
      </div>

      <div class="mb-4" *ngIf="ShowProductionLineType">
        <label class="required form-label">Production Line Type</label>
        <nz-select class="form-select w-100" nzSize="large" [(ngModel)]="NewDowntime.ProductionLineType" nzAllowClear
          nzPlaceHolder="Choose">
          <nz-option *ngFor="let s of this.ProductionLineTypeList;" [nzValue]="s.name" [nzLabel]="s.name">
          </nz-option>
        </nz-select>
      </div>

      <div class="mb-4">
        <label class="required form-label">Production Line No.</label>
        <nz-select class="form-select w-100" nzSize="large" [(ngModel)]="NewDowntime.ProductionLineNo" nzAllowClear
          nzPlaceHolder="Choose">
          <nz-option *ngFor="let s of this.ProductionLineNoList;" [nzValue]="s.value" [nzLabel]="s.name">
          </nz-option>
        </nz-select>
      </div>

      <div class="time-selection-container mb-4">
        <div class="time-inputs">
          <div class="time-input mb-2">
            <label class="required">Start Date and Time</label>
            <nz-date-picker class="w-100" [nzDisabledDate]="disabledStartDate" nzShowTime
              nzFormat="dd-MM-yyyy hh:mm:ss a" [(ngModel)]="NewDowntime.StartTime" nzPlaceHolder="Start"
              (ngModelChange)="calculateDuration()" (nzOnOpenChange)="handleStartOpenChange($event)">
            </nz-date-picker>
          </div>

          <div class="duration-indicator">
            <i nz-icon nzType="arrow-down" nzTheme="outline"></i>
            <div class="duration-box" *ngIf="duration">
              Duration: {{duration}}
            </div>
          </div>

          <div class="time-input">
            <label class="required">End Date and Time</label>
            <nz-date-picker class="w-100" #endDatePicker [nzDisabledDate]="disabledEndDate" nzShowTime
              nzFormat="dd-MM-yyyy hh:mm:ss a" [(ngModel)]="NewDowntime.EndTime" (ngModelChange)="calculateDuration()"
              nzPlaceHolder="End">
            </nz-date-picker>
          </div>
        </div>
      </div>

      <div class="mb-4">
        <label class="required">Comment</label>
        <nz-textarea-count [nzMaxCharacterCount]="500">
          <textarea class="form-control" nz-input rows="4" [(ngModel)]="NewDowntime.Comments" nzPlaceHolder="Comment">
          </textarea>
        </nz-textarea-count>
      </div>
    </div>
  </ng-template>
  <ng-template #modalFooter>
    <button nz-button nzType="primary" (click)="Save()">Save</button>
    <button nz-button nzType="primary" (click)="handleCancel()">Cancel</button>
  </ng-template>
</nz-modal>
import { HttpClient, HttpStatusCode } from "@angular/common/http";
import { Component } from "@angular/core";
import { environment } from "../../../environments/environment";
import { PurchaseOrderModel, PurchaseOrderProductModel } from "../../Models/PurchaseOrderModel";
import { PoDrawerService } from "./services/PoDrawerService";
import { PoTimelineService } from "../../Services/PoTimeLineService";
import { LoadingService } from "../../Services/loadingService";
import { CurrentStockModel } from "src/PmsUIApp/Models/StockModel";
import { AlertMessageService } from "src/PmsUIApp/Services/AlertMessageService";
import { PoEventService } from "./services/PoEventService";
import { NzModalService } from "ng-zorro-antd/modal";
import { Modules, Responsibility } from "src/PmsUIApp/Models/Enums";
import { AuthService } from "src/PmsUIApp/Services/auth.service";

@Component({
  selector: 'app-PoDrawer',
  templateUrl: './PoDrawerView.component.html',
  styleUrls: ['./PoDrawerView.component.css']
})

export class PoDrawerViewComponent {
  ApiUrl = environment.Api_Url;
  IsPopupOpen = this.loader.loading$;
  IsDisabled: boolean = false;
  isTableLoading: boolean = false;
  count: 0;
  permission = {
    View: false,
    Add: false,
    Delete: false,
    Manage: false,
    Approval: false,
    HighValueApproval: false,
    Email: false
  }
  Poid: any;
  PurchaseOrder: PurchaseOrderModel | undefined;
  isPurchaseOrderLoaded = false;
  refreshBtnLoading: boolean;
  IsTimelineOpen = false;
  ShowMoreColumns = false;
  ShowCurrentStock = false;
  fromPage: string;
  CurrentStock: CurrentStockModel[] = [];
  poApprovalCheckCount: number = 0;
  poApprovalCheck: boolean = false;
  isDownloading: boolean = false;
  isEmailing: boolean = false;

  constructor(
    private loader: PoDrawerService,
    public http: HttpClient,
    private timeLine: PoTimelineService,
    private loadsrv: LoadingService,
    private alertService: AlertMessageService,
    private poEventService: PoEventService,
    private modalService: NzModalService,
    private authService: AuthService
  ) {
    this.loader.event_callback.subscribe(x => {
      this.showDrawerData(x.Poid, x.fromPage);
    })
  }
  ngOnInit() {
    this.permission.Approval = this.authService.CheckResponsibility(Modules.PurchaseOrder, Responsibility.Approval);
    this.permission.Email = this.authService.CheckResponsibility(Modules.PurchaseOrder, Responsibility.Email);
    this.permission.HighValueApproval = this.authService.CheckResponsibility(Modules.PurchaseOrder, Responsibility.HighValueApproval);
  }

  handleTimelineCancel(): void {
    this.isPurchaseOrderLoaded = false;
    this.ShowCurrentStock = false;
    this.ShowMoreColumns = false;
    this.CurrentStock = [];
  }
  showDrawerData(Poid: string, fromPage: string) {
    this.isTableLoading = true;
    this.loadsrv.show();
    this.Poid = Poid;
    this.fromPage = fromPage;
    let url = this.ApiUrl + "purchaseorder/getpurchaseorderbyid/" + Poid;
    this.http.get<any>(url).subscribe({
      next: res => {
        this.PurchaseOrder = res;
        this.isPurchaseOrderLoaded = true; // Set the flag to true after PurchaseOrder is loaded
        this.isTableLoading = false;
        this.isApprovalVisible();
        this.loadsrv.hide();
      },
      error: res => {
        this.isTableLoading = false;
        this.loadsrv.hide();
      }
    });
  }
  OpenTimelinePop(data: any): void {
    this.IsTimelineOpen = false;
    this.timeLine.Poid = data.Poid;
    this.timeLine.show();

    // this.TimeLineObject = data;
    // this.GetAllActivityLog(data.Poid);
  }
  CalculatePendingQTY(data: PurchaseOrderProductModel) {
    var RemainingQTY = data.Quantity - data.RecievedQuantity;
    if (RemainingQTY >= 0) {
      return RemainingQTY
    }
    return 0
  }
  CalculateExtraQTY(data: PurchaseOrderProductModel) {
    var RemainingQTY = data.Quantity - data.RecievedQuantity;
    if (RemainingQTY < 0) {
      return '+' + (data.RecievedQuantity - data.Quantity).toFixed(2)
    }
    return 0;
  }
  CalculateGrandTotal(data: PurchaseOrderProductModel[], column: string) {
    var total = 0;
    switch (column) {
      case column = "OrderQuantity": {
        total = data.reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0) ?? 0
        break;
      }

      case column = "ReceivedQuantity": {
        total = data.reduce((sum, current) => sum + parseFloat(current.RecievedQuantity?.toString()), 0) ?? 0
        break;
      }

      case column = "PendingQuantity": {
        total = data.reduce((sum, current) => sum + parseFloat((this.CalculatePendingQTY(current))?.toString()), 0) ?? 0
        break;
      }

      case column = "ExtraQuantity": {
        total = data.reduce((sum, current) => sum + parseFloat(this.CalculateExtraQTY(current)?.toString()), 0) ?? 0
        break;
      }
    }
    return parseFloat(total.toFixed(2));
  }
  GetCurrentStock(event: boolean) {
    if (event) {
      let productIds = this.PurchaseOrder?.PurchaseOrderProduct.map(x => x.ProductId);
      let request = {
        ProductIds: productIds,
        ProductQuality: this.PurchaseOrder?.POType == 'IMPORT' ? 'IMPORTED' : 'DOMESTIC'
      }
      let url = this.ApiUrl + "stock/getproductavailablestockbyproductids/";
      this.http.post<any>(url, request).subscribe({
        next: (res) => {
          this.CurrentStock = res;
        },
        error: (res) => {
        }
      });
    } else {
      this.CurrentStock = [];
    }
  }
  getCurrentStock(productId: number) {
    return this.CurrentStock.find(x => x.ProductId == productId);
  }
  handlePOApproval() {
    this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to approve this PO?',
      nzOkText: 'Approve',
      nzCancelText: 'Cancel',
      nzOnOk: () => this.savePOApproval()
    });
  }
  savePOApproval() {
    this.loadsrv.show();
    let request = {
      Poid: this.Poid,
      Status: 'Approved'
    }
    let url = this.ApiUrl + "purchaseorder/purchaseordereventactions";
    this.http.post<any[]>(url, request).subscribe({
      next: res => {
        this.handleTimelineCancel();
        this.alertService.success(res)
        this.poEventService.triggerRefresh();
        this.loadsrv.hide();
      },
      error: res => {
        if (res.error.StatusCode == HttpStatusCode.BadRequest) {
          this.alertService.warning(res.error);
        }
        else {
          this.alertService.error(res.error);
        }
        this.loadsrv.hide();
      },
    });
  }
  isApprovalVisible() {
    var TotalValue = parseFloat(this.PurchaseOrder?.PototalAmount);
    var POHighValueLimit = parseFloat(this.PurchaseOrder?.POHighValue)
    if (TotalValue <= POHighValueLimit && this.permission.Approval) {
      this.poApprovalCheck = true;
      return this.poApprovalCheck;
    }
    else if (TotalValue <= POHighValueLimit && this.permission.HighValueApproval) {
      this.poApprovalCheck = true;
      return this.poApprovalCheck;
    }
    else if (TotalValue >= POHighValueLimit && this.permission.HighValueApproval) {
      this.poApprovalCheck = true;
      return this.poApprovalCheck;
    }
    else {
      this.poApprovalCheck = false;
      return this.poApprovalCheck;
    }
  }
  GetPurchaseOrderPDF(PoId: number) {
    try {
      this.isDownloading = true;
      let url = this.ApiUrl + "purchaseorder/getpurchaseorderpdf/" + PoId;
      this.http.get<any>(url).subscribe({
        next: (res) => {
          this.isDownloading = false;
          window.open(res.ResponseBody.url, '_blank');
        },
        error: (res) => {
          this.isDownloading = false;
        }
      });
    } catch (error) {
      this.isDownloading = false;
    }
  }
  email(data: PurchaseOrderModel) {
    try {
      this.isEmailing = true;
      window.open(`${window.location.origin}/poemail/` + data.Poid);
      this.isEmailing = false;
    } catch (error) {
      this.isEmailing = false;
    }
  }
}

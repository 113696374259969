import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NzModalService } from "ng-zorro-antd/modal";
import { environment } from "../../../../environments/environment";
import { ColorModel, FactoryWorkersModel, GrainModel, RackModel, StoreModel } from "../../../Models/MasterModel";
import { SaleOrderModel, SaleOrderPostProcessEmbossingModel, SaleOrderPostProcessLacquerModel, SaleOrderPostProcessOrderModel, SaleOrderPostProcessPrintModel, SaleOrderPostProcessTumblingModel, SaleOrderPostProcessVacuumModel } from "../../../Models/SalesOrderModel";
import { JumboActualListModel, WorkPlanJumboMasterModel, WorkPlanMasterModel, WorkPlanOrderModel } from "../../../Models/WorkPlanModel";
import { AlertMessageService } from "../../../Services/AlertMessageService";
import { LoadingService } from "../../../Services/loadingService";
import { UserInfo } from "../../../Authentication/UserInfo";
import { MeasureUnitModel } from "../../../Models/MeasureUnitModel";
import { InterceptorSkipHeader } from "../../../Services/HttpInterceptorService";
import { StorageService } from "../../../Services/storage.service";
import { ESalesOrderStatus, Modules, Responsibility } from "../../../Models/Enums";
import { AuthService } from "../../../Services/auth.service";
import { FormulationCodeModel } from "../../../Models/FormulationCodeModel";
import { CustomerModel } from "../../../Models/SupplierModel";
import { SoDrawerService } from "../../../Services/SoDrawerService";
import { CompatibleDate, NzDatePickerComponent } from "ng-zorro-antd/date-picker";
import { PrdDowntimeService } from "src/PmsUIApp/Features/PrdDowntime/services/PrdDowntimeService";

@Component({
  selector: 'app-postprocess',
  templateUrl: './postprocess.component.html'
})
export class PostprocessComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  WorkPlanList: WorkPlanMasterModel[] = [];
  SelectedWorkPlan: WorkPlanMasterModel = new WorkPlanMasterModel;
  SelectedWorkPlanOrder: WorkPlanOrderModel[] = [];
  SaleOrderList: SaleOrderModel[] = [];
  SelectedOrder!: WorkPlanOrderModel | null;
  Production!: SaleOrderModel;
  PrintModel!: SaleOrderPostProcessPrintModel
  EmbossingModel!: SaleOrderPostProcessEmbossingModel
  VacuumModel!: SaleOrderPostProcessVacuumModel
  LacquerModel!: SaleOrderPostProcessLacquerModel
  TumblingModel!: SaleOrderPostProcessTumblingModel
  isTableLoading = false;
  isLoading: boolean = false;
  isVisible: boolean = false;
  RackList: RackModel[] = [];
  StoreList: StoreModel[] = [];
  MeasureUnits: MeasureUnitModel[] = [];
  StoreId: number = 0
  Type: string = '';
  RequestModel = {
    Id: 0,
    SaleOrderId: 0,
    RackId: 0,
    ReceivedQuantity: 0,
    ShiftSupervisorWorkerId: 0,
    Remark: '',
    CompletedQuantity: 0,
    WastageQuantity: 0,
    Unit: 'Mtrs',
    Status: '',
    StartDateTime: new Date(),
    EndDateTime: new Date(),
    LineNo: 0,
    PricePerUnit: 0,
  }
  IsDataAvailable = false;
  postToken: any;
  getToken: any;
  ImageArray: any[] = [];
  FilteredImageArray: any[] = [];
  isImageVisible = false;
  StorageAccPrintContainerName = 'productionprint';
  StorageAccEmbossingContainerName = 'productionembossing';
  jumboTableData: WorkPlanJumboMasterModel[] = [];
  totalJumboQTY = 0;
  count: number;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  DowntimePermission = {
    Add: false,
  }
  removeBtnToolTipText = '';
  acceptProcessBtnToolTipText = '';
  postProcessRank: any = null;
  DefaultUnit: string = 'Mtrs';
  request = {
    FromAddedDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7)),
    ToAddedDate: new Date(new Date().setHours(23, 59, 59)),
    SaleFormulationCodeId: 0,
    SaleOrderNumber: null,
    DateType: 'productioncomplete', // options are - saleorderdate, productioncomplete, preinspection
    Status: null,
    ArticleName: '',
    CustomerId: 0,
    GrainId: 0,
    ColorId: 0,
    ProductType: '', //options are - GZ- or GZY-
    OrderType: '',
    SaleOrderStatus: ''
  }
  dateTypeList = [
    {
      "Text": "Production Completed",
      "Value": 'productioncomplete'
    },
    {
      "Text": "Sale Order",
      "Value": 'saleorder'
    }];
  orderTypeList: any = [
    'Sample',
    'Trial',
    'Product',
    'Job Work'
  ];
  PUPVCTypeList: any = [
    {
      "Text": "GZ(PVC)",
      "Value": 'GZ-'
    },
    {
      "Text": "GZY(PU)",
      "Value": 'GZY-'
    }
  ]
  SelectedSaleOrderStatusEnum: ESalesOrderStatus = 0;
  status: any = [
    {
      "Text": "MoveToPostProcess",
      "Value": 8
    },
    {
      "Text": "PrintAssigned",
      "Value": 9
    },
    {
      "Text": "PrintInProcess",
      "Value": 10
    },
    {
      "Text": "PrintCompleted",
      "Value": 11
    },
    {
      "Text": "EmbossingAssigned",
      "Value": 12
    },
    {
      "Text": "EmbossingInProcess",
      "Value": 13
    },
    {
      "Text": "EmbossingCompleted",
      "Value": 14
    },
    {
      "Text": "VacuumAssigned",
      "Value": 15
    },
    {
      "Text": "VacuumInProcess",
      "Value": 16
    },
    {
      "Text": "VacuumCompleted",
      "Value": 17
    },
    {
      "Text": "LacquerAssigned",
      "Value": 18
    },
    {
      "Text": "LacquerInProcess",
      "Value": 19
    },
    {
      "Text": "LacquerCompleted",
      "Value": 20
    },
    {
      "Text": "TumblingAssigned",
      "Value": 21
    },
    {
      "Text": "TumblingInProcess",
      "Value": 22
    },
    {
      "Text": "TumblingCompleted",
      "Value": 23
    },
    {
      "Text": "JumboInspection",
      "Value": 24
    },
    {
      "Text": "MoveToDispatch",
      "Value": 25
    },
    {
      "Text": "PartialDispatchReady",
      "Value": 26
    },
    {
      "Text": "PartialDispatchCompleted",
      "Value": 27
    },
    {
      "Text": "DispatchReady",
      "Value": 28
    },
    {
      "Text": "DispatchCompleted",
      "Value": 30
    }
  ];
  totalItemsCount: number = 0;
  CustomerList: CustomerModel[];
  FormulationCodeList: FormulationCodeModel[];
  ColorList: ColorModel[];
  GrainList: GrainModel[];
  FactoryWorkersList: FactoryWorkersModel[];
  @ViewChild('endDatePicker') endDatePicker!: NzDatePickerComponent;
  constructor(public http: HttpClient, private alertService: AlertMessageService,
    private modalService: NzModalService, private route: ActivatedRoute,
    private router: Router, private auth: AuthService,
    private loader: LoadingService, private storage: StorageService,
    private soDrawer: SoDrawerService, private cdr: ChangeDetectorRef,
    private prdDowntimeService: PrdDowntimeService) {
  }

  ngOnInit(): void {
    this.permission.View = this.auth.CheckResponsibility(Modules.PostProcess, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.PostProcess, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.PostProcess, Responsibility.Delete);
    this.DowntimePermission.Add = this.auth.CheckResponsibility(Modules.ProductionDowntime, Responsibility.Add);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    const existingFilters = sessionStorage.getItem('postprocessrequestfilters');
    if (existingFilters) {
      this.request = JSON.parse(existingFilters);
    }
    this.RequestModel.StartDateTime = new Date();
    // this.getWorkOrderList();
    this.GetPostProcessList()
    this.GetAllStore()
    this.GetFactoryWorkersByDepartment();
    this.GetAllUnits()
  }

  getWorkOrderList() {
    let url = this.ApiUrl + 'workplan/getallworkplanbysaleorderstatus';
    var item = ["MoveToPostProcess",
      "PrintAssigned",
      "PrintInProcess",
      "PrintCompleted",
      "EmbossingAssigned",
      "EmbossingInProcess",
      "EmbossingCompleted",
      "VacuumAssigned",
      "VacuumInProcess",
      "VacuumCompleted",
      "LacquerAssigned",
      "LacquerInProcess",
      "LacquerCompleted",
      "TumblingAssigned",
      "TumblingInProcess",
      "TumblingCompleted"];

    this.http.post<any>(url, item).subscribe({
      next: res => {
        this.WorkPlanList = res.Data;
        this.GetAllRack();
      },
      error: res => { this.getWorkOrderList(); },
    });
  }

  //   let url = this.ApiUrl + "workplan/getallworkplan";
  //   this.http.get<any>(url).subscribe(res => {
  //     this.WorkPlanList = res.Data;
  //     this.GetAllRack();
  //     //console.log(this.WorkPlanList)
  //   }, res => { this.getWorkOrderList(); });
  // }

  GetAllUnits() {
    let url = this.ApiUrl + "data/GetMeasureUnits";
    this.http.get<MeasureUnitModel[]>(url).subscribe(res => {
      this.MeasureUnits = res;
      this.RequestModel.Unit = this.MeasureUnits.find(x => x.Unit == 'Mtrs').Unit;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllUnits(); }
    });
  }
  GetPostProcessList() {
    this.isTableLoading = true;

    let url = this.ApiUrl + "saleorder/getpostprocesslistwithfilters";
    this.http.post<SaleOrderModel[]>(url, this.request).subscribe(res => {
      this.SaleOrderList = res;

      this.SaleOrderList.forEach(so => {
        so.SaleOrderPostProcessOrder.forEach(el => {
          el.DisplayProcessName = el.PostProcessName + ' - Pending';

          if (el.PostProcessName == 'Print') {
            so.SaleOrderProductionPostProcess.SaleOrderPostProcessPrint.forEach((x) => {
              if (el.Rank == x.Rank) {
                el.Status = x.PrintStatus
              }
            });
            (so.SaleOrderProductionPostProcess.SaleOrderPostProcessPrint.forEach((x) => {
              if ((x.PrintStatus == undefined || x.PrintStatus == null) && el.Rank == x.Rank) {
                el.DisplayProcessName = 'Print - Pending'
              }
              else if (el.Rank == x.Rank) {
                el.DisplayProcessName = 'Print - ' + x.PrintStatus
              }
            }))

            // if (wp.SaleOrder.IsPrintRequired == false) {
            //   el.Status = 'Completed';
            // }
            el = this.CheckCompletedStatus(so.SaleOrderPostProcessOrder, el, 'Print');
          }
          if (el.PostProcessName == 'Embossing') {
            so.SaleOrderProductionPostProcess.SaleOrderPostProcessEmbossing.forEach((x) => {
              if (el.Rank == x.Rank) {
                el.Status = x.EmbossingStatus
              }
            });
            (so.SaleOrderProductionPostProcess.SaleOrderPostProcessEmbossing.forEach((x) => {
              if ((x.EmbossingStatus == undefined || x.EmbossingStatus == null) && el.Rank == x.Rank) {
                el.DisplayProcessName = 'Embossing - Pending'
              }
              else if (el.Rank == x.Rank) {
                el.DisplayProcessName = 'Embossing - ' + x.EmbossingStatus
              }
            }))
            // if (wp.SaleOrder.IsEmbossingRequired == false) {
            //   el.Status = 'Completed';
            // }
            el = this.CheckCompletedStatus(so.SaleOrderPostProcessOrder, el, 'Embossing');
          }
          if (el.PostProcessName == 'Vaccum') {
            so.SaleOrderProductionPostProcess.SaleOrderPostProcessVacuum.forEach((x) => {
              if (el.Rank == x.Rank) {
                el.Status = x.VacuumStatus
              }
            });
            (so.SaleOrderProductionPostProcess.SaleOrderPostProcessVacuum.forEach((x) => {
              if ((x.VacuumStatus == undefined || x.VacuumStatus == null) && el.Rank == x.Rank) {
                el.DisplayProcessName = 'Vacuum - Pending'
              }
              else if (el.Rank == x.Rank) {
                el.DisplayProcessName = 'Vacuum - ' + x.VacuumStatus
              }
            }))
            // if (wp.SaleOrder.IsVacuumRequired == false) {
            //   el.Status = 'Completed';
            // }
            el = this.CheckCompletedStatus(so.SaleOrderPostProcessOrder, el, 'Vaccum');

          }
          if (el.PostProcessName == 'Lacquar') {
            so.SaleOrderProductionPostProcess.SaleOrderPostProcessLacquer.forEach((x) => {
              if (el.Rank == x.Rank) {
                el.Status = x.LacquerStatus
              }
            });
            (so.SaleOrderProductionPostProcess.SaleOrderPostProcessLacquer.forEach((x) => {
              if ((x.LacquerStatus == undefined || x.LacquerStatus == null) && el.Rank == x.Rank) {
                el.DisplayProcessName = 'Lacquer - Pending'
              }
              else if (el.Rank == x.Rank) {
                el.DisplayProcessName = 'Lacquer - ' + x.LacquerStatus
              }
            }))
            // if (wp.SaleOrder.IsLacquerRequired == false) {
            //   el.Status = 'Completed';
            // }
            el = this.CheckCompletedStatus(so.SaleOrderPostProcessOrder, el, 'Lacquar');

          }
          if (el.PostProcessName == 'Tumbling') {
            so.SaleOrderProductionPostProcess.SaleOrderPostProcessTumbling.forEach((x) => {
              if (el.Rank == x.Rank) {
                el.Status = x.TumblingStatus
              }
            });
            (so.SaleOrderProductionPostProcess.SaleOrderPostProcessTumbling.forEach((x) => {
              if ((x.TumblingStatus == undefined || x.TumblingStatus == null) && el.Rank == x.Rank) {
                el.DisplayProcessName = 'Tumbling - Pending'
              }
              else if (el.Rank == x.Rank) {
                el.DisplayProcessName = 'Tumbling - ' + x.TumblingStatus
              }
            }))
            // if (wp.SaleOrder.IsTumblingRequired == false) {
            //   el.Status = 'Completed';
            // }
            el = this.CheckCompletedStatus(so.SaleOrderPostProcessOrder, el, 'Tumbling');
          }
        });
        // if (wp.SaleOrder.IsPrintRequired == false) {
        //   wp.SaleOrder.SaleOrderProduction.SaleOrderPostProcessPrint.PrintStatus = 'Completed';
        // }
        // if (wp.SaleOrder.IsEmbossingRequired == false) {
        //   wp.SaleOrder.SaleOrderProduction.SaleOrderPostProcessEmbossing.EmbossingStatus = 'Completed';
        // }
        // if (wp.SaleOrder.IsVacuumRequired == false) {
        //   wp.SaleOrder.SaleOrderProduction.SaleOrderPostProcessVacuum.VacuumStatus = 'Completed';
        // }
        // if (wp.SaleOrder.IsLacquerRequired == false) {
        //   wp.SaleOrder.SaleOrderProduction.SaleOrderPostProcessLacquer.LacquerStatus = 'Completed';
        // }
        // if (wp.SaleOrder.IsTumblingRequired == false) {
        //   wp.SaleOrder.SaleOrderProduction.SaleOrderPostProcessTumbling.TumblingStatus = 'Completed';
        // }
      })

      this.SelectedOrder = null;
      this.isTableLoading = false;
    }, res => { });
  }

  CheckCompletedStatus(soppo: SaleOrderPostProcessOrderModel[], el: SaleOrderPostProcessOrderModel, PostProcessName: string) {
    if (el.Rank != 1) {
      //console.log(el.Rank)
      var previousRank = soppo.filter(x => x.Rank < el.Rank).sort((n1, n2) => n2.Rank - n1.Rank);;
      if (previousRank != undefined && previousRank.length > 0) {
        var previousProcess = soppo.filter(x => x.Rank == previousRank[0].Rank)[0];
        if (previousProcess != undefined && previousProcess.Status != 'Completed') {
          el.Status = 'NotStarted';
        }
      }
      //console.log(previousProcess)

      //if (wp.SaleOrder.SaleOrderPostProcessOrder.filter(
      //  x => x.Rank == wp.SaleOrder.SaleOrderPostProcessOrder.filter(
      //    x => x.Rank == wp.SaleOrder.SaleOrderPostProcessOrder.filter(
      //      x => x.PostProcessName == PostProcessName)[0].Rank).length)[0].Status != 'Completed') {
      //  el.Status = 'NotStarted';
      //}
    }
    return el;
  }
  OpenDrawer(type: any, data: SaleOrderModel) {


    this.Clear();
    this.Type = type.PostProcessName;
    this.postProcessRank = type.Rank;
    this.GetSalesorderDatabyid(data.SaleOrderId, type.PostProcessName, type.Rank);
    this.isVisible = true;
  }
  handleCancel(): void {
    this.isVisible = false;
  }
  GetSalesorderDatabyid(id: number, type: string, postProcessRank: number = null) {
    this.loader.show();
    this.Production = new SaleOrderModel();
    //let url = this.ApiUrl + "production/getproductiondatabyid/" + id;
    let url = this.ApiUrl + "saleorder/Getsaleorderpostprocessdatabyid/" + id;
    this.http.get<any>(url).subscribe(res => {
      this.loader.hide();
      this.Production = res;
      var defaultPricePerUnit = 0;
      if (type == 'Print') {
        if (postProcessRank) {
          this.Production.SaleOrderProductionPostProcess.SaleOrderProductionPrint = this.Production.SaleOrderProductionPostProcess.SaleOrderProductionPrint.filter(x => x.Rank == postProcessRank);
          defaultPricePerUnit = this.Production.SaleOrderProductionPostProcess.SaleOrderProductionPrint.find(x => x.Rank == postProcessRank).Price;
        }
        if (this.Production.SaleOrderProductionPostProcess.SaleOrderProductionPrint.filter(x => x.Rank == postProcessRank).length > 0) {
          this.IsDataAvailable = true;
          if (this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessPrint?.find(x => x.Rank == postProcessRank)?.SaleOrderPostProcessPrintId > 0) {
            this.RequestModel.ReceivedQuantity = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessPrint.find(x => x.Rank == postProcessRank).ReceivedQuantity;
            this.RequestModel.Unit = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessPrint.find(x => x.Rank == postProcessRank).PrintMeasurementUnit;
            this.RequestModel.Remark = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessPrint.find(x => x.Rank == postProcessRank).Remark;
            this.RequestModel.Status = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessPrint.find(x => x.Rank == postProcessRank).PrintStatus;
            this.RequestModel.Id = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessPrint.find(x => x.Rank == postProcessRank).SaleOrderPostProcessPrintId;
            this.RequestModel.ShiftSupervisorWorkerId = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessPrint.find(x => x.Rank == postProcessRank).ShiftSupervisorWorkerId;
            this.RequestModel.LineNo = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessPrint.find(x => x.Rank == postProcessRank).LineNo;
            var actualPricePerUnit = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessPrint.find(x => x.Rank == postProcessRank).PricePerUnit;
            this.RequestModel.PricePerUnit = actualPricePerUnit > 0 ? actualPricePerUnit : defaultPricePerUnit;
            var startDateTime = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessPrint.find(x => x.Rank == postProcessRank).StartDateTime;
            var endDateTime = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessPrint.find(x => x.Rank == postProcessRank).EndDateTime;
            this.RequestModel.StartDateTime = startDateTime ? new Date(startDateTime) : new Date();
            this.RequestModel.EndDateTime = endDateTime ? new Date(endDateTime) : null;
            this.GetAllRack();
            this.StoreId = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessPrint.find(x => x.Rank == postProcessRank).StoreId;
            let url = this.ApiUrl + "rack/getallracks";
            this.http.get<RackModel[]>(url).subscribe(res => {
              this.RackList = res.filter(x => x.StoreId == this.StoreId);
              this.RequestModel.RackId = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessPrint.find(x => x.Rank == postProcessRank).PrintRack;
            }, res => { });

          }
        } else {
          this.IsDataAvailable = false;
        }

      }
      if (type == 'Embossing') {
        if (postProcessRank) {
          this.Production.SaleOrderProductionPostProcess.SaleOrderProductionEmbossing = this.Production.SaleOrderProductionPostProcess.SaleOrderProductionEmbossing.filter(x => x.Rank == postProcessRank);
          defaultPricePerUnit = this.Production.SaleOrderProductionPostProcess.SaleOrderProductionEmbossing.find(x => x.Rank == postProcessRank).Price;
        }
        if (this.Production.SaleOrderProductionPostProcess.SaleOrderProductionEmbossing.length > 0) {
          this.IsDataAvailable = true;
          if (this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessEmbossing?.find(x => x.Rank == postProcessRank)?.SaleOrderPostProcessEmbossingId > 0) {
            this.RequestModel.ReceivedQuantity = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessEmbossing.find(x => x.Rank == postProcessRank).ReceivedQuantity;
            this.RequestModel.Unit = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessEmbossing.find(x => x.Rank == postProcessRank).EmbossingMeasurementUnit;
            this.RequestModel.Remark = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessEmbossing.find(x => x.Rank == postProcessRank).Remark;
            this.RequestModel.Status = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessEmbossing.find(x => x.Rank == postProcessRank).EmbossingStatus;
            this.RequestModel.Id = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessEmbossing.find(x => x.Rank == postProcessRank).SaleOrderPostProcessEmbossingId;
            this.StoreId = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessEmbossing.find(x => x.Rank == postProcessRank).StoreId;
            this.RequestModel.ShiftSupervisorWorkerId = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessEmbossing.find(x => x.Rank == postProcessRank).ShiftSupervisorWorkerId;
            this.RequestModel.LineNo = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessEmbossing.find(x => x.Rank == postProcessRank).LineNo;
            var actualPricePerUnit = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessEmbossing.find(x => x.Rank == postProcessRank).PricePerUnit;
            this.RequestModel.PricePerUnit = actualPricePerUnit > 0 ? actualPricePerUnit : defaultPricePerUnit;
            var startDateTime = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessEmbossing.find(x => x.Rank == postProcessRank).StartDateTime;
            var endDateTime = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessEmbossing.find(x => x.Rank == postProcessRank).EndDateTime;
            this.RequestModel.StartDateTime = startDateTime ? new Date(startDateTime) : new Date();
            this.RequestModel.EndDateTime = endDateTime ? new Date(endDateTime) : new Date();
            let url = this.ApiUrl + "rack/getallracks";
            this.http.get<RackModel[]>(url).subscribe(res => {
              this.RackList = res.filter(x => x.StoreId == this.StoreId);
              this.RequestModel.RackId = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessEmbossing.find(x => x.Rank == postProcessRank).EmbossingRack;
            }, res => { });

          }
        } else {
          this.IsDataAvailable = false;
        }

      }
      if (type == 'Vaccum') {
        if (postProcessRank) {
          this.Production.SaleOrderProductionPostProcess.SaleOrderProductionVacuum = this.Production.SaleOrderProductionPostProcess.SaleOrderProductionVacuum.filter(x => x.Rank == postProcessRank);
          defaultPricePerUnit = this.Production.SaleOrderProductionPostProcess.SaleOrderProductionVacuum.find(x => x.Rank == postProcessRank).Price;
        }
        if (this.Production.SaleOrderProductionPostProcess.SaleOrderProductionVacuum.length > 0) {
          this.IsDataAvailable = true;
          if (this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessVacuum?.find(x => x.Rank == postProcessRank)?.SaleOrderPostProcessVacuumId > 0) {
            this.RequestModel.ReceivedQuantity = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessVacuum.find(x => x.Rank == postProcessRank).ReceivedQuantity;
            this.RequestModel.Unit = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessVacuum.find(x => x.Rank == postProcessRank).VacuumMeasurementUnit;
            this.RequestModel.Remark = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessVacuum.find(x => x.Rank == postProcessRank).Remark;
            this.RequestModel.Status = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessVacuum.find(x => x.Rank == postProcessRank).VacuumStatus;
            this.RequestModel.Id = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessVacuum.find(x => x.Rank == postProcessRank).SaleOrderPostProcessVacuumId;
            this.StoreId = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessVacuum.find(x => x.Rank == postProcessRank).StoreId;
            this.RequestModel.ShiftSupervisorWorkerId = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessVacuum.find(x => x.Rank == postProcessRank).ShiftSupervisorWorkerId;
            this.RequestModel.LineNo = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessVacuum.find(x => x.Rank == postProcessRank).LineNo;
            var actualPricePerUnit = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessVacuum.find(x => x.Rank == postProcessRank).PricePerUnit;
            this.RequestModel.PricePerUnit = actualPricePerUnit > 0 ? actualPricePerUnit : defaultPricePerUnit;
            var startDateTime = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessVacuum.find(x => x.Rank == postProcessRank).StartDateTime;
            var endDateTime = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessVacuum.find(x => x.Rank == postProcessRank).EndDateTime;
            this.RequestModel.StartDateTime = startDateTime ? new Date(startDateTime) : new Date();
            this.RequestModel.EndDateTime = endDateTime ? new Date(endDateTime) : new Date();
            let url = this.ApiUrl + "rack/getallracks";
            this.http.get<RackModel[]>(url).subscribe(res => {
              this.RackList = res.filter(x => x.StoreId == this.StoreId);
              this.RequestModel.RackId = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessVacuum.find(x => x.Rank == postProcessRank).VacuumRack;
            }, res => { });

          }
        } else {
          this.IsDataAvailable = false;
        }

      }
      if (type == 'Tumbling') {
        if (postProcessRank) {
          this.Production.SaleOrderProductionPostProcess.SaleOrderProductionTumbling = this.Production.SaleOrderProductionPostProcess.SaleOrderProductionTumbling.filter(x => x.Rank == postProcessRank);
          defaultPricePerUnit = this.Production.SaleOrderProductionPostProcess.SaleOrderProductionTumbling.find(x => x.Rank == postProcessRank).Price;
        }
        if (this.Production.SaleOrderProductionPostProcess.SaleOrderProductionTumbling.length > 0) {
          this.IsDataAvailable = true;
          if (this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessTumbling?.find(x => x.Rank == postProcessRank)?.SaleOrderPostProcessTumblingId > 0) {
            this.RequestModel.ReceivedQuantity = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessTumbling.find(x => x.Rank == postProcessRank).ReceivedQuantity;
            this.RequestModel.Unit = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessTumbling.find(x => x.Rank == postProcessRank).TumblingMeasurementUnit;
            this.RequestModel.Remark = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessTumbling.find(x => x.Rank == postProcessRank).Remark;
            this.RequestModel.Status = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessTumbling.find(x => x.Rank == postProcessRank).TumblingStatus;
            this.RequestModel.Id = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessTumbling.find(x => x.Rank == postProcessRank).SaleOrderPostProcessTumblingId;
            this.StoreId = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessTumbling.find(x => x.Rank == postProcessRank).StoreId;
            this.RequestModel.ShiftSupervisorWorkerId = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessTumbling.find(x => x.Rank == postProcessRank).ShiftSupervisorWorkerId;
            this.RequestModel.LineNo = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessTumbling.find(x => x.Rank == postProcessRank).LineNo;
            var actualPricePerUnit = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessTumbling.find(x => x.Rank == postProcessRank).PricePerUnit;
            this.RequestModel.PricePerUnit = actualPricePerUnit > 0 ? actualPricePerUnit : defaultPricePerUnit;
            var startDateTime = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessTumbling.find(x => x.Rank == postProcessRank).StartDateTime;
            var endDateTime = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessTumbling.find(x => x.Rank == postProcessRank).EndDateTime;
            this.RequestModel.StartDateTime = startDateTime ? new Date(startDateTime) : new Date();
            this.RequestModel.EndDateTime = endDateTime ? new Date(endDateTime) : new Date();
            let url = this.ApiUrl + "rack/getallracks";
            this.http.get<RackModel[]>(url).subscribe(res => {
              this.RackList = res.filter(x => x.StoreId == this.StoreId);
              this.RequestModel.RackId = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessTumbling.find(x => x.Rank == postProcessRank).TumblingRack;
            }, res => { });

          }
        } else {
          this.IsDataAvailable = false;
        }

      }
      if (type == 'Lacquar') {
        if (postProcessRank) {
          this.Production.SaleOrderProductionPostProcess.Lacquer = this.Production.SaleOrderProductionPostProcess.Lacquer.filter(x => x.Rank == postProcessRank);
          defaultPricePerUnit = this.Production.SaleOrderProductionPostProcess.Lacquer.find(x => x.Rank == postProcessRank).Price;
        }
        if (this.Production.SaleOrderProductionPostProcess.Lacquer.length > 0) {
          this.IsDataAvailable = true;
          if (this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessLacquer?.find(x => x.Rank == postProcessRank)?.SaleOrderPostProcessLacquerId > 0) {
            this.RequestModel.ReceivedQuantity = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessLacquer.find(x => x.Rank == postProcessRank).ReceivedQuantity;
            this.RequestModel.Unit = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessLacquer.find(x => x.Rank == postProcessRank).LacquerMeasurementUnit;
            this.RequestModel.Remark = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessLacquer.find(x => x.Rank == postProcessRank).Remark;
            this.RequestModel.Status = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessLacquer.find(x => x.Rank == postProcessRank).LacquerStatus;
            this.RequestModel.Id = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessLacquer.find(x => x.Rank == postProcessRank).SaleOrderPostProcessLacquerId;
            this.StoreId = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessLacquer.find(x => x.Rank == postProcessRank).StoreId;
            this.RequestModel.ShiftSupervisorWorkerId = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessLacquer.find(x => x.Rank == postProcessRank).ShiftSupervisorWorkerId;
            this.RequestModel.LineNo = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessLacquer.find(x => x.Rank == postProcessRank).LineNo;
            var actualPricePerUnit = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessLacquer.find(x => x.Rank == postProcessRank).PricePerUnit;
            this.RequestModel.PricePerUnit = actualPricePerUnit > 0 ? actualPricePerUnit : defaultPricePerUnit;
            var startDateTime = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessLacquer.find(x => x.Rank == postProcessRank).StartDateTime;
            var endDateTime = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessLacquer.find(x => x.Rank == postProcessRank).EndDateTime;
            this.RequestModel.StartDateTime = startDateTime ? new Date(startDateTime) : new Date();
            this.RequestModel.EndDateTime = endDateTime ? new Date(endDateTime) : new Date();
            let url = this.ApiUrl + "rack/getallracks";
            this.http.get<RackModel[]>(url).subscribe(res => {
              this.RackList = res.filter(x => x.StoreId == this.StoreId);
              this.RequestModel.RackId = this.Production.SaleOrderProductionPostProcess.SaleOrderPostProcessLacquer.find(x => x.Rank == postProcessRank).LacquerRack;
            }, res => { });

          }
        } else {
          this.IsDataAvailable = false;
        }

      }
      this.getJumboRollsById(id)
      this.cdr.detectChanges();
    });
  }
  GetAllStore() {

    let url = this.ApiUrl + "store/getallstores";
    this.http.get<StoreModel[]>(url).subscribe(res => {
      this.StoreList = res;

    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllStore(); }
    });
  }
  onSelectedStoreChange() {
    let url = this.ApiUrl + "rack/getallracks";
    this.http.get<RackModel[]>(url).subscribe(res => {
      this.RackList = res.filter(x => x.StoreId == this.StoreId);
      //this.RackID = 0;
    }, res => { });
  }
  GetAllRack() {

    let url = this.ApiUrl + "rack/getallracks";
    this.http.get<RackModel[]>(url).subscribe(res => {
      this.RackList = res;
      //this.RackID = 0;
    }, res => { });
  }

  Accept(IsProcess: boolean, IsCompleted: boolean) {
    this.isLoading = true;
    if (this.RequestModel.ReceivedQuantity <= 0) {
      this.alertService.error('Enter Received QTY');
      this.isLoading = false;
      return;
    }
    if (this.RequestModel.ReceivedQuantity > this.Production.SaleOrderProductionPostProcess.ManufacturingQuantity) {
      this.alertService.error('Received QTY cannot be more than ' + this.Production.SaleOrderProductionPostProcess.ManufacturingQuantity);
      this.isLoading = false;
      return;
    }
    // if (this.RequestModel.Unit == '0') {
    //   this.alertService.error('Select Unit');
    //   this.isLoading = false;
    //   return;
    // }
    if (this.RequestModel.ShiftSupervisorWorkerId <= 0) {
      this.alertService.error('Select Shift Supervisor');
      this.isLoading = false;
      return;
    }
    if (this.RequestModel.LineNo <= 0 && IsCompleted) {
      this.alertService.error('Select Line Number');
      this.isLoading = false;
      return;
    }
    if (this.RequestModel.PricePerUnit <= 0 && IsCompleted) {
      this.alertService.error('Enter Rate Per Unit');
      this.isLoading = false;
      return;
    }
    if (this.RequestModel.StartDateTime == null && IsCompleted) {
      this.alertService.error('Select Start Date and Time');
      this.isLoading = false;
      return;
    }
    if (this.RequestModel.EndDateTime == null && IsCompleted) {
      this.alertService.error('Select End Date and Time');
      this.isLoading = false;
      return;
    }
    if (this.RequestModel.StartDateTime.getTime() > this.RequestModel.EndDateTime.getTime() && IsCompleted) {
      this.alertService.error('Start Date and Time cannot be after End Date and Time');
      this.isLoading = false;
      return;
    }
    if (this.RequestModel.RackId <= 0) {
      this.alertService.error('Select Rack');
      this.isLoading = false;
      return;
    }
    if (IsCompleted) {
      if (this.jumboTableData.filter(x => x.Yield <= 0).length > 0) {
        this.alertService.error('Complete QTY cannot be 0 for any Jumbo Roll');
        this.isLoading = false;
        return;
      }
    }

    var JumboActualList: JumboActualListModel[] = []
    this.jumboTableData.forEach(x => {
      var JumboActual = new JumboActualListModel;
      JumboActual.ActualQuantity = x.Yield;
      JumboActual.WorkPlanJumboMasterId = x.WorkPlanJumboMasterId
      JumboActualList.push(JumboActual);
    })
    if (this.Type == 'Print') {
      this.PrintModel = new SaleOrderPostProcessPrintModel;
      this.PrintModel.AddedBy = UserInfo.EmailID;
      this.PrintModel.SaleOrderId = this.Production.SaleOrderId;
      this.PrintModel.PrintRack = this.RequestModel.RackId;
      this.PrintModel.ReceivedQuantity = this.RequestModel.ReceivedQuantity;
      this.PrintModel.PrintMeasurementUnit = this.RequestModel.Unit;
      this.PrintModel.Remark = this.RequestModel.Remark;
      this.PrintModel.SaleOrderPostProcessPrintId = 0;
      this.PrintModel.ShiftSupervisorWorkerId = this.RequestModel.ShiftSupervisorWorkerId;
      this.PrintModel.JumboActualList = JumboActualList;
      if (IsProcess) {

        this.PrintModel.SaleOrderPostProcessPrintId = this.RequestModel.Id;
        this.PrintModel.LineNo = this.RequestModel.LineNo;
        this.PrintModel.ShiftSupervisorWorkerId = this.RequestModel.ShiftSupervisorWorkerId;
        this.PrintModel.PrintStatus = 'InProcess';
      }
      if (IsCompleted) {
        this.PrintModel.SaleOrderPostProcessPrintId = this.RequestModel.Id;
        this.PrintModel.PrintStatus = 'Completed';
        this.PrintModel.PrintCompletedQuantity = this.RequestModel.CompletedQuantity;
        this.PrintModel.PrintWastageQuantity = this.RequestModel.WastageQuantity;
        this.PrintModel.PricePerUnit = this.RequestModel.PricePerUnit;
        this.PrintModel.StartDateTime = this.RequestModel.StartDateTime.toISOString();
        this.PrintModel.EndDateTime = this.RequestModel.EndDateTime.toISOString();
        this.PrintModel.ShiftSupervisorWorkerId = this.RequestModel.ShiftSupervisorWorkerId;
        this.PrintModel.LineNo = this.RequestModel.LineNo;
      }
      if (this.postProcessRank) {
        this.PrintModel.Rank = this.postProcessRank;
      }
      let url = this.ApiUrl + "saleorder/addpostprocessprint";
      this.http.post<any>(url, this.PrintModel).subscribe({
        next: res => {
          this.Clear();
          this.GetSalesorderDatabyid(this.Production.SaleOrderId, this.Type);
          this.alertService.success(res);
          this.GetPostProcessList();
          this.isVisible = false;
          this.isLoading = false;
        },
        error: res => { this.isLoading = false; this.alertService.error(res.error.ResponseBody); },
      });
    }
    if (this.Type == 'Embossing') {
      this.EmbossingModel = new SaleOrderPostProcessEmbossingModel;
      this.EmbossingModel.AddedBy = UserInfo.EmailID;
      this.EmbossingModel.SaleOrderId = this.Production.SaleOrderId;
      this.EmbossingModel.EmbossingRack = this.RequestModel.RackId;
      this.EmbossingModel.ReceivedQuantity = this.RequestModel.ReceivedQuantity;
      this.EmbossingModel.EmbossingMeasurementUnit = this.RequestModel.Unit;
      this.EmbossingModel.Remark = this.RequestModel.Remark;
      this.EmbossingModel.SaleOrderPostProcessEmbossingId = 0;
      this.EmbossingModel.ShiftSupervisorWorkerId = this.RequestModel.ShiftSupervisorWorkerId;
      if (IsProcess) {
        this.EmbossingModel.SaleOrderPostProcessEmbossingId = this.RequestModel.Id;
        this.EmbossingModel.EmbossingStatus = 'InProcess';
        this.EmbossingModel.LineNo = this.RequestModel.LineNo;
        this.EmbossingModel.ShiftSupervisorWorkerId = this.RequestModel.ShiftSupervisorWorkerId;
      }
      if (IsCompleted) {
        this.EmbossingModel.SaleOrderPostProcessEmbossingId = this.RequestModel.Id;
        this.EmbossingModel.EmbossingStatus = 'Completed';
        this.EmbossingModel.EmbossingCompletedQuantity = this.RequestModel.CompletedQuantity;
        this.EmbossingModel.EmbossingWastageQuantity = this.RequestModel.WastageQuantity;
        this.EmbossingModel.PricePerUnit = this.RequestModel.PricePerUnit;
        this.EmbossingModel.StartDateTime = this.RequestModel.StartDateTime.toISOString();
        this.EmbossingModel.EndDateTime = this.RequestModel.EndDateTime.toISOString();
        this.EmbossingModel.ShiftSupervisorWorkerId = this.RequestModel.ShiftSupervisorWorkerId;
        this.EmbossingModel.LineNo = this.RequestModel.LineNo;
      }
      if (this.postProcessRank) {
        this.EmbossingModel.Rank = this.postProcessRank;
      }
      this.EmbossingModel.JumboActualList = JumboActualList;
      let url = this.ApiUrl + "saleorder/addpostprocessembossing";
      this.http.post<any>(url, this.EmbossingModel).subscribe({
        next: res => {
          this.Clear();
          this.GetSalesorderDatabyid(this.Production.SaleOrderId, this.Type);
          this.alertService.success(res);
          this.GetPostProcessList();
          this.isVisible = false;
          this.isLoading = false;
        },
        error: res => { this.isLoading = false; this.alertService.error(res.error.ResponseBody); },
      });
    }
    if (this.Type == 'Vaccum') {
      this.VacuumModel = new SaleOrderPostProcessVacuumModel;
      this.VacuumModel.AddedBy = UserInfo.EmailID;
      this.VacuumModel.SaleOrderId = this.Production.SaleOrderId;
      this.VacuumModel.VacuumRack = this.RequestModel.RackId;
      this.VacuumModel.ReceivedQuantity = this.RequestModel.ReceivedQuantity;
      this.VacuumModel.VacuumMeasurementUnit = this.RequestModel.Unit;
      this.VacuumModel.Remark = this.RequestModel.Remark;
      this.VacuumModel.SaleOrderPostProcessVacuumId = 0;
      this.VacuumModel.ShiftSupervisorWorkerId = this.RequestModel.ShiftSupervisorWorkerId;
      if (IsProcess) {
        this.VacuumModel.SaleOrderPostProcessVacuumId = this.RequestModel.Id;
        this.VacuumModel.VacuumStatus = 'InProcess';
        this.VacuumModel.LineNo = this.RequestModel.LineNo;
        this.VacuumModel.ShiftSupervisorWorkerId = this.RequestModel.ShiftSupervisorWorkerId;
      }
      if (IsCompleted) {
        this.VacuumModel.SaleOrderPostProcessVacuumId = this.RequestModel.Id;
        this.VacuumModel.VacuumStatus = 'Completed';
        this.VacuumModel.VacuumCompletedQuantity = this.RequestModel.CompletedQuantity;
        this.VacuumModel.VacuumWastageQuantity = this.RequestModel.WastageQuantity;
        this.VacuumModel.PricePerUnit = this.RequestModel.PricePerUnit;
        this.VacuumModel.StartDateTime = this.RequestModel.StartDateTime.toISOString();
        this.VacuumModel.EndDateTime = this.RequestModel.EndDateTime.toISOString();
        this.VacuumModel.ShiftSupervisorWorkerId = this.RequestModel.ShiftSupervisorWorkerId;
        this.VacuumModel.LineNo = this.RequestModel.LineNo;
      }
      if (this.postProcessRank) {
        this.VacuumModel.Rank = this.postProcessRank;
      }
      this.VacuumModel.JumboActualList = JumboActualList;
      let url = this.ApiUrl + "saleorder/addpostprocessvacuum";
      this.http.post<any>(url, this.VacuumModel).subscribe({
        next: res => {
          this.Clear();
          this.GetSalesorderDatabyid(this.Production.SaleOrderId, this.Type);
          this.alertService.success(res);
          this.GetPostProcessList();
          this.isVisible = false;
          this.isLoading = false;
        },
        error: res => { this.isLoading = false; this.alertService.error(res.error.ResponseBody); },
      });
    }
    if (this.Type == 'Tumbling') {
      this.TumblingModel = new SaleOrderPostProcessTumblingModel;
      this.TumblingModel.AddedBy = UserInfo.EmailID;
      this.TumblingModel.SaleOrderId = this.Production.SaleOrderId;
      this.TumblingModel.TumblingRack = this.RequestModel.RackId;
      this.TumblingModel.ReceivedQuantity = this.RequestModel.ReceivedQuantity;
      this.TumblingModel.TumblingMeasurementUnit = this.RequestModel.Unit;
      this.TumblingModel.Remark = this.RequestModel.Remark;
      this.TumblingModel.SaleOrderPostProcessTumblingId = 0;
      this.TumblingModel.ShiftSupervisorWorkerId = this.RequestModel.ShiftSupervisorWorkerId;
      if (IsProcess) {
        this.TumblingModel.SaleOrderPostProcessTumblingId = this.RequestModel.Id;
        this.TumblingModel.TumblingStatus = 'InProcess';
        this.TumblingModel.LineNo = this.RequestModel.LineNo;
        this.TumblingModel.ShiftSupervisorWorkerId = this.RequestModel.ShiftSupervisorWorkerId;
      }
      if (IsCompleted) {
        this.TumblingModel.SaleOrderPostProcessTumblingId = this.RequestModel.Id;
        this.TumblingModel.TumblingStatus = 'Completed';
        this.TumblingModel.TumblingCompletedQuantity = this.RequestModel.CompletedQuantity;
        this.TumblingModel.TumblingWastageQuantity = this.RequestModel.WastageQuantity;
        this.TumblingModel.PricePerUnit = this.RequestModel.PricePerUnit;
        this.TumblingModel.StartDateTime = this.RequestModel.StartDateTime.toISOString();
        this.TumblingModel.EndDateTime = this.RequestModel.EndDateTime.toISOString();
        this.TumblingModel.ShiftSupervisorWorkerId = this.RequestModel.ShiftSupervisorWorkerId;
        this.TumblingModel.LineNo = this.RequestModel.LineNo;
      }
      if (this.postProcessRank) {
        this.TumblingModel.Rank = this.postProcessRank;
      }
      this.TumblingModel.JumboActualList = JumboActualList;
      let url = this.ApiUrl + "saleorder/addpostprocesstumbling";
      this.http.post<any>(url, this.TumblingModel).subscribe({
        next: res => {
          this.Clear();
          this.GetSalesorderDatabyid(this.Production.SaleOrderId, this.Type);
          this.alertService.success(res);
          this.GetPostProcessList();
          this.isVisible = false;
          this.isLoading = false;
        },
        error: res => { this.isLoading = false; this.alertService.error(res.error.ResponseBody); },
      });
    }
    if (this.Type == 'Lacquar') {
      this.LacquerModel = new SaleOrderPostProcessLacquerModel;
      this.LacquerModel.AddedBy = UserInfo.EmailID;
      this.LacquerModel.SaleOrderId = this.Production.SaleOrderId;
      this.LacquerModel.LacquerRack = this.RequestModel.RackId;
      this.LacquerModel.ReceivedQuantity = this.RequestModel.ReceivedQuantity;
      this.LacquerModel.LacquerMeasurementUnit = this.RequestModel.Unit;
      this.LacquerModel.Remark = this.RequestModel.Remark;
      this.LacquerModel.SaleOrderPostProcessLacquerId = 0;
      this.LacquerModel.ShiftSupervisorWorkerId = this.RequestModel.ShiftSupervisorWorkerId;
      if (IsProcess) {
        this.LacquerModel.SaleOrderPostProcessLacquerId = this.RequestModel.Id;
        this.LacquerModel.LacquerStatus = 'InProcess';
        this.LacquerModel.LineNo = this.RequestModel.LineNo;
        this.LacquerModel.ShiftSupervisorWorkerId = this.RequestModel.ShiftSupervisorWorkerId;
      }
      if (IsCompleted) {
        this.LacquerModel.SaleOrderPostProcessLacquerId = this.RequestModel.Id;
        this.LacquerModel.LacquerStatus = 'Completed';
        this.LacquerModel.LacquerCompletedQuantity = this.RequestModel.CompletedQuantity;
        this.LacquerModel.LacquerWastageQuantity = this.RequestModel.WastageQuantity;
        this.LacquerModel.PricePerUnit = this.RequestModel.PricePerUnit;
        this.LacquerModel.StartDateTime = this.RequestModel.StartDateTime.toISOString();
        this.LacquerModel.EndDateTime = this.RequestModel.EndDateTime.toISOString();
        this.LacquerModel.ShiftSupervisorWorkerId = this.RequestModel.ShiftSupervisorWorkerId;
        this.LacquerModel.LineNo = this.RequestModel.LineNo;
      }
      if (this.postProcessRank) {
        this.LacquerModel.Rank = this.postProcessRank;
      }
      this.LacquerModel.JumboActualList = JumboActualList;
      let url = this.ApiUrl + "saleorder/addpostprocesslacquer";
      this.http.post<any>(url, this.LacquerModel).subscribe({
        next: res => {
          this.Clear();
          this.GetSalesorderDatabyid(this.Production.SaleOrderId, this.Type);
          this.alertService.success(res);
          this.GetPostProcessList();
          this.isVisible = false;
          this.isLoading = false;
        },
        error: res => { this.isLoading = false; this.alertService.error(res.error.ResponseBody); },
      });
    }
  }

  Clear() {

    this.RequestModel = {
      Id: 0,
      SaleOrderId: 0,
      RackId: 0,
      ReceivedQuantity: 0,
      CompletedQuantity: 0,
      WastageQuantity: 0,
      Unit: 'Mtrs',
      Status: '',
      Remark: '',
      ShiftSupervisorWorkerId: 0,
      StartDateTime: new Date(),
      EndDateTime: new Date(),
      LineNo: 0,
      PricePerUnit: 0,
    }
  }
  CalculateWastage() {
    if (this.RequestModel.CompletedQuantity > this.RequestModel.ReceivedQuantity) {
      this.RequestModel.CompletedQuantity = 0;
      this.alertService.error('Completed QTY cannot be more than ' + this.RequestModel.ReceivedQuantity);
      return;
    }
    this.RequestModel.WastageQuantity = parseInt(this.RequestModel.ReceivedQuantity.toString()) - parseInt(this.RequestModel.CompletedQuantity.toString());
  }
  getWastageStyle(): { [klass: string]: any } {
    return this.RequestModel.WastageQuantity > 0
      ? {
        'background-color': 'red',
        'color': 'white',
        'padding': '2px 8px',
        'display': 'inline-block'
      }
      : {};
  }

  GetImages($event: any) {
    this.storage.getStorageSASToken(this.StorageAccPrintContainerName).subscribe((res: any) => {
      this.getToken = res;
      this.FilteredImageArray = this.ImageArray = [];

      var Blobtoken = this.getToken.StorageAccountToken;
      let url = this.getToken.StorageAccountHost + "/" + this.getToken.StorageContainerName + Blobtoken + "&restype=container&comp=list";

      const headers = new HttpHeaders().set(InterceptorSkipHeader, ''); // To exclude AD Authorization header
      this.http.get<any>(url, <Object>{ responseType: 'text', headers }).subscribe(res => {

        const parser = new DOMParser();
        const xmlDOM = parser.parseFromString(res, "text/xml");
        var x = xmlDOM.documentElement.childNodes;
        for (var i = 0; i < x.length; i++) {

          if (x[i].nodeName == "Blobs") {
            for (var j = 0; j < x[i].childNodes.length; j++) {

              var str = x[i].childNodes[j].childNodes[0].childNodes[0].nodeValue ?? "";
              var arr = str.split('/')
              var item = {
                //container: arr[0],
                ID: arr[0],
                Img: arr[1],
                URL: this.getToken.StorageAccountHost + "/" + this.getToken.StorageContainerName + "/" + str + Blobtoken
              }
              this.ImageArray.push(item);

            }
          }
        }
        this.FilteredImageArray = this.ImageArray.filter(x => x.ID == $event);
        this.isImageVisible = true;
      }, res => {

        const parser = new DOMParser();
        const xmlDOM = parser.parseFromString(res.error.text, "text/xml");
        const value = xmlDOM.getElementsByTagName("Blobs");

      });
    });
  }

  GetEmbossingImages($event: any) {
    this.storage.getStorageSASToken(this.StorageAccEmbossingContainerName).subscribe((res: any) => {
      this.FilteredImageArray = this.ImageArray = [];
      this.getToken = res;
      var Blobtoken = this.getToken.StorageAccountToken;
      let url = this.getToken.StorageAccountHost + "/" + this.getToken.StorageContainerName + Blobtoken + "&restype=container&comp=list";

      const headers = new HttpHeaders().set(InterceptorSkipHeader, ''); // To exclude AD Authorization header
      this.http.get<any>(url, <Object>{ responseType: 'text', headers }).subscribe(res => {

        const parser = new DOMParser();
        const xmlDOM = parser.parseFromString(res, "text/xml");
        var x = xmlDOM.documentElement.childNodes;
        for (var i = 0; i < x.length; i++) {

          if (x[i].nodeName == "Blobs") {
            for (var j = 0; j < x[i].childNodes.length; j++) {

              var str = x[i].childNodes[j].childNodes[0].childNodes[0].nodeValue ?? "";
              var arr = str.split('/')
              var item = {
                //container: arr[0],
                ID: arr[0],
                Img: arr[1],
                URL: this.getToken.StorageAccountHost + "/" + this.getToken.StorageContainerName + "/" + str + Blobtoken
              }
              this.ImageArray.push(item);

            }
          }
        }
        this.FilteredImageArray = this.ImageArray.filter(x => x.ID == $event);
        this.isImageVisible = true;
      }, res => {

        const parser = new DOMParser();
        const xmlDOM = parser.parseFromString(res.error.text, "text/xml");
        const value = xmlDOM.getElementsByTagName("Blobs");

      });
    });
  }

  handleCancelImage(): void {
    this.isImageVisible = false;
  }

  getJumboRollsById(id: number): void {

    let url = this.ApiUrl + 'workplan/getworkplanjumbobyworkplanordersid';
    this.http.get<any>(url + '/' + id).subscribe(
      (res) => {
        this.jumboTableData = res.Data;
        this.calculateJumboTotal();

      });


  }
  calculateJumboTotal() {

    this.RequestModel.CompletedQuantity = 0;
    this.jumboTableData?.forEach((element) => {
      this.RequestModel.CompletedQuantity += parseFloat(element.Yield?.toString()) ?? 0;
      if (isNaN(this.RequestModel.CompletedQuantity)) {
        this.RequestModel.CompletedQuantity = 0;
      }
    });
    this.CalculateWastage();
    this.cdr.detectChanges();
  }
  calculateReceivedTotal() {
    this.RequestModel.ReceivedQuantity = 0;
    this.jumboTableData.forEach((element) => {
      this.RequestModel.ReceivedQuantity += parseFloat(element.Yield?.toString()) ?? 0;
      if (isNaN(this.RequestModel.ReceivedQuantity)) {
        this.RequestModel.ReceivedQuantity = 0;
      }
    });
    this.cdr.detectChanges();
  }


  SkipFn() {
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure you want to skip and remove this step?',
      nzOnOk: () => this.SkipFnService(),
    });
    setTimeout(() => modal.destroy(), 5000);
  }


  SkipFnService() {
    if (this.Type == 'Print') {
      this.PrintModel = new SaleOrderPostProcessPrintModel;
      this.PrintModel.AddedBy = UserInfo.EmailID;
      this.PrintModel.SaleOrderId = this.Production.SaleOrderId;
      this.PrintModel.SaleOrderPostProcessPrintId = this.RequestModel.Id;
      this.PrintModel.PrintStatus = 'Remove';

      let url = this.ApiUrl + "saleorder/addpostprocessprint";
      this.http.post<any>(url, this.PrintModel).subscribe({
        next: res => {
          this.Clear();
          this.GetSalesorderDatabyid(this.Production.SaleOrderId, this.Type);
          this.alertService.success(res);
          this.GetPostProcessList();
          this.isVisible = false;
        },
        error: res => { this.alertService.error(res.error.ResponseBody); },
      });
    }
    if (this.Type == 'Embossing') {
      this.EmbossingModel = new SaleOrderPostProcessEmbossingModel;
      this.EmbossingModel.AddedBy = UserInfo.EmailID;
      this.EmbossingModel.SaleOrderId = this.Production.SaleOrderId;
      this.EmbossingModel.SaleOrderPostProcessEmbossingId = this.RequestModel.Id;
      this.EmbossingModel.EmbossingStatus = 'Remove';
      let url = this.ApiUrl + "saleorder/addpostprocessembossing";
      this.http.post<any>(url, this.EmbossingModel).subscribe({
        next: res => {
          this.Clear();
          this.GetSalesorderDatabyid(this.Production.SaleOrderId, this.Type);
          this.alertService.success(res);
          this.GetPostProcessList();
          this.isVisible = false;
        },
        error: res => { this.alertService.error(res.error.ResponseBody); },
      });
    }
    if (this.Type == 'Vaccum') {
      this.VacuumModel = new SaleOrderPostProcessVacuumModel;
      this.VacuumModel.AddedBy = UserInfo.EmailID;
      this.VacuumModel.SaleOrderId = this.Production.SaleOrderId;
      this.VacuumModel.SaleOrderPostProcessVacuumId = this.RequestModel.Id;
      this.VacuumModel.VacuumStatus = 'Remove';
      let url = this.ApiUrl + "saleorder/addpostprocessvacuum";
      this.http.post<any>(url, this.VacuumModel).subscribe({
        next: res => {
          this.Clear();
          this.GetSalesorderDatabyid(this.Production.SaleOrderId, this.Type);
          this.alertService.success(res);
          this.GetPostProcessList();
          this.isVisible = false;
        },
        error: res => { this.alertService.error(res.error.ResponseBody); },
      });
    }
    if (this.Type == 'Tumbling') {
      this.TumblingModel = new SaleOrderPostProcessTumblingModel;
      this.TumblingModel.AddedBy = UserInfo.EmailID;
      this.TumblingModel.SaleOrderId = this.Production.SaleOrderId;
      this.TumblingModel.SaleOrderPostProcessTumblingId = this.RequestModel.Id;
      this.TumblingModel.TumblingStatus = 'Remove';
      let url = this.ApiUrl + "saleorder/addpostprocesstumbling";
      this.http.post<any>(url, this.TumblingModel).subscribe({
        next: res => {
          this.Clear();
          this.GetSalesorderDatabyid(this.Production.SaleOrderId, this.Type);
          this.alertService.success(res);
          this.GetPostProcessList();
          this.isVisible = false;
        },
        error: res => { this.alertService.error(res.error.ResponseBody); },
      });
    }
    if (this.Type == 'Lacquar') {
      this.LacquerModel = new SaleOrderPostProcessLacquerModel;
      this.LacquerModel.AddedBy = UserInfo.EmailID;
      this.LacquerModel.SaleOrderId = this.Production.SaleOrderId;
      this.LacquerModel.SaleOrderPostProcessLacquerId = this.RequestModel.Id;
      this.LacquerModel.LacquerStatus = 'Remove';
      let url = this.ApiUrl + "saleorder/addpostprocesslacquer";
      this.http.post<any>(url, this.LacquerModel).subscribe({
        next: res => {
          this.Clear();
          this.GetSalesorderDatabyid(this.Production.SaleOrderId, this.Type);
          this.alertService.success(res);
          this.GetPostProcessList();
          this.isVisible = false;
        },
        error: res => { this.alertService.error(res.error.ResponseBody); },
      });
    }
  }
  enableAcceptProcessBtn() {
    if (this.permission.Add) {
      this.acceptProcessBtnToolTipText = "Click to accept to process this order."
      return false;
    }
    else {
      this.acceptProcessBtnToolTipText = "You don't have acccess. Check with your Supervisor or Admin."
      return true;
    }
  }
  enableSkipRemoveBtn() {
    if (this.permission.Delete) {
      this.removeBtnToolTipText = "Click to remove this process from the Sale Order."
      return false;
    }
    else {
      this.removeBtnToolTipText = "You don't have acccess. Check with your Supervisor or Admin."
      return true;
    }
  }
  GetAllCustomer() {
    let url = this.ApiUrl + "customer/getallcustomers";
    this.http.get<CustomerModel[]>(url).subscribe(res => {
      this.CustomerList = res;
    }, res => {
      this.count++
      if (this.count < 2) {
        this.GetAllCustomer()
      }
    });
  }
  GetAllFormulationCode() {
    let url = this.ApiUrl + "saleorder/getallformulationcodes";
    this.http.get<FormulationCodeModel[]>(url).subscribe(res => {
      this.FormulationCodeList = res;
    }, res => {
      this.count++
      if (this.count < 2) {
        this.GetAllFormulationCode()
      }
    });
  }
  GetAllColor() {
    let url = this.ApiUrl + "Color/getallColors";
    this.http.get<ColorModel[]>(url).subscribe(res => {
      this.ColorList = res;
    }, res => {
      this.count++
      if (this.count < 2) {
        this.GetAllColor()
      }
    });
  }
  GetAllGrain() {
    let url = this.ApiUrl + "Grain/getallGrains";
    this.http.get<GrainModel[]>(url).subscribe(res => {
      this.GrainList = res;
    }, res => {
      this.count++
      if (this.count < 2) {
        this.GetAllGrain()
      }
    });
  }
  onFilterPanelOpen(data: any) {
    if (data == true) {
      this.GetAllCustomer();
      this.GetAllFormulationCode();
      this.GetAllColor();
      this.GetAllGrain();
    }
  }
  onFilterChange() {
    sessionStorage.setItem('postprocessrequestfilters', JSON.stringify(this.request));
  }
  space(el: any) {
    if (el.target.selectionStart === 0 && el.code === "Space") {
      el.preventDefault();
    }
  }
  OpenViewPop(data: any) {
    this.soDrawer.SaleOrderId = data;
    this.soDrawer.show();
  }
  GetFactoryWorkersByDepartment() {
    this.isTableLoading = true;
    this.FactoryWorkersList = [];
    // var request = {
    //   "DepartmentName": "Post Process"
    // }
    let url = this.ApiUrl + "factoryworkers/getallfactoryworkerswithfilters";
    this.http.post<FactoryWorkersModel[]>(url, {}).subscribe({
      next: res => {
        this.FactoryWorkersList = res;
        this.isTableLoading = false;
      },
      error: res => {
        this.count++;
        if (this.count < 2) {
          this.GetFactoryWorkersByDepartment();
        }
      }
    });
  }
  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.RequestModel.EndDateTime) {
      return false;
    }
    return startValue.getTime() > this.RequestModel.EndDateTime.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.RequestModel.StartDateTime) {
      return false;
    }

    // First compare dates without time
    const startDate = new Date(
      this.RequestModel.StartDateTime.getFullYear(),
      this.RequestModel.StartDateTime.getMonth(),
      this.RequestModel.StartDateTime.getDate()
    );
    const endDate = new Date(endValue.getFullYear(), endValue.getMonth(), endValue.getDate());

    // Allow same date, only disable past dates
    return endDate < startDate;
  };

  handleStartTimeSelection = (time: CompatibleDate): void => {
    if (time && this.RequestModel.EndDateTime) {
      // Convert to Date object if it isn't already
      const startDateTime = time instanceof Date ? time : new Date(time as any);
      const endDateTime = this.RequestModel.EndDateTime;

      // If same date, validate time
      const sameDate = startDateTime.getFullYear() === endDateTime.getFullYear() &&
        startDateTime.getMonth() === endDateTime.getMonth() &&
        startDateTime.getDate() === endDateTime.getDate();

      if (sameDate && startDateTime.getTime() >= endDateTime.getTime()) {
        // Reset time or show error message
        this.alertService.error('Start time must be before end time');
        this.RequestModel.StartDateTime = null;
      }
    }
  };

  handleEndTimeSelection = (time: CompatibleDate): void => {
    if (time && this.RequestModel.StartDateTime) {
      // Convert to Date object if it isn't already
      const endDateTime = time instanceof Date ? time : new Date(time as any);
      const startDateTime = this.RequestModel.StartDateTime;

      // If same date, validate time
      const sameDate = startDateTime.getFullYear() === endDateTime.getFullYear() &&
        startDateTime.getMonth() === endDateTime.getMonth() &&
        startDateTime.getDate() === endDateTime.getDate();

      if (sameDate && endDateTime.getTime() <= startDateTime.getTime()) {
        // Reset time or show error message
        this.alertService.error('End time must be after start time');
        this.RequestModel.EndDateTime = null;
      }
    }
  };

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endDatePicker.open();
    }
  }
  OpenPrdDowntimeModal() {
    this.prdDowntimeService.openModal(null, null);
  }
}

import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { ProductCategoryModel, ProductFirstSubCategoryModel, ProductSecSubCategoryModel } from '../../Models/MasterModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { Modules, Responsibility } from '../../Models/Enums';
import { Router } from '@angular/router';
import { AuthService } from '../../Services/auth.service';
import { PurchaseOrderModel } from '../../Models/PurchaseOrderModel';
import { LoadingService } from '../../Services/loadingService';
import { ProductModel } from '../../Models/ProductModel';
import { HttpClient } from '@angular/common/http';
import { YieldReportModel } from '../../Models/ReportModel';
import { UserInfo } from 'src/PmsUIApp/Authentication/UserInfo';
import { UserRoleModel } from '../../Models/UserModel';

@Component({
  selector: 'app-YieldReport',
  templateUrl: './YieldReport.component.html'
})

export class YieldReportComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  DemandList: YieldReportModel[] = [];
  DemandListOriginal: YieldReportModel[] = [];
  PurchaseOrder!: PurchaseOrderModel;
  PurchaseOrderList: PurchaseOrderModel[] = [];
  IsBtnVisible: boolean = false;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  SelectedProductType: string = '';
  SelectedStatus: string = '';
  FilteredProductList: ProductModel[] = [];
  ProductList: ProductModel[] = [];
  CategoryID: number = 0;
  FirstCategoryID: number = 0;
  SecondCategoryID: number = 0;
  selectedProduct: number = 0;
  ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  ProductCategoryList: ProductCategoryModel[] = [];
  FilteredProductCategoryList: ProductCategoryModel[] = [];
  request = { DateFrom: new Date(new Date(new Date().setHours(0, 0, 1))), DateTo: new Date(new Date().setHours(23, 59, 59)), FabricProductId: 0 }
  count: number;
  isEmailReportEnabled: boolean = false;
  isEmailButtonLoading: boolean = false;
  OverPRDPerc: number = 0;
  totalItemsCount: number = 0;

  constructor(private loader: LoadingService, public http: HttpClient, private router: Router, private auth: AuthService, private alertService: AlertMessageService, private modalService: NzModalService) {

  }
  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.ReportsYield, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.ReportsYield, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.ReportsYield, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    console.log(this.permission)
    this.GetAllProducts();
    this.GetAllProductCategory();
    this.YieldReport();
    this.disabledEmailReport();
    this.isEmailReportEnabled = UserInfo.UserRolesMaster.filter(x => x.UserRoleName.toLowerCase().includes('super admin')).length > 0 ? true : false;
  }

  YieldReport() {
    this.isTableLoading = true; let url = this.ApiUrl + "Report/yieldReport";
    let count = this.totalItemsCount = 0;
    this.http.post<YieldReportModel[]>(url, this.request).subscribe(res => {
      console.log(res)
      this.DemandListOriginal = this.DemandList = res;
      count = this.totalItemsCount;
      this.DemandList.forEach((x) => {
        count++;
        x.SerialNo = count;
      })
      this.totalItemsCount = count;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) { this.YieldReport(); }
    });
  }

  CreatePO() {
    this.router.navigate(['/home/po/adddemand/0']);
  }
  OpenViewPop(data: any) {
    console.log(data)
    if (this.PurchaseOrderList.length <= 0) {
      let url = this.ApiUrl + 'purchaseorder/getallpurchaseordersforlist';
      this.loader.show();
      this.http.get<PurchaseOrderModel[]>(url).subscribe(
        (res) => {
          this.PurchaseOrderList = res;
          this.PurchaseOrder = this.PurchaseOrderList.filter(x => x.Poid == data.Poid)[0];
          console.log(this.PurchaseOrder)
          this.isVisible = true;
          this.loader.hide();
        },
        () => { }
      );
    }
    else {
      this.PurchaseOrder = this.PurchaseOrderList.filter(x => x.Poid == data.Poid)[0];
      this.isVisible = true;
    }

  }
  handleCancel(): void {
    this.isVisible = false;
  }
  onSelectedProductTypeChange() {
    this.FilteredProductList = this.ProductList.filter(
      (x) => x.ProductType == this.SelectedProductType
    );
  }
  GetAllProducts() {
    let url = this.ApiUrl + 'product/getallproducts';
    this.http.get<ProductModel[]>(url).subscribe(
      (res) => {
        this.ProductList = res;
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllProducts();
        }
      }
    );
  }
  GetAllProductCategory() {
    let url = this.ApiUrl + 'productcategory/getallproductcategoriesforlisting';
    this.http.get<ProductCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductCategoryList = res;
      },
      (res) => { }
    );
  }

  GetAllFirstCategory(data: any, id: number = 0) {
    this.FilteredProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductCategoryId == this.CategoryID
    );
    let url = this.ApiUrl + 'productcategory/getallproductfirstsubcategories';
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductFirstSubCategoryList = res.filter(
          (x) => x.ProductCategoryId == data
        );

      },
      (res) => { }
    );
  }
  GetAllSecondCategory(data: any, id: number = 0) {
    this.FilteredProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductFirstSubCategoryId == this.FirstCategoryID
    );
    let url = this.ApiUrl + 'productcategory/getallproductsecsubcategories';
    this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductSecSubCategoryList = res.filter(
          (x) => x.ProductFirstSubCategoryId == data
        );
      },
      (res) => { }
    );
  }
  GetSecondCategoryFilteredProduct() {
    this.FilteredProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductSecSubCategoryId == this.SecondCategoryID
    );
  }
  onSelectedProductChange(data: any) {
    var fp = this.FilteredProductList.filter(
      (x) => x.ProductId == data
    )[0];
    //this.NewStockProduct.Unit = fp.Unit;
    this.CategoryID = fp.ProductCategoryId;
    let url = this.ApiUrl + 'productcategory/getallproductfirstsubcategories';
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductFirstSubCategoryList = res.filter(
          (x) => x.ProductCategoryId == this.CategoryID
        );
        this.FirstCategoryID = fp.ProductFirstSubCategoryId;
        let url = this.ApiUrl + 'productcategory/getallproductsecsubcategories';
        this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(
          (res) => {
            this.ProductSecSubCategoryList = res.filter(
              (x) => x.ProductFirstSubCategoryId == this.FirstCategoryID
            );
            this.SecondCategoryID = fp.ProductSecSubCategoryId;
          },
          (res) => { }
        );
      },
      (res) => { }
    );
  }
  GetReport() {
    var res = this.DemandListOriginal;
    //this.DemandList = res.filter((item: DemandModel) => item.ProductType == this.SelectedProductType || this.SelectedProductType == '')
    //  .filter((item: DemandModel) => item.ProductCategoryId == this.CategoryID || this.CategoryID == 0 || this.CategoryID == null)
    //  .filter((item: DemandModel) => item.ProductFirstSubCategoryId == this.FirstCategoryID || this.FirstCategoryID == 0 || this.FirstCategoryID == null)
    //  .filter((item: DemandModel) => item.ProductSecSubCategoryId == this.SecondCategoryID || this.SecondCategoryID == 0 || this.SecondCategoryID == null)
    //  .filter((item: DemandModel) => item.ProductId == this.selectedProduct || this.selectedProduct == 0 || this.selectedProduct == null)
    //  .filter((item: DemandModel) => item.Status == this.SelectedStatus || this.SelectedStatus == null)
    //console.log(this.SelectedProductType)
    //this.DemandList = res.filter((item: DemandModel) => item.ProductId == this.selectedProduct || this.selectedProduct == 0 || this.selectedProduct == null);
  }
  CalculateYield(data: YieldReportModel) {
    var yieldnumber = (data.FirstGrade + data.AGrade + data.SampleQuantity) * 100 / this.CalculateGRDTotal(data)
    return parseFloat(yieldnumber.toFixed(2))
  }
  CalculateTotal(column: string) {
    var total = 0;
    switch (column) {
      case column = "SaleOrderQuantity": {
        total = this.DemandList.reduce((sum, current) => sum + parseFloat(current.SaleOrderQuantity?.toString()), 0) ?? 0
        break;
      }
      case column = "ManufacturingQuantity": {
        total = this.DemandList.reduce((sum, current) => sum + parseFloat(current.ManufacturingQuantity?.toString()), 0) ?? 0
        break;
      }
      case column = "PRDOverPerc": {
        total = this.DemandList.reduce((sum, current) => sum + parseFloat((((current.ManufacturingQuantity - current.SaleOrderQuantity) / current.SaleOrderQuantity) * 100)?.toString()), 0) ?? 0
        break;
      }
      case column = "FirstGrade": {
        total = this.DemandList.reduce((sum, current) => sum + parseFloat(current.FirstGrade?.toString()), 0) ?? 0
        break;
      }
      case column = "AGrade": {
        total = this.DemandList.reduce((sum, current) => sum + parseFloat(current.AGrade?.toString()), 0) ?? 0
        break;
      }
      case column = "LOTGrade": {
        total = this.DemandList.reduce((sum, current) => sum + parseFloat(current.LOTGrade?.toString()), 0) ?? 0
        break;
      }
      case column = "NSGrade": {
        total = this.DemandList.reduce((sum, current) => sum + parseFloat(current.NSGrade?.toString()), 0) ?? 0
        break;
      }
      case column = "CUTPCGrade": {
        total = this.DemandList.reduce((sum, current) => sum + parseFloat(current.CUTPCGrade?.toString()), 0) ?? 0
        break;
      }
      case column = "SampleQuantity": {
        total = this.DemandList.reduce((sum, current) => sum + parseFloat(current.SampleQuantity?.toString()), 0) ?? 0
        break;
      }
      case column = "SCRP": {
        total = this.CalculateSCRP(this.DemandList.reduce((sum, current) => sum + parseFloat(current.FILMGrade?.toString()), 0) ?? 0, this.DemandList.reduce((sum, current) => sum + parseFloat(current.WASTEGrade?.toString()), 0) ?? 0)
        break;
      }
      case column = "Amount": {
        total = this.DemandList.reduce((sum, current) => sum + parseFloat(this.CalculateGRDTotal(current).toString()), 0) ?? 0
        break;
      }
      case column = "Yield": {
        total = this.CalculateTotalYield(this.DemandList.reduce((sum, current) => sum + parseFloat(current.FirstGrade?.toString()), 0) ?? 0,
          this.DemandList.reduce((sum, current) => sum + parseFloat(current.AGrade?.toString()), 0) ?? 0,
          this.DemandList.reduce((sum, current) => sum + parseFloat(current.SampleQuantity?.toString()), 0) ?? 0,
          this.DemandList.reduce((sum, current) => sum + parseFloat(this.CalculateGRDTotal(current).toString()), 0) ?? 0)
        if (isNaN(total)) {
          total = 0;
        }
        break;
      }

    }
    return parseFloat(total.toFixed(2));

  }
  CalculateSCRP(FilmGrade: number, WASTEGrade: number) {
    var total = FilmGrade + WASTEGrade
    return parseFloat(total.toFixed(2))
  }
  CalculateTotalYield(FirstGrade: number, AGrade: number, SampleGrade: number, GRDTotal: number) {
    var yieldnumber = (FirstGrade + AGrade + SampleGrade) * 100 / GRDTotal
    return parseFloat(yieldnumber.toFixed(2))
  }
  CalculateGRDTotal(data: YieldReportModel) {
    var grandtotal = (data.FirstGrade + data.AGrade + data.LOTGrade + data.NSGrade + data.CUTPCGrade + data.SampleQuantity + data.FILMGrade + data.WASTEGrade)
    return parseFloat(grandtotal.toFixed(2))
  }

  sendEmail() {
    this.isEmailButtonLoading = true;
    let url = this.ApiUrl + "Report/yieldreportondemandemail";
    this.http.post<YieldReportModel[]>(url, this.request).subscribe(res => {
      this.isEmailButtonLoading = false;
      this.alertService.success('Email Sent Successfully!!');
    }, res => {
      this.count++;
      if (this.count < 2) { this.sendEmail(); }
    });
    this.isEmailButtonLoading = false;
  }
  disabledEmailReport() {
    if (UserInfo.UserRolesMaster.filter(x => x.UserRoleName.toLowerCase() == 'super admin')) {
      this.isEmailReportEnabled = true;
    }
    else {
      this.isEmailReportEnabled = false;
    }
  }
  setPRDMTRCellColor(data: YieldReportModel) {
    if (data.ManufacturingQuantity >= (((data.SaleOrderQuantity / 100) * 5) + data.SaleOrderQuantity)) {
      return '#ffa500'
    } else if (data.ManufacturingQuantity < data.SaleOrderQuantity) {
      return '#ff0000'
    }
    else if (data.ManufacturingQuantity <= (((data.SaleOrderQuantity / 100) * 5) + data.SaleOrderQuantity) || data.ManufacturingQuantity == data.SaleOrderQuantity) {
      return '#84fc84'
    }
    else {
      return ''
    }
  }
  setYieldCellColor(yieldperc: number) {
    if (yieldperc >= 94) {
      return '#87d068'
    } else if (yieldperc < 94 && yieldperc >= 90) {
      return '#ffa500'
    }
    else if (yieldperc < 90) {
      return '#ff0000'
    }
    else {
      return ''
    }
  }
  setGRDTotalCellColor(data: YieldReportModel) {
    var grdtotal = this.CalculateGRDTotal(data);
    if (grdtotal < data.SaleOrderQuantity) {
      return '#ff0000'
    }
    else {
      return ''
    }
  }
  calculateOverPRDPerc(data: YieldReportModel) {
    return parseFloat((((data.ManufacturingQuantity - data.SaleOrderQuantity) / data.SaleOrderQuantity) * 100).toFixed(2))
  }
  calculateTotalOverPRDPerc() {
    var totalPerc = 0;
    totalPerc = parseFloat((((this.CalculateTotal('ManufacturingQuantity') - this.CalculateTotal('SaleOrderQuantity')) / this.CalculateTotal('SaleOrderQuantity')) * 100).toFixed(2));
    if (!isNaN(totalPerc)) {
      return totalPerc
    }
    else {
      return 0
    }
  }
}

export class ProductionDowntimeReasonModel {
  SerialNo: number = 0;
  ProductionDowntimeReasonId: number = 0;
  ReasonCode: string = '';
  ReasonName: string = '';
  Description: string = '';
  DowntimeType: string = '';
  StatusColor: string = '';
  ProductionLineType: string = '';
  StandardDurationMinutes: number = 0;
  IsActive: boolean = false;
  CreatedBy: string = '';
  CreatedOn: string = '';
  ModifiedBy: string = '';
  ModifiedOn: string = '';
  Action: string = '';
}

export class ProductionDowntimeModel {
  SerialNo: number = 0;
  ProductionDowntimeId: number = 0;
  ProductionDowntimeReasonId: number = 0;
  ReasonName: string = '';
  ReasonCode: string = '';
  ProductionLineType: string = '';
  ProductionLineNo: number = 0;
  StartTime: string = '';
  EndTime: string = '';
  StandardDurationMinutes: number = 0;
  StandardDurationFormatted: string = '';
  ActualDurationMinutes: number = 0;
  ActualDurationFormatted: string = '';
  ExcessDurationMinutes: number = 0;
  ExcessDurationFormatted: string = '';
  Comments: string = '';
  CreatedBy: string = '';
  CreatedOn: string = '';
  ModifiedBy: string = '';
  ModifiedOn: string = '';
  IsDeleted: boolean = false;
}

export class ScheduledDowntimeModel {
  SerialNo: number = 0;
  ScheduledDowntimeId: number = 0;
  ProductionDowntimeReasonId: number = 0;
  Reason: ProductionDowntimeReasonModel = new ProductionDowntimeReasonModel();
  StartTime: string = '';
  StartDateTime: string = '';
  EndTime: string = '';
  EndDateTime: string = '';
  RecurrencePattern: string = '';
  ApplicableDays: string = '';
  DayOfMonth: number = 0;
  IsRecurring: boolean = false;
  EffectiveFrom: string = '';
  EffectiveTo: string = '';
  ProductionLineNo: number = 0;
  IsActive: boolean = false;
  CreatedBy: string = '';
  CreatedOn: string = '';
  ModifiedBy: string = '';
  ModifiedOn: string = '';
}
export interface DowntimeServiceModalData {
  productionLineType: string;
  downtimeData?: ProductionDowntimeModel;
}
<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title> Jumbo Master</nz-page-header-title>
      <nz-page-header-subtitle>Add new</nz-page-header-subtitle>
      <nz-page-header-extra>
        <button nz-button nzType="primary" (click)="OpenPrdDowntimeModal()" *ngIf="DowntimePermission.Add">Add Downtime</button>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-divider></nz-divider>
    <div nz-row [nzGutter]="24">
      <div nz-col [nzSpan]="8">
        <nz-form-item>
          <nz-form-control [nzSpan]="24" nzErrorTip="Select Product Category">
            <label>WorkPlan No </label>
            <nz-select class="form-select" nzShowSearch name="WorkPlanNo" [(ngModel)]="SelectedWorkPlan"
              nzSize="default" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="OnWorkPlanChange()">
              <nz-option *ngFor="let s of this.WorkPlanList;" [nzValue]="s" [nzLabel]="s.WorkPlanNo"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>



      </div>
      <nz-table style="width: 100%" [nzPageSize]="100" nzSize="small" [nzScroll]="{ x: '1200px', y: '515px' }"
        #basicTable [nzData]="['']" [nzShowPagination]="false" [nzLoading]="isTableLoading" nzBordered>
        <thead>
          <tr>
            <th nzWidth="100px">Order Type</th>
            <th nzWidth="100px">Order No</th>
            <th nzWidth="150px">Buyer</th>
            <th nzWidth="150px">Category</th>
            <!-- <th nzWidth="100px">Code</th> -->
            <th nzWidth="100px">Order QTY</th>
            <th nzWidth="100px">MFG QTY</th>
            <!--<th nzWidth="150px">Unit</th>-->

            <th nzWidth="180px" style="text-align: center" nzRight>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of SelectedWorkPlanOrder" [ngStyle]="{'background-color': GetBGColor(data)}">

            <td>{{ data.SaleOrder.SaleOrderType }}</td>
            <td>
              <nz-tag nzColor="blue"><a (click)="OpenSaleOrderViewPop(data.SaleOrder.SaleOrderId)">
                  {{ data.SaleOrder.SaleOrderNumber }}
                </a></nz-tag>
            </td>
            <td>{{ data.SaleOrder.CustomerName}}</td>
            <td>{{ data.SaleOrder.Category }}</td>
            <!-- <td>{{ data.SaleOrder.SaleFormulationCode}}</td> -->
            <td>{{ data.SaleOrder.SaleOrderProduction.OrderQuantity}}</td>
            <td>{{ data.SaleOrder.SaleOrderProduction.ManufacturingQuantity}}</td>
            <!-- <td>{{ data.SaleOrder.SaleOrderProduction.ManufacturingQuantity}}
             &nbsp;&nbsp; <button class="btn btn-sm btn-light-primary" *ngIf="!data.IsEdit" (click)="EditManufacturingQuantity(data)">Edit</button>
             <span *ngIf="data.IsEdit">
              <br>
              <input [(ngModel)]="data.TempManufacturingQTY" style="width: 30%;"/>
              &nbsp;&nbsp;<button class="btn btn-sm btn-light-success" [disabled]="data.TempManufacturingQTY < 1" (click)="SaveManufacturingQuantity(data)">Save</button>
              <button class="btn btn-sm btn-light-danger" (click)="CancelSave(data)">Cancel</button>
            </span>
            </td> -->
            <!--<td></td>-->
            <td nzRight nzWidth="180px" style="text-align: center">
              <button class="btn btn-sm btn-light-danger" [hidden]="hideJumboAddbtn(data)"
                (click)="OnSelectOrderNo(data)">{{this.jumboAddbtnText}}</button>
            </td>
          </tr>
        </tbody>
      </nz-table>
      <div *ngIf="SelectedOrder" nz-col [nzSpan]="24">
        <nz-divider nzText="ORDER NO - {{SelectedOrder.SaleOrder.SaleOrderNumber}}"></nz-divider>
        <!-- <div *ngIf="!isQtyFull"> -->
        <div>
          <div nz-row [nzGutter]="24" style="border: 1px dotted #b5b5c3; padding: 10px; margin-bottom: 10px; ">
            <!-- <div nz-col [nzSpan]="6">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="required">Jumbo Roll Date</label>
                  <nz-date-picker [(ngModel)]="jumboPlanModel.JumboRollDate" class="form-select mb-2"
                    nzAllowClear="false" nzFormat="dd-MMM-yyy"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div> -->
            <div nz-col [nzSpan]="6">
              <nz-form-item>
                <nz-form-control [nzSpan]="15">
                  <label class="required">Start Date and Time</label>
                  <nz-date-picker [nzDisabledDate]="disabledStartDate" nzShowTime nzFormat="dd-MMM-yyyy HH:mm a"
                    [(ngModel)]="jumboPlanModel.JumboRollStartTime" nzPlaceHolder="Start"
                    (nzOnOpenChange)="handleStartOpenChange($event)" (nzOnOk)="handleStartTimeSelection($event)"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="6">
              <nz-form-item>
                <nz-form-control [nzSpan]="15">
                  <label class="required">End Date and Time</label>
                  <nz-date-picker #endDatePicker [nzDisabledDate]="disabledEndDate" nzShowTime
                    nzFormat="dd-MMM-yyyy HH:mm a" [(ngModel)]="jumboPlanModel.JumboRollEndTime" nzPlaceHolder="End"
                    (nzOnOpenChange)="handleEndOpenChange($event)" (nzOnOk)="handleEndTimeSelection($event)"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>

          </div>
          <div nz-row [nzGutter]="24" style="border: 1px dotted #b5b5c3; padding: 10px; margin-bottom: 10px; ">
            <!--<div nz-col [nzSpan]="8">
      <nz-form-item>

        <nz-form-control [nzSpan]="24">
          <label class="required">Jumbo No.</label>
          <input nz-input   [(ngModel)]="jumboPlanModel.JumboNo" />
        </nz-form-control>
      </nz-form-item>

    </div>-->
            <div nz-col [nzSpan]="7">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="required">Supervisor</label>
                  <nz-select nzShowSearch class="form-select mb-2" [(ngModel)]="jumboPlanModel.ShiftSupervisorWorkerId"
                    nzSize="default" nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.FactoryWorkersList;" [nzValue]="s.WorkerId" [nzLabel]="s.Name">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="7">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="required">Jumbo Roll QTY</label>
                  <input nz-input [(ngModel)]="jumboPlanModel.JumboRolQty" (change)="calculateAmount()" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="7">
              <nz-form-item>

                <nz-form-control [nzSpan]="24">
                  <label class="required">Weight</label>
                  <input nz-input [(ngModel)]="jumboPlanModel.Weight" />
                </nz-form-control>
              </nz-form-item>

            </div>
            <div nz-col [nzSpan]="7">
              <nz-form-item>

                <nz-form-control [nzSpan]="24">
                  <label class="required">Store</label>
                  <nz-select nzShowSearch class="form-select mb-2" (ngModelChange)="onSelectedStoreChange()"
                    [(ngModel)]="jumboPlanModel.StoreId" nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.StoreList;" [nzValue]="s.StoreId" [nzLabel]="s.StoreName">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>

            </div>

            <div nz-col [nzSpan]="7">
              <nz-form-item>

                <nz-form-control [nzSpan]="24">
                  <label class="required">Rack</label>
                  <nz-select class="form-select" nzShowSearch name="Rack" nzSize="default" nzAllowClear
                    [(ngModel)]="jumboPlanModel.RackId" nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.RackList;" [nzValue]="s.RackId" [nzLabel]="s.RackName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>

            </div>
            <div nz-col [nzSpan]="3">
              <a class="btn btn-sm btn-light-primary" style="float: right; margin-top: 20px; width: 100px;"
                (click)="btnAddClickSaveSingle()"><i nz-icon nzType="plus-circle" nzTheme="fill"
                  style=" margin-bottom: 3px;"></i>Add</a>
            </div>
          </div>
        </div>
        <table>
          <tr>
            <td style="width: 75%">
              <nz-table nzSize="small" style="width: 100%;" [nzData]="this.jumboTableData" #basicTable1 nzBordered
                [nzShowPagination]="false">
                <thead>
                  <tr>
                    <th>Jumbo No.</th>
                    <th> Date</th>
                    <th>StartTime</th>
                    <th>EndTime</th>
                    <th>Jumbo Roll QTY</th>
                    <th>Rate</th>
                    <th>Amount</th>
                    <th>Weight(K.G.)</th>


                    <!--<th>Rack</th>-->
                    <!-- <th>Lot No</th> -->
                    <!-- <th>Store</th> -->



                    <!--<th>Remark</th>-->
                    <th nzWidth="20%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of jumboTableData;let i=index">
                    <td>{{ data.JumboNo }}</td>
                    <td>{{ data.JumboRollDate }}</td>
                    <td>{{ data.JumboRollStartTime | date: 'hh:mm':'+0530' }}</td>
                    <td>{{ data.JumboRollEndTime | date: 'hh:mm':'+0530' }}</td>
                    <td>{{ data.JumboRolQty }}</td>
                    <td>{{ data.Rate }}</td>
                    <td>{{ data.Amount }}</td>
                    <td>{{ data.Weight }}</td>


                    <!-- <td>{{ data.RackCode }}</td> -->
                    <!--<td></td>-->
                    <!-- <td>{{ data.IGST }}</td> -->
                    <td>
                      <button class="btn btn-sm btn-light-primary" (click)="print(data)">Print</button>
                      <button class="btn btn-sm btn-light-danger" style="float:right  " nz-popconfirm
                        nzPopconfirmTitle="Are you sure to remove this jumbo roll entry?"
                        (nzOnConfirm)="handleRemoveRow(data)" (nzOnCancel)="cancelConfirm()" nzOkText="Confirm"
                        [disabled]="enableRemoveBtn()"><i nz-icon nzType="minus-circle" nzTheme="fill"
                          style=" margin-bottom: 3px;"></i>{{this.removeBtnText}}</button>
                    </td>
                  </tr>

                </tbody>
                <tfoot>
                  <!--<tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th>Total</th>
            <th><input nz-input name="GrandTotal" placeholder="Grand Total" [(ngModel)]="GrandTotal" [ngModelOptions]="{standalone: true}" /></th>
            <th></th>-->
                  <!-- <th nzWidth="20%">Action</th> -->
                  <!--</tr>-->
                </tfoot>
              </nz-table>
            </td>
            <td>
              <div nz-row [nzGutter]="24" style="margin: 0px 0px; margin-top: 10px;">

                <div nz-col [nzSpan]="33">
                  <nz-descriptions nzBordered nzLayout="vertical" [nzColumn]="2"
                    style="border: 2px dotted rgb(181, 181, 195); margin-bottom: 16px;margin-top: 8px;">
                    <nz-descriptions-item nzTitle="Total Jumbo Roll QTY" nzColon="true"><b>{{TotalJumboQty}}</b>
                    </nz-descriptions-item>
                    <nz-descriptions-item nzTitle="Total Jumbo Roll Weight " [nzSpan]="1" nzColon="true">
                      <b>{{TotalWeight}}</b>
                    </nz-descriptions-item>
                  </nz-descriptions>
                </div>
              </div>
            </td>
          </tr>
        </table>


        <br>
        <br>
        <div class="text-center">
          <button nz-button nzType="primary" [nzLoading]="isLoading" [disabled]="enableCompleteProductionBtn()"
            (click)="OpenPop()">Complete Production</button>
        </div>
      </div>

    </div>
  </div>
</div>

<nz-modal [(nzVisible)]="isPopVisible" [nzStyle]="{ top: '0px' }" [nzWidth]="950" [nzTitle]="modalTitle"
  [nzContent]="modalContent" [nzFooter]="modalFooter" (nzOnCancel)="handleCancel()">
  <ng-template #modalTitle>Complete Production</ng-template>

  <ng-template #modalContent>
    <div style="margin-bottom: 20px;">
      <p><b>To get After PRD GSM Values Automatically Click on Calculate GSM button but adjust if you are seeing
          difference in actual on
          Production Line and add the difference in Remark.</b></p>
      <p> <b style="color: red;">Here the GSM Calculation is based on Solid Content Paste Total Quantity and Solid
          Content Material Quantity.</b></p>
    </div>
    <div class="row gx-10 mb-5">
      <div class="col-lg-12" style="margin-bottom: 20px;" *ngIf="this.SelectedOrderPreSkinPasteReq > 0">
        <div class="row gx-10">
          <div class="col-lg-2">
            <label class="form-label"><b>
                <p>Pre Skin:</p>
                <p>STD. GSM: {{this.SelectedOrder?.SaleOrder.SaleOrderProduction.PreSkinGsm}}</p>
              </b>
            </label>
          </div>
          <div class="col-lg-2">
            <label class="form-label">After PRD GSM</label>
            <input type="number" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control"
              [(ngModel)]="completeSaleOrderProductionRequest.PreSkinScGsm" [ngModelOptions]="{standalone: true}" />
          </div>
          <div class="col-lg-2">
            <label class="form-label">Required Paste (Kgs):</label>
            <label class="form-label"><b>{{this.SelectedOrderPreSkinPasteReq}}</b></label>
          </div>
          <div class="col-lg-2">
            <label class="form-label">Actual Paste (Kgs)</label>
            <input type="number" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control"
              [(ngModel)]="SelectedOrderPreSkinActualPasteReq" (ngModelChange)="CalculateRemainingPasteQty('actual')"
              [ngModelOptions]="{standalone: true}" />
          </div>
          <div class="col-lg-3">
            <label class="form-label">Remaining Paste (Kgs)</label>
            <input type="number" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control"
              [(ngModel)]="completeSaleOrderProductionRequest.PreSkinRemainingPasteQty"
              (ngModelChange)="CalculateRemainingPasteQty('remaining')" [ngModelOptions]="{standalone: true}" />
          </div>
        </div>
      </div>
      <div class="col-lg-12" style="margin-bottom: 20px;" *ngIf="this.SelectedOrderSkinPasteReq > 0">
        <div class="row gx-10">
          <div class="col-lg-2">
            <label class="form-label"><b>
                <p>Skin:</p>
                <p>STD. GSM: {{this.SelectedOrder?.SaleOrder.SaleOrderProduction.SkinGsm}}</p>
              </b>
            </label>
          </div>
          <div class="col-lg-2">
            <label class="form-label">After PRD GSM</label>
            <input type="number" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control"
              [(ngModel)]="completeSaleOrderProductionRequest.SkinScGsm" [ngModelOptions]="{standalone: true}" />
          </div>
          <div class="col-lg-2">
            <label class="form-label">Required Paste (Kgs): </label>
            <label class="form-label"><b>{{this.SelectedOrderSkinPasteReq}}</b></label>
          </div>
          <div class="col-lg-2">
            <label class="form-label">Actual Paste (Kgs)</label>
            <input type="number" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control"
              [(ngModel)]="SelectedOrderSkinActualPasteReq" (ngModelChange)="CalculateRemainingPasteQty('actual')"
              [ngModelOptions]="{standalone: true}" />
          </div>
          <div class="col-lg-3">
            <label class="form-label">Remaining Paste (Kgs)</label>
            <input type="number" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control"
              [(ngModel)]="completeSaleOrderProductionRequest.SkinRemainingPasteQty"
              (ngModelChange)="CalculateRemainingPasteQty('remaining')" [ngModelOptions]="{standalone: true}" />
          </div>
        </div>
      </div>
      <div class="col-lg-12" style="margin-bottom: 20px;" *ngIf="this.SelectedOrderFoamPasteReq > 0">
        <div class="row gx-10">
          <div class="col-lg-2">
            <label class="form-label"><b>
                <p>Foam:</p>
                <p>STD. GSM: {{this.SelectedOrder?.SaleOrder.SaleOrderProduction.FoamGsm}}</p>
              </b>
            </label>
          </div>
          <div class="col-lg-2">
            <label class="form-label">After PRD GSM</label>
            <input type="number" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control"
              [(ngModel)]="completeSaleOrderProductionRequest.FoamScGsm" [ngModelOptions]="{standalone: true}" />
          </div>
          <div class="col-lg-2">
            <label class="form-label">Required Paste (Kgs):</label>
            <label class="form-label"><b>{{this.SelectedOrderFoamPasteReq}}</b></label>
          </div>
          <div class="col-lg-2">
            <label class="form-label">Actual Paste (Kgs)</label>
            <input type="number" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control"
              [(ngModel)]="SelectedOrderFoamActualPasteReq" (ngModelChange)="CalculateRemainingPasteQty('actual')"
              [ngModelOptions]="{standalone: true}" />
          </div>
          <div class="col-lg-3">
            <label class="form-label">Remaining Paste (Kgs)</label>
            <input type="number" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control"
              [(ngModel)]="completeSaleOrderProductionRequest.FoamRemainingPasteQty"
              (ngModelChange)="CalculateRemainingPasteQty('remaining')" [ngModelOptions]="{standalone: true}" />
          </div>
        </div>
      </div>
      <div class="col-lg-12" style="margin-bottom: 20px;" *ngIf="this.SelectedOrderAdhesivePasteReq > 0">
        <div class="row gx-10">
          <div class="col-lg-2">
            <label class="form-label"><b>
                <p>Adhesive:</p>
                <p>STD. GSM: {{this.SelectedOrder?.SaleOrder.SaleOrderProduction.AdhesiveGsm}}</p>
              </b>
            </label>
          </div>
          <div class="col-lg-2">
            <label class="form-label">After PRD GSM</label>
            <input type="number" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control"
              [(ngModel)]="completeSaleOrderProductionRequest.AdhesiveScGsm" [ngModelOptions]="{standalone: true}" />
          </div>

          <div class="col-lg-2">
            <label class="form-label">Required Paste (Kgs):</label>
            <label class="form-label"><b>{{this.SelectedOrderAdhesivePasteReq}}</b></label>
          </div>
          <div class="col-lg-2">
            <label class="form-label">Actual Paste (Kgs)</label>
            <input type="number" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control"
              [(ngModel)]="SelectedOrderAdhesiveActualPasteReq" (ngModelChange)="CalculateRemainingPasteQty('actual')"
              [ngModelOptions]="{standalone: true}" />
          </div>
          <div class="col-lg-3">
            <label class="form-label">Remaining Paste (Kgs)</label>
            <input type="number" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control"
              [(ngModel)]="completeSaleOrderProductionRequest.AdhesiveRemainingPasteQty"
              (ngModelChange)="CalculateRemainingPasteQty('remaining')" [ngModelOptions]="{standalone: true}" />
          </div>
        </div>
      </div>
      <div class="col-lg-12" style="margin-bottom: 20px;">
        <div class="row gx-10">
          <div class="col-lg-3"
            *ngIf="SelectedOrder?.SaleOrder.SaleOrderProduction.InspectionFormulationMixing[0]?.FabricGSM > 0">
            <label class="form-label"><b>Fabric GSM:
                {{SelectedOrder?.SaleOrder.SaleOrderProduction.InspectionFormulationMixing[0]?.FabricGSM}}</b></label>
          </div>
          <div class="col-lg-3">
            <label class="form-label"><b>Final GSM: {{CalculateFinalGSM()}}</b></label>
          </div>

        </div>
      </div>
    </div>
    <div class="row gx-10 mb-5">
      <div class="col-lg-9">
        <label class="form-label">Remark</label>
        <input type="text" nz-input style="background: #fff;color: #000;" nzSize="default" class="form-control "
          [(ngModel)]="completeSaleOrderProductionRequest.ProductionCompletionRemarks"
          [ngModelOptions]="{standalone: true}" maxlength="100" />
      </div>
    </div>
    <b>If you want to see more details then check Paste Consumption Report.</b>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="primary" (click)="GetPasteReqQtyToRecalculate()" [nzLoading]="isLoading">Calculate
      GSM</button>
    <button nz-button [disabled]="DisableCompletePrdBtn" nzType="primary" nz-popconfirm
      nzPopconfirmTitle="Are you sure all the GSM values are correct after the production?" (nzOnConfirm)="handleOk()"
      (nzOnCancel)="cancelConfirm()" nzOkText="Complete" [nzLoading]="isLoading">Save</button>
  </ng-template>
</nz-modal>

<app-SoDrawer></app-SoDrawer>
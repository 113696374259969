import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MeasureUnitModel } from 'src/PmsUIApp/Models/MeasureUnitModel';
import { environment } from 'src/environments/environment';
import { StockProductModel, StockProductPriceUpdateModel } from 'src/PmsUIApp/Models/StockProductModel';
import { SupplierModel } from 'src/PmsUIApp/Models/SupplierModel';
import { ProductModel } from 'src/PmsUIApp/Models/ProductModel';
import { StockModel } from 'src/PmsUIApp/Models/StockModel';
import { InvoiceModel } from 'src/PmsUIApp/Models/InvoiceModel';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { subscribeOn, Subscription } from 'rxjs';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';
import { FileUploadFullModel, FileUploadModel } from 'src/PmsUIApp/Models/UploadModel';
import { StorageService } from 'src/PmsUIApp/Services/storage.service';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';

@Component({
  templateUrl: './StockDetails.component.html',
  styleUrls: ['./StockDetails.component.css']
})
export class StockDetailsComponent {
  id: number = 0;
  private route$: Subscription = new Subscription;
  MeasureUnits: MeasureUnitModel[] = [];
  SupplierList: SupplierModel[] = [];
  count: 0;
  ProductList: ProductModel[] = [];;
  NewStockProduct: StockProductModel = new StockProductModel;
  FilteredProductList: ProductModel[] = [];;
  ApiUrl = environment.Api_Url;
  StockProductList: StockProductModel[] = [];;
  SelectedProductType: string = '';
  NewStock: StockModel = new StockModel;
  NewInvoice: InvoiceModel = new InvoiceModel;
  token: any;
  permission = {
    View: false,
    Add: false,
    Edit: false,
    Delete: false
  }
  StockProductPriceUpdate: StockProductPriceUpdateModel = new StockProductPriceUpdateModel;
  isPriceUpdateVisible: boolean = false;
  isMoreDetailsVisible: boolean = false;
  SelectedProductDetails: StockProductModel = new StockProductModel;
  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router, private auth: AuthService, private storage: StorageService,
    private alert: AlertMessageService
  ) { }
  getStorageToken(): any {
    let url = this.ApiUrl + "data/getstoragetokenforinvoice";
    this.http.get<any>(url).subscribe(res => {

      this.token = res;
      this.GetStockDetails();
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.getStorageToken();
      }
    });
  }
  GetStockDetails() {
    let url = this.ApiUrl + "stock/getstockbyid/" + this.id;
    this.http.get<StockModel>(url).subscribe(res => {
      this.NewStock = res;
      if (this.NewStock.Invoice.InvoiceFile != null && this.NewStock.Invoice.InvoiceFile != "") {
        this.NewStock.Invoice.InvoiceFile = this.token.StorageAccountHost + '/' + this.NewStock.Invoice.InvoiceFile + this.token.StorageAccountToken;
      }
      this.NewStock.StockProduct.forEach((nos) => {
        nos.ExpandRecord = false;
      })

    }, res => { });
  }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Stock, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Stock, Responsibility.Add);
    this.permission.Edit = this.auth.CheckResponsibility(Modules.Stock, Responsibility.Edit);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Stock, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.id = params["id"];
    })
    this.NewStock = new StockModel();
    this.getStorageToken();
    this.GetStockDetails();

  }
  hasInspectionFiles(): boolean {
    return this.NewStock.StockProduct.some(product =>
      product.FileUploads && product.FileUploads.length > 0
    );
  }
  getFileIcon(fileName: string): string {
    const ext = fileName.split('.').pop()?.toLowerCase();
    switch (ext) {
      case 'pdf':
        return 'file-pdf';
      case 'jpg':
      case 'jpeg':
      case 'png':
        return 'file-image';
      default:
        return 'file';
    }
  }
  async openFile(file: any): Promise<void> {
    try {
      const response = await this.storage.GetFileByFileUploadId(file.FileUploadId).toPromise();
      if (response && response.StorageBlobSasToken) {
        window.open(response.StorageBlobSasToken, '_blank');
      }
    } catch (error) {
      console.error('Error opening file:', error);
      // Handle error appropriately
    }
  }
  openPriceUpdate(data: StockProductModel) {
    this.StockProductPriceUpdate.StockId = this.NewStock.StockId;
    this.StockProductPriceUpdate.StockProductId = data.StockProductId;
    this.StockProductPriceUpdate.PricePerUnit = data.PricePerUnit;
    this.StockProductPriceUpdate.FreightPerUnit = data.FreightPerUnit || 0;
    this.StockProductPriceUpdate.InvoicePricePerUnit = data.InvoicePricePerUnit <= 0 ? data.PricePerUnit : data.InvoicePricePerUnit;
    this.StockProductPriceUpdate.MiscPerUnit = data.MiscPerUnit || 0;
    this.StockProductPriceUpdate.ShippingHandlingPerUnit = data.ShippingHandlingPerUnit || 0;
    this.isPriceUpdateVisible = true;
  }
  UpdateStockProduct() {
    // Validate if there are any changes in rates
    try {
      let hasChanges = false;
      const selectedProduct = this.NewStock.StockProduct.find(x => x.StockProductId === this.StockProductPriceUpdate.StockProductId);
      
      if (selectedProduct) {
        if (selectedProduct.InvoicePricePerUnit !== this.StockProductPriceUpdate.InvoicePricePerUnit ||
            selectedProduct.FreightPerUnit !== this.StockProductPriceUpdate.FreightPerUnit ||
            selectedProduct.MiscPerUnit !== this.StockProductPriceUpdate.MiscPerUnit ||
            selectedProduct.ShippingHandlingPerUnit !== this.StockProductPriceUpdate.ShippingHandlingPerUnit) {
          hasChanges = true;
        }
      }

      if (!hasChanges) {
        this.alert.warning("No changes detected in the rates");
        return;
      }
    } catch (error) {
      console.error("Error validating rate changes:", error);
      this.alert.error("Error validating rate changes");
      return;
    }
    let url = this.ApiUrl + "stock/updatestockproductpricebystockproductid/";
    this.http.post<StockProductPriceUpdateModel>(url, this.StockProductPriceUpdate).subscribe({
      next: (res) => {
        this.alert.success(res);
        this.isPriceUpdateVisible = false;
        this.GetStockDetails();
      },
      error: (err) => {
        this.alert.error(err.error);
        console.log(err.error);
      }
    });
  }
  handlePriceUpdateCancel() {
    this.isPriceUpdateVisible = false;
  }
  handlePriceUpdateOk() {
    if(this.StockProductPriceUpdate.InvoicePricePerUnit == null || this.StockProductPriceUpdate.InvoicePricePerUnit == 0) {
      this.alert.error("Please enter the price as per invoice. It should be greater than 0");
      return;
    }
    this.UpdateStockProduct();
  }
  handleMoreDetailsCancel() {
    this.isMoreDetailsVisible = false;
  }
  OpenMoreDetails(data: StockProductModel) {
    this.SelectedProductDetails = data;
    this.isMoreDetailsVisible = true;
  }
  calculateTotalPrice(data: StockProductPriceUpdateModel) {
    this.StockProductPriceUpdate.PricePerUnit = data.InvoicePricePerUnit + data.ShippingHandlingPerUnit + data.FreightPerUnit + data.MiscPerUnit;
  }
}

import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, ChangeDetectorRef, HostListener, ElementRef } from '@angular/core';
import { BarcodeScannerLivestreamOverlayComponent } from "ngx-barcode-scanner";
import { BarcodeScannerService } from 'src/PmsUIApp/Features/BarcodeLabelManagement/services/BarcodeScannerService';
import { Subscription } from 'rxjs';
import { FullDetailModalState, LabelFullDetailsModel, StockLabelModel, StockLabelResponseModel } from 'src/PmsUIApp/Models/BarcodeLabelModel';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { LoadingService } from '../../../../Services/loadingService';
import { BarcodeLabelUpdateService } from 'src/PmsUIApp/Features/BarcodeLabelManagement/services/BarcodeLabelUpdateService';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../../Services/auth.service';
import { Modules, Responsibility, ScannerMode } from '../../../../Models/Enums';

@Component({
    selector: 'app-BCScannerView',
    templateUrl: './BCScannerView.component.html',
    styleUrls: ['./BCScannerView.component.css']
})
export class BCScannerViewComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(BarcodeScannerLivestreamOverlayComponent)
    barcodeScannerOverlay: BarcodeScannerLivestreamOverlayComponent;

    @ViewChild('serialNoInput') serialNoInput: ElementRef;

    barcodeValue: string | null = null;
    isOpen: boolean = false;
    private subscriptions: Subscription = new Subscription();
    IsLabelDetailsVisible: boolean = false;
    IsLabelBasicDetailsVisible: boolean = false;
    LabelDetails: LabelFullDetailsModel = new LabelFullDetailsModel();
    LabelBasicDetails: StockLabelModel;
    ApiUrl = environment.Api_Url;
    LabelStatusColor: string = '';
    isMobile: boolean = false;
    manualInputVisible: boolean = false;
    manualInputForm: FormGroup;
    manualInputTitle: string = 'External Scanner Device';
    LabelInputType: string = '';
    manualInputBoxTitle: string = '';
    manualInputBoxPlaceholder: string = '';
    isOverlayOpen: boolean = false;
    isManualInputOpen: boolean = false;
    permission = {
        Edit: false
    }
    totalItemsCountScannedLabel: number = 0;
    scannedLabels: StockLabelResponseModel[] = [];
    currentAction: string = '';
    viewType: FullDetailModalState | null = null;

    constructor(
        private barcodeService: BarcodeScannerService,
        public http: HttpClient,
        private alertService: AlertMessageService,
        private loader: LoadingService,
        private barcodeLabelUpdateService: BarcodeLabelUpdateService,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private auth: AuthService
    ) {
        this.initManualInputForm();
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.barcodeService.scannerMode$.subscribe(mode => {
                this.updateScannerMode(mode);
                if (mode === ScannerMode.ExternalDevice) {
                    this.focusSerialNoInput();
                    this.manualInputTitle = 'External Scanner Device';
                    this.LabelInputType = 'ExternalDevice';
                    this.manualInputBoxTitle = 'Serial Number';
                    this.manualInputBoxPlaceholder = 'Enter Serial Number';
                }
                else if (mode === ScannerMode.ManualEntry) {
                    this.focusSerialNoInput();
                    this.manualInputTitle = 'Manual Entry';
                    this.LabelInputType = 'ManualEntry';
                    this.manualInputBoxTitle = 'Short Code';
                    this.manualInputBoxPlaceholder = 'Enter Short Code';
                }
            })
        );

        this.subscriptions.add(
            this.barcodeService.barcodeValue$.subscribe(value => {
                this.barcodeValue = value;
            })
        );

        this.subscriptions.add(
            this.barcodeService.scannedLabels$.subscribe(labels => {
                this.scannedLabels = labels;
            })
        );
        this.checkScreenSize();
        this.subscriptions.add(
            this.barcodeService.viewType$.subscribe(state => {
                this.viewType = state;
                console.log(this.viewType);
                if (state?.isVisible) {
                    const serialNo = state.SerialNo;
                    if (serialNo) {
                        console.log('Serial Set', serialNo);
                        this.fetchStockDetails(serialNo);
                    }
                }
            })
        );
    }

    ngAfterViewInit(): void {
        if (this.isOpen) {
            this.showOverlay();
        }
    }

    private updateScannerMode(mode: ScannerMode): void {
        this.isOverlayOpen = mode === ScannerMode.OverlayScanner;
        this.isManualInputOpen = mode === ScannerMode.ManualEntry || mode === ScannerMode.ExternalDevice;
        this.cdr.detectChanges();
        if (this.isOverlayOpen && this.barcodeScannerOverlay) {
            setTimeout(() => this.barcodeScannerOverlay.show(), 0);
        }
    }

    private focusSerialNoInput(): void {
        setTimeout(() => {
            if (this.serialNoInput && this.serialNoInput.nativeElement) {
                this.serialNoInput.nativeElement.focus();
            }
        });
    }

    showOverlay(): void {
        setTimeout(() => {
            if (this.barcodeScannerOverlay) {
                this.barcodeScannerOverlay.show();
                this.setHighZIndex();
            }
        }, 0);
    }
    setHighZIndex(): void {
        const overlayElement = document.querySelector('.barcode-scanner-livestream-overlay') as HTMLElement;
        if (overlayElement) {
            overlayElement.style.zIndex = '1000000'; // Set a very high z-index
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    onValueChanges(result: any): void {
        this.barcodeService.setBarcodeValue(result.codeResult.code);
        this.closeScanner();
        this.currentAction = this.barcodeService.getCurrentAction();
        if (this.currentAction == 'InspectionScan') {
            this.fetchBarcodeBasicDetails(result.codeResult.code);
            this.barcodeService.resetValues();
        } else if (this.currentAction == 'IssueRequestScan') {
            this.fetchBarcodeBasicDetails(result.codeResult.code);
        }
        else {
            this.fetchStockDetails(result.codeResult.code);
            this.barcodeService.resetValues();
        }
    }

    closeScanner(): void {
        this.barcodeService.setScannerMode(ScannerMode.Closed);
    }

    onStarted(event: boolean): void {
        console.log('BCScannerView: Scanner started', event);
        if (!event) {
            this.closeScanner();
        }
    }

    handleCancel(): void {
        this.closeScanner()
    }

    openManualInput(): void {
        this.barcodeService.setScannerMode(ScannerMode.ManualEntry);
    }

    handleManualInputCancel(): void {
        this.closeScanner();
        this.manualInputForm.reset();
    }

    fetchStockDetails(barcodeValue: string) {
        this.permission.Edit = this.auth.CheckResponsibility(Modules.StockBarcodeLabel, Responsibility.Edit);
        this.LabelDetails = new LabelFullDetailsModel();
        try {
            let request = {};
            if (this.LabelInputType == 'ExternalDevice') {
                request = {
                    SerialNo: barcodeValue,
                    ShortCode: '',
                    IsSerialNo: true,
                    IsShortCode: false
                }
            } else if (this.LabelInputType == 'ManualEntry') {
                request = {
                    SerialNo: '',
                    ShortCode: barcodeValue,
                    IsSerialNo: false,
                    IsShortCode: true
                }
            }
            let url = this.ApiUrl + `stock/getfullstockdetailbylabelserialno/`;
            this.http.post<LabelFullDetailsModel>(url, request).subscribe({
                next: (res) => {
                    this.LabelDetails = res;
                    this.barcodeService.setLabelDetails(this.LabelDetails);
                    this.SetLabelStatusColor();
                    this.IsLabelDetailsVisible = true;
                    this.manualInputForm.reset();
                    this.loader.hide();
                },
                error: (error) => {
                    this.IsLabelDetailsVisible = false;
                    this.LabelDetails = new LabelFullDetailsModel();
                    this.alertService.error(error.error);
                    this.loader.hide();
                }
            });
        } catch (error) {
            this.IsLabelDetailsVisible = false;
            this.LabelDetails = new LabelFullDetailsModel();
            this.alertService.error('An error occurred while fetching the stock details.');
            this.loader.hide();
        }
    }
    fetchBarcodeBasicDetails(barcodeValue: string) {
        this.LabelBasicDetails = new StockLabelModel();
        this.LabelDetails = new LabelFullDetailsModel();
        let request = {};
        if (this.LabelInputType == 'ExternalDevice') {
            request = {
                SerialNo: barcodeValue,
                ShortCode: '',
                IsSerialNo: true,
                IsShortCode: false
            }
        } else if (this.LabelInputType == 'ManualEntry') {
            request = {
                SerialNo: '',
                ShortCode: barcodeValue,
                IsSerialNo: false,
                IsShortCode: true
            }
        }
        let url = this.ApiUrl + 'stock/getstocklabelbyserialno';
        this.http.post<any>(url, request).subscribe({
            next: (res) => {
                this.LabelBasicDetails = res;
                this.LabelDetails.StockLabel = this.LabelBasicDetails;
                if (this.currentAction == 'IssueRequestScan') {
                    this.barcodeService.addScannedLabel(res, this.barcodeService.getCurrentProductId(), this.barcodeService.getCurrentFromStoreId());
                    this.loader.hide();
                }
                const CurrentScannedStockProductId = this.barcodeLabelUpdateService.getStockProductId();
                if (CurrentScannedStockProductId > 0 && CurrentScannedStockProductId != this.LabelBasicDetails.StockProductId) {
                    this.alertService.error('Current Scanned Product is not matching with the Product of the current batch in the label.');
                    this.loader.hide();
                    return;
                }
                if (this.currentAction == 'InspectionScan') {
                    this.barcodeService.setLabelDetails(this.LabelDetails);
                    this.openUpdateModal(this.LabelBasicDetails.SerialNo);
                }
            },
            error: (error) => {
                this.alertService.error(error.error.ResponseBody);
                this.loader.hide();
            }
        });
    }

    handleLabelDetailsCancel(): void {
        this.IsLabelDetailsVisible = false;
    }

    SetLabelStatusColor() {
        if (this.LabelDetails.StockLabel.LabelStatus == 'Active' || this.LabelDetails.StockLabel.LabelStatus == 'Updated') {
            return '#96f0b1';
        } else if (this.LabelDetails.StockLabel.LabelStatus == 'Pending' || this.LabelDetails.StockLabel.LabelStatus == 'Accepted' || this.LabelDetails.StockLabel.LabelStatus == 'Allocated') {
            return 'yellow';
        } else if (this.LabelDetails.StockLabel.LabelStatus == 'Rejected' || this.LabelDetails.StockLabel.LabelStatus == 'In-Active' || this.LabelDetails.StockLabel.LabelStatus == '') {
            return 'red';
        } else {
            return '';
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.checkScreenSize();
    }

    checkScreenSize() {
        this.isMobile = window.innerWidth < 768; // Adjust this breakpoint as needed
    }

    @HostListener('document:keydown.escape', ['$event'])
    handleEscapeKey(event: KeyboardEvent) {
        if (this.isOverlayOpen) {
            this.handleCancel();
        }
    }
    openUpdateModal(serialNo: string) {
        this.handleLabelDetailsCancel();
        this.barcodeLabelUpdateService.openModal(serialNo, 'Update');
    }
    initManualInputForm(): void {
        this.manualInputForm = this.fb.group({
            serialNo: [null, Validators.required]
        });
    }

    fetchLabelDetailsManually(): void {
        if (this.manualInputForm.valid) {
            this.loader.show();
            this.currentAction = this.barcodeService.getCurrentAction();
            const serialNo = this.manualInputForm.get('serialNo').value;
            if (this.currentAction == 'InspectionScan') {
                this.fetchBarcodeBasicDetails(serialNo);
                // this.barcodeService.resetValues();
            } else if (this.currentAction == 'IssueRequestScan') {
                this.fetchBarcodeBasicDetails(serialNo);
            }
            else {
                this.fetchStockDetails(serialNo);
                this.barcodeService.resetValues();
            }
            this.closeScanner(); // Close the manual input form after fetching details
        }
    }

    onManualInputModalOpen(): void {
        setTimeout(() => {
            if (this.serialNoInput && this.serialNoInput.nativeElement) {
                this.serialNoInput.nativeElement.focus();
            }
        }, 100);
    }
    openSplitModal(StockLabel: StockLabelModel) {
        this.barcodeLabelUpdateService.openSplitModal(StockLabel.StockLabelId, StockLabel.SerialNo, StockLabel.Quantity);
    }
}

<nz-modal [(nzVisible)]="isVisible" [nzTitle]="modalTitle" (nzOnCancel)="handleCancel()" [nzFooter]="modalFooter"
    [nzContent]="modalContent">

    <ng-template #modalTitle>
        <div class="modal-title" *ngIf="labelDetails != null && labelDetails.StockLabel != null">
            <span>Update Label Details for {{labelDetails.StockLabel.SerialNo}}</span>
        </div>
    </ng-template>

    <ng-template #modalContent>
        <div>
            <ng-container *ngIf="labelDetails != null && labelDetails.StockLabel != null">
                <form [formGroup]="updateFormLocation" *ngIf="IsLocationRequired">
                    <nz-form-item>
                        <nz-form-label nzRequired [nzSpan]="8">Current Store</nz-form-label>
                        <nz-form-control [nzSpan]="8">
                            <nz-select class="form-select mb-2" formControlName="currentStoreId" nzPlaceholder="Choose"
                                (ngModelChange)="onSelectedStoreChange()" nzShowSearch>
                                <nz-option *ngFor="let a of StoreList" [nzValue]="a.StoreId"
                                    [nzLabel]="a.StoreName"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label nzRequired [nzSpan]="8">Current Rack</nz-form-label>
                        <nz-form-control [nzSpan]="8">
                            <nz-select class="form-select mb-2" formControlName="currentRackId" nzPlaceholder="Choose"
                                nzShowSearch>
                                <nz-option *ngFor="let a of RackList" [nzValue]="a.RackId"
                                    [nzLabel]="a.RackName"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </form>
                <form [formGroup]="updateForm" (ngSubmit)="onSubmit()">
                    <nz-form-item>
                        <nz-form-label nzRequired [nzSpan]="8">Inspection
                            Status</nz-form-label>
                        <nz-form-control [nzSpan]="8">
                            <nz-select class="form-select mb-2" formControlName="inspectionStatus"
                                nzPlaceholder="Choose">
                                <nz-option nzValue="Accepted" nzLabel="Accepted"></nz-option>
                                <nz-option nzValue="Rejected" nzLabel="Rejected"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label nzRequired [nzSpan]="8">Quantity</nz-form-label>
                        <nz-form-control [nzSpan]="8">
                            <nz-input-number class="form-control mb-2" formControlName="quantity" [nzMin]="0"
                                [nzStep]="1"></nz-input-number>
                            <span style="margin-left: 5px;">{{labelDetails.StockUnit}}</span>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label nzRequired [nzSpan]="8">Packaging Type</nz-form-label>
                        <nz-form-control [nzSpan]="8">
                            <nz-select class="form-select mb-2" formControlName="packagingType" nzPlaceholder="Choose">
                                <nz-option *ngFor="let a of PackagingTypeList" [nzValue]="a.PackagingTypeName"
                                    [nzLabel]="a.PackagingTypeName"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="8">MFG Date</nz-form-label>
                        <nz-form-control [nzSpan]="8">
                            <nz-date-picker class="form-control" formControlName="mfgDate"></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="8">Expiry Date</nz-form-label>
                        <nz-form-control [nzSpan]="8">
                            <nz-date-picker class="form-control" formControlName="expiryDate"></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>
                </form>
            </ng-container>
        </div>
    </ng-template>
</nz-modal>

<ng-template #modalFooter>
    <button nz-button nzType="default" (click)="handleCancel()" style="margin-right: 8px;">Cancel</button>

    <button nz-button nzType="primary" *ngIf="this.permission.Edit && isSplitBtnVisible" (click)="openSplitModal()"
        style="margin-right: 8px;">Split
        Label</button>
    <nz-button-group>
        <button nz-button nzType="primary" *ngIf="this.permission.Add || this.permission.StockInspectionAdd"
            [disabled]="disableUpdateButton()" (click)="SaveAndScanAgain()" style="margin-right: 8px;">Update & Scan
            Again</button>
        <button nz-button nz-dropdown [nzDropdownMenu]="UpdateMoreOptionsMenu" nzPlacement="bottomRight">
            <span nz-icon nzType="ellipsis"></span>
        </button>
    </nz-button-group>
    <nz-dropdown-menu #UpdateMoreOptionsMenu="nzDropdownMenu">
        <ul nz-menu>
            <li *ngIf="this.permission.Add || this.permission.StockInspectionAdd" [nzDisabled]="disableUpdateButton()"
                (click)="onSubmit()" nz-menu-item>
                Update</li>
            <li (click)="ScanAgain()" nz-menu-item>Scan Again</li>
        </ul>
    </nz-dropdown-menu>
</ng-template>

<nz-modal [(nzVisible)]="IsScannedLabelListVisible" [nzStyle]="{ top: '50px'}" [nzWidth]="850"
    [nzTitle]="modalTitleSelectedLabel" [nzContent]="modalContentSelectedLabel" [nzFooter]="modalFooterSelectedLabel"
    (nzOnCancel)="handleScannedLabelListCancel()">
    <ng-template #modalTitleSelectedLabel>Product Barcode Labels</ng-template>

    <ng-template #modalContentSelectedLabel>

        <nz-table nzSize="small" [nzPageSize]="10" style="width: 100%;" #basicTable nzShowPagination="true"
            [nzData]="ScannedStockProductLabelList" nzBordered [nzShowTotal]="totalTemplateScannedLabel">
            <thead>
                <tr>
                    <th nzWidth="140px">Serial No.</th>
                    <th nzWidth="150px">Product Name</th>
                    <th nzWidth="80px">Batch No.</th>
                    <th nzWidth="80px">Quantity</th>
                    <th nzWidth="100px">Label Status</th>
                    <th nzWidth="100px">Inspection Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of basicTable.data;let i = index">
                    <td>{{data.SerialNo}}</td>
                    <td>{{ data.ProductName}}</td>
                    <td>{{ data.BatchNo}}</td>
                    <td>{{ data.Quantity}}</td>
                    <td [ngStyle]="{'background-color': SetScannedLabelColor(data)}">{{ data.LabelStatus }}
                    </td>
                    <td [ngStyle]="{'background-color': SetInspectionStatusColor(data)}">{{ data.InspectionStatus }}
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </ng-template>

    <ng-template #modalFooterSelectedLabel>

        <button nz-button nzType="default" (click)="handleScannedLabelListCancel()">Close</button>
    </ng-template>
</nz-modal>

<ng-template #totalTemplateScannedLabel let-total>Total {{ this.totalItemsCountScannedLabel }} items</ng-template>

<!-- Add this new modal for split functionality -->
<nz-modal [(nzVisible)]="isSplitModalVisible" [nzTitle]="modalTitleSplitLabel" (nzOnCancel)="closeSplitModal()"
    [nzFooter]="splitModalFooter" [nzContent]="modalContentSplitLabel">
    <ng-template #modalTitleSplitLabel>Split Label</ng-template>

    <ng-template #modalContentSplitLabel>
        <form [formGroup]="splitForm" (ngSubmit)="onSplitSubmit()">
            <nz-form-item>
                <nz-form-label [nzSpan]="8">Number of Labels</nz-form-label>
                <nz-form-control [nzSpan]="16">
                    <div class="label-count-control">
                        <a class="btn btn-sm" (click)="decreaseLabels()"
                            [class.disabled]="splitForm.get('numberOfLabels').value <= 1">
                            <i nz-icon nzType="minus-circle" nzTheme="fill"></i>
                        </a>
                        <span class="label-count">{{ splitForm.get('numberOfLabels').value }}</span>
                        <a class="btn btn-sm" (click)="increaseLabels()"
                            [class.disabled]="splitForm.get('numberOfLabels').value >= 10">
                            <i nz-icon nzType="plus-circle" nzTheme="fill"></i>
                        </a>
                    </div>
                </nz-form-control>
            </nz-form-item>
            <div *ngIf="splitServiceData">
                <p>Current Serial No: <b>{{splitServiceData?.SerialNo}}</b></p>
                <p>Current Quantity: <b>{{splitServiceData?.Quantity}}</b></p>
                <p>Current Label New Quantity: <b>{{CurrentLabelNewQuantity}}</b></p>
            </div>

            <div formArrayName="quantities">
                <div *ngFor="let quantity of quantities.controls; let i = index">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="8">Quantity for Label {{i + 1}}</nz-form-label>
                        <nz-form-control [nzSpan]="16">
                            <nz-input-number [formControlName]="i" [nzMin]="1" [nzStep]="1"
                                (ngModelChange)="onQuantityChange()"></nz-input-number>
                            <a class="btn btn-sm" title="Remove" *ngIf="i > 0" (click)="removeQuantityInput(i)">
                                <i nz-icon nzType="minus-circle" nzTheme="fill" style=" margin-bottom: 3px;"></i>
                            </a>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </form>
    </ng-template>

    <ng-template #splitModalFooter>
        <button nz-button nzType="default" (click)="closeSplitModal()">Cancel</button>
        <button nz-button nzType="primary" [disabled]="!splitForm.valid" (click)="onSplitSubmit()">Split Label</button>
    </ng-template>
</nz-modal>

<nz-modal [(nzVisible)]="IsStoreBarcodeListVisible" [nzStyle]="{ top: '50px'}" [nzWidth]="850"
    [nzTitle]="modalTitleStoreBarcodeLabel" [nzContent]="modalContentStoreBarcodeLabel"
    [nzFooter]="modalFooterStoreBarcodeLabel" (nzOnCancel)="handleCancel()">
    <ng-template #modalTitleStoreBarcodeLabel>Store Barcode Labels</ng-template>

    <ng-template #modalContentStoreBarcodeLabel>

        <nz-table nzSize="small" [nzPageSize]="10" style="width: 100%;" #basicTable nzShowPagination="true"
            [nzData]="StoreBarcodeStockProductLabelList" nzBordered [nzShowTotal]="totalTemplateStoreBarcodeLabel">
            <thead>
                <tr>
                    <th nzWidth="90px">Serial No.</th>
                    <th nzWidth="250px">Product Name</th>
                    <th nzWidth="60px">Batch No.</th>
                    <th nzWidth="60px">QTY</th>
                    <th nzWidth="80px">Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of basicTable.data;let i = index">
                    <td>{{data.SerialNo}}</td>
                    <td>{{ data.ProductName}}</td>
                    <td>{{ data.BatchNo}}</td>
                    <td>{{ data.Quantity}}</td>
                    <td [ngStyle]="{'background-color': SetScannedLabelColor(data)}">{{ SetScannedLabelStatus(data) }}
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </ng-template>

    <ng-template #modalFooterStoreBarcodeLabel>

        <button nz-button nzType="default" (click)="handleCancel()">Close</button>
    </ng-template>
</nz-modal>

<nz-modal [(nzVisible)]="IsScanAgainOptionsVisible" [nzStyle]="{ top: '20px'}" [nzWidth]="250"
    [nzTitle]="modalTitleScanAgainOptions" [nzContent]="modalContentScanAgainOptions"
    [nzFooter]="modalFooterScanAgainOptions" (nzOnCancel)="handleScanAgainOptionsCancel()">
    <ng-template #modalTitleScanAgainOptions>Scan Again Options</ng-template>

    <ng-template #modalContentScanAgainOptions>

        <div style="text-align: center;align-self: center;">
            <button nz-button nzType="primary" (click)="openBarcodeInspectionScanner()"
                style="margin-bottom: 5px;">Barcode Scanner</button>
            <button nz-button nzType="primary" (click)="openExternalDeviceInspection()"
                style="margin-bottom: 5px;">External Device</button>
            <button nz-button nzType="primary" (click)="openManualInputInspection()">Manual Input</button>
        </div>
    </ng-template>

    <ng-template #modalFooterScanAgainOptions>

        <button nz-button nzType="default" (click)="handleScanAgainOptionsCancel()">Close</button>
    </ng-template>
</nz-modal>
<ng-template #totalTemplateStoreBarcodeLabel let-total>Total {{ this.totalItemsCountStoreBarcodeLabel }}
    items</ng-template>
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';

import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { AlertMessageService } from '../../Services/AlertMessageService';


import { ActivatedRoute, Params, Router } from '@angular/router';
import { map, Observable, Subscription } from 'rxjs';

import { PurchaseOrderModel } from '../../Models/PurchaseOrderModel';
import { CompanyInfo } from '../../Authentication/UserInfo';
import { SupplierModel } from '../../Models/SupplierModel';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ISasToken } from 'src/PmsUIApp/azure-storage/azureStorage';
import { BlobStorageService } from 'src/PmsUIApp/azure-storage/blob-storage.service';
import { NzQRCodeModule } from 'ng-zorro-antd/qr-code';


@Component({
  selector: 'app-poprint',
  templateUrl: './poprint.component.html',
  styleUrls: ['./poprint.component.css']
})
export class POprintComponent implements OnInit {


  ApiUrl = environment.Api_Url;
  PurchaseOrder!: PurchaseOrderModel;
  NewSupplier: SupplierModel = new SupplierModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Production";
  searchValue = '';
  PrintTableLength: number = 0;
  visible = false;
  private route$: Subscription = new Subscription;
  token: any;
  Poid = 0;
  type: number = 0;
  TotalWord = '';
  Company = {
    CompanyName: '',
    Contact: '',
    Email: '',
    Address: '',
    GST: '',
  };


  constructor(private fb: UntypedFormBuilder, public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService, private route: ActivatedRoute, private blobStorage: BlobStorageService,
    private qrcode: NzQRCodeModule, private router: Router) { }


  ngOnInit() {
    this.route$ = this.route.params.subscribe((params: Params) => {
      this.Poid = params["id"];
      this.type = params["type"];
    })
    this.Company.CompanyName = CompanyInfo.CompanyName;
    this.Company.Contact = CompanyInfo.Contact;
    this.Company.Email = CompanyInfo.Email;
    this.Company.Address = CompanyInfo.Address.replace("\n", "<br>");
    this.Company.GST = CompanyInfo.GST;
    // this.Company.Website = CompanyInfo.Website;
    this.GetAllPurchaseOrder();
  }

  GetAllPurchaseOrder() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "purchaseorder/getpurchaseorderbyid/" + this.Poid;
    this.http.get<PurchaseOrderModel>(url).subscribe(res => {
      this.PurchaseOrder = res;
      if (this.PurchaseOrder.Status == 'New' || this.PurchaseOrder.Status == 'Cancelled' ) {
        this.router.navigate(['/home/unauthorized']);
        return;
      }
      this.PurchaseOrder.PurchaseOrderProduct.forEach(x => {
        x.IgstTotal = (x.Amount * x.Igst) / 100;
        x.Total = x.Amount + x.Igst;
        this.PurchaseOrder.PototalTax += x.IgstTotal;
        this.PurchaseOrder.Pograndtotal += x.Total;
      })

      if (this.PurchaseOrder.PurchaseOrderProduct.length < 8) {
        this.PrintTableLength = 8
      }
      else {
        this.PrintTableLength = 2
      }
      this.PurchaseOrder.Remarks = this.PurchaseOrder.Remarks.replaceAll("\n", "<br>");
      this.PurchaseOrder.PototalTax = this.PurchaseOrder.PurchaseOrderProduct.reduce((sum, current) => sum + current.IgstTotal, 0);
      this.PurchaseOrder.PototalTax = parseFloat(this.PurchaseOrder.PototalTax.toFixed(3))
      this.PurchaseOrder.PototalAmount = parseFloat(this.PurchaseOrder.PototalAmount).toFixed(3)
      this.PurchaseOrder.Pograndtotal = (this.PurchaseOrder.PurchaseOrderProduct.reduce((sum, current) => sum + current.IgstTotal + current.Amount, 0)).toFixed(3);
      this.GetAllSupplier();
      var converter = require('number-to-words');
      // this.TotalWord = converter.toWords(this.PurchaseOrder.Pograndtotal).toUpperCase()
    }, res => { this.GetAllPurchaseOrder(); });
  }

  GetAllSupplier() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "supplier/getallsuppliers";
    this.http.get<SupplierModel[]>(url).subscribe(res => {
      this.NewSupplier = res.filter(x => x.SupplierId == this.PurchaseOrder.SupplierId)[0]
      setTimeout(function () {
        window.print();
        window.onafterprint = close;
        function close() {
          window.close();
        }
      }, 1)
    }, res => { });
  }
}

import { Component } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../../Services/auth.service';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from 'src/environments/environment';
import { ProductionDowntimeReasonModel, ScheduledDowntimeModel } from '../../Models/ProductionDowntime';
import { Responsibility } from 'src/PmsUIApp/Models/Enums';
import { Modules } from 'src/PmsUIApp/Models/Enums';
import moment from 'moment';
import { FactoryWorkersModel } from 'src/PmsUIApp/Models/MasterModel';
import { LoadingService } from 'src/PmsUIApp/Services/loadingService';

@Component({
  selector: 'app-production-downtime-reason',
  templateUrl: './production-downtime-reason.component.html',
  styleUrls: ['./production-downtime-reason.component.css']
})
export class ProductionDowntimeReasonComponent {
  validateForm!: UntypedFormGroup;
  validateScheduleForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  ProductionDowntimeReasonList: ProductionDowntimeReasonModel[] = [];
  ProductionDowntimeReasonListOriginal: ProductionDowntimeReasonModel[] = [];
  ScheduledDowntimeList: ScheduledDowntimeModel[] = [];
  ScheduledDowntimeListOriginal: ScheduledDowntimeModel[] = [];
  NewReason: ProductionDowntimeReasonModel = new ProductionDowntimeReasonModel;
  NewScheduledDowntime: ScheduledDowntimeModel = new ScheduledDowntimeModel;
  SelectedProductionDowntimeReason: ProductionDowntimeReasonModel = new ProductionDowntimeReasonModel;
  isVisible = false;
  isScheduledDowntimeVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Production Downtime Reason";
  ScheduledDowntimePopUpTitle: string = "Add Schedule for:";
  nameError = 'Enter valid Production Downtime Reason name between 3 to 50';
  shortNameError = 'Min 5, Max 5 characters. Only alphanumeric characters are allowed.';
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any
  searchValue = '';
  permission = {
    View: false,
    Add: false,
    Edit: false,
    Delete: false
  }
  ScheduledDowntimeTitle: string = 'Add New Downtime Schedule';
  count: 0;
  totalItemsCount: number;
  isRecurring: boolean = false;
  isScheduledDowntimeListVisible: boolean = false;
  isScheduledDowntimeListLoading: boolean = false;
  ScheduleListCount: number = 0;
  ScheduleListTotalCount: number = 0;
  IsRecurring: boolean = true;
  ApplicableDays: string[] = [];
  RecurrencePattern: string = '';
  DayOfMonth: number = 0;
  IsScheduleEdit: boolean = false;
  isListRefreshRequired: boolean = false;
  constructor(private fb: UntypedFormBuilder, public http: HttpClient,
    private router: Router, private auth: AuthService,
    private alertService: AlertMessageService,
    private modalService: NzModalService,
    private loader: LoadingService) {
  }



  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.ProductionDowntimeReason, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.ProductionDowntimeReason, Responsibility.Add);
    this.permission.Edit = this.auth.CheckResponsibility(Modules.ProductionDowntimeReason, Responsibility.Edit);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.ProductionDowntimeReason, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.validateForm = this.fb.group({
      ReasonName: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      ReasonCode: [null, [Validators.minLength(5), Validators.maxLength(5), Validators.pattern('^[A-Za-z0-9]+$')]],
      Description: [null, Validators.required],
      DowntimeType: [null, Validators.required],
      ProductionLineType: [null, Validators.required],
      StandardDurationMinutes: [null, Validators.required],
      IsActive: [false, Validators.required],
    });
    this.validateScheduleForm = this.fb.group({
      ProductionLineNo: [null, Validators.required],
      StartTime: [null, Validators.required],
      EndTime: [null, Validators.required],
      IsRecurring: [true, Validators.required],
      RecurrencePattern: [null, Validators.required],
      ApplicableDays: [null, this.RecurrencePattern == 'Weekly' ? Validators.required : null],
      DayOfMonth: [null, this.RecurrencePattern == 'Monthly' ? Validators.required : null],
      EffectiveFrom: [null, Validators.required],
      EffectiveTo: [null],
      IsActive: [false, Validators.required],
    });
    this.GetAllProductionDowntimeReason();
  }
  get f() { return this.validateForm.controls; }
  get fSchedule() { return this.validateScheduleForm.controls; }

  GetAllProductionDowntimeReason() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "production/getproductiondowntimereasonlist";
    let count = this.totalItemsCount = 0;
    this.http.get<any>(url).subscribe(res => {
      this.ProductionDowntimeReasonList = res.ResponseBody;
      this.ProductionDowntimeReasonListOriginal = res.ResponseBody;
      this.ProductionDowntimeReasonList?.forEach((x) => {
        count++,
          x.SerialNo = count;
      })
      this.totalItemsCount = count;
      this.isTableLoading = false;

    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllProductionDowntimeReason();
      }
    });
  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  reset(): void {
    this.searchValue = '';
    this.search();
    this.GetAllProductionDowntimeReason();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }

  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  search() {
    var res = this.ProductionDowntimeReasonListOriginal;
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');

    let todate = moment(this.toDate).format('DD-MM-YYYY');

    this.isValidDate = this.validateDates(fromdate, todate);

    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: ProductionDowntimeReasonModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.CreatedOn).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.CreatedOn).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });

      this.ProductionDowntimeReasonList = selectepolist;
    }

    else if (!this.myDateValue && !this.toDate) {
      this.ProductionDowntimeReasonList = res.filter(
        (item: ProductionDowntimeReasonModel) =>
          item?.ReasonName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.ReasonCode?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.DowntimeType?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.ProductionLineType?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.Description?.toLowerCase().includes(this.searchValue?.toLowerCase())
      )
    }

  }

  SaveReason() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoading = false
      return;
    }
    this.loader.show();
    this.NewReason.ReasonName = this.f['ReasonName'].value;
    this.NewReason.ReasonCode = this.f['ReasonCode'].value;
    this.NewReason.DowntimeType = this.f['DowntimeType'].value;
    this.NewReason.ProductionLineType = this.f['ProductionLineType'].value;
    this.NewReason.Description = this.f['Description'].value;
    this.NewReason.IsActive = this.f['IsActive'].value;
    this.NewReason.StandardDurationMinutes = this.f['StandardDurationMinutes'].value;
    let url = this.ApiUrl + "production/addupdateproductiondowntimereason";
    this.http.post<any>(url, this.NewReason).subscribe({

      next: res => {
        this.loader.hide();
        this.alertService.success(res.ResponseBody);
        this.isLoading = this.isVisible = false;
        this.GetAllProductionDowntimeReason();
      },
      error: res => {
        if (res.error.status == 'EarlyHints') {
          this.loader.hide();
          this.alertService.warning(res.error);
          this.isLoading = false;
          return;
        }
        else {
          this.loader.hide();
          this.alertService.error(res.error);
          this.isLoading = false;
          return;
        }
      },

    });

  }

  OpenEditPop(data: ProductionDowntimeReasonModel) {
    this.PopUpTitle = "Modify Production Downtime Reason Details";
    this.validateForm.setValue(
      {
        ReasonName: data.ReasonName,
        ReasonCode: data.ReasonCode,
        Description: data.Description,
        DowntimeType: data.DowntimeType,
        ProductionLineType: data.ProductionLineType,
        StandardDurationMinutes: data.StandardDurationMinutes,
        IsActive: data.IsActive

      }
    )
    this.NewReason = new ProductionDowntimeReasonModel();
    this.NewReason.ProductionDowntimeReasonId = data.ProductionDowntimeReasonId;

    this.isVisible = true;
  }

  showModal(): void {
    this.PopUpTitle = "Add New Production Downtime Reason";
    this.validateForm.reset();
    this.validateForm.patchValue({
      IsActive: false,
    })
    this.NewReason.ProductionDowntimeReasonId = 0;
    this.isVisible = true;
  }

  handleOk(): void {
    this.isLoading = true;
    this.SaveReason();
  }

  handleCancel(): void {
    this.isVisible = false;
    this.validateForm.reset();
  }
  DeleteReason(ProductionDowntimeReasonId: number) {
    this.loader.show();
    let url = this.ApiUrl + "production/productiondowntimereasonstatuschange";
    this.http.post<any>(url, { ProductionDowntimeReasonId: ProductionDowntimeReasonId, Action: 'Delete' }).subscribe({
      next: res => {
        this.loader.hide();
        this.alertService.success(res); this.isLoading = this.isVisible = false;
        this.GetAllProductionDowntimeReason();
      },
      error: res => { this.loader.hide(); this.alertService.error(res.error); this.isLoading = this.isVisible = false; },

    });

  }
  handleDelete(ProductionDowntimeReasonId: number) {
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this Production Downtime Reason?',
      nzOnOk: () => this.DeleteReason(ProductionDowntimeReasonId),
    });
    setTimeout(() => modal.destroy(), 5000);
  }
  showScheduledDowntimePop(data: ProductionDowntimeReasonModel, isListRefreshRequired: boolean = false) {
    this.NewScheduledDowntime = new ScheduledDowntimeModel();
    this.isListRefreshRequired = isListRefreshRequired;
    this.SelectedProductionDowntimeReason = data;
    this.NewScheduledDowntime.ProductionDowntimeReasonId = data.ProductionDowntimeReasonId;
    this.validateScheduleForm.reset();
    this.validateScheduleForm.patchValue({
      IsActive: false,
      IsRecurring: true,
    })
    this.isScheduledDowntimeVisible = true;
  }
  handleScheduledDowntimeOk(isListRefreshRequired: boolean = false) {
    if (this.validateScheduleForm.invalid) {
      Object.values(this.validateScheduleForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }
    // var StartTime = new Date(this.fSchedule['StartTime'].value);
    // var EndTime = new Date(this.fSchedule['EndTime'].value);
    // if (StartTime.getTime() && EndTime.getTime() && StartTime.getTime() >= EndTime.getTime()) {
    //   this.alertService.error('End time must be after start time');
    //   return;
    // }
    var effectiveFrom = new Date(this.fSchedule['EffectiveFrom'].value);
    var effectiveTo = new Date(this.fSchedule['EffectiveTo'].value);
    if (effectiveTo?.getTime() && effectiveFrom.getTime() >= effectiveTo.getTime()) {
      this.alertService.error('Effective To date must be after effective From date');
      return;
    }
    this.SaveScheduledDowntime();
  }
  handleScheduledDowntimeCancel() {
    this.isScheduledDowntimeVisible = false;
    this.IsScheduleEdit = false;
    this.validateScheduleForm.reset();
    this.isListRefreshRequired = false;
  }
  SaveScheduledDowntime() {
    if (this.fSchedule['IsRecurring'].value) {
      this.NewScheduledDowntime.RecurrencePattern = this.fSchedule['RecurrencePattern'].value;
    }
    else {
      this.NewScheduledDowntime.RecurrencePattern = null;
    }
    if (this.fSchedule['IsRecurring'].value && this.fSchedule['RecurrencePattern'].value == 'Daily') {
      this.NewScheduledDowntime.ApplicableDays = null;
      this.NewScheduledDowntime.DayOfMonth = null;
    }
    else if (this.fSchedule['IsRecurring'].value && this.fSchedule['RecurrencePattern'].value == 'Weekly') {
      var days = this.fSchedule['ApplicableDays'].value;
      this.NewScheduledDowntime.ApplicableDays = days.filter(day => day !== 'N/A').join(',');
      this.NewScheduledDowntime.DayOfMonth = null;
    }
    else if (this.fSchedule['IsRecurring'].value && this.fSchedule['RecurrencePattern'].value == 'Monthly') {
      this.NewScheduledDowntime.ApplicableDays = null;
      this.NewScheduledDowntime.DayOfMonth = this.fSchedule['DayOfMonth'].value;
    }
    else {
      this.NewScheduledDowntime.ApplicableDays = null;
      this.NewScheduledDowntime.DayOfMonth = null;
    }
    this.NewScheduledDowntime.ProductionDowntimeReasonId = this.SelectedProductionDowntimeReason.ProductionDowntimeReasonId;
    this.NewScheduledDowntime.ProductionLineNo = this.fSchedule['ProductionLineNo'].value;
    this.NewScheduledDowntime.StartTime = this.fSchedule['StartTime'].value;
    this.NewScheduledDowntime.EndTime = this.fSchedule['EndTime'].value;
    this.NewScheduledDowntime.EffectiveFrom = this.fSchedule['EffectiveFrom'].value;
    this.NewScheduledDowntime.EffectiveTo = this.fSchedule['EffectiveTo'].value;
    this.NewScheduledDowntime.IsActive = this.fSchedule['IsActive'].value;
    this.loader.show();
    let url = this.ApiUrl + "production/addupdateproductiondowntimeschedule";
    this.http.post<any>(url, this.NewScheduledDowntime).subscribe({
      next: res => {
        this.loader.hide();
        this.alertService.success(res);
        if (this.isListRefreshRequired) {
          this.GetDowntimeScheduleListByReasonId(this.SelectedProductionDowntimeReason);
        }
        this.handleScheduledDowntimeCancel();
      },
      error: res => { this.loader.hide(); this.alertService.error(res.error); this.isScheduledDowntimeVisible = false; },
    });
  }
  handleScheduledDowntimeListCancel() {
    this.isScheduledDowntimeListVisible = false;
  }
  GetDowntimeScheduleListByReasonId(data: ProductionDowntimeReasonModel) {
    this.isScheduledDowntimeListLoading = true;
    this.SelectedProductionDowntimeReason = data;
    this.ScheduledDowntimeList = [];
    this.loader.show();
    let url = this.ApiUrl + "production/getproductiondowntimeschedulelistbyreasonid/" + data.ProductionDowntimeReasonId;
    this.http.get<any>(url).subscribe({
      next: res => {
        this.ScheduledDowntimeList = res;
        if (!this.IsScheduleEdit) {
          this.isScheduledDowntimeListVisible = true;
        }
        this.isScheduledDowntimeListLoading = false;
        this.loader.hide();
        this.ScheduleListCount = this.ScheduleListTotalCount = 0;
        this.ScheduledDowntimeList?.forEach((x) => {
          this.ScheduleListCount++,
            x.SerialNo = this.ScheduleListCount;
          // Convert StartTime to IST
          try {
            // Parse the time string and convert to IST
            x.StartTime = moment.utc(x.StartTime)
              .utcOffset('+05:30')
              .format('hh:mm A'); // Using 'A' for AM/PM format
          } catch (error) {
            console.error('Error converting StartTime to IST:', error);
            x.StartTime = 'Invalid Time';
          }

          // Convert EndTime to IST
          try {
            // Parse the time string and convert to IST
            x.EndTime = moment.utc(x.EndTime)
              .utcOffset('+05:30')
              .format('hh:mm A'); // Using 'A' for AM/PM format
          } catch (error) {
            console.error('Error converting EndTime to IST:', error);
            x.EndTime = 'Invalid Time';
          }
        })
        this.ScheduleListTotalCount = this.ScheduleListCount
      },
      error: res => { this.loader.hide(); this.alertService.error(res.error); this.isScheduledDowntimeListVisible = false; },
    });
  }
  editScheduledDowntime(data: ScheduledDowntimeModel) {
    try {
      this.IsScheduleEdit = true;
      this.isListRefreshRequired = true;
      this.NewScheduledDowntime.ScheduledDowntimeId = data.ScheduledDowntimeId;
      this.ScheduledDowntimePopUpTitle = "Edit Schedule for: " + this.SelectedProductionDowntimeReason.ReasonName;

      // Handle applicable days
      const selectedDays = data.ApplicableDays ?
        data.ApplicableDays.split(',').map(day => day.trim()) : [];

      this.validateScheduleForm.patchValue({
        ProductionLineNo: data.ProductionLineNo.toString(),
        IsActive: data.IsActive,
        IsRecurring: true,
        RecurrencePattern: data.RecurrencePattern,
        ApplicableDays: selectedDays,
        DayOfMonth: data.DayOfMonth,
        EffectiveFrom: data.EffectiveFrom,
        EffectiveTo: data.EffectiveTo,
        StartTime: moment(data.StartTime, 'hh:mm A'),
        EndTime: moment(data.EndTime, 'hh:mm A')
      });

      this.isScheduledDowntimeVisible = true;
    } catch (error) {
      console.error('Error in editScheduledDowntime:', error);
      this.alertService.error('Error loading schedule details');
    }
  }
  handleDeleteScheduledDowntime(ScheduledDowntimeId: number) {
    this.loader.show();
    let url = this.ApiUrl + "production/deleteproductiondowntimeschedule/" + ScheduledDowntimeId;
    this.http.get<any>(url).subscribe({
      next: res => { this.loader.hide(); this.alertService.success(res); },
      error: res => { this.loader.hide(); this.alertService.error(res.error); },
    });
  }
}

<div class="card card-flush h-xl-100">
    <div class="card-body pt-5">
        <nz-page-header [nzGhost]="false">
            <nz-page-header-title>Production Downtime Reason List</nz-page-header-title>
            <nz-page-header-subtitle>Manage your production downtime reasons here</nz-page-header-subtitle>
            <nz-page-header-extra>
                <button nz-button nzType="primary" *ngIf="this.permission.Add" (click)="showModal()">
                    Add New
                </button>
            </nz-page-header-extra>
        </nz-page-header>
        <nz-divider></nz-divider>
        <div style="padding: 8px;float:right">
            <nz-date-picker [(ngModel)]="myDateValue" nzPlaceHolder="Start Date"
                style="width: 150px;margin-right: 8px;"></nz-date-picker>
            <nz-date-picker [(ngModel)]="toDate" nzPlaceHolder="End Date"
                style="width: 150px;margin-right: 8px;"></nz-date-picker>
            <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search "
                [(ngModel)]="searchValue" (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />
            <button nz-button nzSize="small" nzType="primary" (click)="search()"
                style="margin-right: 8px;">Search</button>
            <button nz-button nzSize="small" (click)="reset()">Reset</button>
        </div>

        <nz-table nzSize="small" [nzPageSize]="10" style="width: 100%" [nzScroll]="{ x: '1400px', y: '515px' }"
            #basicTable [nzData]="this.ProductionDowntimeReasonList" [nzLoading]="isTableLoading" nzBordered
            nzShowPagination="true" nzShowSizeChanger [nzPageSizeOptions]=[10,50,100,200,500]
            [nzShowTotal]="totalTemplate" nzShowQuickJumper="true" nzResponsive=true>
            <thead>
                <tr>
                    <th nzWidth="60px">S.No.</th>
                    <th nzWidth="130px">Reason Code</th>
                    <th nzWidth="190px">Reason Name</th>
                    <th nzWidth="150px">Description</th>
                    <th nzWidth="150px">Downtime Type</th>
                    <th nzWidth="150px">PRD Line Type</th>
                    <th nzWidth="150px">Std. Duration</th>
                    <th nzWidth="150px">Is Active</th>
                    <th nzWidth="180px">Created/Updated By</th>
                    <th nzWidth="180px">Created/Updated On</th>

                    <th nzWidth="120px" nzRight style="text-align: center" *ngIf="permission.Add || permission.Delete">
                        Action
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of basicTable.data">
                    <td>{{ data.SerialNo }}</td>
                    <td>{{ data.ReasonCode }}</td>
                    <td>{{ data.ReasonName }}</td>
                    <td>{{ data.Description }}</td>
                    <td>{{ data.DowntimeType }}</td>
                    <td>{{ data.ProductionLineType }}</td>
                    <td>{{ data.StandardDurationMinutes }} Minutes</td>
                    <td>{{ data.IsActive ? 'Yes' : 'No' }}</td>
                    <td>{{ data.ModifiedBy ? data.ModifiedBy : data.CreatedBy }}</td>
                    <td>{{ data.ModifiedOn ? (data.ModifiedOn | DatetimeConverter) : (data.CreatedOn |
                        DatetimeConverter) }}
                    </td>

                    <td nzRight style="text-align: center">
                        <button nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu"
                            class="btn btn-sm btn-light-primary" style="line-height:0">
                            Action
                            <span nz-icon nzType="down"></span>
                        </button>
                        <nz-dropdown-menu #menu="nzDropdownMenu">
                            <ul nz-menu nzSelectable>
                                <li style="width: 120px;" nz-menu-item *ngIf="permission.Edit"
                                    (click)="OpenEditPop(data)">
                                    Edit
                                </li>
                                <li style="width: 120px;" nz-menu-item *ngIf="permission.Delete"
                                    (click)="handleDelete(data.ProductionDowntimeReasonId)">
                                    Delete
                                </li>
                                <li style="width: 120px;" nz-menu-item *ngIf="permission.Add"
                                    (click)="showScheduledDowntimePop(data, false)">
                                    Add Schedule
                                </li>
                                <li style="width: 120px;" nz-menu-item *ngIf="permission.Add"
                                    (click)="GetDowntimeScheduleListByReasonId(data)">
                                    View Schedule
                                </li>
                            </ul>
                        </nz-dropdown-menu>
                    </td>
                </tr>
            </tbody>
        </nz-table>

        <nz-modal [nzWidth]="800" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isVisible" [nzTitle]="modalTitle"
            [nzContent]="modalContent" [nzFooter]="null" (nzOnCancel)="handleCancel()">
            <ng-template #modalTitle>{{ PopUpTitle }}</ng-template>

            <ng-template #modalContent>
                <form nz-form [formGroup]="validateForm">

                    <nz-form-item>
                        <nz-form-label [nzSpan]="8" nzRequired>Reason Name</nz-form-label>
                        <nz-form-control [nzSpan]="14" [nzErrorTip]="nameError">
                            <input nz-input formControlName="ReasonName" name="ReasonName"
                                placeholder="Enter Reason Name" />
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="8">Reason Code</nz-form-label>
                        <nz-form-control [nzSpan]="14" [nzErrorTip]="shortNameError">
                            <nz-input-group [nzSuffix]="suffixTemplateInfo">
                                <input nz-input formControlName="ReasonCode" maxlength="5" name="ReasonCode"
                                    placeholder="Enter 5 Character Reason Code" />
                            </nz-input-group>
                            <ng-template #suffixTemplateInfo>
                                <span nz-icon nzType="info-circle" nzTheme="outline" nz-tooltip
                                    nzTooltipTitle="Leave empty to create auto generated code" style="color: #1890ff;">
                                </span>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="8" nzRequired>Downtime Type</nz-form-label>
                        <nz-form-control [nzSpan]="14" nzErrorTip="Select Downtime Type">
                            <nz-select class="form-select mb-2" nzShowSearch name="DowntimeType" nzSize="default"
                                nzPlaceHolder="Choose" formControlName="DowntimeType">
                                <nz-option nzValue="Planned" nzLabel="Planned"></nz-option>
                                <nz-option nzValue="Unplanned" nzLabel="Unplanned"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="8" nzRequired>Production Line Type</nz-form-label>
                        <nz-form-control [nzSpan]="14" nzErrorTip="Select Production Line Type">
                            <nz-select class="form-select mb-2" nzShowSearch name="ProductionLineType" nzSize="default"
                                nzPlaceHolder="Choose" formControlName="ProductionLineType">
                                <nz-option nzValue="Manufacturing" nzLabel="Manufacturing"></nz-option>
                                <nz-option nzValue="Printing" nzLabel="Printing"></nz-option>
                                <nz-option nzValue="Embossing" nzLabel="Embossing"></nz-option>
                                <nz-option nzValue="Vacuum" nzLabel="Vacuum"></nz-option>
                                <nz-option nzValue="Laquer" nzLabel="Laquer"></nz-option>
                                <!-- <nz-option nzValue="Packaging" nzLabel="Packaging"></nz-option> -->
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="8" nzRequired>STD. Duration(Minutes)</nz-form-label>
                        <nz-form-control [nzSpan]="14" nzErrorTip="Enter Standard Duration">
                            <nz-input-number class="form-select mb-2" name="StandardDurationMinutes" nzSize="default"
                                nzPlaceHolder="Enter Standard Duration" formControlName="StandardDurationMinutes"
                                [nzMin]="1" [nzMax]="1440" [nzStep]="1" />
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="8" nzRequired>Description</nz-form-label>
                        <nz-form-control [nzSpan]="14" nzErrorTip="Enter Description">
                            <nz-textarea-count [nzMaxCharacterCount]="500">
                                <textarea nz-input formControlName="Description" name="Description"
                                    placeholder="Enter Description"></textarea>
                            </nz-textarea-count>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="8" nzRequired>Activate</nz-form-label>
                        <nz-form-control [nzSpan]="14" nzErrorTip="Select Activate">
                            <nz-switch name="IsActive" formControlName="IsActive" />
                        </nz-form-control>
                    </nz-form-item>
                    <div class="text-center">
                        <button nz-button nzType="primary" [nzLoading]="isLoading" (click)="handleOk()">
                            Save
                        </button>
                    </div>
                </form>
            </ng-template>
        </nz-modal>
    </div>
</div>
<ng-template #totalTemplate let-total>Total {{ this.totalItemsCount }} items</ng-template>

<nz-modal [nzWidth]="500" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isScheduledDowntimeVisible"
    [nzTitle]="ScheduledDowntimeHeader" [nzContent]="ScheduledDowntimeContent"
    (nzOnCancel)="handleScheduledDowntimeCancel()" (nzOnOk)="handleScheduledDowntimeOk()" nzOkText="Save"
    nzCancelText="Cancel">
    <ng-template #ScheduledDowntimeTitle>{{ ScheduledDowntimeTitle }}</ng-template>

    <ng-template #ScheduledDowntimeHeader>
        <div>
            {{ ScheduledDowntimePopUpTitle }}
        </div>
    </ng-template>
    <ng-template #ScheduledDowntimeContent>
        <form nz-form [formGroup]="validateScheduleForm">
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Production Line No</nz-form-label>
                <nz-form-control [nzSpan]="6" nzErrorTip="Select Production Line No">
                    <nz-select class="form-select mb-2" nzShowSearch name="ProductionLineNo" nzSize="default"
                        nzPlaceHolder="Choose" formControlName="ProductionLineNo">
                        <nz-option nzValue="1" nzLabel="1"></nz-option>
                        <nz-option nzValue="2" nzLabel="2"></nz-option>
                        <nz-option nzValue="3" nzLabel="3"></nz-option>
                        <nz-option nzValue="4" nzLabel="4"></nz-option>
                        <nz-option nzValue="5" nzLabel="5"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Start Time</nz-form-label>
                <nz-form-control [nzSpan]="14" nzErrorTip="Select Start Time">
                    <nz-time-picker name="StartTime" nzSize="default" nzPlaceHolder="Start Time"
                        formControlName="StartTime" nzUse12Hours nzFormat="hh:mm a" />
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>End Time</nz-form-label>
                <nz-form-control [nzSpan]="14" nzErrorTip="Select End Time">
                    <nz-time-picker name="EndTime" nzSize="default" nzPlaceHolder="End Time" formControlName="EndTime"
                        nzUse12Hours nzFormat="hh:mm a" />
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Recurring</nz-form-label>
                <nz-form-control [nzSpan]="14" nzErrorTip="Select Recurring">
                    <nz-switch name="IsRecurring" formControlName="IsRecurring" (ngModelChange)="IsRecurring"
                        nzDisabled="true" />
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="validateScheduleForm.get('IsRecurring').value">
                <nz-form-label [nzSpan]="10" nzRequired>Recurrence Pattern</nz-form-label>
                <nz-form-control [nzSpan]="14" nzErrorTip="Select Recurrence Pattern">
                    <nz-select class="form-select mb-2" nzShowSearch name="RecurrencePattern" nzSize="default"
                        nzPlaceHolder="Choose" formControlName="RecurrencePattern" (ngModelChange)="RecurrencePattern">
                        <nz-option nzValue="Daily" nzLabel="Daily"></nz-option>
                        <nz-option nzValue="Weekly" nzLabel="Weekly"></nz-option>
                        <nz-option nzValue="Monthly" nzLabel="Monthly"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="validateScheduleForm.get('RecurrencePattern').value == 'Weekly'">
                <nz-form-label [nzSpan]="10" nzRequired>Applicable Days</nz-form-label>
                <nz-form-control [nzSpan]="14" nzErrorTip="Select Applicable Days">
                    <nz-select class="form-select mb-2" nzMode="multiple" name="ApplicableDays"
                        formControlName="ApplicableDays" nzSize="default" (ngModelChange)="ApplicableDays">
                        <nz-option nzValue="Sunday" nzLabel="Sunday"></nz-option>
                        <nz-option nzValue="Monday" nzLabel="Monday"></nz-option>
                        <nz-option nzValue="Tuesday" nzLabel="Tuesday"></nz-option>
                        <nz-option nzValue="Wednesday" nzLabel="Wednesday"></nz-option>
                        <nz-option nzValue="Thursday" nzLabel="Thursday"></nz-option>
                        <nz-option nzValue="Friday" nzLabel="Friday"></nz-option>
                        <nz-option nzValue="Saturday" nzLabel="Saturday"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item *ngIf="validateScheduleForm.get('RecurrencePattern').value == 'Monthly'">
                <nz-form-label [nzSpan]="10" nzRequired>Day of Month</nz-form-label>
                <nz-form-control [nzSpan]="14" nzErrorTip="Select Day of Month">
                    <nz-select class="form-select mb-2" name="DayOfMonth" formControlName="DayOfMonth" nzSize="default"
                        (ngModelChange)="DayOfMonth">
                        <nz-option
                            *ngFor="let day of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]"
                            [nzValue]="day" [nzLabel]="day"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Effective From Date</nz-form-label>
                <nz-form-control [nzSpan]="14" nzErrorTip="Select Effective From Date">
                    <nz-date-picker name="EffectiveFrom" nzSize="default" nzPlaceHolder="Effective From Date"
                        formControlName="EffectiveFrom" nzFormat="dd-MM-YYYY" />
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10">Effective To Date</nz-form-label>
                <nz-form-control [nzSpan]="14" nzErrorTip="Select Effective To Date">
                    <nz-date-picker name="EffectiveTo" nzSize="default" nzPlaceHolder="Effective To Date"
                        formControlName="EffectiveTo" nzFormat="dd-MM-YYYY" />
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="10" nzRequired>Activate</nz-form-label>
                <nz-form-control [nzSpan]="14" nzErrorTip="Select Activate">
                    <nz-switch name="IsActive" formControlName="IsActive" />
                </nz-form-control>
            </nz-form-item>
        </form>
    </ng-template>
</nz-modal>

<nz-modal [nzWidth]="800" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isScheduledDowntimeListVisible"
    [nzTitle]="ScheduledDowntimeListHeader" [nzContent]="ScheduledDowntimeListContent"
    [nzFooter]="ScheduledDowntimeListFooter" (nzOnCancel)="handleScheduledDowntimeListCancel()">
    <ng-template #ScheduledDowntimeListHeader>Scheduled Downtime List for Reason: {{
        SelectedProductionDowntimeReason?.ReasonName }}</ng-template>
    <ng-template #ScheduledDowntimeListContent>
        <div style="display: flex; justify-content: flex-end; margin-bottom: 10px;">
            <button nz-button nzType="primary" nzShape="circle" *ngIf="permission.Edit"
                (click)="showScheduledDowntimePop(SelectedProductionDowntimeReason, true)" nz-tooltip
                nzTooltipTitle="Add New"
                style="display: flex; align-items: center; justify-content: center; width: 32px; height: 32px;">
                <i nz-icon nzType="plus"></i>
            </button>
        </div>
        <nz-table *ngIf="this.ScheduledDowntimeList.length > 0" nzSize="small" [nzPageSize]="10" style="width: 100%"
            [nzScroll]="{ x: '1400px', y: '515px' }" #basicTable [nzData]="this.ScheduledDowntimeList"
            [nzLoading]="isScheduledDowntimeListLoading" nzBordered nzShowPagination="true" nzShowPagination="true"
            nzShowSizeChanger [nzPageSizeOptions]=[10,50,100,200,500] [nzShowTotal]="ScheduledDowntimeListTotalTemplate"
            nzShowQuickJumper="true" nzResponsive=true>
            <thead>
                <tr>
                    <th nzWidth="40px">S.No.</th>
                    <th nzWidth="100px">Recurrence</th>
                    <th nzWidth="100px">Start Time</th>
                    <th nzWidth="100px">End Time</th>
                    <th nzWidth="100px">Applicable Days</th>
                    <th nzWidth="100px">Day of Month</th>
                    <th nzWidth="50px">Line No</th>
                    <th nzWidth="100px">Effective From</th>
                    <th nzWidth="100px">Effective To</th>
                    <th nzWidth="80px">Activated</th>
                    <th nzWidth="80px" nzRight>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of basicTable.data">
                    <td>{{ data.SerialNo }}</td>
                    <td>{{ data.RecurrencePattern }}</td>
                    <td>{{ data.StartTime }}</td>
                    <td>{{ data.EndTime }}</td>
                    <td>{{ data.ApplicableDays }}</td>
                    <td>{{ data.DayOfMonth > 0 ? data.DayOfMonth : 'N/A' }}</td>
                    <td>{{ data.ProductionLineNo }}</td>
                    <td>{{ data.EffectiveFrom | DatetimeConverter }}</td>
                    <td>{{ data.EffectiveTo ? (data.EffectiveTo | DatetimeConverter) : 'No End Date' }}</td>
                    <td>{{ data.IsActive ? 'Yes' : 'No' }}</td>
                    <td nzRight>
                        <button nz-button nzType="primary" nzShape="circle" *ngIf="permission.Edit"
                            (click)="editScheduledDowntime(data)" nz-tooltip nzTooltipTitle="Edit">
                            <span nz-icon nzType="edit"></span>
                        </button>
                        <button nz-button nzType="primary" nzDanger nzShape="circle" *ngIf="permission.Delete"
                            (click)="handleDeleteScheduledDowntime(data.ScheduledDowntimeId)" nz-tooltip
                            nzTooltipTitle="Delete" style="margin-left: 8px;">
                            <span nz-icon nzType="delete"></span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </nz-table>
        <div *ngIf="this.ScheduledDowntimeList.length == 0">No Scheduled Downtime Found</div>
    </ng-template>
    <ng-template #ScheduledDowntimeListFooter>
        <button nz-button nzType="default" (click)="handleScheduledDowntimeListCancel()">Close</button>
    </ng-template>
</nz-modal>

<ng-template #ScheduledDowntimeListTotalTemplate let-total>Total {{ this.ScheduleListTotalCount }} items</ng-template>
import { HttpClient } from "@angular/common/http";
import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../../../environments/environment";
import { Modules, Responsibility } from "../../../../Models/Enums";
import { ProductCategoryModel, ProductFirstSubCategoryModel, ProductSecSubCategoryModel, StoreModel } from "../../../../Models/MasterModel";
import { MeasureUnitModel } from "../../../../Models/MeasureUnitModel";
import { ProductModel } from "../../../../Models/ProductModel";
import { StockModel } from "../../../../Models/StockModel";
import { AlertMessageService } from "../../../../Services/AlertMessageService";
import { AuthService } from "../../../../Services/auth.service";
import { ProductionDowntimeModel, ProductionDowntimeReasonModel } from "../../../../Models/ProductionDowntime";
import { CompatibleDate, NzDatePickerComponent } from "ng-zorro-antd/date-picker";
import { DemandEventService } from "../../../../Demand/services/DemandEventService";
import { LoadingService } from "src/PmsUIApp/Services/loadingService";
import { PrdDowntimeService } from "../../services/PrdDowntimeService";
import { Subscription } from "rxjs";
import * as moment from 'moment';
import { DatetimeConverter } from "src/PmsUIApp/Services/DatetimeConverter.pipe";

@Component({
  selector: 'app-prd-downtime',
  templateUrl: './prddowntime.component.html',
  styleUrls: ['./prddowntime.component.css']
})

export class PrdDowntimeComponent {
  ApiUrl = environment.Api_Url;
  CategoryID: number = 0;
  FirstCategoryID: number = 0;
  SecondCategoryID: number = 0;
  SelectedProductType: string = '';
  NewDowntime: ProductionDowntimeModel = new ProductionDowntimeModel;
  ProductionDowntimeReasonList: ProductionDowntimeReasonModel[] = [];
  ProductionLineTypeList = [
    { name: 'Manufacturing' },
    { name: 'Printing' },
    { name: 'Embossing' },
    { name: 'Lacquer' },
    { name: 'Vacuum' },
    { name: 'Packaging' },
  ];
  ProductionLineNoList = [
    { name: '1', value: 1 },
    { name: '2', value: 2 },
    { name: '3', value: 3 },
    { name: '4', value: 4 },
    { name: '5', value: 5 }
  ];
  count: 0;
  ShowProductionLineType: boolean = false;
  permission = {
    View: false,
    Add: false,
    Delete: false,
    Manage: false
  }
  IsPrdDowntimeVisible: boolean = false;
  SelectedProductionLineType: string = '';
  private subscription: Subscription = new Subscription();
  @ViewChild('endDatePicker') endDatePicker!: NzDatePickerComponent;
  duration: string = '';

  constructor(public http: HttpClient, private alertService: AlertMessageService,
    private router: Router,
    private auth: AuthService,
    private loader: LoadingService,
    private prdDowntimeService: PrdDowntimeService) {

  }
  ngOnInit() {
    this.subscription.add(
      this.prdDowntimeService.modalVisibility$.subscribe(
        visible => {
          this.IsPrdDowntimeVisible = visible;
          if (visible) {
            this.GetAllProductionDowntimeActiveReasons();
          }
        }
      )
    );

    // Subscribe to modal data changes
    this.subscription.add(
      this.prdDowntimeService.modalData$.subscribe(
        data => {
          if (data) {
            this.NewDowntime = data.downtimeData;
            this.calculateDuration();
            this.SelectedProductionLineType = data.productionLineType;
            this.ShowProductionLineType = data.productionLineType == "Manufacturing" ? false : true;
          }
        }
      )
    );

  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  GetAllProductionDowntimeActiveReasons() {
    try {
      if (this.ProductionDowntimeReasonList.length == 0) {
        this.loader.show();
        let url = this.ApiUrl + "production/getproductiondowntimeactivereasonlist";
        this.http.get<any>(url).subscribe({
          next: res => {
            this.ProductionDowntimeReasonList = res.ResponseBody;
            this.loader.hide();
          },
          error: res => {
            this.loader.hide();
            this.count++;
            if (this.count < 2) {
              this.GetAllProductionDowntimeActiveReasons();
            }
          }
        });
      }
    } catch (error) {
      this.loader.hide();
      this.alertService.error(error.error);
    }
  }

  handleCancel() {
    this.prdDowntimeService.closeModal();
    this.NewDowntime = new ProductionDowntimeModel();
  }
  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.NewDowntime.EndTime) {
      return false;
    }
    return startValue.getTime() > new Date(this.NewDowntime.EndTime).getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.NewDowntime.StartTime) {
      return false;
    }
    const startDate = new Date(
      new Date(this.NewDowntime.StartTime).getFullYear(),
      new Date(this.NewDowntime.StartTime).getMonth(),
      new Date(this.NewDowntime.StartTime).getDate()
    );
    const endDate = new Date(endValue.getFullYear(), endValue.getMonth(), endValue.getDate());

    // Allow same date, only disable past dates
    return endDate < startDate;
  };
  handleStartTimeSelection = (time: CompatibleDate): void => {
    if (time && this.NewDowntime.EndTime) {
      // Convert to Date object if it isn't already
      const startDateTime = time instanceof Date ? time : new Date(time as any);
      const endDateTime = new Date(this.NewDowntime.EndTime);

      // If same date, validate time
      const sameDate = startDateTime.getFullYear() === endDateTime.getFullYear() &&
        startDateTime.getMonth() === endDateTime.getMonth() &&
        startDateTime.getDate() === endDateTime.getDate();

      if (sameDate && startDateTime.getTime() >= endDateTime.getTime()) {
        // Reset time or show error message
        this.alertService.error('Start time must be before end time');
        this.NewDowntime.StartTime = null;
      }
    }
  };

  handleEndTimeSelection = (time: CompatibleDate): void => {
    if (time && this.NewDowntime.StartTime) {
      // Convert to Date object if it isn't already
      const endDateTime = time instanceof Date ? time : new Date(time as any);
      const startDateTime = new Date(this.NewDowntime.StartTime);

      // If same date, validate time
      const sameDate = startDateTime.getFullYear() === endDateTime.getFullYear() &&
        startDateTime.getMonth() === endDateTime.getMonth() &&
        startDateTime.getDate() === endDateTime.getDate();

      if (sameDate && endDateTime.getTime() <= startDateTime.getTime()) {
        // Reset time or show error message
        this.alertService.error('End time must be after start time');
        this.NewDowntime.EndTime = null;
      }
    }
  };
  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endDatePicker.open();
    }
  }
  Save() {
    this.NewDowntime.ProductionLineType = this.SelectedProductionLineType == null ? this.NewDowntime.ProductionLineType : this.SelectedProductionLineType;
    if (this.NewDowntime.ProductionDowntimeReasonId <= 0 || this.NewDowntime.ProductionDowntimeReasonId == null) {
      this.alertService.error("Select Reason");
      return;
    }
    else if (this.NewDowntime.ProductionLineNo <= 0 || this.NewDowntime.ProductionLineNo == null) {
      this.alertService.error("Select Production Line No.");
      return;
    }
    else if (this.NewDowntime.ProductionLineType == null || this.NewDowntime.ProductionLineType == undefined) {
      this.alertService.error("Select Production Line Type");
      return;
    }
    else if (this.NewDowntime.StartTime == null || this.NewDowntime.StartTime == undefined) {
      this.alertService.error("Enter Start Time");
      return;
    }
    else if (this.NewDowntime.EndTime == null || this.NewDowntime.EndTime == undefined) {
      this.alertService.error("Enter End Time");
      return;
    }
    else if (this.NewDowntime.Comments == null || this.NewDowntime.Comments == undefined || this.NewDowntime.Comments == '') {
      this.alertService.error("Enter Comment with details about the downtime");
      return;
    }
    this.NewDowntime.StartTime = new Date(this.NewDowntime.StartTime).toISOString();
    this.NewDowntime.EndTime = new Date(this.NewDowntime.EndTime).toISOString();
    this.loader.show();
    let url = this.ApiUrl + "production/addupdateproductiondowntime";
    this.http.post<any>(url, this.NewDowntime).subscribe({
      next: res => {
        this.alertService.success(res);
        this.loader.hide();
        this.NewDowntime = new ProductionDowntimeModel();
        this.prdDowntimeService.closeModal();
        this.prdDowntimeService.triggerRefresh();
      },
      error: res => {
        this.alertService.error(res.error);
        this.loader.hide();
      },
    });
  }

  calculateDuration() {
    if (this.NewDowntime.StartTime && this.NewDowntime.EndTime) {
      const start = moment(this.NewDowntime.StartTime);
      const end = moment(this.NewDowntime.EndTime);

      if (end.isAfter(start)) {
        const duration = moment.duration(end.diff(start));
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        let durationStr = '';
        if (days > 0) durationStr += `${days}d `;
        if (hours > 0) durationStr += `${hours}h `;
        if (minutes > 0) durationStr += `${minutes}m `;
        if (seconds > 0) durationStr += `${seconds}s`;

        this.duration = durationStr.trim();
      } else {
        this.duration = '';
      }
    } else {
      this.duration = '';
    }
  }
}

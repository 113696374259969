import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { ProductModel } from 'src/PmsUIApp/Models/ProductModel';
import { LoadingService } from 'src/PmsUIApp/Services/loadingService';
import { CommonService } from 'src/PmsUIApp/Services/CommonService';
import { KnittingDivisionStockModel } from 'src/PmsUIApp/Models/KnittingDivisionStockModel';


@Component({
  selector: 'app-digital-weight',
  templateUrl: './digital-weight.component.html',
  styleUrls: ['./digital-weight.component.css']
})
export class DigitalWeightComponent {

  ApiUrl = environment.Api_Url;

  permission = {
    View: false,
    Add: false,
    Edit: false,
    Delete: false
  }
  ProductList: ProductModel[];
  weightres: number[];
  count: any;
  subCategories = ['FABRIC LOOPKNIT', 'FABRIC MICRO HOSIERY', 'FABRIC SPUN HOSIERY	'];
  selectedCategory = 'FABRIC LOOPKNIT';
  KnittingBambooWeight: number;
  WeightCaptureList: any[];
  isWeightCaptureListVisible: boolean = false;
  WeightCaptureListTotalCount: number;
  constructor(
    public http: HttpClient,
    private commonService: CommonService,
    private auth: AuthService,
    private alertService: AlertMessageService,
    private router: Router,
    private loader: LoadingService,
    private modal: NzModalService) { }

  name = null;
  index = -1;
  SelectedRow: ProductModel;
  textLable = null;

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.IoTDevicesDigitalWeight, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.IoTDevicesDigitalWeight, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.KnittingDivisionStock, Responsibility.Delete);

    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    else {
      this.GetProductsByCategory();
      this.GetKnittingBambooWeight();
    }

  }

  setSelected(item: ProductModel, index) {
    this.SelectedRow = item;
    this.name = item.ProductName;
    this.index = index;
    this.weightres = [];
    console.log(item);
  }

  captureWeight() {
    this.loader.show();
    let url = "https://iot.pms.zaibunco.com/Weight/captureweight";
    this.http.get<number[]>(url).subscribe({
      next: res => {
        console.log(res);
        this.weightres = res;
        console.log(this.weightres);
        this.loader.hide();
      },
      error: () => {
        this.loader.hide();
        this.alertService.error("Error trying to reach Digital Weight Machine. Check if device is Powered On or Switched Off.")
      }
    })
  }

  GetKnittingBambooWeight() {
    this.commonService.getConfigValue('KnittingBambooRollWeightInKgs').subscribe(res => {
      this.KnittingBambooWeight = Number(res);
    });
  }

  save(item: ProductModel, weight: number) {
    var request = {
      ProductId: item.ProductId,
      Weight: weight - this.KnittingBambooWeight,
      BambooRollWeightInKgs: this.KnittingBambooWeight
    }
    this.loader.show();
    let url = this.ApiUrl + "iot/addknittingfabricwithweight";
    this.http.post<any>(url, request).subscribe({
      next: res => {
        this.alertService.success("Weight Saved Successfully...!!!");
        this.loader.hide();
      },
      error: (res) => {
        this.loader.hide();
        this.alertService.error(res.error.ResponseBody);
      }
    })

  }

  selectCategory(category: string) {
    this.selectedCategory = category;
    this.GetProductsByCategory();
  }

  GetProductsByCategory() {
    var request = {
      ProductCategoryName: 'Fabric',
      ProductFirstSubCategoryName: this.selectedCategory,
      ProductSecSubCategoryName: ''
    }
    this.loader.show();
    let url = this.ApiUrl + "product/getproductsbycategorynames";
    this.http.post<ProductModel[]>(url, request).subscribe({
      next: res => {
        this.ProductList = res;
        this.loader.hide();
      },
      error: () => {
        this.loader.hide();
        this.count++;
        if (this.count < 2) { this.GetProductsByCategory(); }
      }
    })
  }
  GetAllWeightCapture() {
    var request = {
      FromDate: new Date(new Date(new Date().setHours(0, 0, 1))),
      ToDate: new Date(new Date().setHours(23, 59, 59))
    }
    this.loader.show();
    let url = this.ApiUrl + "iot/getknittingfabricstockswithfilter";
    this.http.post<KnittingDivisionStockModel[]>(url, request).subscribe({
      next: res => {
        this.WeightCaptureList = res;
        this.WeightCaptureListTotalCount = res.length;
        this.WeightCaptureList.forEach(element => {
          element.SerialNo = this.WeightCaptureList.indexOf(element) + 1;
        });
        this.isWeightCaptureListVisible = true;
        this.loader.hide();
      },
      error: () => {
        this.WeightCaptureList = [];
        this.WeightCaptureListTotalCount = 0;
        this.isWeightCaptureListVisible = false;
        this.loader.hide();
      }
    })
  }
  showWeightCaptureList() {
    this.GetAllWeightCapture();
    
  }

  handleWeightCaptureListCancel() {
    this.isWeightCaptureListVisible = false;
  }
  deleteWeightCapture(id: number) {
    this.loader.show();
    let url = this.ApiUrl + "iot/deleteknittingfabricrecord";
    this.http.post<any>(url, id).subscribe({
      next: res => { this.alertService.success("Record Deleted"); this.loader.hide(); },
      error: res => { this.alertService.error(res.error); this.loader.hide(); },
    });
  }
  handleDeleteWeightCapture(id: number) {
    this.modal.confirm({
      nzTitle: 'Are you sure you want to delete this weight capture?',
      nzOkText: 'Delete',
      nzCancelText: 'Cancel',
      nzOnOk: () => this.deleteWeightCapture(id)
    });
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { DemandModel } from "../../Models/DemandModel";

@Injectable()

export class DemandPopService {
  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this._loading.asObservable();
  public _demandModel = new DemandModel;
  public IsFromOutside = false;
  public callbacks: any = [];
  constructor() { }

  show() {
    this._loading.next(true);
  }

  hide() {
    this._loading.next(false);
  }
  _registerCallback(callback: any) {
    this.callbacks.push(callback);
  }
}

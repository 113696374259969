import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { ProductCategoryModel, ProductFirstSubCategoryModel, ProductSecSubCategoryModel } from '../../Models/MasterModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { Modules, Responsibility } from '../../Models/Enums';
import { Router } from '@angular/router';
import { AuthService } from '../../Services/auth.service';
import { PurchaseOrderModel } from '../../Models/PurchaseOrderModel';
import { LoadingService } from '../../Services/loadingService';
import { ProductModel } from '../../Models/ProductModel';
import { HttpClient } from '@angular/common/http';
import { WastageReportModel, YieldReportModel } from '../../Models/ReportModel';

@Component({
  selector: 'app-WastageReport',
  templateUrl: './WastageReport.component.html'
})


export class WastageReportComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  WastageReportList: WastageReportModel[] = [];
  WastageReportListOriginal: WastageReportModel[] = [];
  PurchaseOrder!: PurchaseOrderModel;
  PurchaseOrderList: PurchaseOrderModel[] = [];
  IsBtnVisible: boolean = false;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  SelectedProductType: string = '';
  SelectedStatus: string = '';
  FilteredProductList: ProductModel[] = [];
  ProductList: ProductModel[] = [];
  CategoryID: number = 0;
  FirstCategoryID: number = 0;
  SecondCategoryID: number = 0;
  selectedProduct: number = 0;
  ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  ProductCategoryList: ProductCategoryModel[] = [];
  FilteredProductCategoryList: ProductCategoryModel[] = [];
  //request = {}
  count: number;
  request = {
    DateFrom: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7)),
    DateTo: new Date(new Date().setHours(23, 59, 59))
  }
  ShowWastage = 'Y';
  constructor(private loader: LoadingService, public http: HttpClient, private router: Router, private auth: AuthService, private alertService: AlertMessageService, private modalService: NzModalService) {

  }
  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.ReportsWastage, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.ReportsWastage, Responsibility.Add);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    console.log(this.permission)
    this.WastageReport();
  }

  WastageReport() {
    this.isTableLoading = true; let url = this.ApiUrl + "Report/wastagereport";

    this.http.post<WastageReportModel[]>(url, this.request).subscribe(res => {
      console.log(res)
      this.WastageReportListOriginal = this.WastageReportList = res; this.isTableLoading = false;
      this.WastageReportListOriginal.forEach(x => {
        if (x.WastageEmbossing == 0 && x.WastageLacquer == 0 && x.WastagePrint == 0 && x.WastageTumbling == 0 && x.WastageVacuum == 0) {
          x.IsShow = true;
        } else {
          x.IsShow = false;
        }
      })
      //this.Wastage.Show = true;
      this.CalculateWastage();
    }, res => {
      this.count++;
      if (this.count < 2) { this.WastageReport(); }
    });
  }

  CalculateWastage() {
    if (this.ShowWastage == 'Y') {
      this.WastageReportList = this.WastageReportListOriginal.filter(x => x.IsShow == false)
    }
    else {
      this.WastageReportList = this.WastageReportListOriginal
    }
  }
}


import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NzModalService } from "ng-zorro-antd/modal";
import { environment } from "../../../../environments/environment";
import { FactoryWorkersModel, RackModel, StoreModel } from "../../../Models/MasterModel";
import { SaleOrderCompleteProductionModel, SaleOrderProductionModel } from "../../../Models/SalesOrderModel";
import { WorkPlanJumboMasterModel, WorkPlanMasterModel, WorkPlanOrderModel } from "../../../Models/WorkPlanModel";
import { AlertMessageService } from "../../../Services/AlertMessageService";
import { UserInfo } from "src/PmsUIApp/Authentication/UserInfo";
import { Modules, Responsibility } from "../../../Models/Enums";
import { AuthService } from "../../../Services/auth.service";
import { NzMessageService } from "ng-zorro-antd/message";
import { LoadingService } from "src/PmsUIApp/Services/loadingService";
import { DatetimeConverter } from "src/PmsUIApp/Services/DatetimeConverter.pipe";
import { FormulationProductCalculatePasteReqRequestModel, FormulationProductCalculatePasteReqResponseModel } from "src/PmsUIApp/Models/FormulationCodeModel";
import { formatDate } from "@angular/common";
import { SoDrawerService } from "src/PmsUIApp/Services/SoDrawerService";
import { CompatibleDate, NzDatePickerComponent } from "ng-zorro-antd/date-picker";
import { PrdDowntimeService } from "src/PmsUIApp/Features/PrdDowntime/services/PrdDowntimeService";

@Component({
  selector: 'app-jumbomaster',
  templateUrl: './jumbomaster.component.html'
})
export class JumboMasterComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  WorkPlanList: WorkPlanMasterModel[] = [];
  SelectedWorkPlan: WorkPlanMasterModel = new WorkPlanMasterModel;
  jumboPlanModel: WorkPlanJumboMasterModel = new WorkPlanJumboMasterModel;
  jumboTableData: WorkPlanJumboMasterModel[] = [];
  jumboResponse: WorkPlanJumboMasterModel = new WorkPlanJumboMasterModel;
  SelectedWorkPlanOrder: WorkPlanOrderModel[] = [];
  SelectedOrder!: WorkPlanOrderModel | null;
  RackList: RackModel[] = [];
  StoreList: StoreModel[] = [];
  completeSaleOrderProductionRequest: SaleOrderCompleteProductionModel = new SaleOrderCompleteProductionModel;
  ProductionCompletionRemark = '';
  Rate: number = 0;
  Amount: number = 0;
  JumboRolQty: number = 0;
  Weight: number = 0;
  RackId: number = 0;
  isTableLoading = false;
  isLoading: boolean = false;
  isQtyFull: boolean = false;
  TotalJumboQty: number = 0;
  SelectedOrderId: number = 0;
  SelectedWorkPlanOrdersId: number = 0;
  TotalWeight: number = 0;
  count: number;
  permission = {
    View: false,
    Add: false,
    Delete: false,
    Manage: false
  }
  DowntimePermission = {
    Add: false,
  }
  isPopVisible = false;
  isConfirmOpen = false;
  jumboAddbtnText = '';
  removeBtnText = '';
  SelectedOrderPreSkinPasteReq: number = 0;
  SelectedOrderSkinPasteReq: number = 0;
  SelectedOrderFoamPasteReq: number = 0;
  SelectedOrderAdhesivePasteReq: number = 0;
  SelectedOrderPreSkinActualPasteReq: number = 0;
  SelectedOrderSkinActualPasteReq: number = 0;
  SelectedOrderFoamActualPasteReq: number = 0;
  SelectedOrderAdhesiveActualPasteReq: number = 0;
  FormulationPasteReqRecalculateRequest: FormulationProductCalculatePasteReqRequestModel = new FormulationProductCalculatePasteReqRequestModel;
  DisableCompletePrdBtn = true;
  FactoryWorkersList: FactoryWorkersModel[];
  @ViewChild('endDatePicker') endDatePicker!: NzDatePickerComponent;
  constructor(public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService, private route: ActivatedRoute, private router: Router, private auth: AuthService,
    private loader: LoadingService, private soDrawer: SoDrawerService, private prdDowntimeService: PrdDowntimeService) {
  }

  ngOnInit(): void {
    this.permission.View = this.auth.CheckResponsibility(Modules.Jumbo, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Jumbo, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.Jumbo, Responsibility.Delete);
    this.permission.Manage = this.auth.CheckResponsibility(Modules.Jumbo, Responsibility.Manage);
    this.DowntimePermission.Add = this.auth.CheckResponsibility(Modules.ProductionDowntime, Responsibility.Add);
    if (this.permission.Add != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.jumboPlanModel.JumboRollDate = new Date().toString();

    this.jumboPlanModel.JumboRollStartTime = new Date();
    this.getWorkOrderList();
    this.GetAllStore();
    this.GetAllFactoryWorkers()
    this.completeSaleOrderProductionRequest.SaleOrderProduction = new SaleOrderProductionModel;
    //this.getJumboRollsById();
  }

  getWorkOrderList() {
    if (this.WorkPlanList.length <= 0) {

      let url = this.ApiUrl + 'workplan/getallworkplanbysaleorderstatus';
      var item = ["ProductionStarted", "InJumbo"];
      this.http.post<any>(url, item).subscribe({
        next: res => {
          this.WorkPlanList = res.Data;
        },
        error: res => {
          this.count++;
          if (this.count < 2) { this.getWorkOrderList(); }
        },
      });
    }
  }


  GetAllStore() {

    let url = this.ApiUrl + "store/getallstores";
    this.http.get<StoreModel[]>(url).subscribe(res => {
      this.StoreList = res;

    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllStore(); }
    });
  }
  onSelectedStoreChange() {
    let url = this.ApiUrl + "rack/getallracks";
    this.http.get<RackModel[]>(url).subscribe(res => {
      this.RackList = res.filter(x => x.StoreId == this.jumboPlanModel.StoreId);
      //this.RackID = 0;
    }, res => { });
  }
  GetAllRack() {

    let url = this.ApiUrl + "rack/getallracks";
    this.http.get<RackModel[]>(url).subscribe(res => {
      this.RackList = res;

    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllRack(); }
    });
  }
  OnWorkPlanChange() {
    this.SelectedWorkPlanOrdersId = null;
    if (this.SelectedWorkPlan == null) {
      this.SelectedWorkPlanOrder = [];
      this.SelectedOrder = null;
      return;
    }
    this.isTableLoading = true;
    let url = this.ApiUrl + "workplan/getworkplanorderbyworkplanid/" + this.SelectedWorkPlan.WorkPlanId;
    this.http.get<WorkPlanOrderModel[]>(url).subscribe(res => {
      this.SelectedWorkPlanOrder = res.filter(x => x.SaleOrder.Status.toString() == "InJumbo" || x.SaleOrder.Status.toString() == "ProductionStarted");;
      this.SelectedWorkPlanOrder.forEach((element) => {
        element.SaleOrder.SaleOrderProduction.ManufacturingQuantity = element.SaleOrder.SaleOrderProduction.ManufacturingQuantity ?? 0;
      })
      //console.log(this.SelectedWorkPlanOrder)
      this.SelectedOrder = null;
      this.isTableLoading = false;
    }, res => { });
  }
  OnSelectOrderNo(data: WorkPlanOrderModel) {
    this.SelectedOrder = data;
    data.SaleOrder.SaleOrderProduction.InspectionFormulationMixing.forEach(x => {
      if (x.MixingName.toLowerCase() == 'pre skin') {
        this.SelectedOrderPreSkinPasteReq = this.SelectedOrderPreSkinActualPasteReq = parseFloat(x.StdPasteRequirementScquantity.toFixed(2));
      }
      else if (x.MixingName.toLowerCase() == 'skin') {
        this.SelectedOrderSkinPasteReq = this.SelectedOrderSkinActualPasteReq = parseFloat(x.StdPasteRequirementScquantity.toFixed(2));
      }
      else if (x.MixingName.toLowerCase() == 'foam') {
        this.SelectedOrderFoamPasteReq = this.SelectedOrderFoamActualPasteReq = parseFloat(x.StdPasteRequirementScquantity.toFixed(2));
      }
      else if (x.MixingName.toLowerCase() == 'adhesive') {
        this.SelectedOrderAdhesivePasteReq = this.SelectedOrderAdhesiveActualPasteReq = parseFloat(x.StdPasteRequirementScquantity.toFixed(2));
      }
    });
    this.SelectedWorkPlanOrdersId = data.SaleOrder.SaleOrderId;
    this.SelectedOrderId = data.OrderId;
    this.getJumboRollsById();

  }
  EditManufacturingQuantity(data: WorkPlanOrderModel) {
    data.IsEdit = true;
  }
  CancelSave(data: WorkPlanOrderModel) {
    data.IsEdit = false;
    data.TempManufacturingQTY = 0;
  }

  SaveManufacturingQuantity(data: WorkPlanOrderModel) {
    this.isTableLoading = true;
    let url = this.ApiUrl + "saleorder/updatemanufacturingquantity?saleorderid=" + data.SaleOrder.SaleOrderId + "&quantity=" + data.TempManufacturingQTY;
    this.http.get<any>(url).subscribe(res => {
      data.SaleOrder.SaleOrderProduction.ManufacturingQuantity = data.TempManufacturingQTY;
      data.IsEdit = false;
      data.TempManufacturingQTY = 0;
      this.isTableLoading = false;
    }
      , res => {
        this.alertService.error('An error has been occured. Please try again');
        this.isTableLoading = false;
      });
  }

  getJumboRollsById(): void {
    var id = this.SelectedOrder?.OrderId;
    let url = this.ApiUrl + 'workplan/getworkplanjumbobyworkplanordersid';
    this.http.get<any>(url + '/' + id).subscribe(
      (res) => {
        this.jumboTableData = res.Data;
        this.jumboTableData.forEach((x) => {
          x.JumboRollDate = this.formatToIST(x.JumboRollDate);
        })
        this.calculateTotal();
        this.convertJumboDetailTimeZone();
        this.completeSaleOrderProductionRequest.SaleOrderProduction.PreSkinGsm = this.SelectedOrder?.SaleOrder.SaleOrderProduction.PreSkinGsm;
        this.completeSaleOrderProductionRequest.SaleOrderProduction.SkinGsm = this.SelectedOrder?.SaleOrder.SaleOrderProduction.SkinGsm;
        this.completeSaleOrderProductionRequest.SaleOrderProduction.AdhesiveGsm = this.SelectedOrder?.SaleOrder.SaleOrderProduction.AdhesiveGsm;
        this.completeSaleOrderProductionRequest.SaleOrderProduction.FoamGsm = this.SelectedOrder?.SaleOrder.SaleOrderProduction.FoamGsm;
        var FabricGSM = this.SelectedOrder?.SaleOrder.SaleOrderProduction.InspectionFormulationMixing[0].FabricGSM;
        this.completeSaleOrderProductionRequest.SaleOrderProduction.FabricGsm = FabricGSM > 0 ? FabricGSM : 0;

      });


  }
  convertJumboDetailTimeZone() {
    this.jumboTableData.forEach(element => {
      element.JumboRollStartTime = new Date(new DatetimeConverter().transform(element.JumboRollStartTime.toString()));
      element.JumboRollEndTime = new Date(new DatetimeConverter().transform(element.JumboRollEndTime.toString()));
    }
    );
  }
  private formatToIST(dateString: string): string {
    const istDate = new Date(dateString);
    // const istDate = new Date(date.getTime() + (5.5 * 60 * 60 * 1000)); // Convert to IST. New Update: No need to convert to IST, as it is getting converted in API while saving.

    // Format the date as dd-MM-yyyy
    const day = String(istDate.getDate()).padStart(2, '0');
    const month = String(istDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = istDate.getFullYear();

    return `${day}-${month}-${year}`;
  }
  calculateTotal() {
    this.TotalJumboQty = 0;
    this.TotalWeight = 0;
    this.jumboTableData.forEach((element) => {
      this.TotalJumboQty += parseFloat(element.JumboRolQty.toString());
      this.TotalWeight += parseFloat(element.Weight.toString());
    });
    //var MFGQty = this.SelectedOrder?.SaleOrder.SaleOrderProduction.ManufacturingQuantity ?? 1;
    var OrderQty = this.SelectedOrder?.SaleOrder.SaleOrderProduction.OrderQuantity;
    this.SelectedOrder.SaleOrder.SaleOrderProduction.ManufacturingQuantity = this.TotalJumboQty;
    if (this.TotalJumboQty >= OrderQty) {
      this.isQtyFull = true;
    } else {
      this.isQtyFull = false;
    }
  }
  btnAddClick() {
    if (this.validateJumbo()) {
      this.jumboPlanModel.OrderId = this.SelectedOrderId;
      this.jumboPlanModel.SaleOrderId = this.SelectedWorkPlanOrdersId;
      this.jumboTableData.push(this.jumboPlanModel);
      this.jumboPlanModel = new WorkPlanJumboMasterModel();
      this.jumboPlanModel.JumboRollDate = new Date().toString();
      this.jumboPlanModel.JumboRollStartTime = new Date();
      //this.jumboPlanModel.Rate = this.jumboPlanModel.JumboRolQty = this.jumboPlanModel.Amount = this.jumboPlanModel.Weight = this.jumboPlanModel.StoreId = this.jumboPlanModel.RackId = 0

      this.calculateTotal();
    }
  }
  btnAddClickSaveSingle() {
    if (this.validateJumbo()) {
      this.jumboPlanModel.SaleOrderId = this.SelectedWorkPlanOrdersId;
      //this.jumboPlanModel.Rate = this.jumboPlanModel.JumboRolQty = this.jumboPlanModel.Amount = this.jumboPlanModel.Weight = this.jumboPlanModel.StoreId = this.jumboPlanModel.RackId = 0

      this.jumboPlanModel.JumboRollDate = new Date(this.jumboPlanModel.JumboRollDate).toISOString()
      let url = this.ApiUrl + 'workplan/addworkplanjumbosingleobj';
      this.isLoading = true;
      this.loader.show();
      this.http.post<any>(url, this.jumboPlanModel).subscribe({
        next: (res) => {
          //this.jumboTableData.push(res.Data);
          this.alertService.success('Jumbo Data Added Successfully');
          this.isLoading = false;
          this.loader.hide();
          //this.jumboTableData.push(this.jumboPlanModel);
          this.jumboPlanModel = new WorkPlanJumboMasterModel();
          this.jumboPlanModel.JumboRollDate = new Date().toString();
          this.jumboPlanModel.JumboRollStartTime = new Date();
          this.getJumboRollsById()
        },
        error: (res) => {
          this.alertService.error(res);
          this.isLoading = false;
          this.loader.hide();

        },
      });
    }
  }
  validateJumbo() {

    var MFGQty = this.SelectedOrder?.SaleOrder.SaleOrderProduction.ManufacturingQuantity ?? 0;
    var TotalJQTY = this.jumboTableData.reduce((sum, current) => sum + parseFloat(current.JumboRolQty?.toString()), 0) ?? 0
    var CurrentQTY = this.jumboPlanModel.JumboRolQty ?? 0
    var TQ = parseInt(TotalJQTY.toString()) + parseInt(CurrentQTY.toString());
    console.log(TQ)
    if (this.jumboPlanModel.JumboRollDate === undefined) {
      this.alertService.error('JumboRoll Date required');
      return false;
    }
    if (this.jumboPlanModel.JumboRollStartTime === undefined) {
      this.alertService.error('StartTime required');
      return false;
    }
    if (this.jumboPlanModel.JumboRollEndTime === undefined) {
      this.alertService.error('EndTime required');
      return false;
    }
    if (this.jumboPlanModel.JumboRollStartTime.getTime() >= this.jumboPlanModel.JumboRollEndTime.getTime()) {
      this.alertService.error('Start time must be before end time');
      return false;
    }
    if (this.jumboPlanModel.ShiftSupervisorWorkerId === 0) {
      this.alertService.error('Select Supervisor Name');
      return false;
    }
    //if (isNaN(this.jumboPlanModel.Rate) || this.jumboPlanModel.Rate <= 0) {
    //  this.alertService.error('Rate required');
    //  return false;
    //}
    if (CurrentQTY <= 0) {
      this.alertService.error('Jumbo Roll QTY required');
      return false;
    }
    if (isNaN(this.jumboPlanModel.Weight) || this.jumboPlanModel.Weight <= 0) {
      this.alertService.error('Weight required');
      return false;
    }

    if (isNaN(this.jumboPlanModel.StoreId) || this.jumboPlanModel.StoreId <= 0) {
      this.alertService.error('Store required');
      return false;
    }
    if (isNaN(this.jumboPlanModel.RackId) || this.jumboPlanModel.RackId <= 0) {
      this.alertService.error('Rack required');
      return false;
    }
    return true;


  }
  calculateAmount() {
    if (isNaN(this.jumboPlanModel.JumboRolQty)) {
      this.jumboPlanModel.JumboRolQty = 0;
    }
    if (isNaN(this.jumboPlanModel.Rate)) {
      this.jumboPlanModel.Rate = 0;
    }
    this.jumboPlanModel.Amount = this.jumboPlanModel.JumboRolQty * this.jumboPlanModel.Rate

  }

  SaveJumboItems() {
    if (this.jumboTableData.length > 0) {
      var MFGQty = this.SelectedOrder?.SaleOrder.SaleOrderProduction.ManufacturingQuantity ?? 0;
      var totalQty = this.jumboTableData.reduce((sum, current) => sum + parseFloat(current.JumboRolQty?.toString()), 0) ?? 0;
      if (totalQty < MFGQty) {
        this.alertService.error(MFGQty - totalQty + ' quantity is pending');
        return;
      }
      let url = this.ApiUrl + 'workplan/addworkplanjumbo';
      this.isLoading = true;
      for (let index = 0; index < this.jumboTableData.length; index++) {
        this.jumboTableData[index].AddedBy = UserInfo.UserName
      }
      this.http.post<WorkPlanJumboMasterModel[]>(url, this.jumboTableData).subscribe({
        next: (res) => {
          this.alertService.success('Jumbo Data added Successfully');
          this.isLoading = false;
          this.SelectedWorkPlan == null
          this.SelectedWorkPlanOrder = [];
          this.SelectedOrder = null;
          this.WorkPlanList = [];
          this.SelectedWorkPlan = new WorkPlanMasterModel;
          this.getWorkOrderList();
        },
        error: (res) => {
          this.alertService.error(res);
          this.isLoading = false;

        },
      });
    }
    else {
      alert("Please add Items");
    }
  }
  CompleteProduction() {
    if (this.jumboTableData.length > 0) {
      this.isLoading = true;
      // var MFGQty = this.SelectedOrder?.SaleOrder.SaleOrderProduction.ManufacturingQuantity ?? 0;
      // var totalQty = this.jumboTableData.reduce((sum, current) => sum + parseFloat(current.JumboRolQty?.toString()), 0) ?? 0;
      // if (totalQty < MFGQty) {
      //   this.alertService.error(MFGQty - totalQty + ' quantity is pending');
      //   return;
      // }
      this.completeSaleOrderProductionRequest.SaleOrderId = this.SelectedOrderId;
      this.completeSaleOrderProductionRequest.SaleOrderProduction.ManufacturingQuantity = this.TotalJumboQty;
      this.completeSaleOrderProductionRequest.PreSkinActualPasteQty = this.SelectedOrderPreSkinActualPasteReq;
      this.completeSaleOrderProductionRequest.SkinActualPasteQty = this.SelectedOrderSkinActualPasteReq;
      this.completeSaleOrderProductionRequest.AdhesiveActualPasteQty = this.SelectedOrderAdhesiveActualPasteReq;
      this.completeSaleOrderProductionRequest.FoamActualPasteQty = this.SelectedOrderFoamActualPasteReq;

      if (this.SelectedOrderPreSkinPasteReq > 0 && this.SelectedOrderPreSkinActualPasteReq <= 0) {
        this.alertService.error("Pre Skin Actual Paste Quantity cannot be 0")
        return;
      }
      else if (this.SelectedOrderSkinPasteReq > 0 && this.SelectedOrderSkinActualPasteReq <= 0) {
        this.alertService.error("Skin Actual Paste Quantity cannot be 0")
        return;
      }
      else if (this.SelectedOrderAdhesivePasteReq > 0 && this.SelectedOrderAdhesiveActualPasteReq <= 0) {
        this.alertService.error("Adhesive Actual Paste Quantity cannot be 0")
        return;
      }
      else if (this.SelectedOrderFoamPasteReq > 0 && this.SelectedOrderFoamActualPasteReq <= 0) {
        this.alertService.error("Foam Actual Paste Quantity cannot be 0")
        return;
      }

      this.loader.show();
      let url = this.ApiUrl + 'saleorder/completesaleorderproduction';
      this.http.post<SaleOrderCompleteProductionModel>(url, this.completeSaleOrderProductionRequest).subscribe({
        next: (res) => {
          this.alertService.success('Production Completed Successfully');
          this.handleCancel();
          this.completeSaleOrderProductionRequest = null;
          this.router.navigate(['/home/production/jumbo/list']);
          this.loader.hide();
        },
        error: (res) => {
          this.alertService.error(res.Result.Message);
          this.isLoading = false;
          this.loader.hide();
        },
      });
    }
  }
  OpenPop() {
    this.isPopVisible = true;
  }
  handleOk() {
    // if (this.completeSaleOrderProductionRequest.SaleOrderProduction.PreSkinGsm <= 0) {
    //   this.alertService.error("PreSkinGsm cannot be empty");
    //   return
    // }
    // if (this.completeSaleOrderProductionRequest.SaleOrderProduction.SkinGsm <= 0) {
    //   this.alertService.error("SkinGsm cannot be empty");
    //   return
    // }
    // if (this.completeSaleOrderProductionRequest.SaleOrderProduction.AdhesiveGsm <= 0) {
    //   this.alertService.error("AdhesiveGsm cannot be empty");
    //   return
    // }
    // if (this.completeSaleOrderProductionRequest.SaleOrderProduction.FoamGsm <= 0) {
    //   this.alertService.error("FoamGsm cannot be empty");
    //   return
    // }
    this.CompleteProduction();
  }
  handleCancel() {
    this.isPopVisible = false;
    this.loader.hide();
  }
  handleRemoveRow(data: WorkPlanJumboMasterModel) {
    var request = {
      WorkPlanJumboMasterId: data.WorkPlanJumboMasterId,
      ManufacturingQuantity: this.TotalJumboQty - data.JumboRolQty,
    }
    this.loader.show();
    let url = this.ApiUrl + 'saleorder/removesaleorderjumbo';
    this.http.post<SaleOrderCompleteProductionModel>(url, request).subscribe({
      next: (res) => {
        this.getJumboRollsById();
        this.alertService.success('Jumbo Deleted Successfully');
        this.calculateTotal();
        this.loader.hide();
      },
      error: (res) => {
        this.alertService.error(res.Result.Message);
        this.isLoading = false;
        this.loader.hide();
      },
    });
  }
  cancelConfirm(): void {
  }
  print(data: WorkPlanJumboMasterModel) {
    window.open(`${window.location.origin}/jumboprint/` + data.SaleOrderId + '/' + data.WorkPlanJumboMasterId);
  }
  GetBGColor(data: WorkPlanOrderModel): string {
    if (data.SaleOrder.SaleOrderId == this.SelectedWorkPlanOrdersId) {
      return '';
    }
    else {
      return '';
    }

  }
  hideJumboAddbtn(data: WorkPlanOrderModel) {
    if ((this.SelectedWorkPlanOrdersId == 0 || this.SelectedWorkPlanOrdersId == null) && this.permission.Add) {
      this.jumboAddbtnText = "Add Jumbo";
      return false;
    }
    else if (data.SaleOrder.SaleOrderId == this.SelectedWorkPlanOrdersId && this.permission.Add) {
      this.jumboAddbtnText = "Started";
      return false;
    }
    else {
      return true;
    }
  }
  enableRemoveBtn() {
    if (this.permission.Delete) {
      this.removeBtnText = "Remove";
      return false;
    }
    else {
      this.removeBtnText = "Disabled"
      return true;
    }
  }
  enableCompleteProductionBtn() {
    if (this.permission.Manage)
      return false;
    else
      return true;
  }
  GetPasteReqQtyToRecalculate() {
    if (this.SelectedOrderPreSkinPasteReq > 0 && this.SelectedOrderPreSkinActualPasteReq <= 0) {
      this.alertService.error("Pre Skin Actual Paste Quantity cannot be 0")
      return;
    }
    else if (this.SelectedOrderSkinPasteReq > 0 && this.SelectedOrderSkinActualPasteReq <= 0) {
      this.alertService.error("Skin Actual Paste Quantity cannot be 0")
      return;
    }
    else if (this.SelectedOrderAdhesivePasteReq > 0 && this.SelectedOrderAdhesiveActualPasteReq <= 0) {
      this.alertService.error("Adhesive Actual Paste Quantity cannot be 0")
      return;
    }
    else if (this.SelectedOrderFoamPasteReq > 0 && this.SelectedOrderFoamActualPasteReq <= 0) {
      this.alertService.error("Foam Actual Paste Quantity cannot be 0")
      return;
    }
    this.SetTotalProductionQty();
    if (this.SelectedOrder?.SaleOrder.SaleOrderProduction.ExtraProduction == null || this.SelectedOrder?.SaleOrder.SaleOrderProduction.ExtraProduction == undefined) {
      this.SelectedOrder.SaleOrder.SaleOrderProduction.ExtraProduction = 0;
      this.FormulationPasteReqRecalculateRequest.TotalProductionQty = this.SelectedOrder?.SaleOrder.SaleOrderProduction.OrderQuantity;
    }
    if (this.SelectedOrder?.SaleOrder.SaleOrderProduction.LMConstant == 0 || this.SelectedOrder?.SaleOrder.SaleOrderProduction.LMConstant == null) {
      this.alertService.error("LM Constant cannot be 0");
    }
    this.FormulationPasteReqRecalculateRequest
    this.FormulationPasteReqRecalculateRequest.PreSkinRemainingPasteScQty = this.completeSaleOrderProductionRequest.PreSkinRemainingPasteQty;
    this.FormulationPasteReqRecalculateRequest.SkinRemainingPasteScQty = this.completeSaleOrderProductionRequest.SkinRemainingPasteQty;
    this.FormulationPasteReqRecalculateRequest.FoamRemainingPasteScQty = this.completeSaleOrderProductionRequest.FoamRemainingPasteQty;
    this.FormulationPasteReqRecalculateRequest.AdhesiveRemainingPasteScQty = this.completeSaleOrderProductionRequest.AdhesiveRemainingPasteQty;
    this.FormulationPasteReqRecalculateRequest.SaleOrderId = this.SelectedOrder.SaleOrder.SaleOrderId;
    this.FormulationPasteReqRecalculateRequest.SaleOrderProduction = this.SelectedOrder.SaleOrder.SaleOrderProduction;
    this.loader.show();
    let url = this.ApiUrl + "saleorder/getgsmfrompastequantity";
    this.http.post<FormulationProductCalculatePasteReqResponseModel>(url, this.FormulationPasteReqRecalculateRequest).subscribe({
      next: (res) => {
        this.SelectedOrder?.SaleOrder.SaleOrderProduction.InspectionFormulationMixing.forEach(element => {
          element.MixingRawMaterial.forEach(raw => {
            if (element.MixingName.trim().toLowerCase() == 'pre skin') {
              element.PreSkinGsmPasteReq = res.PreSkinGsmPasteReq;
              this.completeSaleOrderProductionRequest.PreSkinGsm = parseFloat(res.PreSkinGsm?.toFixed(2));
              this.completeSaleOrderProductionRequest.PreSkinScGsm = parseFloat(res.PreSkinScGsm?.toFixed(2));
            } else if (element.MixingName.trim().toLowerCase() == 'skin') {
              element.SkinGsmPasteReq = res.SkinGsmPasteReq;
              this.completeSaleOrderProductionRequest.SkinGsm = parseFloat(res.SkinGsm?.toFixed(2));
              this.completeSaleOrderProductionRequest.SkinScGsm = parseFloat(res.SkinScGsm?.toFixed(2));
            }
            else if (element.MixingName.trim().toLowerCase() == 'foam') {
              element.FoamGsmPasteReq = res.FoamGsmPasteReq;
              this.completeSaleOrderProductionRequest.FoamGsm = parseFloat(res.FoamGsm?.toFixed(2));
              this.completeSaleOrderProductionRequest.FoamScGsm = parseFloat(res.FoamScGsm?.toFixed(2));
            }
            else if (element.MixingName.trim().toLowerCase() == 'adhesive') {
              element.AdhesiveGsmPasteReq = res.AdhesiveGsmPasteReq
              this.completeSaleOrderProductionRequest.AdhesiveGsm = parseFloat(res.AdhesiveGsm?.toFixed(2));
              this.completeSaleOrderProductionRequest.AdhesiveScGsm = parseFloat(res.AdhesiveScGsm?.toFixed(2));
            }
          })
        });
        this.DisableCompletePrdBtn = false;
        this.loader.hide();
      },
      error: (err) => {
        this.alertService.warning(err.error)
        this.loader.hide()
      }
    });
  }
  SetTotalProductionQty() {
    if (this.SelectedOrder.SaleOrder.SaleOrderProduction.ExtraProduction > 0) {
      this.FormulationPasteReqRecalculateRequest.TotalProductionQty = parseFloat((this.SelectedOrder.SaleOrder.SaleOrderProduction.OrderQuantity + (this.SelectedOrder.SaleOrder.SaleOrderProduction.OrderQuantity * this.SelectedOrder.SaleOrder.SaleOrderProduction.ExtraProduction) / 100).toFixed(2));
    }
    else {
      this.FormulationPasteReqRecalculateRequest.TotalProductionQty = this.SelectedOrder.SaleOrder.SaleOrderProduction.OrderQuantity;
    }
  }
  CalculateFinalGSM() {
    return (this.completeSaleOrderProductionRequest.PreSkinScGsm + this.completeSaleOrderProductionRequest.SkinScGsm + this.completeSaleOrderProductionRequest.FoamScGsm + this.completeSaleOrderProductionRequest.AdhesiveScGsm + this.SelectedOrder?.SaleOrder.SaleOrderProduction.InspectionFormulationMixing[0]?.FabricGSM).toFixed(2)
  }
  CalculateRemainingPasteQty(fieldName: string) {
    if (fieldName == 'actual') {
      if (this.SelectedOrderPreSkinPasteReq > 0) {
        this.completeSaleOrderProductionRequest.PreSkinRemainingPasteQty = parseFloat((this.SelectedOrderPreSkinPasteReq - this.SelectedOrderPreSkinActualPasteReq).toFixed(2));
      }
      if (this.SelectedOrderSkinPasteReq > 0) {
        this.completeSaleOrderProductionRequest.SkinRemainingPasteQty = parseFloat((this.SelectedOrderSkinPasteReq - this.SelectedOrderSkinActualPasteReq).toFixed(2));
      }
      if (this.SelectedOrderFoamPasteReq > 0) {
        this.completeSaleOrderProductionRequest.FoamRemainingPasteQty = parseFloat((this.SelectedOrderFoamPasteReq - this.SelectedOrderFoamActualPasteReq).toFixed(2));
      }
      if (this.SelectedOrderAdhesivePasteReq > 0) {
        this.completeSaleOrderProductionRequest.AdhesiveRemainingPasteQty = parseFloat((this.SelectedOrderAdhesivePasteReq - this.SelectedOrderAdhesiveActualPasteReq).toFixed(2));
      }
    }
    else if (fieldName == 'remaining') {
      if (this.SelectedOrderPreSkinPasteReq > 0) {
        this.SelectedOrderPreSkinActualPasteReq = parseFloat((this.SelectedOrderPreSkinPasteReq - this.completeSaleOrderProductionRequest.PreSkinRemainingPasteQty).toFixed(2));
        this.completeSaleOrderProductionRequest.PreSkinRemainingPasteQty = parseFloat((this.SelectedOrderPreSkinPasteReq - this.SelectedOrderPreSkinActualPasteReq).toFixed(2));
      }
      if (this.SelectedOrderSkinPasteReq > 0) {
        this.SelectedOrderSkinActualPasteReq = parseFloat((this.SelectedOrderSkinPasteReq - this.completeSaleOrderProductionRequest.SkinRemainingPasteQty).toFixed(2));
        this.completeSaleOrderProductionRequest.SkinRemainingPasteQty = parseFloat((this.SelectedOrderSkinPasteReq - this.SelectedOrderSkinActualPasteReq).toFixed(2));
      }
      if (this.SelectedOrderFoamPasteReq > 0) {
        this.SelectedOrderFoamActualPasteReq = parseFloat((this.SelectedOrderFoamPasteReq - this.completeSaleOrderProductionRequest.FoamRemainingPasteQty).toFixed(2));
        this.completeSaleOrderProductionRequest.FoamRemainingPasteQty = parseFloat((this.SelectedOrderFoamPasteReq - this.SelectedOrderFoamActualPasteReq).toFixed(2));
      }
      if (this.SelectedOrderAdhesivePasteReq > 0) {
        this.SelectedOrderAdhesiveActualPasteReq = parseFloat((this.SelectedOrderAdhesivePasteReq - this.completeSaleOrderProductionRequest.AdhesiveRemainingPasteQty).toFixed(2));
        this.completeSaleOrderProductionRequest.AdhesiveRemainingPasteQty = parseFloat((this.SelectedOrderAdhesivePasteReq - this.SelectedOrderAdhesiveActualPasteReq).toFixed(2));
      }
    }
  }
  GetAllFactoryWorkers() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "factoryworkers/getallfactoryworkerswithfilters";
    this.http.post<FactoryWorkersModel[]>(url, {}).subscribe(res => {
      this.FactoryWorkersList = res;

      this.isTableLoading = false;

    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllFactoryWorkers();
      }
    });
  }
  OpenSaleOrderViewPop(SaleOrderId: number) {
    this.soDrawer.SaleOrderId = SaleOrderId.toString();
    this.soDrawer.show();
  }
  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.jumboPlanModel.JumboRollEndTime) {
      return false;
    }
    return startValue.getTime() > this.jumboPlanModel.JumboRollEndTime.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.jumboPlanModel.JumboRollStartTime) {
      return false;
    }
    const startDate = new Date(
      this.jumboPlanModel.JumboRollStartTime.getFullYear(),
      this.jumboPlanModel.JumboRollStartTime.getMonth(),
      this.jumboPlanModel.JumboRollStartTime.getDate()
    );
    const endDate = new Date(endValue.getFullYear(), endValue.getMonth(), endValue.getDate());

    // Allow same date, only disable past dates
    return endDate < startDate;
  };
  handleStartTimeSelection = (time: CompatibleDate): void => {
    if (time && this.jumboPlanModel.JumboRollEndTime) {
      // Convert to Date object if it isn't already
      const startDateTime = time instanceof Date ? time : new Date(time as any);
      const endDateTime = this.jumboPlanModel.JumboRollEndTime;

      // If same date, validate time
      const sameDate = startDateTime.getFullYear() === endDateTime.getFullYear() &&
        startDateTime.getMonth() === endDateTime.getMonth() &&
        startDateTime.getDate() === endDateTime.getDate();

      if (sameDate && startDateTime.getTime() >= endDateTime.getTime()) {
        // Reset time or show error message
        this.alertService.error('Start time must be before end time');
        this.jumboPlanModel.JumboRollStartTime = null;
      }
    }
  };

  handleEndTimeSelection = (time: CompatibleDate): void => {
    if (time && this.jumboPlanModel.JumboRollStartTime) {
      // Convert to Date object if it isn't already
      const endDateTime = time instanceof Date ? time : new Date(time as any);
      const startDateTime = this.jumboPlanModel.JumboRollStartTime;

      // If same date, validate time
      const sameDate = startDateTime.getFullYear() === endDateTime.getFullYear() &&
        startDateTime.getMonth() === endDateTime.getMonth() &&
        startDateTime.getDate() === endDateTime.getDate();

      if (sameDate && endDateTime.getTime() <= startDateTime.getTime()) {
        // Reset time or show error message
        this.alertService.error('End time must be after start time');
        this.jumboPlanModel.JumboRollEndTime = null;
      }
    }
  };
  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endDatePicker.open();
    }
    // console.log(new Date(new Date(this.jumboPlanModel.JumboRollStartTime.setHours(0, 0, 0))).toUTCString())
    // console.log(new Date(new Date(new Date().setHours(0, 0, 0))).toUTCString())
    // console.log('handleStartOpenChange', open);
  }

  handleEndOpenChange(open: boolean): void {
    console.log('handleEndOpenChange', open);
  }
  OpenPrdDowntimeModal() {
    this.prdDowntimeService.openModal("Manufacturing", null);
  }
}


import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PoEventService {
    private refreshPoList = new Subject<void>();
    refreshPoList$ = this.refreshPoList.asObservable();

    triggerRefresh() {
        this.refreshPoList.next();
    }
}
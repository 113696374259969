<div *ngIf="IsPopupOpen | async">
    <nz-drawer [nzClosable]="true" [nzVisible]="isPurchaseOrderLoaded" nzPlacement="right" [nzWidth]="800"
        nzTitle="Purchase Order details" (nzOnClose)="handleTimelineCancel()">
        <ng-container *nzDrawerContent>
            <div class="drawer-header">
                <div class="approval-section"
                    *ngIf="(this.PurchaseOrder?.Status == 'New' || this.PurchaseOrder?.Status == 'Revised') && fromPage == 'PoList' && poApprovalCheck">
                    <button style="margin:15px; display: flex; align-items: center; justify-content: center;"
                        class="btn btn-lg btn-light-success" (click)="handlePOApproval()">
                        Approve
                    </button>
                </div>

                <div class="document-actions">
                    <!-- Only show actions if PO is approved -->
                    <ng-container *ngIf="PurchaseOrder?.Status !== 'New' && PurchaseOrder?.Status !== 'Revised'">
                        <button nz-button nzType="default" nzTooltip="Download PDF" [nzLoading]="isDownloading"
                            (click)="GetPurchaseOrderPDF(PurchaseOrder.Poid)">
                            <i nz-icon nzType="file-pdf"></i>
                            PDF
                        </button>
                        <button nz-button nzType="default" nzTooltip="Send Email" [nzLoading]="isEmailing"
                            (click)="email(PurchaseOrder)" *ngIf="permission.Email">
                            <i nz-icon nzType="mail"></i>
                            Email
                        </button>
                        <!-- <button nz-button nzType="default" nzTooltip="Share on WhatsApp" [nzLoading]="isSharing"
                            (click)="shareWhatsApp()">
                            <i nz-icon nzType="whats-app"></i>
                            WhatsApp
                        </button> -->
                    </ng-container>

                    <button nz-button nzType="primary" (click)="OpenTimelinePop(this.PurchaseOrder)">
                        <i nz-icon nzType="history"></i>
                        Timeline
                    </button>
                </div>
                <div *ngIf="!poApprovalCheck">
                    <p style="color: red;font-weight: bold;">Either you don't have permission to approve or
                        this PO requires high value approval.</p>
                </div>
            </div>
            <nz-descriptions nzBordered nzLayout="vertical" *ngIf="PurchaseOrder">
                <nz-descriptions-item nzTitle="Order No" nzColon="true"
                    *ngIf="PurchaseOrder.Status == 'Active' || PurchaseOrder.Status == 'Complete'"><b>{{PurchaseOrder.Ponumber}}</b>
                </nz-descriptions-item>
                <nz-descriptions-item nzTitle="Order Date"><b>{{PurchaseOrder.PocreationDate | date:
                        'dd-MMM-yyyy'}}</b></nz-descriptions-item>
                <nz-descriptions-item
                    nzTitle="Department Name"><b>{{PurchaseOrder.DepartmentName}}</b></nz-descriptions-item>
                <!-- <nz-descriptions-item nzTitle="PO Type"><b>{{PurchaseOrder.POType}}</b></nz-descriptions-item> -->
                <nz-descriptions-item nzTitle="Supplier Name"
                    [nzSpan]="2"><b>{{PurchaseOrder.SupplierName}}</b></nz-descriptions-item>
                <nz-descriptions-item nzTitle="GRN"><b>{{PurchaseOrder.Grn}}</b></nz-descriptions-item>
                <nz-descriptions-item nzTitle="Reference#"><b>{{PurchaseOrder.Reference}}</b></nz-descriptions-item>
                <nz-descriptions-item nzTitle="Delivery Terms">
                    <b>{{PurchaseOrder.DeliveryTerm}}</b></nz-descriptions-item>
                <nz-descriptions-item
                    nzTitle="Shipment Preference"><b>{{PurchaseOrder.TransportCompanyName}}</b></nz-descriptions-item>
                <nz-descriptions-item
                    nzTitle="Payment Terms"><b>{{PurchaseOrder.PaymentTerm}}</b></nz-descriptions-item>
                <nz-descriptions-item
                    nzTitle="Currency"><b>{{PurchaseOrder.PurchaseOrderProduct[0].Currency}}</b></nz-descriptions-item>
                <nz-descriptions-item
                    nzTitle="Prepared By"><b>{{PurchaseOrder.AddedBy?.Name}}</b></nz-descriptions-item>
                <nz-descriptions-item nzTitle="Remark"
                    [nzSpan]="3"><b>{{PurchaseOrder.Remarks}}</b></nz-descriptions-item>
            </nz-descriptions>
            <nz-divider></nz-divider>
            <div style="display: flex; align-items: center;">
                <div style="margin-right: 10px;" *ngIf="PurchaseOrder.Status != 'New'">
                    <label style="margin-right: 10px;">Show Quantity Details:</label>
                    <nz-switch [(ngModel)]="ShowMoreColumns" (ngModelChange)="ShowMoreColumns = $event"></nz-switch>
                </div>
                <div style="margin-right: 10px;margin-left: 30px;"
                    *ngIf="PurchaseOrder.Status != 'Complete' && PurchaseOrder.Status != 'Cancelled'">
                    <label style="margin-right: 10px;">Show Current Stock:</label>
                    <nz-switch [(ngModel)]="ShowCurrentStock"
                        (ngModelChange)="GetCurrentStock(ShowCurrentStock)"></nz-switch>
                </div>
            </div>
            <nz-divider></nz-divider>
            <nz-table nzSize="small" *ngIf="PurchaseOrder" [nzData]="['']" #basicTable1 nzBordered style="width:100%">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Product</th>
                        <th>Unit</th>
                        <th>Grade</th>
                        <th>Rate</th>
                        <th>Order QTY</th>
                        <th *ngIf="PurchaseOrder.Status != 'New' && ShowMoreColumns">Received QTY</th>
                        <th *ngIf="PurchaseOrder.Status != 'New' && ShowMoreColumns">Pending QTY</th>
                        <th *ngIf="PurchaseOrder.Status != 'New' && ShowMoreColumns">Extra QTY</th>
                        <th *ngIf="ShowCurrentStock">Current Stock</th>
                        <th *ngIf="ShowCurrentStock">Minimum Level</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of PurchaseOrder.PurchaseOrderProduct;let i=index" nzShowPagination="false">
                        <td>{{i+1}}</td>
                        <td>{{ data.ProductName }}</td>
                        <td>{{ data.Unit }}</td>
                        <td>{{ data.Grade }}</td>
                        <td>{{ data.Rate }}</td>
                        <td>{{ data.Quantity }}</td>
                        <td *ngIf="PurchaseOrder.Status != 'New' && ShowMoreColumns">{{ data.RecievedQuantity }}</td>
                        <td *ngIf="PurchaseOrder.Status != 'New' && ShowMoreColumns">{{ CalculatePendingQTY(data) }}
                        </td>
                        <td *ngIf="PurchaseOrder.Status != 'New' && ShowMoreColumns">{{ CalculateExtraQTY(data) }}</td>
                        <td *ngIf="ShowCurrentStock">{{ getCurrentStock(data.ProductId)?.Quantity ?? 0 }}</td>
                        <td *ngIf="ShowCurrentStock">{{ getCurrentStock(data.ProductId)?.MinimumQuantity ?? 0 }}</td>
                        <td>{{ data.Amount }}</td>
                    </tr>
                </tbody>
                <tfoot *ngIf="this.PurchaseOrder.PurchaseOrderProduct.length>0">
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>Total</th>
                        <th>{{ CalculateGrandTotal(this.PurchaseOrder.PurchaseOrderProduct ,'OrderQuantity') }}</th>
                        <th *ngIf="PurchaseOrder.Status != 'New' && ShowMoreColumns">{{
                            CalculateGrandTotal(this.PurchaseOrder.PurchaseOrderProduct ,'ReceivedQuantity') }}</th>
                        <th *ngIf="PurchaseOrder.Status != 'New' && ShowMoreColumns">{{
                            CalculateGrandTotal(this.PurchaseOrder.PurchaseOrderProduct ,'PendingQuantity') }}</th>
                        <th *ngIf="PurchaseOrder.Status != 'New' && ShowMoreColumns">{{
                            CalculateGrandTotal(this.PurchaseOrder.PurchaseOrderProduct ,'ExtraQuantity') }}</th>
                        <th>{{PurchaseOrder.PototalAmount}}</th>
                    </tr>
                </tfoot>
            </nz-table>
        </ng-container>
    </nz-drawer>
</div>
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { tap, delay } from 'rxjs/operators';
import { UserInfo } from '../Authentication/UserInfo';
import { MsalService } from '@azure/msal-angular';

import { Client, PageCollection } from "@microsoft/microsoft-graph-client";
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { ActiveX, environment } from '../../environments/environment';
import { SessionExceptionService } from './session-exception.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private msalService: MsalService,
    private sessionExceptionService: SessionExceptionService
  ) { }
  isUserLoggedIn: boolean = false;
  ForceLogoutSession = false;
  public graphClient?: Client;
  login(userName: string, password: string): Observable<any> {
    this.isUserLoggedIn = userName == 'admin' && password == 'admin';
    localStorage.setItem('isUserLoggedIn', this.isUserLoggedIn ? "true" : "false");

    return of(this.isUserLoggedIn).pipe(
      delay(1000),
      tap(val => {
      })
    );
  }

  getAppSessionTime(key: string): Promise<number | null> {
    return new Promise((resolve) => {
      const itemStr = localStorage.getItem(key);
      const currentUser = localStorage.getItem('userName')?.toLowerCase();

      if (!itemStr || !currentUser) {
        resolve(null);
        return;
      }

      const item = parseInt(itemStr);
      const now = new Date();

      this.sessionExceptionService.getExceptionList().subscribe({
        next: (exceptionList) => {
          // If user is in exception list, don't force logout
          if (exceptionList.includes(currentUser)) {
            this.ForceLogoutSession = false;
            resolve(item);
            return;
          }

          // Check session expiry for non-excepted users
          if (now.getTime() > item) {
            this.clearLocalDataStorage();
            this.ForceLogoutSession = true;
            resolve(null);
            return;
          }

          resolve(item);
        },
        error: (error) => {
          // If API call fails with auth error, force logout
          if (error.status === 401) {
            this.clearLocalDataStorage();
            this.ForceLogoutSession = true;
            resolve(null);
            return;
          }

          // For other errors, check normal session expiry
          if (now.getTime() > item) {
            this.clearLocalDataStorage();
            this.ForceLogoutSession = true;
            resolve(null);
            return;
          }

          resolve(item);
        }
      });
    });
  }

  clearLocalDataStorage() {
    localStorage.removeItem('token');
    localStorage.removeItem('homeAccountId');
    localStorage.removeItem('expiresOn');
    localStorage.removeItem('userName');
    localStorage.removeItem('appSessionExpiry');
    return true;
  }


  // setSessionData() {
  //   // localStorage.setItem('userName', this.msalService.instance.getActiveAccount().username?.toLowerCase());
  //   // localStorage.setItem('homeAccountId', this.msalService.instance.getActiveAccount()?.homeAccountId);
  //   const accessTokenRequest = {
  //     scopes: ["openid"],
  //     loginHint: localStorage.getItem('userName') || "",
  //     //forceRefresh: true
  //   }
  //   this.msalService.acquireTokenSilent(accessTokenRequest).toPromise().then(function (accessTokenResponse) {
  //     // call API
  //     let expiryTime = accessTokenResponse.expiresOn.toString();
  //     localStorage.setItem('expiresOn', expiryTime);
  //     localStorage.setItem('token', accessTokenResponse.accessToken);
  //   }).catch((error) => {
  //     console.log("Error in login code  acquireTokenSilent " + error);
  //   });
  //   this.ForceLogoutSession = false;
  // }

  async setSessionData() {
    try {
      const accessTokenRequest = {
        scopes: ["openid"],
        loginHint: localStorage.getItem('userName') || "",
        //forceRefresh: true
      };
      const accessTokenResponse = await this.msalService.acquireTokenSilent(accessTokenRequest).toPromise();
      let expiryTime = accessTokenResponse?.expiresOn?.toString();
      localStorage.setItem('expiresOn', expiryTime);
      localStorage.setItem('token', accessTokenResponse.accessToken);
      localStorage.setItem('userName', this.msalService.instance.getActiveAccount()?.username?.toLowerCase());
      localStorage.setItem('homeAccountId', this.msalService.instance.getActiveAccount()?.homeAccountId);
    } catch (error) {
      console.log("Error in login code acquireTokenSilent " + error);
    }
    this.ForceLogoutSession = false;
  }

  logout(): void {
    this.isUserLoggedIn = false;
    localStorage.removeItem('isUserLoggedIn');
  }



  CheckModule(Module: string) {
    if (Module == 'Master' || Module == 'Production' || Module == 'Inventory' || Module == 'Product' || Module == 'Post Process' || Module == 'Gate' || Module == 'Admin' || Module == 'Sales') {

      if (UserInfo.MyRoles?.filter(x => x.Module.includes(Module) && x.IsChecked).length > 0) {
        return true;
      }
      else {
        return false;
      }


    }
    else {
      return UserInfo.MyRoles?.filter(x => x.Module == Module)[0]?.IsChecked
    }

  }
  CheckResponsibility(Module: string, Responsibility: string) {
    return UserInfo.MyRoles?.filter(x => x.Module == Module)[0]?.Responsibilities?.filter(y => y.ResponsibilityName == Responsibility)[0]?.IsChecked
  }

  async GetSignInList(): Promise<PageCollection> {
    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
      this.msalService.instance as PublicClientApplication,
      {
        account: this.msalService.instance.getActiveAccount()!,
        scopes: ['AuditLog.Read.All'],
        interactionType: InteractionType.Popup,
      }
    );
    this.graphClient = Client.initWithMiddleware({
      authProvider: authProvider,
    });
    const LoginHistory = await this.graphClient.api("/auditLogs/signIns")
      .filter('startsWith(appDisplayName,\'' + ActiveX.AppName + '\')')
      .get();
    return LoginHistory;
    //console.log((LoginHistory))
  }

}


import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { environment } from '../../../environments/environment';
import {
  POActionModel,
  PurchaseOrderModel,
  PurchaseOrderPrintModel,
  PurchaseOrderProductModel,
} from '../../Models/PurchaseOrderModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import * as moment from 'moment';
import { AuthService } from '../../Services/auth.service';
import { Modules, PMSPurchaseOrderStatus, Responsibility } from '../../Models/Enums';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { LoadingService } from '../../Services/loadingService';
import { DeliveryTermModel, PaymentTermModel, ProductCategoryModel, ProductFirstSubCategoryModel, ProductSecSubCategoryModel } from '../../Models/MasterModel';
import { ProductModel } from '../../Models/ProductModel';
import { UserModel } from '../../Models/UserModel';
import { MeasureUnitModel } from '../../Models/MeasureUnitModel';
import { SupplierModel } from '../../Models/SupplierModel';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PoTimelineService } from '../../Services/PoTimeLineService';
import { PoDrawerService } from '../../Features/PoDrawerView/services/PoDrawerService';
import { PoEventService } from 'src/PmsUIApp/Features/PoDrawerView/services/PoEventService';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-polist',
  templateUrl: './polist.component.html',
  styleUrls: ['./polist.component.css'],
})
export class POListComponent implements OnInit, OnDestroy {
  ApiUrl = environment.Api_Url;
  PurchaseOrderList: PurchaseOrderModel[] = [];
  PurchaseOrderListOriginal: PurchaseOrderModel[] = [];
  PurchaseOrder!: PurchaseOrderModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = 'Add New PurchaseOrder';
  searchValue = '';
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  visible = false;
  error: any = { isError: false, errorMessage: '' };
  permission = {
    View: false,
    Add: false,
    Edit: false,
    Manage: false,
    Cancel: false,
    Approval: false,
    HighValueApproval: false,
    Email: false,
    InTransit: false,
    PartialPayment: false,
    FullPayment: false,
    Complete: false,
    ReOpen: false,
  }
  exportoptions = {
    headers: [
      'Supplier Name',
      'PO Number',
      'Delivery Term',
      'Payment Term',
      'Product Name',
      "Currency",
      'PO Total Amount',
      'PO Date',

      'GRN',
      'Reference',
      'Added Date',
      'Added By',
      "Status"
    ],
  };
  fields: PurchaseOrderPrintModel = new PurchaseOrderPrintModel();
  exportfields: PurchaseOrderPrintModel[] = [];
  isValidDate: any;
  count: number;
  poApprovalCheckCount = 1;
  poApprovalCheck = false;
  request = {
    FromAddedDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7)),
    ToAddedDate: new Date(new Date().setHours(23, 59, 59)),
    DateFrom: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7)),
    DateTo: new Date(new Date().setHours(23, 59, 59)),
    FromApprovedDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7)),
    ToApprovedDate: new Date(new Date().setHours(23, 59, 59)),
    ProductId: 0,
    Unit: "",
    ProductType: "Raw",
    SupplierId: 0,
    ProductCategoryId: 0,
    ProductFirstSubCategoryId: 0,
    ProductSecSubCategoryId: 0,
    DateType: 'addeddate',
    Status: "",
    ActionBy: "",
    POType: "",
    PONumber: "",
    PaymentTermId: 0,
    DeliveryTermId: 0
  }
  DeliveryTermList: DeliveryTermModel[] = [];
  PaymentTermList: PaymentTermModel[] = [];
  dateTypeList = [
    {
      "Text": "Added Date",
      "Value": 'addeddate'
    },
    {
      "Text": "PO Date",
      "Value": 'podate'
    },
    {
      "Text": "Approved Date",
      "Value": 'approveddate'
    }];
  UserList: UserModel[];
  SelectedProductType: string = '';
  SelectedStatus: string = '';
  FilteredProductList: ProductModel[] = [];
  ProductList: ProductModel[] = [];
  CategoryID: number = 0;
  FirstCategoryID: number = 0;
  SecondCategoryID: number = 0;
  selectedProduct: number = 0;
  ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  ProductCategoryList: ProductCategoryModel[] = [];
  FilteredProductCategoryList: ProductCategoryModel[] = [];
  UnitList: MeasureUnitModel[] = [];
  SupplierList: SupplierModel[] = [];
  IsActivitiesActionPopVisible: boolean = false;
  POActivitiesActionForm!: UntypedFormGroup;
  ActionPO!: PurchaseOrderModel;
  ActionPORequest: POActionModel = new POActionModel();
  ActionName: PMSPurchaseOrderStatus;
  totalItemsCount: number = 0;
  IsTimelineOpen = false;
  subscription: Subscription;
  constructor(
    public http: HttpClient,
    private alertService: AlertMessageService,
    private modalService: NzModalService,
    private auth: AuthService,
    private router: Router,
    private loader: LoadingService,
    private fb: UntypedFormBuilder,
    private timeLine: PoTimelineService,
    private poDrawer: PoDrawerService,
    private poEventService: PoEventService
  ) { }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.PurchaseOrder, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.PurchaseOrder, Responsibility.Add);
    this.permission.Edit = this.auth.CheckResponsibility(Modules.PurchaseOrder, Responsibility.Edit);
    this.permission.Manage = this.auth.CheckResponsibility(Modules.PurchaseOrder, Responsibility.Manage);
    this.permission.Cancel = this.auth.CheckResponsibility(Modules.PurchaseOrder, Responsibility.Cancel);
    this.permission.Approval = this.auth.CheckResponsibility(Modules.PurchaseOrder, Responsibility.Approval);
    this.permission.HighValueApproval = this.auth.CheckResponsibility(Modules.PurchaseOrder, Responsibility.HighValueApproval);
    this.permission.Email = this.auth.CheckResponsibility(Modules.PurchaseOrder, Responsibility.Email);
    this.permission.InTransit = this.auth.CheckResponsibility(Modules.PurchaseOrderInTransit, Responsibility.Add);
    this.permission.PartialPayment = this.auth.CheckResponsibility(Modules.PurchaseOrderMarkPartialPayment, Responsibility.Add);
    this.permission.FullPayment = this.auth.CheckResponsibility(Modules.PurchaseOrderMarkFullPayment, Responsibility.Add);
    this.permission.Complete = this.auth.CheckResponsibility(Modules.PurchaseOrder, Responsibility.Edit);
    this.permission.ReOpen = this.auth.CheckResponsibility(Modules.PurchaseOrder, Responsibility.Edit);

    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetAllPurchaseOrderWithFilters();
    this.POActivitiesActionForm = this.fb.group({
      remark: [null, [Validators.required, Validators.maxLength(180)]]
    });
    this.subscription = this.poEventService.refreshPoList$.subscribe(() => {
      this.GetAllPurchaseOrderWithFilters();
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }
  GetAllPurchaseOrder() {
    this.isTableLoading = true;
    let url = this.ApiUrl + 'purchaseorder/getallpurchaseordersforlist';

    this.http.get<PurchaseOrderModel[]>(url).subscribe(
      (res) => {

        this.PurchaseOrderListOriginal = res;
        this.PurchaseOrderList = this.PurchaseOrderListOriginal;
        this.PurchaseOrderList.forEach((x) => {
          x.PototalAmount = parseFloat(x.PototalAmount).toFixed(3);
        })
        console.log(` this.PurchaseOrderList`, this.PurchaseOrderList)

        this.exportfields = [];
        this.PurchaseOrderList.forEach((x) => {
          this.fields = new PurchaseOrderPrintModel();
          this.fields.SupplierName = x.SupplierName;
          this.fields.Ponumber = x.Ponumber;
          this.fields.DeliveryTerm = x.DeliveryTerm;
          this.fields.PaymentTerm = x.PaymentTerm;
          this.fields.ProductName = x.PurchaseOrderProduct[0].ProductName;
          this.fields.PototalAmount = x.PototalAmount;
          this.fields.Currency = x.PurchaseOrderProduct[0].Currency
          this.fields.PocreationDate = x.PocreationDate
          this.fields.Grn = x.Grn;
          this.fields.Reference = x.Reference;
          this.fields.AddedDate = x.AddedDate;
          this.fields.AddedBy = x.AddedBy?.Name;
          this.fields.Status = x.Status
          this.exportfields.push(this.fields);

        });

        this.isTableLoading = false;
        console.log(`this.exportfields`, this.exportfields)
      },
      () => {
        this.count++;
        if (this.count < 2) {
          this.GetAllPurchaseOrder();
        }
      }
    );
  }
  GetAllPurchaseOrderWithFilters() {
    this.isTableLoading = true;
    let url = this.ApiUrl + 'purchaseorder/getallpurchaseorderswithfilters';
    this.request.ProductType = this.SelectedProductType;
    this.request.ProductCategoryId = this.CategoryID;
    this.request.ProductFirstSubCategoryId = this.FirstCategoryID;
    this.request.ProductSecSubCategoryId = this.SecondCategoryID;
    this.http.post<PurchaseOrderModel[]>(url, this.request).subscribe(
      (res) => {

        this.PurchaseOrderListOriginal = res;
        this.PurchaseOrderList = this.PurchaseOrderListOriginal;
        this.PurchaseOrderList.forEach((x) => {
          x.PototalAmount = parseFloat(x.PototalAmount).toFixed(3);
        })
        let count = this.totalItemsCount = 0;
        this.exportfields = [];
        this.PurchaseOrderList.forEach((x) => {
          count++;
          x.SerialNo = count
          this.fields = new PurchaseOrderPrintModel();
          this.fields.SupplierName = x.SupplierName;
          this.fields.Ponumber = x.Ponumber;
          this.fields.DeliveryTerm = x.DeliveryTerm;
          this.fields.PaymentTerm = x.PaymentTerm;
          this.fields.ProductName = x.PurchaseOrderProduct[0].ProductName;
          this.fields.PototalAmount = x.PototalAmount;
          this.fields.Currency = x.PurchaseOrderProduct[0].Currency
          this.fields.PocreationDate = x.PocreationDate
          this.fields.Grn = x.Grn;
          this.fields.Reference = x.Reference;
          this.fields.AddedDate = x.AddedDate;
          this.fields.AddedBy = x.AddedBy?.Name;
          this.fields.Status = x.Status
          this.exportfields.push(this.fields);

        });
        this.totalItemsCount = count;
        this.isTableLoading = false;
        console.log(`this.exportfields`, this.exportfields)
      },
      () => {
        this.count++;
        if (this.count < 2) {
          this.GetAllPurchaseOrderWithFilters();
        }
      }
    );
  }
  OpenViewPop(data: any) {
    // this.poApprovalCheckCount = 1;
    // this.poApprovalCheck = false;
    // this.isVisible = true;
    // this.PurchaseOrder = data;
    // let url = this.ApiUrl + "purchaseorder/getpurchaseorderbyid/" + data.Poid;
    // this.http.get<PurchaseOrderModel>(url).subscribe(res => {
    //   this.PurchaseOrder = res;
    // })
    this.poDrawer.Poid = data.Poid;
    this.poDrawer.fromPage = 'PoList';
    this.poDrawer.show();
  }

  handleOk(): void {

  }

  handleCancel(): void {
    this.isVisible = false;
  }
  reset(): void {
    this.searchValue = '';
    this.search();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }
  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        'purchaseorder-export' + exportdate,
        this.exportoptions
      );
  }

  // CompleteApproval(poid: number, suppliername: string) {
  //   const modal = this.modalService.confirm({
  //     nzTitle: 'Confirm',
  //     nzContent: 'Are you sure you want to approve this PO for Supplier Name - ' + suppliername + '?',
  //     nzOnOk: () => this.CompleteApprovalService(poid),
  //   });
  //   setTimeout(() => modal.destroy(), 5000);
  // }
  // CompleteApprovalService(poid: number) {
  //   let url = this.ApiUrl + 'purchaseorder/ApprovePurchaseOrder/' + poid;
  //   this.http.get<any>(url).subscribe({
  //     next: (res) => {
  //       this.handleCancel();
  //       this.GetAllPurchaseOrder();
  //       this.alertService.success(res);
  //       this.isLoading = false;
  //     },
  //     error: (res) => {
  //       this.alertService.error('An error has been occured. Please try again');
  //     },
  //   });
  // }
  get f() { return this.POActivitiesActionForm.controls; }
  showPOActivitiesActionPopup(data: PurchaseOrderModel, Status: string) {
    this.ActionPO = data;
    this.POActivitiesActionForm.reset();
    this.IsActivitiesActionPopVisible = true;
    if (Status == 'Approval') {
      this.ActionName = PMSPurchaseOrderStatus.Approved;
    }
    else if (Status == 'Cancelled') {
      this.ActionName = PMSPurchaseOrderStatus.Cancelled;
    }
    else if (Status == 'Blocked') {
      this.ActionName = PMSPurchaseOrderStatus.Blocked;
    }
    else if (Status == 'Unblock') {
      this.ActionName = PMSPurchaseOrderStatus.Unblock;
    }
    else if (Status == 'Complete') {
      this.ActionName = PMSPurchaseOrderStatus.Complete;
    }
    else if (Status == 'ReOpen') {
      this.ActionName = PMSPurchaseOrderStatus.ReOpen;
    }
    else if (Status == 'InTransit') {
      this.ActionName = PMSPurchaseOrderStatus.InTransit;
    }
    else if (Status == 'PartialPayment') {
      this.ActionName = PMSPurchaseOrderStatus.PartialPaymentCompleted;
    }
    else if (Status == 'FullPayment') {
      this.ActionName = PMSPurchaseOrderStatus.FullPaymentCompleted;
    }

  }
  handlePOActivitiesActionCancel() {
    this.IsActivitiesActionPopVisible = false;
  }
  savePOAction() {
    var actionremark = ''
    // if (this.ActionPO.Status != 'New' && this.ActionPO.Status != 'Revised') {
    actionremark = this.f["remark"].value;
    // }
    if ((actionremark == "" || actionremark == null) && (this.ActionPO.Status != 'New' && this.ActionPO.Status != 'Revised')) {
      this.alertService.error("Please provide reason.");
      return;
    }
    if ((actionremark == "" || actionremark == null) && this.ActionName == 'Blocked') {
      this.alertService.error("Please provide reason for blocking.");
      return;
    }
    this.isLoading = true;
    this.loader.show();
    this.ActionPORequest.Poid = this.ActionPO.Poid;
    this.ActionPORequest.Remark = actionremark
    this.ActionPORequest.Status = this.ActionName;
    let url = this.ApiUrl + "purchaseorder/purchaseordereventactions";
    this.http.post<any[]>(url, this.ActionPORequest).subscribe({
      next: res => {
        this.isLoading = false;
        this.handleCancel();
        this.handlePOActivitiesActionCancel();
        this.alertService.success(res)
        this.GetAllPurchaseOrderWithFilters();
        this.loader.hide();
      },
      error: res => {
        if (res.error.StatusCode == HttpStatusCode.BadRequest) {
          this.alertService.warning(res.error);
        }
        else {
          this.alertService.error(res.error);
        }
        this.isLoading = false;
        this.loader.hide();
      },
    });
  }

  isApprovalDisabled(data: any) {
    var TotalValue = parseFloat(data?.PototalAmount);
    var POHighValueLimit = parseFloat(data?.POHighValue)

    if (TotalValue <= POHighValueLimit && this.permission.Approval)
      return false
    else if (TotalValue <= POHighValueLimit && this.permission.HighValueApproval)
      return false
    else if (TotalValue >= POHighValueLimit && this.permission.HighValueApproval)
      return false;
    else
      return true;
  }
  isApprovalDisabledOnProductView(data: any) {
    if (this.poApprovalCheckCount == 1) {
      var TotalValue = parseFloat(data?.PototalAmount);
      var POHighValueLimit = parseFloat(data?.POHighValue)
      this.poApprovalCheckCount++;
      if (TotalValue <= POHighValueLimit && this.permission.Approval) {
        this.poApprovalCheck = true;
        return this.poApprovalCheck;
      }
      else if (TotalValue <= POHighValueLimit && this.permission.HighValueApproval) {
        this.poApprovalCheck = true;
        return this.poApprovalCheck;
      }
      else if (TotalValue >= POHighValueLimit && this.permission.HighValueApproval) {
        this.poApprovalCheck = true;
        return this.poApprovalCheck;
      }
      else {
        this.poApprovalCheck = false;
        return this.poApprovalCheck;
      }
    }
    else {
      return this.poApprovalCheck
    }
  }


  //filter data by date

  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }



  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }




    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }



  search(): void {
    this.visible = false;
    var res = this.PurchaseOrderListOriginal;
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');
    console.log(`fromdate`, fromdate)
    let todate = moment(this.toDate).format('DD-MM-YYYY');
    console.log(`todate`, todate)

    this.isValidDate = this.validateDates(fromdate, todate);

    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: PurchaseOrderModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.PocreationDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.PocreationDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });

      this.PurchaseOrderList = selectepolist;
    }
    else if (!this.myDateValue && !this.toDate) {
      this.PurchaseOrderList = res.filter(
        (item: PurchaseOrderModel) =>
          item?.SupplierName?.toLocaleLowerCase('en').includes(this.searchValue?.toLocaleLowerCase('en')) ||
          item?.Ponumber?.toLocaleLowerCase('en').indexOf(this.searchValue?.toLocaleLowerCase('en')) !== -1 ||
          item?.Grn?.toLocaleLowerCase('en').includes(this.searchValue?.toLocaleLowerCase('en')) ||
          item?.DeliveryTerm?.toLocaleLowerCase('en').includes(this.searchValue?.toLocaleLowerCase('en')) ||
          item?.PaymentTerm?.toLocaleLowerCase('en').includes(this.searchValue?.toLocaleLowerCase('en')) ||
          item?.Reference?.toLocaleLowerCase('en').includes(this.searchValue?.toLocaleLowerCase('en')) ||
          item?.Remarks?.toLocaleLowerCase('en').includes(this.searchValue?.toLocaleLowerCase('en')) ||
          item?.Status?.toLocaleLowerCase('en').includes(this.searchValue?.toLocaleLowerCase('en')) ||
          item?.AddedBy?.Name?.toLocaleLowerCase('en').includes(this.searchValue?.toLocaleLowerCase('en')) ||
          item?.AddedBy?.Email?.toLocaleLowerCase('en').includes(this.searchValue?.toLocaleLowerCase('en')) ||
          item?.PurchaseOrderProduct?.filter(x => x.ProductName?.toLocaleLowerCase('en').includes(this.searchValue?.toLocaleLowerCase('en'))).length > 0 ||
          item?.PurchaseOrderProduct?.filter(x => x.Currency?.toLocaleLowerCase('en').includes(this.searchValue?.toLocaleLowerCase('en'))).length > 0
      );
    }
    else {
      this.PurchaseOrderList = res;
    }

    this.exportfields = [];
    this.PurchaseOrderList.forEach((x) => {
      this.fields = new PurchaseOrderPrintModel();
      this.fields.Ponumber = x.Ponumber;
      this.fields.SupplierName = x.SupplierName;
      this.fields.DeliveryTerm = x.DeliveryTerm;
      this.fields.PaymentTerm = x.PaymentTerm;
      this.fields.ProductName = x.PurchaseOrderProduct[0].ProductName;;
      this.fields.PototalAmount = x.PototalAmount;
      this.fields.Grn = x.Grn;
      this.fields.Reference = x.Reference;
      this.fields.AddedDate = x.AddedDate;
      this.fields.AddedBy = x.AddedBy?.Name;
      this.exportfields.push(this.fields);
    });
  }
  print(data: PurchaseOrderModel) {
    window.open(`${window.location.origin}/poprint/` + data.Poid);
  }
  printWithType(data: PurchaseOrderModel, type: number) {
    window.open(`${window.location.origin}/poprint/` + data.Poid + '/' + type);
  }

  email(data: PurchaseOrderModel) {
    window.open(`${window.location.origin}/poemail/` + data.Poid);
  }
  cancel(data: PurchaseOrderModel) {
    this.isTableLoading = true;
    let url = this.ApiUrl + 'purchaseorder/cancelpurchaseorder';

    this.http.get<PurchaseOrderModel[]>(url + '/' + data.Poid).subscribe(
      () => {
        this.alertService.success("PO has been Cancelled Successfully");
        this.GetAllPurchaseOrder();

      },
      () => { }
    );

  }
  GetProductName(data: PurchaseOrderModel) {
    if (data.PurchaseOrderProduct.length == 0) {
      return 'NA'
    }
    else if (data.PurchaseOrderProduct.length == 1) {
      return data.PurchaseOrderProduct[0].ProductName
    }
    else {
      return data.PurchaseOrderProduct[0].ProductName + '<span class="spn"> & <a class="btn-link"> ' + (data.PurchaseOrderProduct.length - 1) + ' more </a></span>'
    }

  }

  GetColor(data: string): string {
    if (data == "Cancelled") {
      return 'RGBA(196,92,92)';
    }
    else {
      return '';
    }
  }

  GetRowColor(data: string): string {
    if (data == "Cancelled") {
      return 'RGBA(255,255,0)';
    }
    else {
      return '';
    }
  }
  EnableEditButton(data: PurchaseOrderModel) {
    if (this.permission.Manage)
      return true
    if (data.IsInvoiceAttached == false && this.permission.Manage)
      return true
    else if ((data.Status == 'New' || data.Status == 'Revised') && this.permission.Edit)
      return true;
    else
      return false;
  }
  EnablePOActivitiesButton(data: PurchaseOrderModel, Action: string) {
    if (this.permission.InTransit == true && Action == 'InTransit' && data.Status == 'Active')
      return true
    else if (this.permission.Cancel == true && Action == 'Blocked' && (data.Status == 'New' || data.Status == 'Revised'))
      return true
    else if (this.permission.Cancel == true && Action == 'Unblock' && data.Status == 'Blocked')
      return true
    else if (this.permission.PartialPayment == true && Action == 'PartialPayment')
      return true
    else if (this.permission.FullPayment == true && Action == 'FullPayment')
      return true
    else if (this.permission.Complete == true && Action == 'Complete' && data.Status == 'Active')
      return true
    else if (this.permission.ReOpen == true && Action == 'ReOpen' && data.Status == 'Complete')
      return true
    else
      return false;
  }
  EnableCancelButton(data: any) {
    if (data.IsInvoiceAttached == false && this.permission.Manage)
      return true
    else if (data.IsInvoiceAttached == false && this.permission.Cancel)
      return true;
    else
      return false;
  }
  EnableEmailButton(data: any) {
    if (data.Status == 'Active' && this.permission.Email)
      return true
    else
      return false;
  }
  GetStatus(Status: string) {
    if (Status == 'Cancelled' || Status == 'Complete') {
      return true
    }
    else
      return false
  }
  CalculatePendingQTY(data: PurchaseOrderProductModel) {
    var RemainingQTY = data.Quantity - data.RecievedQuantity;
    if (RemainingQTY >= 0) {
      return RemainingQTY
    }
    return 0
  }
  CalculateExtraQTY(data: PurchaseOrderProductModel) {
    var RemainingQTY = data.Quantity - data.RecievedQuantity;
    if (RemainingQTY < 0) {
      return '+' + (data.RecievedQuantity - data.Quantity).toFixed(2)
    }
    return 0;
  }
  CalculateGrandTotal(data: PurchaseOrderProductModel[], column: string) {
    var total = 0;
    switch (column) {
      case column = "OrderQuantity": {
        total = data.reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0) ?? 0
        break;
      }

      case column = "ReceivedQuantity": {
        total = data.reduce((sum, current) => sum + parseFloat(current.RecievedQuantity?.toString()), 0) ?? 0
        break;
      }

      case column = "PendingQuantity": {
        total = data.reduce((sum, current) => sum + parseFloat((this.CalculatePendingQTY(current))?.toString()), 0) ?? 0
        break;
      }

      case column = "ExtraQuantity": {
        total = data.reduce((sum, current) => sum + parseFloat(this.CalculateExtraQTY(current)?.toString()), 0) ?? 0
        break;
      }
    }
    return parseFloat(total.toFixed(2));
  }
  GetAllUsers() {
    this.loader.show();
    let url = this.ApiUrl + 'userrolesresponsibility/getalluserdata';
    this.http.get<UserModel[]>(url).subscribe(
      (res) => {
        this.UserList = res;
        this.loader.hide();
      },
      (res) => {
        this.loader.hide();
        this.count++
        if (this.count < 2) {
          this.GetAllUsers();
        }
      }
    );
  }
  onSelectedProductTypeChange() {
    this.FilteredProductList = this.ProductList.filter(
      (x) => x.ProductType == this.SelectedProductType
    );
    this.GetAllProductCategory();
  }
  GetAllProducts() {
    let url = this.ApiUrl + 'product/getallproducts';
    this.http.get<ProductModel[]>(url).subscribe(
      (res) => {
        this.ProductList = res;
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllProducts();
        }
      }
    );
  }
  GetAllProductCategory() {
    let url = this.ApiUrl + 'productcategory/getallproductcategoriesforlisting';
    this.http.get<ProductCategoryModel[]>(url).subscribe(
      (res) => {
        const filteredProductCategoryIds = this.FilteredProductList.map(x => x.ProductCategoryId);
        this.ProductCategoryList = res.filter(x => filteredProductCategoryIds.includes(x.ProductCategoryId));
      },
      (res) => { }
    );
  }

  GetAllFirstCategory(data: any, id: number = 0) {
    this.FilteredProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductCategoryId == this.CategoryID
    );
    let url = this.ApiUrl + 'productcategory/getallproductfirstsubcategories';
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductFirstSubCategoryList = res.filter(
          (x) => x.ProductCategoryId == data
        );

      },
      (res) => { }
    );
  }
  GetAllSecondCategory(data: any, id: number = 0) {
    this.FilteredProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductFirstSubCategoryId == this.FirstCategoryID
    );
    let url = this.ApiUrl + 'productcategory/getallproductsecsubcategories';
    this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductSecSubCategoryList = res.filter(
          (x) => x.ProductFirstSubCategoryId == data
        );
      },
      (res) => { }
    );
  }
  GetSecondCategoryFilteredProduct() {
    this.FilteredProductList = this.ProductList.filter(
      (x) =>
        x.ProductType == this.SelectedProductType &&
        x.ProductSecSubCategoryId == this.SecondCategoryID
    );
  }
  onSelectedProductChange(data: any) {
    var fp = this.FilteredProductList.filter(
      (x) => x.ProductId == data
    )[0];
    this.CategoryID = fp.ProductCategoryId;
    let url = this.ApiUrl + 'productcategory/getallproductfirstsubcategories';
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(
      (res) => {
        this.ProductFirstSubCategoryList = res.filter(
          (x) => x.ProductCategoryId == this.CategoryID
        );
        this.FirstCategoryID = fp.ProductFirstSubCategoryId;
        let url = this.ApiUrl + 'productcategory/getallproductsecsubcategories';
        this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(
          (res) => {
            this.ProductSecSubCategoryList = res.filter(
              (x) => x.ProductFirstSubCategoryId == this.FirstCategoryID
            );
            this.SecondCategoryID = fp.ProductSecSubCategoryId;
          },
          (res) => { }
        );
      },
      (res) => { }
    );
  }
  GetAllUnit() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "data/getmeasureunits";
    this.http.get<any>(url).subscribe(res => {
      this.UnitList = res;


      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllUnit(); }
    });
  }
  GetAllSupplier() {
    let url = this.ApiUrl + "supplier/getallsuppliers";
    this.http.get<SupplierModel[]>(url).subscribe(res => {
      this.SupplierList = res;

    }, res => {
      this.count++;
      if (this.count < 2) { this.GetAllSupplier(); }
    });
  }
  onFilterPanelOpen(data: any) {
    if (data == true) {
      this.loader.show();
      this.GetAllSupplier();
      this.GetAllUnit();
      this.GetAllProducts();
      this.GetAllProductCategory();
      this.GetAllUsers();
      this.GetAllDeliveryTerm();
      this.GetAllPaymentTerm();
    }
  }
  OpenTimelinePop(data: any): void {
    this.IsTimelineOpen = false;
    this.timeLine.Poid = data.Poid;
    this.timeLine.show();

    // this.TimeLineObject = data;
    // this.GetAllActivityLog(data.Poid);
  }
  GetAllDeliveryTerm() {

    let url = this.ApiUrl + "deliveryterm/getalldeliveryterms";
    this.http.get<DeliveryTermModel[]>(url).subscribe(res => {
      this.DeliveryTermList = res;

    }, res => { this.GetAllPaymentTerm() });
  }
  GetAllPaymentTerm() {

    let url = this.ApiUrl + "paymentTerm/getallpaymentterms";
    this.http.get<PaymentTermModel[]>(url).subscribe(res => {
      this.PaymentTermList = res;

    }, res => { this.GetAllPaymentTerm() });
  }
  GetPurchaseOrderPDF(PoId: number) {
    let url = this.ApiUrl + "purchaseorder/getpurchaseorderpdf/" + PoId;
    this.http.get<any>(url).subscribe({
      next: (res) => {
        window.open(res.ResponseBody.url, '_blank');
      },
      error: (res) => { }
    });
  }
}

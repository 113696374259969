<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Add Stock</nz-page-header-title>
      <nz-page-header-subtitle>Add stock details here</nz-page-header-subtitle>
    </nz-page-header>
    <nz-divider></nz-divider>
    <nz-card nzType="inner" nzTitle="Stock Details">
      <div class="row gx-10 mb-5">
        <div class="col-lg-2">
          <label class="form-label">GRN</label>
          <!-- <input type="text" [(ngModel)] = "NewStock.Invoice.InvoiceNumber" class="form-control mb-2" placeholder="Invoice Number" value="" /> -->
          <nz-select nzShowSearch #microwaveRef class="form-select mb-2" (ngModelChange)="onSelectedGrnChange($event)" nzSize="large" [(ngModel)]="NewStock.Invoice.Grn" nzAllowClear nzPlaceHolder="Choose">
            <nz-option *ngFor="let s of this.FilteredPurchaseOrderList;" [nzValue]="s.Grn" [nzLabel]="s.Grn"></nz-option>
            <!--<nz-option *ngFor="let s of this.FilteredInvoiceListWithoutPO;" [nzValue]="s.Grn" [nzLabel]="s.Grn"></nz-option>-->
          </nz-select>
        </div>
        <div class="col-lg-4">
          <div class="row gx-10 mb-5">
            <div class="col-lg-8">
              <label class="required form-label">Purchase Order</label>
              <nz-select nzShowSearch #microwaveRef class="form-select mb-2" nzSize="large" [(ngModel)]="NewStock.Invoice.Poid" (ngModelChange)="onSelectedPOChange($event)" nzAllowClear nzPlaceHolder="Choose">
                <nz-option *ngFor="let p of this.FilteredPurchaseOrderList;" [nzValue]="p.Poid" [nzLabel]="p.Ponumber"></nz-option>
              </nz-select>
            </div>
            <div class="col-lg-4">
              <br>
              <button class="btn btn-sm btn-light-success" (click)="GetProductfromPO()" [disabled]="IsPoGet">Get Products from Purchase order</button>
            </div>
          </div>


        </div>
        <div class="col-lg-3">
          <label class="required form-label">Supplier Name</label>
          <nz-select nzShowSearch #microwaveRef class="form-select mb-2" nzSize="large" (ngModelChange)="onSelectedSupplierChange($event)" [(ngModel)]="NewStock.Invoice.SupplierId" nzAllowClear nzPlaceHolder="Choose">
            <nz-option *ngFor="let s of this.SupplierList;" [nzValue]="s.SupplierId" [nzLabel]="s.SupplierName"></nz-option>
          </nz-select>
        </div>
        <div class="col-lg-2" *ngIf="!IsKnittingStock">
          <label class="required form-label">Invoice No.</label>
          <!-- <input type="text" [(ngModel)] = "NewStock.Invoice.InvoiceNumber" class="form-control mb-2" placeholder="Invoice Number" value="" /> -->
          <nz-select nzShowSearch #microwaveRef class="form-select mb-2" (ngModelChange)="onSelectedInvoiceChange($event)" nzSize="large" [(ngModel)]="NewStock.Invoice.InvoiceId" nzAllowClear nzPlaceHolder="Choose">
            <nz-option *ngFor="let s of this.InvoiceList;" [nzValue]="s.InvoiceId" [nzLabel]="s.InvoiceNumber"></nz-option>
          </nz-select>
        </div>
        <div class="col-lg-1" *ngIf="!IsKnittingStock">
          <button nz-button *ngIf="this.isInvoiceEditEnabled" nzType="primary" [nzSize]="smallsize" nzShape="round"
            style="margin-top: 30px; height: 40px;" (click)="OpenEditInvoice()"><span nz-icon
              nzType="edit"></span></button>

        </div>


        <!--end::Col-->
      </div>
      <div class="row gx-10 mb-5">
        <div class="col-lg-2">
          <label class="required form-label">Stock Date </label>
          <nz-date-picker class="form-select mb-2" nzSize="large" [(ngModel)]="NewStock.StockDate" nzValue="large"></nz-date-picker>
        </div>
        <div class="col-lg-2">
          <label class=" form-label">Eway Bill</label>
          <input type="text" [(ngModel)]="NewStock.Invoice.EwayBill" class="form-control mb-2"
            placeholder="E-Way Bill Number" value="" />
        </div>
        <div class="col-lg-2">
          <label class=" form-label">Eway Bill Date </label>
          <nz-date-picker class="form-select mb-2" nzSize="large" [(ngModel)]="NewStock.Invoice.EwayBillDate" nzValue="large"></nz-date-picker>
        </div>
        <div class="col-lg-2">
          <label class="required form-label">Invoice Date</label>
          <nz-date-picker class="form-select mb-2" nzSize="large" [(ngModel)]="NewStock.Invoice.InvoiceDate" nzValue="large"></nz-date-picker>
        </div>
        <!-- <div class="col-lg-3">
          <label class="required form-label">Total PO Value w/o GST</label>
          <input type="text" class="form-control mb-2" [(ngModel)]="NewStock.Invoice.InvoiceTotalPrice" name="price[]"
            placeholder="0.00" value="0.00" data-kt-element="price" />
        </div> -->
        <div class="col-lg-4">
          <label class="form-label">Upload Invoice <b style="color: red;">(Only PDF/JPEG/PNG files are accepted)</b></label>
          <input class="form-select mb-2" [(ngModel)]="uploadinvoice" type="file" (change)="onChange($event)">
          <div *ngIf="uploadProgress.length > 0" class="mt-3">
            <div *ngFor="let progress of uploadProgress" class="mb-2">
              <span>{{progress.filename}}</span>
              <nz-progress 
                [nzPercent]="progress.progress" 
                [nzStatus]="progress.status"
                [nzShowInfo]="true">
              </nz-progress>
            </div>
          </div>
          <!-- <nz-upload
          nzName="file"
          [(nzFileList)]="files"
          [nzTransformFile]="transformFile"
          [nzData]="getExtraData"
          [nzFileType]="'image/png,image/jpeg'"
          [nzAction]="mockOSSData.host"
          (nzChange)="onChange($event)"
          [nzLimit]="1"
        >

          <button nz-button class="form-control mb-2">
            <i nz-icon nzType="upload"></i>
            Upload Invoice
          </button>
        </nz-upload> -->
          <!-- <input type="text" name="sku" [(ngModel)] = "NewStock.SupplierId" class="form-control mb-2" placeholder="Upload Invoice" value="" /> -->
        </div>
      </div>
    </nz-card>
    <nz-card nzType="inner" style="margin-top:16px;" nzTitle="Products" [nzExtra]="extraTemplate">


      <table class="table align-middle table-row-dashed" id="kt_ecommerce_products_table" *ngIf="this.NewStock.StockProduct.length>0">
        <!--begin::Table head-->
        <thead class="ant-table-thead ng-star-inserted">
          <!--begin::Table row-->
          <tr class="ant-table-row ng-star-inserted">
            <th class="ant-table-cell">Product Type</th>
            <th>Product Name</th>
            <!-- <th>SKU</th> -->
            <!-- <th>Barcode</th> -->
            <th>Mfd Date</th>
            <th>Expiry</th>
            <th>Unit</th>
            <th>Quantity</th>
            <th>Inv./Unit</th>
            <th>S&H/Unit</th>
            <th>Frt & Ins./Unit</th>
            <th>Misc/Unit</th>
            <th>Total Price/Unit</th>
            <th>Grade</th>
            <th>Actions</th>
          </tr>
          <!--end::Table row-->
        </thead>
        <!--end::Table head-->
        <!--begin::Table body-->
        <tbody class="fw-bold text-gray-600">
          <!--begin::Table row-->
          <tr *ngFor="let spl of this.NewStock.StockProduct;">
            <td>
              {{spl.ProductType}}
            </td>
            <td>
              {{spl.ProductName}}
            </td>
            <!-- <td>
              {{spl.Sku}}
            </td>
            <td>
              {{spl.Barcode}}
            </td> -->
            <td>
              {{spl.ManufacturedDate  | date: 'dd-MMM-yyyy'}}
            </td>
            <td>
              {{spl.ExpiryDate  | date: 'dd-MMM-yyyy'}}
            </td>
            <td>
              {{spl.Unit}}
            </td>
            <td>
              {{spl.AcceptedQuantity}}
            </td>
            <td>
              &#8377; {{spl.InvoicePricePerUnit}}
            </td>
            <td>
              &#8377; {{spl.ShippingHandlingPerUnit}}
            </td>
            <td>
              &#8377; {{spl.FreightPerUnit}}
            </td>
            <td>
              &#8377; {{spl.MiscPerUnit}}
            </td>
            <td>
              &#8377; {{spl.PricePerUnit}}
            </td>
            <td>
              {{spl.Grade}}
            </td>
            <td>
              <a class="btn btn-sm btn-light-primary" (click)="EditProduct(spl)">Add Details</a>
              <a class="btn btn-sm btn-light-danger" (click)="RemoveStockProduct(spl)">Remove</a>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="producttotaltable" style="width: 100%;">

        <tbody>
          <tr>
            <td style="width:60%">
             
            </td>
            <td>
              <table class="producttotaltable" style="text-align: right;width: 100%;">
                <tbody>
                  <tr>
                    <td style="width:50%"><b> Sub Total (A) </b></td>
                    <td style="width:50%"> &#8377; {{this.calculatesubtotal()}}</td>
                  </tr>
                  <tr>
                    <td style="width:50%"><b>Total IGST/TAX (B) </b></td>
                    <td style="width:50%">
                      <nz-input-group nzPrefix="&#8377;">
                        <input nz-input type="number" class="form-control" (change)="this.CalculateGTotal();"
                          [(ngModel)]="NewStock.Invoice.GST" nzSize="small"
                          style="width: 70%;float: right;padding: 5px;height: 28px;text-align: right;border: 0;border-bottom: 1px solid;border-radius: 0;" />
                      </nz-input-group>
                    </td>
                  </tr>
                  <tr>
                    <td style="width:50%"><b>Shipping & Handling (C) </b> <i nz-icon nzType="info-circle" nz-tooltip
                        nzTooltipTitle="Total Shipping and Handling Charges"></i></td>
                    <td style="width:50%"> 
                      <nz-input-group nzPrefix="&#8377;">
                        <input nz-input type="number" (change)="this.CalculateTotalShippingHandling();"
                          [(ngModel)]="NewStock.Invoice.ShippingHandling" class="form-control" nzSize="small"
                          style="width: 70%;float: right;padding: 5px;height: 28px;text-align: right;border: 0;border-bottom: 1px solid;border-radius: 0;" />
                      </nz-input-group>
                    </td>
                  </tr>
                  <tr>
                    <td style="width:50%"><b>Freight & Insurance (D) </b> <i nz-icon nzType="info-circle" nz-tooltip
                        nzTooltipTitle="Total Freight and Insurance Charges"></i></td>
                    <td style="width:50%"> 
                      <nz-input-group nzPrefix="&#8377;">
                        <input nz-input type="number" (change)="this.CalculateTotalFreight();"
                          [(ngModel)]="NewStock.Invoice.FreightInsurance" class="form-control" nzSize="small"
                        style="width: 70%;float: right;padding: 5px;height: 28px;text-align: right;border: 0;border-bottom: 1px solid;border-radius: 0;" />
                      </nz-input-group>
                    </td>
                  </tr>
                  <tr>
                    <td style="width:50%"> <b>Other/Misc (E)</b> <i nz-icon nzType="info-circle" nz-tooltip
                        nzTooltipTitle="Total Miscellaneous Charges"></i>
                    </td>
                    <td style="width:50%"> 
                      <nz-input-group nzPrefix="&#8377;">
                        <input nz-input type="number" (change)="this.CalculateTotalMisc();"
                          [(ngModel)]="NewStock.Invoice.OtherCharges" class="form-control" nzSize="small"
                          style="width: 70%;float: right;padding: 5px;height: 28px;text-align: right;border: 0;border-bottom: 1px solid;border-radius: 0;" />
                      </nz-input-group>
                    </td>
                  </tr>
                </tbody>

                <tfoot>
                  <tr style="border-top: 2px solid;">
                    <td style="width:50%"><b>Invoice Total (A+B+C+D+E)</b><i nz-icon nzType="info-circle" nz-tooltip nzTooltipTitle="Total Invoice Amount (B+C+D+E)"></i>
                    </td>
                    <td style="width:50%"> &#8377; {{ NewStock.Invoice.InvoiceTotal }}</td>
                  </tr>
                </tfoot>
                <tfoot>
                  <tr style="border-top: 2px solid;">
                    <td style="width:50%"><b>Total without GST (A+C+D+E)</b><i nz-icon nzType="info-circle" nz-tooltip nzTooltipTitle="Total PO Value w/o GST"></i>
                    </td>
                    <td style="width:50%"> &#8377; {{ NewStock.Invoice.InvoiceTotalPrice }}</td>
                  </tr>
                </tfoot>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </nz-card>
    <ng-template #extraTemplate>
      <!-- <a class="btn btn-sm btn-success" (click)="showModal()">Add Product</a> -->
    </ng-template>
    <br>
    <div class="d-flex justify-content-end">
      <label style="margin:10px" nz-checkbox  [(ngModel)]="NewStock.Invoice.IsPocomplete"><b> Is PO Completed </b></label>
      <button type="submit" id="kt_ecommerce_add_product_submit" [disabled]="isLoading" (click)="SaveStock()" class="btn btn-primary">
        <i nz-icon nzType="loading" *ngIf="isLoading"></i>
        <span class="indicator-label">Save Changes</span>
      </button>
    </div>
  </div>
</div>



<nz-modal [nzWidth]="1000"
          [nzStyle]="{ top: '20px' }"
          [(nzVisible)]="isVisible"
          [nzTitle]="modalTitle"
          [nzContent]="modalContent"
          [nzFooter]=null
          (nzOnCancel)="handleCancel()">
  <ng-template #modalTitle>Add Product</ng-template>

  <ng-template #modalContent>
    <div>
      <div class="mb-10 fv-row">
        <div class="row gx-10 mb-5">
          <div class="col-lg-3">
            <label class="required form-label">Product Type</label>
            <nz-select class="form-select mb-2" nzSize="large" disabled [(ngModel)]="SelectedProductType" (ngModelChange)="onSelectedProductTypeChange()" nzAllowClear nzPlaceHolder="Choose">
              <nz-option nzValue="Raw" nzLabel="Raw"></nz-option>
              <nz-option nzValue="Finished" nzLabel="Finished"></nz-option>
              <nz-option nzValue="Internal-Use" nzLabel="Internal-Use"></nz-option>
            </nz-select>
          </div>
          <div class="col-lg-3">
            <label class="required form-label">Category</label>

            <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="large" disabled [(ngModel)]="CategoryID" name="Category" nzAllowClear nzPlaceHolder="Category" (ngModelChange)="GetAllFirstCategory($event)">
              <nz-option *ngFor="let s of this.ProductCategoryList;" [nzValue]="s.ProductCategoryId" [nzLabel]="s.ProductCategory"></nz-option>
            </nz-select>
          </div>
          <div class="col-lg-3">
            <label class="required form-label">SubCategory</label>
            <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="large" disabled [(ngModel)]="FirstCategoryID" name="FirstCategoryID" nzAllowClear nzPlaceHolder="Category" (ngModelChange)="GetAllSecondCategory($event)">
              <nz-option *ngFor="let s of this.ProductFirstSubCategoryList;" [nzValue]="s.ProductFirstSubCategoryId" [nzLabel]="s.ProductFirstSubCategory"></nz-option>
            </nz-select>
          </div>
          <div class="col-lg-3">
            <label class="required form-label">2nd SubCategory</label>
            <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="large" disabled [(ngModel)]="SecondCategoryID" name="SecondCategoryID" nzAllowClear nzPlaceHolder="SecondCategoryID" (ngModelChange)="GetSecondCategoryFilteredProduct()">
              <nz-option *ngFor="let s of this.ProductSecSubCategoryList;" [nzValue]="s.ProductSecSubCategoryId" [nzLabel]="s.ProductSecSubCategory"></nz-option>
            </nz-select>
          </div>

          <div class="col-lg-6">
            <label class="required form-label">Product Name</label>
            <nz-select nzShowSearch class="form-select " nzSize="large" disabled [(ngModel)]="NewStockProduct.ProductId" name="ProductId" (ngModelChange)="onSelectedProductChange($event)" nzAllowClear nzPlaceHolder="Choose">
              <nz-option *ngFor="let s of this.FilteredProductList;" [nzValue]="s.ProductId" [nzLabel]="s.ProductName"></nz-option>
            </nz-select>
          </div>

          <!-- <div class="col-lg-2">
            <label class="form-label">Barcode</label>
            <input type="text" name="Barcode" [(ngModel)]="NewStockProduct.Barcode" nzSize="large" class="form-control " placeholder="Barcode Number" value="" />
          </div> -->
          <div class="col-lg-2">
            <label class=" form-label">Stock Date</label>
            <input type="text" name="Batch"  nzSize="large" class="form-control " disabled placeholder="new Date()" [(ngModel)]="BatchNo"/>
          </div>
          <div class="col-lg-2">
            <label class=" form-label">Manufactured Date</label>
            <nz-date-picker class="form-select" nzSize="large" [nzDisabledDate]="disabledStartDate" [(ngModel)]="NewStockProduct.ManufacturedDate" nzValue="default"></nz-date-picker>
          </div>
          <div class="col-lg-2">
            <label class=" form-label">Expiry </label><br>
            <nz-date-picker class="form-select " nzSize="large" [nzDisabledDate]="disabledEndDate" [(ngModel)]="NewStockProduct.ExpiryDate" nzValue="default"></nz-date-picker>
          </div>
        </div>
      </div>
      <div class="mb-10 fv-row">
        <div class="row gx-10 mb-5">
          <!-- <div class="col-lg-2">
            <label class="form-label">SKU</label>
            <input type="text" name="sku" [(ngModel)]="NewStockProduct.Sku" nzSize="large" class="form-control" placeholder="SKU Number" value="" />
          </div> -->
          <div class="col-lg-2">
            <label class="required form-label">Measure unit</label>
            <nz-select nzShowSearch class="form-select mb-2" nzSize="large" [(ngModel)]="NewStockProduct.Unit" disabled="true"  nzAllowClear nzPlaceHolder="Choose">
              <nz-option *ngFor="let s of this.MeasureUnits;" [nzValue]="s.Unit" [nzLabel]="s.Unit"></nz-option>
            </nz-select>
          </div>
          <div class="col-lg-2">
            <label class="required form-label">Quantity</label>
            <input type="text" name="Quantity" [(ngModel)]="NewStockProduct.Quantity" class="form-control" disabled="true" placeholder="Quantity" value="" nzSize="large" />
          </div>
          <div class="col-lg-2">
            <label class="required form-label">Recieved Quantity</label>
            <input type="number" name="Quantity" [(ngModel)]="NewStockProduct.AcceptedQuantity" class="form-control"
              placeholder="AcceptedQuantity" value="" nzSize="large" />
          </div>
          <div class="col-lg-2">
            <label class="form-label">S&H/Unit (A) <i nz-icon nzType="info-circle" nz-tooltip
                nzTooltipTitle="Shipping and Handling Charges per unit"></i></label>
            <nz-input-group nzPrefix="&#8377;">
              <input nz-input type="number" class="form-control" name="price[]"
                [(ngModel)]="NewStockProduct.ShippingHandlingPerUnit" nzSize="large" placeholder="0.00" value="0.00"
                data-kt-element="price" (change)="calculateTotalPrice()" />
            </nz-input-group>
          </div>
          <div class="col-lg-2">
            <label class="form-label">Frt & Ins./Unit (B) <i nz-icon nzType="info-circle" nz-tooltip
                nzTooltipTitle="Freight and Insurance Charges per unit"></i></label>
            <nz-input-group nzPrefix="&#8377;">
              <input nz-input type="number" class="form-control" name="price[]"
                [(ngModel)]="NewStockProduct.FreightPerUnit" nzSize="large" placeholder="0.00" value="0.00"
                data-kt-element="price" (change)="calculateTotalPrice()" />
            </nz-input-group>
          </div>
          <div class="col-lg-2">
            <label class="form-label">Misc/Unit (C)</label>
            <nz-input-group nzPrefix="&#8377;">
              <input nz-input type="number" class="form-control" name="price[]" [(ngModel)]="NewStockProduct.MiscPerUnit"
                nzSize="large" placeholder="0.00" value="0.00" data-kt-element="price" (change)="calculateTotalPrice()" />
            </nz-input-group>
          </div>
          <div class="col-lg-3">
            <label class="required form-label">Invoice Price/Unit (D) <i nz-icon nzType="info-circle" nz-tooltip
                nzTooltipTitle="Per Unit Price (as per invoice)"></i></label>
            <nz-input-group nzPrefix="&#8377;">
              <input nz-input type="number" class="form-control" name="price[]"
                [(ngModel)]="NewStockProduct.InvoicePricePerUnit" nzSize="large" placeholder="0.00" value="0.00"
                data-kt-element="price" (change)="calculateTotalPrice()" />
            </nz-input-group>
          </div>
          <div class="col-lg-2">
            <label class="form-label">Total Price/Unit <i nz-icon nzType="info-circle" nz-tooltip
                nzTooltipTitle="A+B+C+D"></i></label>
            <div><b>&#8377; {{NewStockProduct.PricePerUnit | number:'1.2-2'}}</b></div>
          </div>
          <div class="col-lg-2">
            <label class="form-label">Grade</label>
            <nz-select class="form-select mb-2" nzSize="large" [(ngModel)]="NewStockProduct.Grade" nzAllowClear nzPlaceHolder="Choose">
              <nz-option nzValue="NA" nzLabel="NA"></nz-option>
              <nz-option nzValue="0" nzLabel="0"></nz-option>
              <nz-option nzValue="1" nzLabel="1"></nz-option>
              <nz-option nzValue="2" nzLabel="2"></nz-option>
              <nz-option nzValue="3" nzLabel="3"></nz-option>
              <nz-option nzValue="4" nzLabel="4"></nz-option>
              <nz-option nzValue="5" nzLabel="5"></nz-option>
              <nz-option nzValue="6" nzLabel="6"></nz-option>
              <nz-option nzValue="7" nzLabel="7"></nz-option>
              <nz-option nzValue="8" nzLabel="8"></nz-option>
              <nz-option nzValue="9" nzLabel="9"></nz-option>
              <nz-option nzValue="A" nzLabel="A"></nz-option>
              <nz-option nzValue="B" nzLabel="B"></nz-option>
              <nz-option nzValue="c" nzLabel="C"></nz-option>
              <nz-option nzValue="D" nzLabel="D"></nz-option>
              <nz-option nzValue="E" nzLabel="E"></nz-option>
            </nz-select>
          </div>
          <div class="col-lg-2" style="text-align: right;padding-top: 25px;">
            <button type="submit" id="kt_ecommerce_add_product_submit" (click)="AddNewProductRecord()" class="btn btn-success">
              <span class="indicator-label">Update</span>
              <span class="indicator-progress">
                Please wait...
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
          <!--end::Col-->
        </div>
      </div>
      <!--end::Input group-->
    </div>
  </ng-template>



</nz-modal>
<nz-modal [nzWidth]="300"
          [nzStyle]="{ top: '20px' }"
          [(nzVisible)]="isVisibleEdit"
          [nzTitle]="modalTitleEdit"
          [nzContent]="modalContentEdit"
          [nzFooter]=null
          (nzOnCancel)="handleCancelEdit()">
  <ng-template #modalTitleEdit>Edit Invoice Number</ng-template>

  <ng-template #modalContentEdit>
    <div>
      <div class="mb-10 fv-row">
        <div class="row gx-10 mb-5">
          <div class="col-lg-12">
            <label class=" form-label">Select Invoice </label><br>
            <nz-select nzShowSearch class="form-select mb-2" (ngModelChange)="UpdateInvoiceNo($event)" nzSize="large" [(ngModel)]="UpdatedInvoiceNo.NewInvoiceId" nzAllowClear nzPlaceHolder="Choose">
              <nz-option *ngFor="let s of this.InvoiceListWithoutPO;" [nzValue]="s.InvoiceId" [nzLabel]="s.InvoiceNumber"></nz-option>
            </nz-select>
          </div>
        </div>
        <div class="row gx-10 mb-5">
          <div class="col-lg-12">
            <label class=" form-label">Invoice Number </label><br>
            <input type="text" name="InvoiceId" [(ngModel)]="UpdatedInvoiceNo.NewInvoiceNo" class="form-control" placeholder="Invoice Number" value="" nzSize="large" />
          </div>
        </div>
        <br>
        <div class="row gx-10 mb-5">
          <div class="col-lg-12">
            <label class=" form-label">Purchase Order </label><br>
            <nz-select nzShowSearch #microwaveRef class="form-select mb-2" nzSize="large" [(ngModel)]="UpdatedInvoiceNo.NewPO" nzPlaceHolder="Choose">
              <nz-option *ngFor="let p of this.FilteredPurchaseOrderList;" [nzValue]="p.Poid" [nzLabel]="p.Ponumber"></nz-option>
            </nz-select>
          </div>
        </div>
        <br>
        <div class="d-flex justify-content-end">
          <button type="submit" id="kt_ecommerce_add_product_submit" [disabled]="isLoading" (click)="UpdateInvoice()" class="btn btn-primary">
            <i nz-icon nzType="loading" *ngIf="isLoading"></i>
            <span class="indicator-label">Save Changes</span>
          </button>
        </div>
      </div>
        </div>
</ng-template>



</nz-modal>

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { DowntimeServiceModalData, ProductionDowntimeModel } from '../../../Models/ProductionDowntime';

@Injectable({
    providedIn: 'root'
})
export class PrdDowntimeService {
    // For list refresh
    private refreshList = new Subject<void>();
    refreshList$ = this.refreshList.asObservable();

    // For modal state
    private modalVisibility = new BehaviorSubject<boolean>(false);
    modalVisibility$ = this.modalVisibility.asObservable();

    // For modal data
    private modalData = new BehaviorSubject<DowntimeServiceModalData | null>(null);
    modalData$ = this.modalData.asObservable();

    triggerRefresh() {
        this.refreshList.next();
    }

    openModal(productionLineType: string, downtimeData?: ProductionDowntimeModel) {
        this.modalData.next({
            productionLineType,
            downtimeData: downtimeData || new ProductionDowntimeModel()
        });
        this.modalVisibility.next(true);
    }

    closeModal() {
        this.modalVisibility.next(false);
        this.modalData.next(null);
    }
}
<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Out Pass </nz-page-header-title>
      <nz-page-header-subtitle>Add new</nz-page-header-subtitle>
      <nz-page-header-extra>

      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="8">
          <nz-form-item>
            <nz-form-label [nzSpan]="10">Out Pass To </nz-form-label>
            <nz-form-control [nzSpan]="18">

              <input *ngIf="!CustomerMaster" nz-input name="OutPassTo" [(ngModel)]="NewOutPass.OutpassTo"
                [ngModelOptions]="{standalone: true}" />
              <nz-select class="form-select mb-2" nzSize="default" *ngIf="CustomerMaster" name="OutPassToCustomerId"
                [(ngModel)]="SelectedCustomer" nzShowSearch nzPlaceHolder="Choose"
                [ngModelOptions]="{standalone: true}">
                <nz-option *ngFor="let data of CustomerList" [nzValue]="data" [nzLabel]="data.CustomerName"></nz-option>
              </nz-select>
              <nz-switch [(ngModel)]="CustomerMaster" nzCheckedChildren="Master Customer Name"
                nzUnCheckedChildren="Custom Name" [ngModelOptions]="{standalone: true}"></nz-switch>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label nzRequired [nzSpan]="10">Purpose </nz-form-label>
            <nz-form-control [nzSpan]="18" nzErrorTip="Out Pass To ">
              <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="SelectedPurpose" nzPlaceHolder="Choose"
                [ngModelOptions]="{standalone: true}" nzShowSearch nzPlaceHolder="Choose">
                <nz-option *ngFor="let s of this.OutPassPurposeList;" [nzValue]="s"
                  [nzLabel]="s.PurposeName"></nz-option>
              </nz-select>

            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label nzRequired [nzSpan]="10">Transport</nz-form-label>
            <nz-form-control [nzSpan]="18">
              <nz-select nzShowSearch class="form-select mb-2" (ngModelChange)="onSelectedTransportChange($event)"
                [(ngModel)]="NewOutPass.TransportId" nzSize="default" nzAllowClear nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                <nz-option *ngFor="let s of this.TransportList;" [nzValue]="s.TransportId"
                  [nzLabel]="s.TransportCompanyName"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="8">

          <nz-form-item>
            <nz-form-label nzRequired [nzSpan]="10">Out Pass Type</nz-form-label>
            <nz-form-control [nzSpan]="18">
              <nz-select class="form-select" nzShowSearch name="VehicleId" [(ngModel)]="NewOutPass.OutpassType"
                [ngModelOptions]="{standalone: true}" nzSize="default" nzPlaceHolder="Choose">
                <nz-option nzValue="Non-Returnable" nzLabel="Non-Returnable"></nz-option>
                <nz-option nzValue="Returnable" nzLabel="Returnable"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="10">Remark</nz-form-label>
            <nz-form-control [nzSpan]="18" nzErrorTip="Select Payment Terms">
              <textarea nz-input name="SupplierContactNumber" [(ngModel)]="NewOutPass.Remark"
                [ngModelOptions]="{standalone: true}"></textarea>


            </nz-form-control>
          </nz-form-item>
          <nz-form-item style="margin-top: 43px;">
            <nz-form-label nzRequired [nzSpan]="10">Vehicle Number</nz-form-label>
            <nz-form-control [nzSpan]="18">
              <nz-select class="form-select" nzShowSearch name="Rack" nzSize="default"
                (ngModelChange)="onSelectedVehicleChange($event)" [(ngModel)]="NewOutPass.VehicleId" nzAllowClear
                nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                <nz-option *ngFor="let s of this.TransportVehicleList;" [nzValue]="s.VehicleId"
                  [nzLabel]="s.VehicleNumber"></nz-option>
                <nz-option nzValue="0" nzLabel="Others"></nz-option>
              </nz-select>
              <span style="color: crimson;"><b>{{this.VehicleStatus}}</b></span>
            </nz-form-control>
          </nz-form-item>

        </div>
        <div nz-col [nzSpan]="8">
          <nz-form-item>
            <nz-form-label nzRequired [nzSpan]="10">Out Pass Date</nz-form-label>
            <nz-form-control [nzSpan]="18" nzErrorTip="Out Pass Date required">
              <nz-date-picker class="form-select" [(ngModel)]="NewOutPass.OutpassDate"
                [ngModelOptions]="{standalone: true}"></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item *ngIf="NewOutPass.OutpassType == 'Returnable'">
            <nz-form-label nzRequired [nzSpan]="10">Expected Return Date</nz-form-label>
            <nz-form-control [nzSpan]="18">
              <nz-date-picker class="form-select" nzShowTime nzFormat="dd-MMM-yyy hh:mm a"
                [(ngModel)]="NewOutPass.ExpectedReturnDate" [ngModelOptions]="{standalone: true}"></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <nz-divider></nz-divider>
      <a style="float:right" nz-button nzType="primary" (click)="showModal()">Add New</a>
      <nz-divider></nz-divider>

      <nz-table nzSize="small" [nzPageSize]="100" style="width: 100%;" [nzData]="['']" #basicTable1 nzBordered>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Store</th>
            <th>Rack</th>
            <th>Product</th>
            <th>Quantity</th>
            <th>Amount</th>
            <th>Unit</th>
            <th>Total</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of this.NewOutPass.OutpassItems;let i=index">
            <td>{{i+1}}</td>
            <td>{{ data.StoreName }}</td>
            <td>{{ data.RackName }}</td>
            <td>{{ data.ProductName }}</td>
            <td>{{ data.Quantity }}</td>
            <td>{{ data.Amount }}</td>
            <td>{{ data.Unit }}</td>
            <td>{{ data.Total }}</td>
            <td>
              <a class="btn btn-sm btn-light-danger" style="float:right  " (click)="handleRemoveRow(data)">
                <i nz-icon nzType="minus-circle" nzTheme="fill" style=" margin-bottom: 3px;">
                </i>Remove
              </a>
            </td>
          </tr>

        </tbody>
        <tfoot *ngIf="this.NewOutPass.OutpassItems.length>0">
        </tfoot>
      </nz-table>
      <nz-divider></nz-divider>



      <div class="text-center">
        <button nz-button nzType="primary" [nzLoading]="isLoading">Save</button>
      </div>

    </form>

    <nz-modal [nzWidth]="700" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isVisible" [nzTitle]="modalTitle"
      [nzContent]="modalContent" [nzFooter]="modalFooter" (nzOnCancel)="handleCancel()">
      <ng-template #modalTitle>{{PopUpTitle}}</ng-template>

      <ng-template #modalContent>
        <nz-form-item>
          <nz-form-label [nzSpan]="8"></nz-form-label>
          <nz-form-control [nzSpan]="14">
            <label style="margin:10px" nz-checkbox [(ngModel)]="IsFromStore"><b> From Store </b></label>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="IsFromStore">

          <nz-form-label [nzSpan]="8">Select Store</nz-form-label>
          <nz-form-control [nzSpan]="14">
            <nz-select class="form-select mb-2" nzShowSearch name="StoreId" nzSize="default" nzPlaceHolder="Choose"
              [(ngModel)]="SelectedStoreID" (ngModelChange)="GetStorewiseStock($event)">
              <nz-option *ngFor="let s of this.AdminStoreList;" [nzValue]="s.StoreId"
                [nzLabel]="s.StoreName"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="IsFromStore">
          <nz-form-label [nzSpan]="8">Select Rack</nz-form-label>
          <nz-form-control [nzSpan]="14">
            <nz-select class="form-select mb-2" nzShowSearch name="StoreId" nzSize="default" nzPlaceHolder="Choose"
              [(ngModel)]="SelectedRackID" (ngModelChange)="OnRackChange()">
              <nz-option *ngFor="let s of this.RackList;" [nzValue]="s.RackId" [nzLabel]="s.RackName"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="IsFromStore">
          <nz-form-label [nzSpan]="8">Select Product</nz-form-label>
          <nz-form-control [nzSpan]="14">
            <nz-select class="form-select mb-2" nzShowSearch name="StoreId" nzSize="default" nzPlaceHolder="Choose"
              [(ngModel)]="SelectedStockProductId" (ngModelChange)="OnProductChange()">
              <nz-option *ngFor="let s of this.ProductList;" [nzValue]="s.StockProductId"
                [nzLabel]="s.ProductName"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="!IsFromStore">
          <nz-form-label [nzSpan]="8">Enter Product</nz-form-label>
          <nz-form-control [nzSpan]="14">
            <input nz-input nzType="text" nzMaxLength="100" name="ProductName" [(ngModel)]="ProductName" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="8">Enter Quantity</nz-form-label>
          <nz-form-control [nzSpan]="14">
            <input nz-input nzType="number" [(ngModel)]="SelectedQty" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSpan]="8">Enter Amount</nz-form-label>
          <nz-form-control [nzSpan]="14">
            <input nz-input nzType="number" [(ngModel)]="SelectedAmount" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="IsFromStore">
          <nz-form-label [nzSpan]="8">Select Unit</nz-form-label>
          <nz-form-control [nzSpan]="14">
            <nz-select #microwaveRef class="form-select" disabled nzShowSearch nzSize="default"
              [(ngModel)]="selectedunit" name="UnitID" nzAllowClear nzPlaceHolder="Unit">
              <nz-option *ngFor="let s of this.MeasureUnits;" [nzValue]="s.Unit" [nzLabel]="s.Unit"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="!IsFromStore">
          <nz-form-label [nzSpan]="8">Select Unit</nz-form-label>
          <nz-form-control [nzSpan]="14">
            <nz-select #microwaveRef class="form-select" nzShowSearch nzSize="default" [(ngModel)]="selectedunit"
              name="UnitID" nzAllowClear nzPlaceHolder="Unit">
              <nz-option *ngFor="let s of this.MeasureUnits;" [nzValue]="s.Unit" [nzLabel]="s.Unit"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>

      </ng-template>
      <ng-template #modalFooter>
        <div class="text-center">
          <a nz-button nzType="primary" (click)="AddStock()">Add</a>
        </div>
      </ng-template>

    </nz-modal>
  </div>
</div>
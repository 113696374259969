<div class="card card-flush h-xl-100">
    <div class="card-body pt-5">
        <nz-page-header [nzGhost]="false">
            <nz-page-header-title>Production Downtime List</nz-page-header-title>
            <nz-page-header-subtitle>Manage your production downtime here</nz-page-header-subtitle>
            <nz-page-header-extra>
                <button nz-button nzType="primary" *ngIf="this.permission.Add" (click)="openPrdDowntimeModal()">
                    Add New Downtime
                </button>
            </nz-page-header-extra>
        </nz-page-header>
        <nz-divider></nz-divider>
        <nz-collapse>
            <nz-collapse-panel nzHeader="More Filters" [nzActive]="false" (nzActiveChange)="onFilterPanelOpen($event)">
                <div>
                    <div nz-row [nzGutter]="24">
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label>Date Filters</label>
                                    <nz-select [(ngModel)]="selecteddateFilter"
                                        (ngModelChange)="getDateRange(selecteddateFilter)" nzPlaceHolder="Choose"
                                        [ngModelOptions]="{standalone: true}">
                                        <nz-option *ngFor="let data of dateFilterOptions" [nzValue]="data.Value"
                                            [nzLabel]="data.Text"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4" *ngIf="this.enableCustomDateRange">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label> From Date</label>
                                    <nz-date-picker nzPlaceHolder="From Date" name="orderDateFrom" class="form-control"
                                        [(ngModel)]="request.StartTime" nzShowTime nzAllowClear></nz-date-picker>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4" *ngIf="this.enableCustomDateRange">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label> To Date</label>
                                    <nz-date-picker nzPlaceHolder="To Date" name="orderDateTo" class="form-control"
                                        [(ngModel)]="request.EndTime" nzShowTime nzAllowClear></nz-date-picker>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label>Reason</label>
                                    <nz-select class="form-select mb-2" nzShowSearch nzAllowClear
                                        name="ProductionLineType" nzSize="default" nzPlaceHolder="Choose"
                                        [(ngModel)]="request.ProductionDowntimeReasonId"
                                        [ngModelOptions]="{standalone: true}"
                                        (nzFocus)="GetAllProductionDowntimeReason()">
                                        <nz-option *ngFor="let data of ProductionDowntimeReasonListForFilter"
                                            [nzValue]="data.ProductionDowntimeReasonId"
                                            [nzLabel]="data.ReasonName + ' (' + data.ReasonCode + ')'"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label>Line Type</label>
                                    <nz-select class="form-select mb-2" nzShowSearch name="ProductionLineType"
                                        nzSize="default" nzPlaceHolder="Choose" [(ngModel)]="request.ProductionLineType"
                                        [ngModelOptions]="{standalone: true}">
                                        <nz-option nzValue="Manufacturing" nzLabel="Manufacturing"></nz-option>
                                        <nz-option nzValue="Printing" nzLabel="Printing"></nz-option>
                                        <nz-option nzValue="Embossing" nzLabel="Embossing"></nz-option>
                                        <nz-option nzValue="Laquer" nzLabel="Laquer"></nz-option>
                                        <nz-option nzValue="Vacuum" nzLabel="Vacuum"></nz-option>
                                        <!-- <nz-option nzValue="Packaging" nzLabel="Packaging"></nz-option> -->
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label>Production Line No.</label>
                                    <nz-select [(ngModel)]="request.ProductionLineNo" nzPlaceHolder="Choose">
                                        <nz-option *ngFor="let data of ProductionLineNoList" [nzValue]="data.Value"
                                            [nzLabel]="data.Text"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>

                    <div style="float: right; margin-bottom: 5%; margin-right: 10px">
                        <div style="display: flex">
                            <button nz-button nzSize="small" nzType="primary" style="margin-right: 8px"
                                (click)="GetAllProductionDowntime()">
                                Apply Filter
                            </button>
                            <!-- <button nz-button nzSize="small" (click)="onReset()">Reset</button> -->
                        </div>
                    </div>
                </div>
            </nz-collapse-panel>
        </nz-collapse>
        <nz-divider></nz-divider>
        <div style="padding: 8px;float:right">
            <button nz-button nzSize="small" (click)="exportToCSV()"><i nz-icon nzType="export" nzTheme="outline"
                    style="top: 2px;right: 50px;position: absolute;"></i> Export</button>
        </div>
        <nz-table nzSize="small" [nzPageSize]="pageSize" style="width: 100%" [nzScroll]="{ x: '1400px', y: '515px' }"
            #basicTable [nzData]="[{}]" [nzLoading]="isTableLoading" nzBordered nzShowPagination="true"
            nzShowSizeChanger [nzShowTotal]="totalTemplate" nzShowQuickJumper="true" nzResponsive=true
            [nzPageSizeOptions]="[10, 20, 50, 100]" (nzPageIndexChange)="onPageIndexChange($event)"
            (nzPageSizeChange)="onPageSizeChange($event)" [nzPageIndex]="pageIndex" [nzPageSize]="pageSize"
            [nzFrontPagination]="false" [nzTotal]="totalRecords">
            <thead>
                <tr>
                    <th nzWidth="60px">S.No.</th>
                    <th nzWidth="180px">Reason (Code)</th>
                    <th nzWidth="150px">PRD Line Type</th>
                    <th nzWidth="80px">PRD Line No</th>
                    <th nzWidth="120px">Start Time</th>
                    <th nzWidth="120px">End Time</th>
                    <th nzWidth="130px">Actual Duration</th>
                    <th nzWidth="130px">Standard Duration</th>
                    <th nzWidth="130px">Excess Duration</th>
                    <th nzWidth="200px">Comments</th>
                    <th nzWidth="150px">Last Updated By</th>
                    <th nzWidth="150px">Last Updated On</th>

                    <th nzWidth="200px" nzRight style="text-align: center" *ngIf="permission.Add || permission.Delete">
                        Action
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of ProductionDowntimeList">
                    <td>{{ data.SerialNo }}</td>
                    <td>{{ data.ReasonName }} ({{ data.ReasonCode }})</td>
                    <td>{{ data.ProductionLineType }}</td>
                    <td>{{ data.ProductionLineNo }}</td>
                    <td>{{ data.StartTime}}</td>
                    <td>{{ data.EndTime}}</td>
                    <td>{{ data.ActualDurationFormatted }}</td>
                    <td>{{ data.StandardDurationFormatted }}</td>
                    <td>{{ data.ExcessDurationFormatted }}</td>
                    <td>{{ data.Comments }}</td>
                    <td>{{ data.ModifiedBy ? data.ModifiedBy : data.CreatedBy }}</td>
                    <td>{{ data.ModifiedOn ? (data.ModifiedOn | DatetimeConverter) : (data.CreatedOn |
                        DatetimeConverter) }}
                    </td>

                    <td nzRight style="text-align: center">
                        <button class="btn btn-sm btn-light-primary" *ngIf="permission.Edit"
                            (click)="OpenEditPop(data)">
                            Edit
                        </button>
                        &nbsp;
                        <button class="btn btn-sm btn-light-danger" *ngIf="permission.Delete"
                            (click)="handleDelete(data.ProductionDowntimeReasonId)">
                            Delete
                        </button>
                    </td>
                </tr>
            </tbody>
        </nz-table>

        <!-- <nz-modal [nzWidth]="800" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isVisible" [nzTitle]="modalTitle"
            [nzContent]="modalContent" [nzFooter]="null" (nzOnCancel)="handleCancel()">
            <ng-template #modalTitle>{{ PopUpTitle }}</ng-template>

            <ng-template #modalContent>
                <form nz-form [formGroup]="validateForm">

                    <nz-form-item>
                        <nz-form-label [nzSpan]="8" nzRequired>Reason Name</nz-form-label>
                        <nz-form-control [nzSpan]="14" nzErrorTip="Select Reason">
                            <nz-select class="form-select mb-2" nzShowSearch name="ProductionDowntimeReasonId"
                                nzSize="default" nzPlaceHolder="Choose" formControlName="ProductionDowntimeReasonId">
                                <nz-option *ngFor="let item of ProductionDowntimeReasonList"
                                    [nzValue]="item.ProductionDowntimeReasonId"
                                    [nzLabel]="item.ReasonName + ' (' + item.ReasonCode + ')'"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="8" nzRequired>Production Line No</nz-form-label>
                        <nz-form-control [nzSpan]="14" nzErrorTip="Select Production Line No">
                            <nz-select class="form-select mb-2" nzShowSearch name="ProductionLineNo" nzSize="default"
                                nzPlaceHolder="Choose" formControlName="ProductionLineNo">
                                <nz-option nzValue="1" nzLabel="1"></nz-option>
                                <nz-option nzValue="2" nzLabel="2"></nz-option>
                                <nz-option nzValue="3" nzLabel="3"></nz-option>
                                <nz-option nzValue="4" nzLabel="4"></nz-option>
                                <nz-option nzValue="5" nzLabel="5"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="8" nzRequired>Production Line Type</nz-form-label>
                        <nz-form-control [nzSpan]="14" nzErrorTip="Select Production Line Type">
                            <nz-select class="form-select mb-2" nzShowSearch name="ProductionLineType" nzSize="default"
                                nzPlaceHolder="Choose" formControlName="ProductionLineType">
                                <nz-option nzValue="Manufacturing" nzLabel="Manufacturing"></nz-option>
                                <nz-option nzValue="Printing" nzLabel="Printing"></nz-option>
                                <nz-option nzValue="Embossing" nzLabel="Embossing"></nz-option>
                                <nz-option nzValue="Laquer" nzLabel="Laquer"></nz-option>
                                <nz-option nzValue="Packaging" nzLabel="Packaging"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <div class="mb-4">
                        <label class="required">Start Date and Time</label>
                        <nz-date-picker class="w-100" [nzDisabledDate]="disabledStartDate" nzShowTime nzFormat="dd-MM-yyyy hh:mm a"
                          [(ngModel)]="NewDowntime.StartTime" nzPlaceHolder="Start" (nzOnOpenChange)="handleStartOpenChange($event)">
                        </nz-date-picker>
                      </div>
                
                      <div class="mb-4">
                        <label class="required">End Date and Time</label>
                        <nz-date-picker class="w-100" #endDatePicker [nzDisabledDate]="disabledEndDate" nzShowTime
                          nzFormat="dd-MM-yyyy hh:mm a" [(ngModel)]="NewDowntime.EndTime" nzPlaceHolder="End">
                        </nz-date-picker>
                      </div>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="8" nzRequired>Start Time</nz-form-label>
                        <nz-form-control [nzSpan]="14" nzErrorTip="Enter Start Time">
                            <nz-date-picker class="form-select mb-2" [nzDisabledDate]="disabledStartDate" nzShowTime nzFormat="dd-MM-yyyy hh:mm a" name="StartTime" nzSize="default"
                                nzPlaceHolder="Choose Start Time" formControlName="StartTime" (nzOnOpenChange)="handleStartOpenChange($event)" [(ngModel)]="NewDowntime.StartTime"></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="8" nzRequired>End Time</nz-form-label>
                        <nz-form-control [nzSpan]="14" nzErrorTip="Enter End Time">
                            <nz-date-picker class="form-select mb-2" #endDatePicker [nzDisabledDate]="disabledEndDate" nzShowTime nzFormat="dd-MM-yyyy hh:mm a" name="EndTime" nzSize="default"
                                nzPlaceHolder="Choose End Time" formControlName="EndTime" [(ngModel)]="NewDowntime.EndTime"></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [nzSpan]="8" nzRequired>Comments</nz-form-label>
                        <nz-form-control [nzSpan]="14" nzErrorTip="Enter Comments">
                            <nz-textarea-count [nzMaxCharacterCount]="500">
                                <textarea nz-input formControlName="Comments" name="Comments"
                                    placeholder="Enter Comments"></textarea>
                            </nz-textarea-count>
                        </nz-form-control>
                    </nz-form-item>
                    <div class="text-center">
                        <button nz-button nzType="primary" [nzLoading]="isLoading" (click)="handleOk()">
                            Save
                        </button>
                    </div>
                </form>
            </ng-template>
        </nz-modal> -->
    </div>
</div>
<ng-template #totalTemplate let-total>Total {{ this.totalItemsCount }} items</ng-template>
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ProductionDowntimeReasonModel, ProductionDowntimeModel } from '../../Models/ProductionDowntime';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';
import { Router } from '@angular/router';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Responsibility } from 'src/PmsUIApp/Models/Enums';
import { Modules } from 'src/PmsUIApp/Models/Enums';
import { Validators } from '@angular/forms';
import moment from 'moment';
import { LoadingService } from 'src/PmsUIApp/Services/loadingService';
import { PrdDowntimeService } from 'src/PmsUIApp/Features/PrdDowntime/services/PrdDowntimeService';
import { Subscription } from 'rxjs';
import { DatetimeConverter } from 'src/PmsUIApp/Services/DatetimeConverter.pipe';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';

@Component({
  selector: 'app-production-downtime-list',
  templateUrl: './production-downtime-list.component.html',
  styleUrls: ['./production-downtime-list.component.css']
})
export class ProductionDowntimeListComponent implements OnInit, OnDestroy {
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  ProductionDowntimeList: ProductionDowntimeModel[] = [];
  ProductionDowntimeListOriginal: ProductionDowntimeModel[] = [];
  ProductionDowntimeReasonList: ProductionDowntimeReasonModel[] = [];
  ProductionDowntimeReasonListForFilter: ProductionDowntimeReasonModel[] = [];
  NewDowntime: ProductionDowntimeModel = new ProductionDowntimeModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Production Downtime";
  nameError = 'Enter valid Production Downtime name between 3 to 50';
  shortNameError = 'Min 5, Max 5 characters';
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any
  searchValue = '';
  permission = {
    View: false,
    Add: false,
    Edit: false,
    Delete: false
  }
  pageSize = 10;
  pageIndex = 1;
  request = {
    ProductionDowntimeReasonId: 0,
    ProductionLineNo: 0,
    ProductionLineType: '',
    StartTime: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7)),
    EndTime: new Date(new Date().setHours(23, 59, 59)),
    IsExceededDuration: null,
    PageNo: this.pageIndex,
    PageSize: this.pageSize,
    TotalCount: 0
  }
  count: number = 0;
  totalItemsCount: number;
  isRecurring: boolean = false;
  enableCustomDateRange: boolean = false;
  ProductionLineNoList: any = [
    {
      "Text": "Line 1",
      "Value": 1
    },
    {
      "Text": "Line 2",
      "Value": 2
    },
    {
      "Text": "Line 3",
      "Value": 3
    },
    {
      "Text": "Line 4",
      "Value": 4
    },
    {
      "Text": "Line 5",
      "Value": 5
    }
  ];
  dateFilterOptions: any = [
    {
      "Text": "Day Shift (8AM to 8PM)",
      "Value": 'dayshift'
    },
    {
      "Text": "Night Shift (8PM to 8AM)",
      "Value": 'nightshift'
    },
    {
      "Text": "Today",
      "Value": 'today'
    },
    {
      "Text": "Yesterday",
      "Value": 'yesterday'
    },
    {
      "Text": "Last 7 Days",
      "Value": 'last7days'
    },
    {
      "Text": "Last 30 Days",
      "Value": 'last30days'
    },
    {
      "Text": "Last Month",
      "Value": 'lastmonth'
    },
    {
      "Text": "Last Year",
      "Value": 'lastyear'
    },
    {
      "Text": "Custom Range",
      "Value": 'custom'
    }
  ];
  ProductionLineTypeList = [
    { name: 'Manufacturing' },
    { name: 'Printing' },
    { name: 'Embossing' },
    { name: 'Lacquer' },
    { name: 'Vacuum' },
    { name: 'Packaging' },
  ];
  selecteddateFilter: string = 'today';
  previouslyselecteddateFilter: string = null;
  totalRecords: number = 0;
  
  prdDowntimeListRefreshSubscription: Subscription;
  constructor(private fb: UntypedFormBuilder, public http: HttpClient,
    private router: Router, private auth: AuthService,
    private alertService: AlertMessageService,
    private modalService: NzModalService,
    private loadingService: LoadingService,
    private prdDowntimeService: PrdDowntimeService
  ) {
  }



  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.ProductionDowntime, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.ProductionDowntime, Responsibility.Add);
    this.permission.Edit = this.auth.CheckResponsibility(Modules.ProductionDowntime, Responsibility.Edit);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.ProductionDowntime, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetAllProductionDowntime();
    this.prdDowntimeListRefreshSubscription = this.prdDowntimeService.refreshList$.subscribe(() => {
      this.GetAllProductionDowntime();
    });
  }
  ngOnDestroy() {
    this.prdDowntimeListRefreshSubscription.unsubscribe();
  }
  GetAllProductionDowntime() {
    this.isTableLoading = true;
    try {
      let url = this.ApiUrl + "production/getproductiondowntimelist";
      // let count = this.totalItemsCount = 0;
      this.http.post<any>(url, this.request).subscribe({
        next: res => {
          this.ProductionDowntimeList = res.ProductionDowntimeList;
          this.ProductionDowntimeListOriginal = res.ProductionDowntimeList;
          this.totalRecords = this.totalItemsCount = res.TotalRecords;
          this.ProductionDowntimeList?.forEach((x) => {
            x.StartTime = moment.utc(x.StartTime)
              .utcOffset('+05:30')
              .format('DD-MMM-yyyy hh:mm:ss A');
            x.EndTime = moment.utc(x.EndTime)
              .utcOffset('+05:30')
              .format('DD-MMM-yyyy, hh:mm:ss A');
          })
          this.ProductionDowntimeList.map((x, index) => {
            x.SerialNo = (this.pageIndex - 1) * this.pageSize + index + 1;
          })
          // this.totalItemsCount = count;
          this.isTableLoading = false;
        },
        error: res => {
          this.count++;
          if (this.count < 2) {
            this.GetAllProductionDowntime();
          }
        }
      });
    } catch (error) {
      this.isTableLoading = false;
      this.alertService.error(error.error);
    }
  }

  OpenEditPop(data: ProductionDowntimeModel) {
    this.prdDowntimeService.openModal(null, data);
  }

  DeleteReason(ProductionDowntimeId: number) {
    let url = this.ApiUrl + "production/deleteproductiondowntime/" + ProductionDowntimeId;
    this.http.get<any>(url).subscribe({
      next: res => {
        this.alertService.success(res); this.isLoading = this.isVisible = false;
        this.GetAllProductionDowntime();
      },
      error: res => { this.alertService.error(res.error); this.isLoading = this.isVisible = false; },

    });
  }
  handleDelete(ProductionDowntimeId: number) {
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this Production Downtime?',
      nzOnOk: () => this.DeleteReason(ProductionDowntimeId),
    });
    setTimeout(() => modal.destroy(), 5000);
  }
  openPrdDowntimeModal() {
    this.prdDowntimeService.openModal(null, null);
  }
  getDateRange(label: string) {
    this.enableCustomDateRange = false;
    const today = new Date();
    let startDate: Date;
    let endDate: Date;

    switch (label) {
      case 'dayshift':
        startDate = new Date(new Date(new Date().setHours(8, 0, 0)))
        endDate = new Date(new Date(new Date().setHours(20, 0, 0)))
        break;

      case 'nightshift':
        var currentHour = parseInt(new Date().getHours().toString());
        if (currentHour >= 0 && currentHour <= 8) {
          startDate = new Date(new Date(new Date().setHours(20, 0, 0)).setDate(new Date().getDate() - 1))
          endDate = new Date(new Date().setHours(8, 0, 0))
        }
        else {
          startDate = new Date(new Date().setHours(20, 0, 0))
          endDate = new Date(new Date(new Date().setHours(8, 0, 0)).setDate(new Date().getDate() + 1))
        }
        break;

      case 'today':
        startDate = new Date(new Date(new Date().setHours(0, 0, 0)).setDate(today.getDate()));
        endDate = new Date(new Date(new Date().setHours(23, 59, 59)).setDate(today.getDate()));
        break;

      case 'yesterday':
        startDate = new Date(new Date(new Date().setHours(0, 0, 0)).setDate(new Date().getDate() - 1));
        // startDate.setDate(today.getDate() - 1);
        endDate = new Date(new Date(new Date().setHours(23, 59, 59)).setDate(new Date().getDate() - 1));
        // endDate.setDate(today.getDate() - 1);
        break;

      case 'last7days':
        // startDate = new Date(today);
        // startDate.setDate(today.getDate() - 6);
        // endDate = new Date(today);
        startDate = new Date(new Date(new Date().setHours(0, 0, 0)).setDate(new Date().getDate() - 6));
        // startDate.setDate(today.getDate() - 1);
        endDate = new Date(new Date(new Date().setHours(23, 59, 59)).setDate(today.getDate()));
        break;

      case 'last30days':
        startDate = new Date(new Date(new Date().setHours(0, 0, 0)).setDate(new Date().getDate() - 29));
        endDate = new Date(new Date(new Date().setHours(23, 59, 59)).setDate(today.getDate()));
        break;

      case 'lastmonth':
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1, 0, 0, 0);
        endDate = new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59);
        break;

      case 'lastyear':
        startDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate(), 0, 0, 0);
        endDate = new Date(new Date(new Date().setHours(23, 59, 59)).setDate(today.getDate()));
        break;

      case 'custom':
        startDate = new Date(today);
        endDate = new Date(today);
        this.enableCustomDateRange = true;
        break;

      default:
        startDate = new Date();
        endDate = new Date();
        break;
    }

    this.request.StartTime = startDate;
    this.request.EndTime = endDate;

  }
  onFilterPanelOpen(event: boolean) {
    console.log('Filter panel open:', event);
  }
  GetAllProductionDowntimeReason() {
    try {
      let url = this.ApiUrl + "production/getproductiondowntimereasonlist";
      this.http.get<any>(url).subscribe({
        next: res => {
          this.ProductionDowntimeReasonListForFilter = res.ResponseBody;
        },
        error: res => {
          this.count++;
          if (this.count < 2) {
            this.GetAllProductionDowntimeReason();
          }
        }
      });
    } catch (error) {
      this.alertService.error(error.error);
    }
  }
  onPageIndexChange(event: number) {
    this.pageIndex = event;
    this.request.PageNo = event;
    this.GetAllProductionDowntime();
  }
  onPageSizeChange(event: number) {
    this.pageSize = event;
    this.pageIndex = 1; // Reset to first page when changing page size
    this.request.PageNo = 1;
    this.request.PageSize = event;
    this.GetAllProductionDowntime();
  }
  exportToCSV() {
    try {
      const exportData = this.ProductionDowntimeList.map(item => ({
        'S.No.': item.SerialNo,
        'Reason (Code)': `${item.ReasonName} (${item.ReasonCode})`,
        'PRD Line Type': item.ProductionLineType,
        'PRD Line No': item.ProductionLineNo,
        'Start Time': item.StartTime,
        'End Time': item.EndTime,
        'Actual Duration': item.ActualDurationFormatted,
        'Standard Duration': item.StandardDurationFormatted,
        'Excess Duration': item.ExcessDurationFormatted,
        'Comments': item.Comments,
        'Last Updated By': item.ModifiedBy ? item.ModifiedBy : item.CreatedBy,
        'Last Updated On': item.ModifiedOn ? 
          new DatetimeConverter().transform(item.ModifiedOn) : 
          new DatetimeConverter().transform(item.CreatedOn)
      }));

      const options = {
        headers: [
          'S.No.',
          'Reason (Code)',
          'PRD Line Type',
          'PRD Line No',
          'Start Time',
          'End Time',
          'Actual Duration',
          'Standard Duration',
          'Excess Duration',
          'Comments',
          'Last Updated By',
          'Last Updated On'
        ],
        nullToEmptyString: true,
        showLabels: true
      };

      const filename = `Production_Downtime_${moment().format('DDMMYYYY-HHmmss')}`;
      
      new AngularCsv(exportData, filename, options);
      
      this.alertService.success('Export completed successfully');
    } catch (error) {
      console.error('Export error:', error);
      this.alertService.error('Failed to export data');
    }
  }
}



<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Digital Weighing Machine</nz-page-header-title>
      <nz-page-header-subtitle>Knitting Fabric Stock Input</nz-page-header-subtitle>
      <nz-page-header-extra>
        <button nz-button nzType="primary" (click)="showWeightCaptureList()">Weight Capture List</button>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-divider></nz-divider>
    <div style="font-weight: bold;margin-bottom: 20px;">
      <p>Products in below list are by default filtered to Raw Product Type, Category Name "FABRIC" and then select
        sub-category from below buttons.</p>
    </div>

    <div class="subcategory-buttons">
      <button *ngFor="let category of subCategories" class="btn btn-lg"
        [class.btn-primary]="selectedCategory === category" [class.btn-light]="selectedCategory !== category"
        (click)="selectCategory(category)">
        {{category}}
      </button>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <nz-list class="weight-table" nzBordered nzSize="small">
          <nz-list-header>
            <div class="headerSection">
              <p class="mb-0">Fabric Product Names</p>
            </div>
          </nz-list-header>
          <div class="list-scroll-container">
            <nz-list-item *ngFor="let item of ProductList; let i = index"
              [ngClass]="{ 'isSelected': name == item.ProductName}" (click)="setSelected(item, i)">
              <div class="weightItems">
                <p>{{ item.ProductName }}</p>
              </div>
            </nz-list-item>
          </div>
        </nz-list>
      </div>
      <div class="col-lg-6" *ngIf="SelectedRow">
        <div class="viewdetails">
          <div style="font-size: larger;font-weight: bolder;">
            <span>
              Selected Product:
              <p style="font-weight: bolder;font-size: 1.5rem;">{{SelectedRow?.ProductName}} </p>
            </span>
            <span>
              <p>Knitting Bamboo Default Weight: {{KnittingBambooWeight}} Kgs</p>
            </span>
          </div>
          <nz-divider nzText="Device Connection"></nz-divider>
          <div>
            <button class="btn btn-sm btn-light-danger" (click)="captureWeight()">Capture Weight</button>
          </div>
          <div style="margin-top: 20px; font-size: larger;font-weight: bolder;" *ngIf="this.weightres.length > 0">
            <span>Weight Captured:- {{weightres[0]}} Kgs</span>
          </div>
          <div style="margin-top: 20px; font-size: larger;font-weight: bolder;" *ngIf="this.weightres.length > 0">
            <span>Fabric Weight:- {{weightres[0] - KnittingBambooWeight}} Kgs</span>
          </div>
          <div *ngIf="weightres.length > 0 && weightres[0] > 0">
            <nz-divider nzText="Save to Send Data"></nz-divider>
            <button class="btn btn-sm btn-primary" (click)="save(SelectedRow, weightres[0])">Save</button>
          </div>
        </div>
      </div>
    </div>
    <div style="font-weight: bold; color: red;margin-top: 20px;">
      <p>NOTE: The functionality of connecting to Weight Machine only works when you open this page on Factory Intranet.
        It will not work if you are not using a computer either on Factory Wired LAN or Factory WiFi.</p>
    </div>
  </div>
</div>

<nz-modal [nzWidth]="800" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isWeightCaptureListVisible"
    [nzTitle]="WeightCaptureListHeader" [nzContent]="WeightCaptureListContent"
    [nzFooter]="WeightCaptureListFooter" (nzOnCancel)="handleWeightCaptureListCancel()">
    <ng-template #WeightCaptureListHeader>Weight Capture List for today</ng-template>
    <ng-template #WeightCaptureListContent>
        <nz-table *ngIf="this.WeightCaptureList.length > 0" nzSize="small" [nzPageSize]="10" style="width: 100%"
            [nzScroll]="{ x: '800px', y: '515px' }" #basicTable [nzData]="this.WeightCaptureList"
            nzBordered nzShowPagination="true" nzShowPagination="true"
            nzShowSizeChanger [nzPageSizeOptions]=[10,50,100,200,500] [nzShowTotal]="WeightCaptureListTotalTemplate"
            nzShowQuickJumper="true" nzResponsive=true>
            <thead>
                <tr>
                    <th nzWidth="30px">S.No.</th>
                    <th nzWidth="100px">Product Name</th>
                    <th nzWidth="40px">Weight</th>
                    <th nzWidth="40px">Bamboo Weight</th>
                    <th nzWidth="60px">Added Date</th>
                    <th nzWidth="80px">Added By</th>                    
                    <th nzWidth="30px" nzRight>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of basicTable.data">
                    <td>{{ data.SerialNo }}</td>
                    <td>{{ data.ProductName }}</td>
                    <td>{{ data.Weight }} Kgs</td>
                    <td>{{ data.BambooRollWeightInKgs }} Kgs</td>
                    <td>{{ data.AddedDate | DatetimeConverter }}</td>
                    <td>{{ data.AddedBy }}</td>
                    <td nzRight>
                        <button nz-button nzType="primary" nzDanger nzShape="circle" *ngIf="permission.Delete"
                            (click)="handleDeleteWeightCapture(data.WeightCaptureId)" nz-tooltip
                            nzTooltipTitle="Delete" style="margin-left: 8px;">
                            <span nz-icon nzType="delete"></span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </nz-table>
        <div *ngIf="this.WeightCaptureList.length == 0">No Weight Capture Found</div>
    </ng-template>
    <ng-template #WeightCaptureListFooter>
        <button nz-button nzType="default" (click)="handleWeightCaptureListCancel()">Close</button>
    </ng-template>
</nz-modal>

<ng-template #WeightCaptureListTotalTemplate let-total>Total {{ this.WeightCaptureListTotalCount }} items</ng-template>
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DemandEventService {
    private refreshDemandList = new Subject<void>();
    refreshDemandList$ = this.refreshDemandList.asObservable();

    triggerRefresh() {
        this.refreshDemandList.next();
    }
}
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, HostListener } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CostingModel } from 'src/PmsUIApp/Models/CostingModel';

@Component({
  selector: 'app-CostingListPrint',
  templateUrl: './CostingListPrint.component.html',
  styleUrls: ['./CostingListPrint.component.css'],
})
export class CostingListPrintComponent implements OnInit {
  ApiUrl = environment.Api_Url;
  private route$: Subscription = new Subscription();
  count: 0;
  totalItemsCount: number;
  CostingListOriginal: CostingModel[];
  request = {
    FromAddedDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7)),
    ToAddedDate: new Date(new Date().setHours(23, 59, 59)),
    FromSaleOrderDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7)),
    ToSaleOrderDate: new Date(new Date().setHours(23, 59, 59)),
    FromDeliveryDate: new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7)),
    ToDeliveryDate: new Date(new Date().setHours(23, 59, 59)),
    SaleFormulationCodeId: 0,
    SaleOrderNumber: null,
    DateType: 'submitteddate', // options are - addeddate, saleorderdate, deliverydate
    AddedBy: '',
    ArticleName: '',
    CustomerId: 0,
    GrainId: 0,
    ColorId: 0,
    ProductType: '', //options are - GZ- or GZY-
    OrderType: '',
    CostingStatus: '',
    IsShowAll: false
  }
  GrandFinalTotalCost: number = 0;
  GrandTotalProfitLoss: number = 0;
  GrandTotalOrderQty: number = 0;
  GrandTotalManufacturingQty: number = 0;
  currentDate = new Date();
  isLoading: boolean = true;

  constructor(
    public http: HttpClient,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route$ = this.route.queryParams.subscribe(params => {
      this.request.AddedBy = params['AddedBy'];
      this.request.ArticleName = params['ArticleName'];
      this.request.ColorId = params['ColorId'];
      this.request.CostingStatus = params['CostingStatus'];
      this.request.CustomerId = params['CustomerId'];
      this.request.DateType = params['DateType'];
      this.request.FromAddedDate = params['FromAddedDate'];
      this.request.FromDeliveryDate = params['FromDeliveryDate'];
      this.request.FromSaleOrderDate = params['FromSaleOrderDate'];
      this.request.GrainId = params['GrainId'];
      this.request.OrderType = params['OrderType'];
      this.request.ProductType = params['ProductType'];
      this.request.SaleFormulationCodeId = params['SaleFormulationCodeId'];
      this.request.SaleOrderNumber = params['SaleOrderNumber'];
      this.request.ToAddedDate = params['ToAddedDate'];
      this.request.ToDeliveryDate = params['ToDeliveryDate'];
      this.request.ToSaleOrderDate = params['ToSaleOrderDate'];
      this.request.IsShowAll = true;
    });
    this.GetFilteredCosting();
  }
  GetFilteredCosting() {
    this.isLoading = true;
    let count = this.totalItemsCount = 0;
    let url = this.ApiUrl + 'costing/getfilteredcostinglist';
    this.http.post<any>(url, this.request).subscribe({
      next: res => {
        this.CostingListOriginal = res.ResponseBody.CostingListData;
        this.GrandFinalTotalCost = res.ResponseBody.GrandFinalTotalCost;
        this.GrandTotalProfitLoss = res.ResponseBody.GrandTotalProfitLoss;
        this.GrandTotalOrderQty = res.ResponseBody.GrandTotalOrderQty;
        this.GrandTotalManufacturingQty = res.ResponseBody.GrandTotalManufacturingQty;
        count = this.totalItemsCount;
        this.CostingListOriginal.forEach((x) => {
          count++;
          x.SerialNo = count;
        });
        this.totalItemsCount = count;
        setTimeout(function () {
          window.print();
          window.onafterprint = close;
          function close() {
            window.close();
          }
        }, 1)
        this.isLoading = false;
      },
      error: res => {
        this.isLoading = false;
        this.count++
        if (this.count < 2) {
          this.GetFilteredCosting();
        }
      }
    })
  }

  @HostListener('window:beforeprint')
  onBeforePrint() {
    // Any preparation before printing
  }

  @HostListener('window:afterprint')
  onAfterPrint() {
    // Any cleanup after printing
  }

  formatCurrency(value: number): string {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 2
    }).format(value);
  }
}

<!-- Loader -->
<div *ngIf="isLoading" class="loading-container">
    <nz-spin nzSize="large">
        <div class="loading-text">Loading Report Data...</div>
    </nz-spin>
</div>

<!-- Main Content -->
<div *ngIf="!isLoading && this.CostingListOriginal.length > 0" class="print-wrapper">
    <!-- Add Report Header -->
    <div class="report-header">
        <h2>Production Costing Report</h2>
        <div class="report-meta">
            <p>Generated On: {{currentDate.toString() | DatetimeConverter}}</p>
            <p>Total Records: {{CostingListOriginal.length}}</p>
        </div>
    </div>

    <div class="producttable-container">
        <table class="producttable">
            <thead>
                <tr>
                    <th nzWidth="2%">S.No.</th>
                    <th nzWidth="8%">Sale Order No.</th>
                    <th nzWidth="7%" style="text-align:center">Status</th>
                    <th nzWidth="19%">Customer Name</th>
                    <!-- <th nzWidth="20%">Sale Order Code</th> -->
                    <th nzWidth="10%">Alias</th>
                    <th nzWidth="5%">Order QTY</th>
                    <th nzWidth="5%">MFD QTY</th>
                    <th nzWidth="5%">Rejection %</th>
                    <th nzWidth="5%">Rejection Cost /LM</th>
                    <th nzWidth="5%">Fabric Total Cost</th>
                    <th nzWidth="5%">PRD Cost /LM</th>
                    <th nzWidth="5%">Overhead Cost /LM</th>
                    <th nzWidth="5%">Total Cost /LM</th>
                    <th nzWidth="5%">Final Total Cost</th>
                    <th nzWidth="5%">Profit/Loss Per LM</th>
                    <th nzWidth="5%">Total Profit/Loss</th>
                    <th nzWidth="7%">Submitted Date</th>
                    <th nzWidth="7%">Submitted By</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of CostingListOriginal">
                    <td>{{ data.SerialNo }}</td>
                    <td>{{ data.SaleOrderNumber }}</td>
                    <td [ngClass]="{
                        'status-partial': data.CostingStatus === 'Partial Ready',
                        'status-ready': data.CostingStatus === 'Ready',
                        'status-submitted': data.CostingStatus === 'Submitted'
                    }">{{data.CostingStatus}}</td>
                    <td nzBreakWord>{{ data.CustomerName }}</td>
                    <!-- <td style="width: 60px;">{{ data.SaleOrderCode }}</td> -->
                    <td nzBreakWord>{{ data.SaleOrderProduction.ManufacturingProductName }}</td>
                    <td>{{ data.SaleOrderProduction.OrderQuantity }} MTR</td>
                    <td>{{ data.SaleOrderProduction.ManufacturingQuantity }} MTR</td>
                    <td>{{ data.Rejection }} %</td>
                    <td>&#8377;{{ data.RejectionCostLm }}</td>
                    <td>&#8377;{{ data.FabricCost }}</td>
                    <td>&#8377;{{ data.ProductionCostLm }}</td>
                    <td>&#8377;{{ data.OverheadCost }}</td>
                    <td>&#8377;{{ data.TotalCostLm }}</td>
                    <td>&#8377;{{ data.FinalTotalCost | RoundOff}}</td>
                    <td>&#8377;{{ data.ProfitLossLm | RoundOff }}</td>
                    <td>&#8377;{{ data.TotalProfitLoss }}</td>
                    <td>{{ data.AddedDate | DatetimeConverter }}</td>
                    <td>{{ data.AddedBy }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr style="text-align: left;font-weight: bold;">
                    <td colspan="5" style="text-align: right;">Grand Total</td>
                    <td>{{GrandTotalOrderQty}} MTRs</td>
                    <td>{{GrandTotalManufacturingQty}} MTRs</td>
                    <td colspan="6"></td>
                    <td>&#8377;{{GrandFinalTotalCost}}</td>
                    <td></td>
                    <td>&#8377;{{GrandTotalProfitLoss}}</td>
                    <td colspan="2"></td>
                </tr>
            </tfoot>
        </table>
    </div>

    <!-- Add Report Footer -->
    <div class="report-footer">
        <div class="page-number"></div>
        <div class="report-summary">
            <p>Total Order Quantity: {{GrandTotalOrderQty}} MTRs</p>
            <p>Total Manufacturing Quantity: {{GrandTotalManufacturingQty}} MTRs</p>
            <p>Total Cost: ₹{{GrandFinalTotalCost}}</p>
            <p>Total Profit/Loss: ₹{{GrandTotalProfitLoss}}</p>
        </div>
    </div>
</div>

<!-- No Data Message -->
<div *ngIf="!isLoading && !this.CostingListOriginal?.length" class="no-data-container">
    <nz-empty nzDescription="No data available"></nz-empty>
</div>
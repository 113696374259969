import { Component, ElementRef, TemplateRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MeasureUnitModel } from 'src/PmsUIApp/Models/MeasureUnitModel';
import { environment } from 'src/environments/environment';
import { StockProductModel } from 'src/PmsUIApp/Models/StockProductModel';
import { SupplierModel } from 'src/PmsUIApp/Models/SupplierModel';
import { ProductModel } from 'src/PmsUIApp/Models/ProductModel';
import { IUploadProgressNew, StockModel } from 'src/PmsUIApp/Models/StockModel';
import { InvoiceModel } from 'src/PmsUIApp/Models/InvoiceModel';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { NzUploadFile, NzUploadChangeParam } from 'ng-zorro-antd/upload';
import { combineAll, combineLatestAll, from, map, Observable } from 'rxjs';
import { ISasToken } from 'src/PmsUIApp/azure-storage/azureStorage';
import { BlobStorageService } from 'src/PmsUIApp/azure-storage/blob-storage.service';
import { PurchaseOrderModel } from 'src/PmsUIApp/Models/PurchaseOrderModel';
import { ProductSecSubCategoryModel, ProductFirstSubCategoryModel, ProductCategoryModel } from '../../Models/MasterModel';
import * as moment from 'moment';
import { UserInfo } from 'src/PmsUIApp/Authentication/UserInfo';
import { any } from '@amcharts/amcharts5/.internal/core/util/Array';
import { Router } from '@angular/router';
import { Modules, Responsibility } from '../../Models/Enums';
import { AuthService } from '../../Services/auth.service';
import { StorageService } from 'src/PmsUIApp/Services/storage.service';

@Component({
  templateUrl: './AddStock.component.html',
  styleUrls: ['./AddStock.component.css']
})


export class AddStockComponent {

  MeasureUnits: MeasureUnitModel[] = [];
  SupplierList: SupplierModel[] = [];
  ProductList: ProductModel[] = [];
  NewStockProduct: StockProductModel = new StockProductModel;
  FilteredProductList: ProductModel[] = [];
  ApiUrl = environment.Api_Url;
  StockProductList: StockProductModel[] = [];
  SelectedProductType: string = '';
  NewStock: StockModel = new StockModel;
  NewInvoice: InvoiceModel = new InvoiceModel;
  InvoiceList: InvoiceModel[] = [];
  InvoiceListOriginal: InvoiceModel[] = [];
  isLoading: boolean = false;
  size: NzButtonSize = 'large';
  smallsize: NzButtonSize = 'small';
  uploadProgress: IUploadProgressNew[] = [];
  filesSelected = false;
  uploadinvoicefile: any;
  uploadinvoice: any;
  PurchaseOrderList: PurchaseOrderModel[] = [];
  FilteredPurchaseOrderList: PurchaseOrderModel[] = [];
  isVisible = false;
  isVisibleEdit = false;
  ProductSecSubCategoryList: ProductSecSubCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[] = [];
  ProductCategoryList: ProductCategoryModel[] = [];
  FilteredProductCategoryList: ProductCategoryModel[] = [];
  InvoiceListWithoutPO: InvoiceModel[] = [];
  FilteredInvoiceListWithoutPO: InvoiceModel[] = [];
  CategoryID: number = 0;
  FirstCategoryID: number = 0;
  SecondCategoryID: number = 0;
  selectedProduct: number = 0;
  IsPoGet = false;
  token: any;
  startValue: Date | null = null;
  endValue: Date | null = null;
  BatchNo: string = moment(new Date()).format('DD/MM/YYYY');
  isInvoiceEditEnabled = false;
  InvoiceFileName = '';
  InvoiceFileExtension = '';
  TempFileName: string = '';
  UpdatedInvoiceNo =
    {
      OldInvoiceNo: '',
      NewInvoiceNo: '',
      NewInvoiceId: 0,
      NewPO: 0
    }
  count: 0;
  permission = {
    View: false,
    Add: false,
    Delete: false
  }
  IsKnittingStock: boolean = false;
  constructor(public http: HttpClient, private alertService: AlertMessageService, private blobStorage: BlobStorageService, private auth: AuthService, private router: Router,
    public storageService: StorageService
  ) { }


  onSelectedProductTypeChange() {
    this.FilteredProductList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType);
  }

  onSelectedProductChange(data: any) {

    var fp = this.FilteredProductList.filter(x => x.ProductId == this.NewStockProduct.ProductId)[0];

    //this.NewStockProduct.Unit = fp.Unit;
    this.CategoryID = fp.ProductCategoryId;
    let url = this.ApiUrl + "productcategory/getallproductfirstsubcategories";
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(res => {
      this.ProductFirstSubCategoryList = res.filter(x => x.ProductCategoryId == this.CategoryID);
      this.FirstCategoryID = fp.ProductFirstSubCategoryId;
      let url = this.ApiUrl + "productcategory/getallproductsecsubcategories";
      this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(res => {
        this.ProductSecSubCategoryList = res.filter(x => x.ProductFirstSubCategoryId == this.FirstCategoryID);
        this.SecondCategoryID = fp.ProductSecSubCategoryId;

      }, res => { });


    }, res => { });
  }

  GetAllUnits() {
    let url = this.ApiUrl + "data/GetMeasureUnits";
    this.http.get<MeasureUnitModel[]>(url).subscribe(res => {
      this.MeasureUnits = res;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllUnits()
      }
    });
  }

  GetAllInvoices() {
    let url = this.ApiUrl + "stock/getallunstockedinvoices";
    this.http.get<InvoiceModel[]>(url).subscribe(res => {
      this.InvoiceList = res;

      this.InvoiceListOriginal = res;
      this.InvoiceListWithoutPO = res.filter(x => x.Poid == 0);
      this.FilteredInvoiceListWithoutPO = this.InvoiceListWithoutPO;
      // var re = this.InvoiceList.map(item => (item.SupplierId, item.SupplierName));
      //   var sup = this.InvoiceList.map((item: InvoiceModel): SupplierModel => ({
      //     SupplierId: item.SupplierId,
      //     SupplierName: item.SupplierName,
      //     ContactPersonName : '',
      //     Email : '',
      //     SupplierContactNumber : '',
      //     ContactPersonNumber : '',
      //     Address : '',
      //     Gst : ''
      //     }));
      //  this.SupplierList = Array.from(sup.reduce((m, t) => m.set(t.SupplierId, t), new Map()).values());
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllInvoices()
      }
    });
  }


  onSelectedInvoiceChange($event: any) {
    if ($event == null) {
      this.NewStock.Invoice = new InvoiceModel;
    }
    else {
      var res = this.InvoiceList.filter(x => x.InvoiceId == $event)[0];
      console.log(res)
      this.NewStock.Invoice.InvoiceId = res.InvoiceId;
      this.NewStock.Invoice.InvoiceNumber = res.InvoiceNumber;


      this.onSelectedPOChange(this.NewStock.Invoice.Poid)
    }
  }
  onSelectedGrnChange($event: any) {
    //var supplierid = this.InvoiceListOriginal.filter(x => x.Grn == $event)[0]?.InvoiceId;
    //console.log($event);
    //console.log(supplierid);
    //console.log(this.FilteredPurchaseOrderList);
    //if (supplierid) {
    //  this.NewStock.Invoice.InvoiceId = supplierid
    //  this.NewStock.Invoice.InvoiceNumber = this.InvoiceListOriginal.filter(x => x.Grn == $event)[0]?.InvoiceNumber;
    //  this.NewStock.Invoice.Poid = 0
    //  this.onSelectedInvoiceChange(supplierid)
    //} else {
    //  this.alertService.error("PO not found");
    //  this.NewStock.Invoice.Poid = 0;
    //  this.NewStock.Invoice.SupplierId = 0;
    //  //supplierid = this.PurchaseOrderList.filter(x => x.Grn == $event)[0].Poid;
    //  //this.NewStock.Invoice.Poid = supplierid
    //  //this.NewStock.Invoice.InvoiceId = 0;
    //  //this.NewStock.Invoice.InvoiceNumber = '';

    //}
    this.NewStock.Invoice.Poid = this.FilteredPurchaseOrderList.filter(x => x.Grn == $event)[0].Poid
    this.onSelectedPOChange(this.FilteredPurchaseOrderList.filter(x => x.Grn == $event)[0].Poid)


  }

  onSelectedSupplierChange($event: any) {
    if ($event == null) {
      this.InvoiceList = this.InvoiceListOriginal;
      this.FilteredPurchaseOrderList = this.PurchaseOrderList;
      this.FilteredInvoiceListWithoutPO = this.InvoiceListWithoutPO;
      this.NewStock.Invoice.Grn = '';
      this.NewStock.Invoice.Poid = 0;
    }
    else {
      var res = this.InvoiceListOriginal.filter(x => x.SupplierId == $event);
      this.InvoiceList = res;
      this.FilteredPurchaseOrderList = this.PurchaseOrderList.filter(x => x.SupplierId == $event);
      this.FilteredInvoiceListWithoutPO = this.InvoiceListWithoutPO.filter(x => x.SupplierId == $event);
    }

  }

  onSelectedPOChange($event: any) {
    var res = this.InvoiceListOriginal.filter(x => x.Poid == $event);
    this.InvoiceList = res;
    this.IsPoGet = false;
    this.NewStock.StockProduct = [];
    if (this.InvoiceList.length > 0) {
      this.NewStock.Invoice.InvoiceId = this.InvoiceList[0].InvoiceId;
      this.NewStock.Invoice.InvoiceNumber = this.InvoiceList[0].InvoiceNumber;
    }
    else
      this.NewStock.Invoice.InvoiceId = 0;
    this.NewStock.Invoice.SupplierId = this.FilteredPurchaseOrderList.filter(x => x.Poid == $event)[0]?.SupplierId;
    var price = parseFloat(this.FilteredPurchaseOrderList.filter(x => x.Poid == $event)[0]?.PototalAmount);
    this.NewStock.Invoice.InvoiceTotalPrice = isNaN(price) == true ? 0 : parseFloat(price.toFixed(2));
    this.NewStock.Invoice.Grn = this.FilteredPurchaseOrderList.filter(x => x.Poid == $event)[0]?.Grn;
    this.IsKnittingStock = this.FilteredPurchaseOrderList.filter(x => x.Poid == $event)[0]?.IsKnittingStock;
  }

  GetAllProducts() {
    let url = this.ApiUrl + "product/getallproducts";
    this.http.get<ProductModel[]>(url).subscribe(res => {
      this.ProductList = res;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllProducts()
      }
    });
  }

  GetAllPurchaseOrder() {
    let url = this.ApiUrl + "purchaseorder/getallpurchaseorders";
    this.http.get<PurchaseOrderModel[]>(url).subscribe(res => {
      res = res.filter(x => x.Status == 'Active');
      this.PurchaseOrderList = res.filter(
        x => x.IsPocomplete != true
      );
      this.FilteredPurchaseOrderList = this.PurchaseOrderList;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllPurchaseOrder();
      }
    });
  }

  AddNewProductRecord() {
    if (!this.ValidateProduct(this.NewStockProduct)) {
      return
    }

    if (this.selectedProduct == 0) {
      this.NewStockProduct.ProductType = this.SelectedProductType;
      this.NewStockProduct.ProductName = this.ProductList.filter(x => x.ProductId == this.NewStockProduct.ProductId)[0].ProductName;
      this.NewStockProduct.StockProductId = this.NewStock.StockProduct.length + 1;
      this.NewStock.StockProduct.push(this.NewStockProduct);
    }
    else {
      var index = this.NewStock.StockProduct.findIndex(x => x.StockProductId == this.selectedProduct);
      var selectedpro = this.NewStock.StockProduct[index];
      //selectedpro = this.NewStockProduct;

      selectedpro.ProductType = this.SelectedProductType;
      selectedpro.ProductName = this.ProductList.filter(x => x.ProductId == this.NewStockProduct.ProductId)[0].ProductName;
      selectedpro.ExpiryDate = this.NewStockProduct.ExpiryDate;
      selectedpro.ManufacturedDate = this.NewStockProduct.ManufacturedDate;
      selectedpro.Unit = this.NewStockProduct.Unit;
      selectedpro.Quantity = this.NewStockProduct.Quantity;
      selectedpro.PricePerUnit = this.NewStockProduct.PricePerUnit;
      selectedpro.ShippingHandlingPerUnit = this.NewStockProduct.ShippingHandlingPerUnit;
      selectedpro.FreightPerUnit = this.NewStockProduct.FreightPerUnit;
      selectedpro.MiscPerUnit = this.NewStockProduct.MiscPerUnit;
      selectedpro.InvoicePricePerUnit = this.NewStockProduct.InvoicePricePerUnit;
      selectedpro.AcceptedQuantity = this.NewStockProduct.AcceptedQuantity;
      selectedpro.Grade = this.NewStockProduct.Grade;
      selectedpro.Sku = this.NewStockProduct.Sku;
      selectedpro.ManufacturedDate = this.NewStockProduct.ManufacturedDate;
      selectedpro.ExpiryDate = this.NewStockProduct.ExpiryDate;
      selectedpro.Barcode = this.NewStockProduct.Barcode;
      this.selectedProduct = 0;
    }
    this.NewStockProduct = new StockProductModel;
    this.CalculateTotalShippingHandling(true);
    this.CalculateTotalFreight(true);
    this.CalculateTotalMisc(true);
    this.handleCancel();
  }

  RemoveStockProduct(item: StockProductModel) {
    var index = this.NewStock.StockProduct.indexOf(item);
    if (index !== -1) {
      this.NewStock.StockProduct.splice(index, 1);
    }
    this.CalculateGTotal();
  }


  selectMeausreUnit(unit: string) {
    this.NewStockProduct.Unit = unit;
  }

  async SaveStock() {
    if (!this.Validate(this.NewStock)) {
      return;
    }

    try {
      this.isLoading = true;

      // Check if file is selected
      if (!this.IsKnittingStock && (!this.uploadinvoicefile || this.uploadinvoicefile.length === 0)) {
        this.alertService.error("Please select an invoice file");
        this.isLoading = false;
        return;
      }

      // First upload the file
      try {
        if (!this.IsKnittingStock) {
          await this.fetchStorageTokenAndUpload();
        }

        // Only proceed with save if upload was successful
        let url = this.ApiUrl + "stock/addstock";
        this.http.post<StockModel>(url, this.NewStock).subscribe({
          next: res => {
            this.alertService.success("Stock added Successfully");
            this.isLoading = false;
            this.NewStockProduct = new StockProductModel();
            this.NewStock = new StockModel();
            this.NewStock.Invoice = new InvoiceModel();
            this.uploadinvoice = null;
            this.GetAllPurchaseOrder();
          },
          error: res => {
            this.alertService.error("An error has occurred. Please try again");
            this.isLoading = false;
          },
        });

      } catch (uploadError) {
        console.error('File upload failed:', uploadError);
        this.alertService.error("File upload failed. Please try again.");
        this.isLoading = false;
        return;
      }

    } catch (error) {
      console.error('Error in SaveStock:', error);
      this.alertService.error("An error occurred. Please try again");
      this.isLoading = false;
    }
  }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.AddStock, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.AddStock, Responsibility.Add);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.AddStock, Responsibility.Delete);
    if (this.permission.Add != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    this.GetAllUnits();
    this.GetAllProducts();
    this.GetAllInvoices();
    this.GetAllProductCategory();
    this.GetAllPurchaseOrder();
    this.GetAllSuppliers();
    this.NewStockProduct = new StockProductModel();
    this.NewStock = new StockModel();
    this.NewStock.Invoice = new InvoiceModel();
    this.NewStock.Invoice.InvoiceDate = this.NewStock.Invoice.EwayBillDate = this.NewStock.StockDate = new Date().toUTCString()
    // this.postInvoiceToken();
    this.disabledInvoiceEdit();
  }

  Validate(model: StockModel) {

    var Isvalid: boolean = true;
    if (model.Invoice.SupplierId <= 0) {
      this.alertService.error("Select Supplier");
      Isvalid = false;

    }
    else if (model.StockDate == '') {
      this.alertService.error("Select Stock Date"); Isvalid = false;
    }
    else if (!this.IsKnittingStock && (model.Invoice.InvoiceNumber == '' || model.Invoice.InvoiceId <= 0)) {
      this.alertService.error("Enter Invoice Number"); Isvalid = false;
    }
    else if (model.Invoice.InvoiceDate == '') {
      this.alertService.error("Select Invoice Date"); Isvalid = false;
    }
    // else if (model.Invoice.InvoiceTotalPrice == 0) {
    //   this.alertService.error("Invoice Price is required"); Isvalid = false;
    // }
    else if (model.StockProduct.length <= 0) {
      this.alertService.error("Add min one product"); Isvalid = false;
    }
    else if (model.StockProduct.filter(x => x.AcceptedQuantity <= 0).length > 0) {
      this.alertService.error("Please review all stock"); Isvalid = false;
    }
    //else if (model.StockProduct.filter(x => x.ManufacturedDate == '').length > 0) {
    //  this.alertService.error("ManufacturedDate required in products"); Isvalid = false;
    //}
    //else if (model.StockProduct.filter(x => x.ExpiryDate == '').length > 0) {
    //  this.alertService.error("ExpiryDate required in products"); Isvalid = false;
    //}
    return Isvalid;
  }

  ValidateProduct(model: StockProductModel) {
    var Isvalid: boolean = true;
    if (this.SelectedProductType == "") { this.alertService.error("Select ProductType"); Isvalid = false; }
    else if (model.ProductId <= 0) { this.alertService.error("Select Product"); Isvalid = false; }
    //else if(model.Sku=="")
    //{this.alertService.error("Enter Product SKU");Isvalid=false;}
    //else if(model.Barcode=="")
    //{this.alertService.error("Enter Product Barcode");Isvalid=false;}
    //else if(model.ManufacturedDate.toString()=="")
    //{this.alertService.error("Select Manufactured Date");Isvalid=false;}
    //else if(model.ExpiryDate.toString()=="")
    //{this.alertService.error("Select Expiry Date");Isvalid=false;}
    else if (model.Unit.toString() == "") { this.alertService.error("Select Unit"); Isvalid = false; }
    else if (model.AcceptedQuantity <= 0) { this.alertService.error("Enter Recieved Quantity"); Isvalid = false; }
    else if (model.Quantity <= 0) { this.alertService.error("Enter Quantity"); Isvalid = false; }
    else if (model.PricePerUnit <= 0) { this.alertService.error("Enter Per Unit Price"); Isvalid = false; }
    //else if (model.AcceptedQuantity > model.Quantity) {
    //this.alertService.error("Accepted Quantity cannot be mor than " + model.Quantity); Isvalid = false;
    //}
    return Isvalid;
  }


  onChange(event: any): void {
    this.filesSelected = true;
    this.uploadinvoicefile = event.target.files;
    // this.uploadProgress$ = from(event.target.files as FileList).pipe(
    //   map(file => this.uploadFile(file)),
    //   combineAll()
    // );
  }

  // oninvoiceupload() {
  //   this.fetchStorageToken();
  //   this.uploadProgress$ = from(this.uploadinvoicefile as FileList).pipe(
  //     map(file => this.uploadFile(file)),
  //     combineAll()
  //   );
  // }
  // postInvoiceToken(): any {
  //   let url = this.ApiUrl + "data/getstoragetokenforinvoice";
  //   this.http.get<any>(url).subscribe(res => {

  //     this.token = res;
  //   }, res => { this.postInvoiceToken(); });
  // }
  private fetchStorageTokenAndUpload(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.uploadinvoicefile || this.uploadinvoicefile.length === 0) {
        reject(new Error('No file selected'));
        return;
      }

      this.storageService.getStorageSASToken('invoices')
        .subscribe({
          next: async (token: any) => {
            try {
              // Clear previous progress
              this.uploadProgress = [];

              // Upload each file
              for (let i = 0; i < this.uploadinvoicefile.length; i++) {
                const file = this.uploadinvoicefile[i];
                await this.uploadFile(file, token);
              }
              resolve();
            } catch (error) {
              reject(error);
            }
          },
          error: (error) => reject(error)
        });
    });
  }
  private uploadFile(file: File, token: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const url = this.generateBlobUrl(file, token);

      xhr.open('PUT', url, true);
      xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded * 100) / event.total);
          this.updateProgress(file.name, progress, 'active');
        }
      };

      xhr.onload = () => {
        if (xhr.status === 201) {
          this.updateProgress(file.name, 100, 'success');
          resolve();
        } else {
          this.updateProgress(file.name, 100, 'exception');
          reject(new Error(`Upload failed with status: ${xhr.status}`));
        }
      };

      xhr.onerror = () => {
        this.updateProgress(file.name, 100, 'exception');
        reject(new Error('Upload failed'));
      };

      xhr.send(file);
    });
  }
  private generateBlobUrl(file: File, token: any): string {
    try {
      // Generate a unique filename using timestamp and original filename
      const timestamp = new Date().getTime();
      const originalName = file.name;
      const extension = originalName.split('.').pop();
      const dateFolder = moment().format('YYYY/MM/DD');

      this.InvoiceFileExtension = originalName.substring(originalName.lastIndexOf('.') + 1, originalName.length);

      this.InvoiceFileName = "invoices/" + dateFolder + "/POId_" + this.NewStock.Invoice.Poid + "_SupId_" + + this.NewStock.Invoice.SupplierId + '_InvId_' +
        this.NewStock.Invoice.InvoiceId + "." + this.InvoiceFileExtension;
      // Create the blob path
      const blobPath = this.InvoiceFileName;

      // Construct the full URL with SAS token
      const blobUrl = `${token.StorageAccountHost}/${blobPath}${token.StorageAccountToken}`;

      // Store the path for database reference
      this.NewStock.Invoice.InvoiceFile = blobPath;

      return blobUrl;
    } catch (error) {
      console.error('Error generating blob URL:', error);
      throw new Error('Failed to generate upload URL');
    }
  }
  private updateProgress(filename: string, progress: number, status: 'active' | 'success' | 'exception' = 'active'): void {
    const existingProgress = this.uploadProgress.find(p => p.filename === filename);
    if (existingProgress) {
      existingProgress.progress = progress;
      existingProgress.status = status;
    } else {
      this.uploadProgress.push({ filename, progress, status });
    }
  }
  handleOk(): void {

    this.isLoading = true;
    //this.Save();

  }

  handleCancel(): void {
    this.isVisible = false;
  }
  handleCancelEdit(): void {
    this.isVisibleEdit = false;
  }
  OpenEditInvoice() {
    console.log(this.NewStock.Invoice)
    if (this.NewStock.Invoice.InvoiceId > 0) {
      this.UpdatedInvoiceNo.OldInvoiceNo = this.UpdatedInvoiceNo.NewInvoiceNo = this.InvoiceList.filter(x => x.InvoiceId == this.NewStock.Invoice.InvoiceId)[0].InvoiceNumber;
      this.NewStock.Invoice.InvoiceNumber = this.UpdatedInvoiceNo.OldInvoiceNo;
      this.UpdatedInvoiceNo.NewInvoiceId = this.NewStock.Invoice.InvoiceId;
      this.isVisibleEdit = true;
    }
    else {
      //this.alertService.error("Select Invoice to Modify")
      this.UpdatedInvoiceNo.NewPO = this.NewStock.Invoice.Poid;
      this.isVisibleEdit = true;
    }
  }
  showModal(): void {
    this.isVisible = true;
    this.NewStockProduct = new StockProductModel;
    this.SelectedProductType = "";
    this.CategoryID = 0;
    this.FirstCategoryID = 0;
    this.SecondCategoryID = 0;
  }
  GetProductfromPO() {
    if (this.NewStock.Invoice.Poid > 0) {
      let url = this.ApiUrl + "purchaseorder/getpurchaseorderbyid/" + this.NewStock.Invoice.Poid;
      this.http.get<PurchaseOrderModel>(url).subscribe(res => {
        var productlist = res.PurchaseOrderProduct;
        if (productlist.length > 0) {
          //this.NewStock.StockProduct
          productlist.forEach(x => {

            var item = new StockProductModel;
            item.ProductId = x.ProductId;
            item.ProductName = x.ProductName;
            item.PricePerUnit = x.Rate;
            item.Quantity = x.Quantity;
            item.Unit = x.Unit;
            item.ProductType = this.ProductList.filter(y => y.ProductId == x.ProductId)[0].ProductType;
            item.StockProductId = this.NewStock.StockProduct.length + 1;
            item.Grade = x.Grade;
            this.NewStock.StockProduct.push(item);
            this.NewStock.Invoice.SubTotal += x.Rate * x.Quantity;
          });

          this.CalculateGTotal();
        }
        this.IsPoGet = true;
      })
    }
  }

  GetAllSuppliers() {
    let url = this.ApiUrl + "supplier/getallsuppliers";
    this.http.get<SupplierModel[]>(url).subscribe(res => {
      this.SupplierList = res;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllSuppliers()
      }
    });
  }

  GetAllProductCategory() {

    let url = this.ApiUrl + "productcategory/getallproductcategoriesforlisting";
    this.http.get<ProductCategoryModel[]>(url).subscribe(res => {
      this.ProductCategoryList = res;

    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllProductCategory()
      }
    });
  }

  GetAllFirstCategory(data: any, id: number = 0) {

    this.FilteredProductList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType && x.ProductCategoryId == this.CategoryID);

    let url = this.ApiUrl + "productcategory/getallproductfirstsubcategories";
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(res => {
      this.ProductFirstSubCategoryList = res.filter(x => x.ProductCategoryId == data);


    }, res => { });
  }
  GetAllSecondCategory(data: any, id: number = 0) {

    this.FilteredProductList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType && x.ProductFirstSubCategoryId == this.FirstCategoryID);

    let url = this.ApiUrl + "productcategory/getallproductsecsubcategories";
    this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(res => {
      this.ProductSecSubCategoryList = res.filter(x => x.ProductFirstSubCategoryId == data);


    }, res => { });
  }
  GetSecondCategoryFilteredProduct() {
    this.FilteredProductList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType && x.ProductSecSubCategoryId == this.SecondCategoryID);
  }
  EditProduct(data: any) {
    this.NewStockProduct.InvoicePricePerUnit = data.InvoicePricePerUnit <= 0 ? data.PricePerUnit : data.InvoicePricePerUnit;
    this.NewStockProduct.ProductType = this.SelectedProductType = data.ProductType;
    this.FilteredProductList = this.ProductList.filter(x => x.ProductType == data.ProductType);
    setTimeout(() => {
      this.NewStockProduct.ProductId = data.ProductId;
      this.onSelectedProductChange(data.ProductId);
      this.NewStockProduct.Quantity = data.Quantity;
      this.NewStockProduct.AcceptedQuantity = data.AcceptedQuantity;
      this.NewStockProduct.PricePerUnit = data.PricePerUnit;
      this.NewStockProduct.ShippingHandlingPerUnit = data.ShippingHandlingPerUnit;
      this.NewStockProduct.FreightPerUnit = data.FreightPerUnit;
      this.NewStockProduct.MiscPerUnit = data.MiscPerUnit;
      this.NewStockProduct.Unit = data.Unit;
      this.NewStockProduct.Grade = data.Grade;
      this.NewStockProduct.Sku = data.Sku;
      this.NewStockProduct.ManufacturedDate = data.ManufacturedDate;
      this.NewStockProduct.ExpiryDate = data.ExpiryDate;
      this.NewStockProduct.Barcode = data.Barcode;
      this.selectedProduct = data.StockProductId;


    }, 0);


    this.isVisible = true;

  }
  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.NewStockProduct.ExpiryDate) {
      return false;
    }
    return startValue.getTime() > new Date(this.NewStockProduct.ExpiryDate).getTime();
  };
  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.NewStockProduct.ManufacturedDate) {
      return false;
    }
    return endValue.getTime() <= new Date(this.NewStockProduct.ManufacturedDate).getTime();
  };
  UpdateInvoice() {
    console.log('InvoiceList', this.InvoiceList)
    console.log('UpdatedInvoiceNo', this.UpdatedInvoiceNo)
    if (this.UpdatedInvoiceNo.OldInvoiceNo == '') {
      this.NewStock.Invoice.InvoiceNumber = this.UpdatedInvoiceNo.NewInvoiceNo;

      var inv: InvoiceModel = new InvoiceModel;
      inv.InvoiceId = this.UpdatedInvoiceNo.NewInvoiceId > 0 ? this.UpdatedInvoiceNo.NewInvoiceId : - 1;
      inv.InvoiceNumber = this.NewStock.Invoice.InvoiceNumber
      this.InvoiceList.push(inv)
      this.NewStock.Invoice.InvoiceId = inv.InvoiceId;

    } else {
      this.InvoiceList.filter(x => x.InvoiceNumber == this.UpdatedInvoiceNo.OldInvoiceNo)[0].InvoiceNumber = this.UpdatedInvoiceNo.NewInvoiceNo;

      this.NewStock.Invoice.InvoiceNumber = this.UpdatedInvoiceNo.NewInvoiceNo;


    }
    this.NewStock.Invoice.Poid = this.UpdatedInvoiceNo.NewPO
    this.IsPoGet = false;
    this.NewStock.StockProduct = [];
    this.handleCancelEdit()
  }
  UpdateInvoiceNo(data: any) {
    console.log(data)
    if (data > 0) {
      this.UpdatedInvoiceNo.NewInvoiceNo = this.InvoiceListWithoutPO.filter(x => x.InvoiceId == data)[0].InvoiceNumber;
    }
  }
  CalculateGTotal(): void {
    try {
      // Get base values, defaulting to 0 if undefined/null
      const subtotal = this.calculatesubtotal();
      const gst = Number(this.NewStock.Invoice.GST) || 0;
      const freightInsurance = Number(this.NewStock.Invoice.FreightInsurance) || 0;
      const shippingHandling = Number(this.NewStock.Invoice.ShippingHandling) || 0;
      const otherCharges = Number(this.NewStock.Invoice.OtherCharges) || 0;

      // Calculate total with GST
      this.NewStock.Invoice.InvoiceTotal = Number(
        (subtotal + gst + freightInsurance + shippingHandling + otherCharges).toFixed(2)
      );

      // Calculate total without GST
      this.NewStock.Invoice.InvoiceTotalPrice = Number(
        (subtotal + freightInsurance + shippingHandling + otherCharges).toFixed(2)
      );
    } catch (error) {
      console.error('Error calculating grand total:', error);
      this.NewStock.Invoice.InvoiceTotal = 0;
      this.NewStock.Invoice.InvoiceTotalPrice = 0;
    }
  }
  CalculateTotalShippingHandling(isPerUnitUpdate: boolean = false) {
    try {
      // Calculate total accepted quantity
      const totalAcceptedQty = this.NewStock.StockProduct.reduce((sum, product) => {
        return sum + (product.AcceptedQuantity || 0);
      }, 0);

      if (isPerUnitUpdate) {
        // When per unit rates are updated, calculate total
        const totalAmount = this.NewStock.StockProduct.reduce((accumulator, obj) => {
          return accumulator + ((obj.AcceptedQuantity || 0) * (obj.ShippingHandlingPerUnit || 0));
        }, 0);

        this.NewStock.Invoice.ShippingHandling = parseFloat(totalAmount.toFixed(2));
      } else {
        // When total amount is changed, update per unit rates
        if (totalAcceptedQty > 0) {
          const newPerUnitRate = this.NewStock.Invoice.ShippingHandling / totalAcceptedQty;

          // Update all products with new per unit rate
          this.NewStock.StockProduct.forEach(product => {
            product.ShippingHandlingPerUnit = parseFloat(newPerUnitRate.toFixed(2));
          });
        }
      }
    } catch (error) {
      console.error('Error calculating shipping/handling:', error);
      // Reset values on error
      this.NewStock.Invoice.ShippingHandling = 0;
      this.NewStock.StockProduct.forEach(product => {
        product.ShippingHandlingPerUnit = 0;
      });
    }

    this.CalculateGTotal();
  }
  CalculateTotalFreight(isPerUnitUpdate: boolean = false) {
    try {
      // Calculate total accepted quantity
      const totalAcceptedQty = this.NewStock.StockProduct.reduce((sum, product) => {
        return sum + (product.AcceptedQuantity || 0);
      }, 0);

      if (isPerUnitUpdate) {
        // When per unit rates are updated, calculate total
        const totalAmount = this.NewStock.StockProduct.reduce((accumulator, obj) => {
          return accumulator + ((obj.AcceptedQuantity || 0) * (obj.FreightPerUnit || 0));
        }, 0);

        this.NewStock.Invoice.FreightInsurance = parseFloat(totalAmount.toFixed(2));
      } else {
        // When total amount is changed, update per unit rates
        if (totalAcceptedQty > 0) {
          const newPerUnitRate = this.NewStock.Invoice.FreightInsurance / totalAcceptedQty;

          // Update all products with new per unit rate
          this.NewStock.StockProduct.forEach(product => {
            product.FreightPerUnit = parseFloat(newPerUnitRate.toFixed(2));
          });
        }
      }
    } catch (error) {
      console.error('Error calculating freight:', error);
      // Reset values on error
      this.NewStock.Invoice.FreightInsurance = 0;
      this.NewStock.StockProduct.forEach(product => {
        product.FreightPerUnit = 0;
      });
    }
    this.CalculateGTotal();
  }
  CalculateTotalMisc(isPerUnitUpdate: boolean = false) {
    try {
      // Calculate total accepted quantity
      const totalAcceptedQty = this.NewStock.StockProduct.reduce((sum, product) => {
        return sum + (product.AcceptedQuantity || 0);
      }, 0);

      if (isPerUnitUpdate) {
        // When per unit rates are updated, calculate total
        const totalAmount = this.NewStock.StockProduct.reduce((accumulator, obj) => {
          return accumulator + ((obj.AcceptedQuantity || 0) * (obj.MiscPerUnit || 0));
        }, 0);

        this.NewStock.Invoice.OtherCharges = parseFloat(totalAmount.toFixed(2));
      } else {
        // When total amount is changed, update per unit rates
        if (totalAcceptedQty > 0) {
          const newPerUnitRate = this.NewStock.Invoice.OtherCharges / totalAcceptedQty;

          // Update all products with new per unit rate
          this.NewStock.StockProduct.forEach(product => {
            product.MiscPerUnit = parseFloat(newPerUnitRate.toFixed(2));
          });
        }
      }
    } catch (error) {
      console.error('Error calculating misc:', error);
      // Reset values on error
      this.NewStock.Invoice.OtherCharges = 0;
      this.NewStock.StockProduct.forEach(product => {
        product.MiscPerUnit = 0;
      });
    }
    this.CalculateGTotal();
  }

  calculatesubtotal(): number {
    try {
      // Calculate subtotal from all products
      const subtotal = this.NewStock.StockProduct.reduce((accumulator, product) => {
        const quantity = Number(product.AcceptedQuantity) || 0;
        const price = Number(product.InvoicePricePerUnit) || 0;
        return accumulator + (quantity * price);
      }, 0);

      return Number(subtotal.toFixed(2));
    } catch (error) {
      console.error('Error calculating subtotal:', error);
      return 0;
    }
  }
  disabledInvoiceEdit() {
    if (UserInfo.UserRolesMaster.filter(x => x.UserRoleName.toLowerCase() == 'admin')) {
      this.isInvoiceEditEnabled = true;
    }
    else {
      this.isInvoiceEditEnabled = false;
    }
  };
  calculateTotalPrice(): void {
    try {
      // Default values to 0 if undefined/null
      const shippingHandlingPerUnit = Number(this.NewStockProduct.ShippingHandlingPerUnit) || 0;
      const freightPerUnit = Number(this.NewStockProduct.FreightPerUnit) || 0;
      const miscPerUnit = Number(this.NewStockProduct.MiscPerUnit) || 0;
      const invoicePricePerUnit = Number(this.NewStockProduct.InvoicePricePerUnit) || 0;

      // Calculate total with precision handling
      const total = Number((shippingHandlingPerUnit + freightPerUnit + miscPerUnit + invoicePricePerUnit).toFixed(2));

      // Update the total price
      this.NewStockProduct.PricePerUnit = total;
    } catch (error) {
      console.error('Error calculating total price:', error);
      // Set default value in case of error
      this.NewStockProduct.PricePerUnit = 0;
    }
  }
}
